import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-object-viewer',
    templateUrl: './object-viewer.component.html',
    styleUrls: ['./object-viewer.component.scss']
})
export class ObjectViewerComponent implements OnInit {
    @Input() object: any;
    @Input() class: string;

    ngOnInit(): void {
        
    }

    isPrimitive(value: any): boolean {
        return value !== Object(value);
    }

    isArray(value: any): boolean {
        return Array.isArray(value);
    }

    isObject(value: any): boolean {
        return typeof value === 'object' && !Array.isArray(value) && value !== null;
    }

    getObjectKeys(obj: any): string[] {
        return Object.keys(obj).filter(key => !key.startsWith('pointHealth') || !this.isArray(obj[key]));
    }

    getNameForKey(key: string): string {
        switch (key) {
            case 'entityTypeCode': return 'Entity';
            case 'processedToday': return 'Processed Today';
            case 'unprocessedCount': return 'Unprocessed';
            case 'temporarilyFailingCount': return 'Temporarily Failing';
            case 'permanentlyFailedCount': return 'Permanently Failed';
            case 'orphanedStuckCount': return 'Stuck Processing';
            case 'sameObjectChangeCount': return 'Deleted Duplicates';
            case 'totalItemCount': return 'Total Messages';
            case 'totalProcessedCount': return 'Total Processed';
            default: return key;
        }
    }
}
