<div class="row">
	<div class="col-24">
		<ng-container *ngIf="!memberPlans || memberPlans?.length == 0">
			<app-empty class="bg-light rounded" [message]="'no plans'"></app-empty>
		</ng-container>

		<ng-container *ngIf="memberPlans && memberPlans.length > 0">
			<table class="table table-hover small">
				<thead class="table-light">
					<tr>
						<th>Status</th>
						<th>Plan</th>
						<th class="text-nowrap text-center">Plan Dates</th>
						<th class="text-nowrap text-center">Coverage Type</th>
						<th class="text-nowrap">Group</th>
						<th class="text-nowrap text-center">Copay</th>
						<th style="width: 1px;"></th>
					</tr>
				</thead>

				<tbody>
					<ng-container *ngFor="let memberPlan of memberPlans">
						<tr class="align-top">
							<td class="text-center px-2">
								<div class="badge text-bg-success rounded-pill w-100" *ngIf="memberPlan.isActive">Active</div>
								<div class="badge text-bg-light rounded-pill w-100" *ngIf="!memberPlan.isActive">Terminated</div>
							</td>

							<td>
								<div>
									<app-client-plan-value clientName="{{ memberPlan.clientName }}" planName="{{ memberPlan.planName }}" clientLogoSrc="{{ memberPlan.clientLogoUrl }}"></app-client-plan-value>
									<span class="badge rounded-pill text-bg-primary ms-1" *ngIf="!memberPlan.primaryClientMemberID">Primary</span>
								</div>
								<!-- non-primary - show primary info -->
								<div *ngIf="memberPlan.primaryClientMemberID">
									Dependent ({{ memberPlan.primaryRelationshipName }}) of <a [routerLink]="['../', memberPlan.primaryClientMemberID]">{{ memberPlan.primaryMemberName }}</a>
								</div>
							</td>

							<td class="text-center">
								<div class="text-nowrap">
									From {{ memberPlan.effectiveDate | date:'M/dd/yyyy' }}
								</div>

								<div *ngIf="memberPlan.terminationDate" class="text-nowrap">
									to {{ memberPlan.terminationDate | date:'M/d/yyyy' }}
								</div>
							</td>

							<td class="text-center" [class.value-empty]="!memberPlan.coverageTypeName">
								{{ memberPlan.coverageTypeName || 'empty' }}
							</td>

							<td>
								<ng-container *ngIf="memberPlan.groupName">
									{{ memberPlan.groupName }}
								</ng-container>

								<ng-container *ngIf="memberPlan.groupID">
									<span class="text-muted">({{ memberPlan.groupID }})</span>
								</ng-container>
							</td>

							<td class="text-center px-0" [class.value-empty]="!memberPlan.copay">
								{{ memberPlan.copay ? (memberPlan.copay | currency:'USD') : 'empty' }}
							</td>

							<td class="text-center px-0">
								<div dropdown class="btn-group">
									<button dropdownToggle type="button" class="btn btn-icon-only px-2">
										<fa-icon [icon]="faEllipsisVertical"></fa-icon>
									</button>

									<div *dropdownMenu class="dropdown-menu dropdown-menu-end">
										<button type="button" class="dropdown-item" (click)="onEditMemberPlan(memberPlan)">Edit</button>
										<ng-container *ngIf="memberPlan.canManuallyDeactivateMembers && !memberPlan.terminationDate">
											<button class="dropdown-item text-danger" (click)="onDeactivateMemberPlan(memberPlan)">Deactivate</button>
										</ng-container>
									</div>
								</div>
							</td>
						</tr>

						<ng-container *ngIf="memberPlan.editing">
							<tr>
								<td class="px-0 py-3" colspan="7">
									<div class="card bg-light">
										<div class="card-body">
											<app-member-plans-form [clientMemberID]="clientMemberID" [model]="memberPlan" (save)="onReloadMemberPlans()" (close)="onMemberPlanEditClosed()"></app-member-plans-form>
										</div>
									</div>
								</td>
							</tr>
						</ng-container>
					</ng-container>
				</tbody>
			</table>
		</ng-container>
	</div>
</div>
