<div *ngIf="showProviderSelect" class="row mb-3">
	<label class="col-9 col-form-label" for="provider">
		Provider
	</label>

	<div class="col-15">
		<app-provider-input [providerType]="'Individual'"
							[zipCode]="searchNearZipCode"
							[(providerName)]="providerName"
							(providerNameChange)="providerNameChange.emit($event)"
							(providerChange)="onProviderChange($event)"
							[placeholder]="'Provider'">
		</app-provider-input>
	</div>
</div>

<hr *ngIf="showProviderSelect" />

<div class="row mb-3">
	<label class="col-9 col-form-label" for="facility">
		Facility
	</label>

	<div class="col-15">
		<app-provider-input [providerType]="'Organization'"
							[zipCode]="searchNearZipCode"
							[(providerName)]="facilityName"
							(providerNameChange)="facilityNameChange.emit($event)"
							(providerChange)="onFacilityChange($event)"
							[placeholder]="'Facility'">
		</app-provider-input>
	</div>
</div>

<hr *ngIf="!showProviderSelect" />

<div class="row mt-3">
	<label class="col-9 col-form-label" for="facilityName">
		Location Address
	</label>

	<div class="col-15">
		<div class="row mb-2">
			<div class="col-24">
				<input type="text" class="form-control" id="facilityName" name="facilityName" [(ngModel)]="facilityName" (ngModelChange)="facilityNameChange.emit($event)" autocomplete="off" placeholder="Location name" />
			</div>
		</div>

		<div class="row mb-2">
			<div class="col-24">
				<input type="text" class="form-control" name="facilityAddress1" [(ngModel)]="address1" (ngModelChange)="address1Change.emit($event)" autocomplete="off" placeholder="Address 1" />
			</div>
		</div>

		<div class="row mb-2">
			<div class="col-24">
				<input type="text" class="form-control" name="facilityAddress2" [(ngModel)]="address2" (ngModelChange)="address2Change.emit($event)" autocomplete="off" placeholder="Address 2" />
			</div>
		</div>

		<div class="row mb-2">
			<div class="col-24">
				<input type="text" class="form-control" name="facilityCity" [(ngModel)]="city" (ngModelChange)="cityChange.emit($event)" autocomplete="off" placeholder="City" />
			</div>
		</div>

		<div class="row g-2">
			<div class="col-16">
				<ng-select class="custom-ng-select"
						   name="facilityStateCode"
						   [items]="states"
						   [bindLabel]="'stateName'"
						   [bindValue]="'stateCode'"
						   [(ngModel)]="stateCode"
						   (ngModelChange)="stateCodeChange.emit($event)"
						   [clearable]="true"
						   [multiple]="false"
						   [searchable]="true"
						   [loading]="loadingStates"
						   [loadingText]="'loading states...'"
						   [notFoundText]="'no state matching your search'"
						   [placeholder]="'State'">
				</ng-select>
			</div>

			<div class="col-8">
				<app-zipcode-input [name]="'facilityZipCode'"
								   [(ngModel)]="zipCode"
								   (ngModelChange)="zipCodeChange.emit($event)">
				</app-zipcode-input>
			</div>
		</div>
	</div>
</div>

<div class="row mt-3">
	<label class="col-9 col-form-label" for="phoneNumber">
		Phone Number
	</label>

	<div class="col-8">
		<app-phone-input [id]="'facilityPhoneNumber'"
						 [name]="'facilityPhoneNumber'"
						 [(ngModel)]="phoneNumber"
						 (ngModelChange)="phoneNumberChange.emit($event)">
		</app-phone-input>
	</div>
</div>

<div class="row mt-3">
	<label class="col-9 col-form-label" for="faxNumber">
		Fax Number
	</label>

	<div class="col-8">
		<app-phone-input [id]="'facilityFaxNumber'"
						 [name]="'facilityFaxNumber'"
						 [(ngModel)]="faxNumber"
						 (ngModelChange)="faxNumberChange.emit($event)">
		</app-phone-input>
	</div>
</div>

<div class="row mt-3">
	<label class="col-9 col-form-label" for="emailAddress">
		Email Address
	</label>

	<div class="col-15">
		<input type="email"
			   class="form-control"
			   id="providerEmailAddress"
			   name="providerEmailAddress"
			   [(ngModel)]="emailAddress"
			   (ngModelChange)="emailAddressChange.emit($event)"
			   autocomplete="off"
			   placeholder="Email Address" />
	</div>
</div>
