import { Component, OnInit } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';

import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';

@Component({
	templateUrl: 'file-delete-modal.component.html',
	styleUrls: ['file-delete-modal.component.scss']
})
export class FileDeleteModalComponent implements OnInit {
	faSpinnerThird = faSpinnerThird;

	fileName: string;
	delete: () => Promise<boolean>;
	deleted: boolean = false;
	deleting: boolean = false;

	constructor(
		private modalRef: BsModalRef
	) {
	}

	ngOnInit() {
	}

	async onDelete() {
		try {
			this.deleting = true;
			this.deleted = await this.delete();

			if (this.deleted) {
				this.onClose();
			}
		} finally {
			this.deleting = false;
		}
	}

	async onClose() {
		this.modalRef.hide();
	}
}
