import { Component, Input, ContentChildren, QueryList } from '@angular/core';

import { StackedBarChartValueComponent } from './stacked-bar-chart-value.component';

@Component({
	selector: 'app-stacked-bar-chart',
	templateUrl: 'stacked-bar-chart.component.html',
	styleUrls: ['stacked-bar-chart.component.scss']
})
export class StackedBarChartComponent {
	@Input()
	total: string;

	@ContentChildren(StackedBarChartValueComponent)
	values: QueryList<StackedBarChartValueComponent>;

	@Input()
	height: number = 15;
}
