import { Component, ViewEncapsulation, ChangeDetectionStrategy, OnInit, ChangeDetectorRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { FileViewModel } from '@app/shared/models';
import { FilesService } from '@app/shared/services';

import {
	faCaretLeft,
	faCaretRight,
} from '@fortawesome/pro-solid-svg-icons';

import {
	faPencil,
	faTrash,
	faPlus,
	faFileCirclePlus,
	faCrop,
	faMaximize,
	faMagnifyingGlassPlus,
	faMagnifyingGlassMinus,
	faRotateLeft,
	faRotateRight,
	faArrowLeftFromLine,
	faArrowDownFromLine,
	faArrowsRotate,
	faFileDownload
} from '@fortawesome/pro-regular-svg-icons';

@Component({
	selector: 'app-file-viewer',
	templateUrl: 'file-viewer.component.html',
	styleUrls: ['file-viewer.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileViewerComponent implements OnInit {
	faPencil = faPencil;
	faTrash = faTrash;
	faPlus = faPlus;
	faCaretLeft = faCaretLeft;
	faCaretRight = faCaretRight;
	faFileCirclePlus = faFileCirclePlus;
	faCrop = faCrop;
	faMaximize = faMaximize;
	faMagnifyingGlassPlus = faMagnifyingGlassPlus;
	faMagnifyingGlassMinus = faMagnifyingGlassMinus;
	faRotateLeft = faRotateLeft;
	faRotateRight = faRotateRight;
	faArrowLeftFromLine = faArrowLeftFromLine;
	faArrowDownFromLine = faArrowDownFromLine;
	faArrowsRotate = faArrowsRotate;

	downloadIcon = faFileDownload;

	title: string = '';
	files: FileViewerFile[] = [];

	selectedFile: FileViewerFile;
	zoomLevel: string | number = 100;
	minZoomLevel: number = 30;
	maxZoomLevel: number = 300;
	currentPage: number = 1;
	totalPages: number = 0;
	overlayMessage: string = '';

	downloadingFile = false;

	showEdit: boolean = true;
	showDelete: boolean = true;

	// @ViewChild('fileBrowser', { static: false })
	// fileBrowser: ElementRef<HTMLInputElement>;

	openFile: (file: FileViewerFile, callback: Function) => void;
	renameFile: (file: FileViewerFile, callback: (newName: string) => void) => void;
	deleteFile: (file: FileViewerFile, callback: Function) => void;

	constructor(
		private http: HttpClient,
		private cdr: ChangeDetectorRef,
		public modalRef: BsModalRef,
		private toastrService: ToastrService,
		private filesService: FilesService
	) {
	}

	ngOnInit() {
		if (!this.selectedFile && this.files?.length > 0) {
			this.onSelectFile(this.files.find(file => file._selected) || this.files[0]);
		}
	}

	async onDownloadFile() {
		if (!this.selectedFile) {
			return;
		}

		// NOTE: SETTING TO FALSE DOENST PROPAGATE INTO THE UI (ISSUE)
		// this.downloadingFile = true;
		const downloadingToast = this.toastrService.warning(`Downloading ${this.selectedFile.fileName} file...`, null, { disableTimeOut: true, closeButton: false, progressBar: true });

		try {
			await this.filesService.downloadFile(this.selectedFile._path, this.selectedFile.fileName);
		} catch (error: any) {
		} finally {
			// hide toast after delay to allow for the download dialog to appear
			setTimeout(() => {
				// this.downloadingFile = false;
				downloadingToast.toastRef.close();
			}, 1000);
		}
	}

	async onZoomIn() {
		if (+this.zoomLevel + 10 <= this.maxZoomLevel) {
			this.zoomLevel = +this.zoomLevel + 10;
		}
	}

	async onZoomOut() {
		if (+this.zoomLevel - 10 >= this.minZoomLevel) {
			this.zoomLevel = +this.zoomLevel - 10;
		}
	}

	async onReloadDocument() {
		try {
			this.overlayMessage = `loading ${this.selectedFile.fileName}...`;

			this.selectedFile._loading = true;
			this.selectedFile._source = await this.http
				.get(this.selectedFile._path, { responseType: 'arraybuffer' })
				.toPromise();
		} catch (error) {
			console.log(error);
		} finally {
			this.selectedFile._loading = false;
			this.overlayMessage = null;

			this.cdr.detectChanges();
		}
	}

	async onSelectFile(file: FileViewerFile) {
		this.files.forEach(file => file._selected = false);

		this.selectedFile = file;

		if (!this.selectedFile) {
			return;
		}

		this.selectedFile._selected = true;

		this.currentPage = 1;

		this.overlayMessage = null;

		this.openFile(file, async () => {
			if (!this.selectedFile._source) {
				await this.onReloadDocument();
			}
		});
	}

	async onRenameFile() {
		this.renameFile(this.selectedFile, (newName: string) => {
			this.selectedFile.fileName = newName;
			this.cdr.detectChanges();
		});
	}

	async onDeleteFile() {
		this.deleteFile(this.selectedFile, () => {
			let index = this.files.indexOf(this.selectedFile);
			this.files.splice(index, 1);
			if (index > this.files.length - 1) {
				index = this.files.length - 1;
			}
			this.onSelectFile(this.files[index]);
			this.cdr.detectChanges();
		});
	}

	isSelectedFileAnImage() {
		let imageMimeTypes = [
			'image/bmp',
			'image/jpeg',
			'image/x-png',
			'image/png',
			'image/gif',
			'image/webp',
			'image/svg+xml',
			// pure extensions
			'bmp',
			'jpg',
			'jpeg',
			'png',
			'gif',
			'webp',
			'svg',
			'svgz',
		];

		return imageMimeTypes.includes(this.selectedFile.mimeType?.trim()?.toLowerCase() || this.selectedFile.fileType?.toLowerCase());
	}
}

class FileViewerFile extends FileViewModel {
	_path?: string;
	_safeURL?: string;
	_source?: ArrayBuffer = null;
	_selected?: boolean = false;
	_loading?: boolean = false;
}
