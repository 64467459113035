<div class="row mb-3">
	<div class="col-24">
		<input type="text" class="form-control" id="address1Input" name="address1Input" [(ngModel)]="address1" (ngModelChange)="address1Change.emit($event)" placeholder="Address 1" autocomplete="noautocomplete" />
	</div>
</div>

<div class="row mb-3">
	<div class="col-24">
		<input type="text" class="form-control" id="address2Input" name="address2Input" [(ngModel)]="address2" (ngModelChange)="address2Change.emit($event)" placeholder="Address 2" autocomplete="noautocomplete" />
	</div>
</div>

<div class="row mb-3">
	<div class="col-24">
		<input type="text" class="form-control" id="cityInput" name="cityInput" [(ngModel)]="city" (ngModelChange)="cityChange.emit($event)" placeholder="City" autocomplete="off" />
	</div>
</div>

<div class="row g-2">
	<div class="col-16">
		<ng-select class="custom-ng-select"
				   id="stateCode"
				   name="stateCode"
				   [items]="states"
				   [bindLabel]="'stateName'"
				   [bindValue]="'stateCode'"
				   [(ngModel)]="stateCode"
				   (ngModelChange)="stateCodeChange.emit($event)"
				   [clearable]="true"
				   [multiple]="false"
				   [searchable]="true"
				   [disabled]="loadingStates"
				   [loading]="loadingStates"
				   [loadingText]="'loading genders...'"
				   [placeholder]="'State'">
		</ng-select>
	</div>

	<div class="col-8">
		<app-zipcode-input [id]="'zipCode'"
						   [name]="'zipCode'"
						   [(ngModel)]="zipCode"
						   (ngModelChange)="zipCodeChange.emit($event)">
		</app-zipcode-input>
	</div>
</div>
