import {Component, OnInit, Input, ViewChild, Output, EventEmitter} from '@angular/core';

import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

import { AuthorizationEditModel } from '@app/shared/models';
import { AuthorizationDigitalModalComponent } from '@app/root/app/shared/components/authorization-digital-modal/authorization-digital-modal.component';
import { AuthorizationCreateModalComponent } from '@app/root/app/shared/components/authorization-create-modal/authorization-create-modal.component';
import { AuthorizationRequestModalComponent } from '@app/shared/components/authorization-request-modal/authorization-request-modal.component';
import { FileViewerComponent } from '@app/shared/components/file-viewer/file-viewer.component';
import { ActionConfirmationComponent } from '@app/shared/components/action-confirmation/action-confirmation.component';
import { AsideWidgetComponent } from '@app/shared/components/aside-widget/aside-widget.component';
import { FilesService } from '@app/shared/services';

import { Messages } from '@app/constants';

import { faEllipsisVertical, faExclamationTriangle, faCircleCheck, faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { AuthorizationViewModel } from "@app/shared/models/authorization.models";

@Component({
	selector: 'app-authorizations-widget',
	templateUrl: 'authorizations-widget.component.html',
	styleUrls: ['authorizations-widget.component.scss'],
	exportAs: 'authorizationsWidget'
})
export class AuthorizationsWidgetComponent implements OnInit {
	spinnerIcon = faEllipsisVertical;
	authExpireWarningIcon = faExclamationTriangle;
	authExpireOkIcon = faCircleCheck;
	authExpiredIcon = faExclamationCircle;

	today: Date = new Date();
	saving: boolean = false;

	loadingAuthorizations: boolean = false;
	loadingAuthorizationsError: boolean = false;
	authorizations: AuthorizationViewModel[];
	activeAuthorizations: AuthorizationViewModel[];
	expiredAuthorizations: AuthorizationViewModel[];

	@Input()
	title: string = 'Authorizations';

	@Input()
	load: () => Promise<AuthorizationViewModel[]>;

	@Input()
	view: (authorization: AuthorizationViewModel) => Promise<string>;

	@Input()
	save: (authorization: AuthorizationEditModel) => Promise<AuthorizationViewModel>;

	@Input()
	request: (authorization: AuthorizationViewModel) => Promise<AuthorizationViewModel>;

	@Input()
	delete: (authorization: AuthorizationViewModel) => Promise<void>;

	@ViewChild('asideWidget', { static: false })
	authorizationsAsideWidget: AsideWidgetComponent;

	constructor(
		private modalService: BsModalService,
		private filesService: FilesService,
		private toastrService: ToastrService
	) {
	}

	async ngOnInit() {
		this.onReloadAuthorizations();
	}

	private processAuthorizations() {
		this.authorizations.forEach(authorization => {
			// Pre-process expiration days/months
			authorization.expireInDaysAmount = moment(authorization.expirationDate).diff(this.today, 'days');
			authorization.expireInMonthsAmount = moment(authorization.expirationDate).diff(this.today, 'months');
			authorization.isAboutToExpire = authorization.expireInDaysAmount <= 7
		});

		this.authorizations.sort((a: AuthorizationViewModel, b: AuthorizationViewModel) => new Date(a.expirationDate).getTime() - new Date(b.expirationDate).getTime());

		this.activeAuthorizations = this.authorizations.filter(m => !m.isExpired);
		this.expiredAuthorizations = this.authorizations.filter(m => m.isExpired);

		// reload member files
		this.authorizationSaved.emit();
	}

	async onReloadAuthorizations() {
		try {
			this.loadingAuthorizations = true;
			this.loadingAuthorizationsError = false;

			this.authorizations = await this.load();
			this.processAuthorizations();
		} catch (error: any) {
			console.error(error);
			this.loadingAuthorizationsError = true;
		} finally {
			this.loadingAuthorizations = false;
		}
	}

	async onShowNewAuthorizationModal() {
		this.modalService.show<AuthorizationCreateModalComponent>(AuthorizationCreateModalComponent, {
			class: 'modal-lg',
			ignoreBackdropClick: true,
			animated: false,
			initialState: {
				save: async (model: AuthorizationEditModel) => {
					try {
						let authorization = await this.save(model);

						if (!this.authorizations) {
							this.authorizations = [];
						}

						this.authorizations.push(authorization);
						this.processAuthorizations();

						return true;
					} catch (error: any) {
						this.toastrService.error(`Failed to save ${model.authName} authorization`);
						return false;
					}
				}
			}
		});
	}
	@Output() authorizationSaved = new EventEmitter<void>();

	async onEditAuthorization(authorization: AuthorizationViewModel) {
		this.modalService.show<AuthorizationCreateModalComponent>(AuthorizationCreateModalComponent, {
			class: 'modal-lg',
			ignoreBackdropClick: true,
			animated: false,
			initialState: {
				model: {
					clientMemberID: authorization.clientMemberID,
					languageCode: authorization.languageCode,
					isGeneric:authorization.isGeneric,
					customName: authorization.customName,
					customText: authorization.customText,
					dateSigned: authorization.dateSigned,
				} as AuthorizationEditModel,
				save: async (model: AuthorizationEditModel) => {
					try {
						await this.delete(authorization);

						this.authorizations.splice(this.authorizations.indexOf(authorization), 1);

						let authorizationModel = await this.save(model);

						if (!this.authorizations) {
							this.authorizations = [];
						}

						this.authorizations.push(authorizationModel);
						this.processAuthorizations();

						return true;
					} catch (error: any) {
						this.toastrService.error(`Failed to save ${model.authName} authorization`);
						return false;
					}
				}
			}
		});
	}

	async onViewAuthorization(originalAuth: AuthorizationViewModel) {
		if (originalAuth.fileID) {
			// authorization with file attachment
			this.showAuthorizationFile(originalAuth);
		} else {
			// digital signature authorization
			this.modalService.show<AuthorizationDigitalModalComponent>(AuthorizationDigitalModalComponent, {
				class: 'modal-lg',
				backdrop: 'static',
				ignoreBackdropClick: true,
				animated: false,
				initialState: {
					model: originalAuth
				}
			});
		}
	}

	private showAuthorizationFile(originalAuth: AuthorizationViewModel) {
		this.modalService.show<FileViewerComponent>(FileViewerComponent, {
			class: 'modal-xl',
			backdrop: 'static',
			ignoreBackdropClick: true,
			animated: false,
			initialState: {
				showEdit: false,
				showDelete: false,
				title: `View ${this.title}`,
				files: this.authorizations.map(authorization => ({
					fileID: authorization.authorizationID,
					fileName: authorization.customName,
					_selected: authorization.authorizationID == originalAuth?.authorizationID
				})),

				openFile: async (f, done) => {
					try {
						let authToOpen = this.authorizations.find(authorization => authorization.authorizationID == f.fileID);

						f._path = await this.view(authToOpen);

						done();
					} catch (error: any) {
						console.error(error);
						this.toastrService.error(`Failed to generate access token to view '${originalAuth.customName}' authorization`);
					}
				},

				deleteFile: async (f, done) => {
					let authToDelete = this.authorizations.find(authorization => authorization.fileID === f.fileID);

					this.modalService.show<ActionConfirmationComponent>(ActionConfirmationComponent, {
						class: 'modal-md',
						ignoreBackdropClick: true,
						animated: false,
						initialState: {
							title: 'Delete Authorization?',
							messageHtml: `Are you sure you want to delete <strong>${authToDelete.customName}</strong> authorization?`,
							actionTitle: 'Delete',
							actionButtonClass: 'btn-danger',
							actionFunc: async () => {
								try {
									await this.delete(authToDelete);

									this.authorizations.splice(this.authorizations.indexOf(authToDelete), 1);
									this.processAuthorizations();

									this.toastrService.success(`Successfully deleted ${authToDelete.customName} authorization.`);
									done();

									return true;
								} catch (error: any) {
									this.toastrService.error(Messages.ErrorRetry, `Failed to delete ${authToDelete.customName} authorization`);
									return false;
								}
							}
						}
					});
				}
			}
		});
	}

	async onDownloadAuthorization(authorization: AuthorizationViewModel) {
		const downloadToast = this.toastrService.warning(`Downloading ${authorization.customName} authorization...`, null, { disableTimeOut: true, closeButton: false, progressBar: true });

		try {
			const authorizationURL = await this.view(authorization);

			await this.filesService.downloadFile(authorizationURL, authorization.customName);
		} catch (error: any) {
			console.error(error);
		} finally {
			// hide toast after delay to allow for the download dialog to appear
			setTimeout(() => {
				downloadToast.toastRef.close();
			}, 1000);
		}
	}

	async onRequestAuthorization(originalAuth: AuthorizationViewModel) {
		this.modalService.show<AuthorizationRequestModalComponent>(AuthorizationRequestModalComponent, {
			class: 'modal-xl',
			ignoreBackdropClick: true,
			animated: false,
			initialState: {
				model: {
					...originalAuth,

					// TODO: remove after testing
					personalRepresentative: 'Michael Barret',
					phoneNumber: '2243424534',
					emailAddress: 'mbarret@gmail.com'
				},
				requestFunc: async (requestAuth: AuthorizationViewModel) => {
					try {
						let updatedAuth = await this.request(requestAuth);

						this.authorizations.splice(this.authorizations.indexOf(originalAuth), 1, updatedAuth);
						this.processAuthorizations();

						return true;
					} catch (error: any) {
						return false;
					}
				}
			}
		});
	}

	onDeleteAuthorization(authorization: AuthorizationViewModel) {
		this.modalService.show<ActionConfirmationComponent>(ActionConfirmationComponent, {
			class: 'modal-md',
			ignoreBackdropClick: true,
			animated: false,
			initialState: {
				title: 'Delete Authorization?',
				messageHtml: `Are you sure you want to delete <strong>${authorization.customName}</strong> authorization?`,
				actionTitle: 'Delete',
				actionButtonClass: 'btn-danger',
				actionFunc: async () => {
					try {
						await this.delete(authorization);

						this.authorizations.splice(this.authorizations.indexOf(authorization), 1);
						this.processAuthorizations();

						this.toastrService.success(`Successfully deleted ${authorization.customName} authorization.`);

						return true;
					} catch (error: any) {
						this.toastrService.error(Messages.ErrorRetry, `Failed to delete ${authorization.customName} authorization`);
						return false;
					}
				}
			}
		});
	}
}
