export class PortalUser {
	portalUserID: number;
	firstName: string;
	lastName: string;
	fullName: string;
	shortName: string;
	initials: string;
	profileImageUrl: string;
	openCaseCount: number;
	openBillCount: number;
	openBillAmount: number;
	billsAssignedPerWeek: number;
}
