<div class="container placeholder-glow">
	<!-- breadcrumbs -->
	<div class="d-flex align-items-center mt-6" *ngIf="showBreadcrumbs">
		<span class="placeholder col-2"></span> <span class="mx-2">/</span> <span class="placeholder col-6"></span>
	</div>

	<!-- title header -->
	<div class="d-flex align-items-center mt-3" *ngIf="showTitle">
		<h4 class="placeholder col-11 mb-0"></h4>
	</div>

	<!-- status bar -->
	<div class="card bg-light mt-3" *ngIf="showStatusBar">
		<div class="card-body d-flex gap-5">
			<div class="col" *ngFor="let i of [].constructor(7)">
				<span class="placeholder col-20"></span>
				<span class="placeholder col-24"></span>
			</div>
		</div>
	</div>

	<div class="row mt-3">
		<!-- content cards -->
		<div class="col-sm-16">
			<div class="card">
				<div class="card-body">
					<div class="row mt-3" *ngFor="let i of [].constructor(3)">
						<div class="col" *ngFor="let j of [].constructor(3)">
							<span class="placeholder col-20"></span>
							<span class="placeholder col-24"></span>
						</div>
					</div>
				</div>
			</div>

			<div class="card mt-3">
				<div class="card-body">
					<h6 class="placeholder col-10"></h6>

					<div class="row mt-3">
						<div class="col">
							<span class="placeholder col-24"></span>
							<span class="placeholder col-24"></span>
						</div>
						<div class="col">
							<span class="placeholder col-24"></span>
							<span class="placeholder col-24"></span>
							<span class="placeholder col-24"></span>
							<span class="placeholder col-24"></span>
							<span class="placeholder col-24"></span>
							<span class="placeholder col-24"></span>
						</div>
						<div class="col">
							<span class="placeholder col-24"></span>
							<span class="placeholder col-24"></span>
						</div>
					</div>
				</div>
				<div class="card-footer d-flex justify-content-around py-4">
					<span class="placeholder col-6"></span>
					<span class="placeholder col-6"></span>
					<span class="placeholder col-6"></span>
				</div>
			</div>

			<div class="card mt-3">
				<div class="card-body">
					<h6 class="placeholder col-10"></h6>

					<div class="card mt-3">
						<div class="card-body">
							<span class="placeholder col-24"></span>
							<span class="placeholder col-24"></span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- aside cards -->
		<div class="col-sm-8">
			<div class="card">
				<div class="card-body">
					<span class="placeholder col-24"></span>
					<span class="placeholder col-24"></span>
					<span class="placeholder col-24"></span>
				</div>
			</div>

			<div class="card mt-3">
				<div class="card-body">
					<div class="d-grid gap-2">
						<button class="btn placeholder"></button>
						<button class="btn placeholder"></button>
					</div>
				</div>
			</div>

			<div class="card mt-3" *ngFor="let i of [].constructor(3)">
				<div class="card-body">
					<h6 class="placeholder col-10"></h6>

					<div class="card">
						<div class="card-body">
							<span class="placeholder col-24"></span>
							<span class="placeholder col-24"></span>
						</div>
					</div>
				</div>
			</div>

			<div class="card mt-3">
				<div class="card-body">
					<div class="d-grid gap-2">
						<button class="btn placeholder"></button>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- content card -->

</div>
