import { Component, Input } from '@angular/core';

import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';

@Component({
	selector: 'app-field-separator',
	templateUrl: 'field-separator.component.html',
	styleUrls: ['field-separator.component.scss']
})
export class FieldSeparatorComponent {
}
