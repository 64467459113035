import { Component, OnInit, OnDestroy, ViewChild, Input, TemplateRef } from '@angular/core';
import { formatDate } from '@angular/common';

import { ToastrService } from 'ngx-toastr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subscription, tap, catchError, throwError } from 'rxjs';

import { isValidationError, getValidationErrors } from '@app/functions';
import { SwitchToggleEvent } from '@app/shared/components/switch/switch.component';
import { LookupService, ProfileService } from "@app/shared/services";
import { AsideWidgetComponent } from '@app/shared/components';
import { Task, TaskTemplatesLookup, PortalUserLookup, UserProfile } from '@app/shared/models';

import { Messages, LookupCodes } from '@app/constants';

import { faEllipsisVertical, faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';
import { faBracketCurlyLeft, faBracketCurlyRight } from '@fortawesome/pro-regular-svg-icons';

interface TaskTemplateGroup {
	name: string;
	taskTemplates: TaskTemplatesLookup[]
}

@Component({
	selector: 'app-tasks-widget',
	templateUrl: 'tasks-widget.component.html',
	styleUrls: ['tasks-widget.component.scss'],
	exportAs: 'tasksWidget'
})
export class TasksWidgetComponent implements OnInit, OnDestroy {
	templateLeftIcon = faBracketCurlyLeft;
	templateRightIcon = faBracketCurlyRight;
	faEllipsisVertical = faEllipsisVertical;
	spinnerIcon = faSpinnerThird;

	loadingTasks: boolean = false;
	loadingTasksError: boolean = false;
	tasks: Task[] = [];
	openTasks: Task[] = [];
	closedTasks: Task[] = [];
	totalTasks: number = 0;
	currentPage: number = 1;
	hasMore: boolean = false;
	loadingMore: boolean = false;
	showAllCompletedTasks: boolean = false;

	taskToDelete: Task;
	deleteConfirmationModalRef: BsModalRef;

	loadingTaskTypes: boolean = false;
	loadingTaskTypesError: boolean = false;
	activityTypes: TaskType[] = [];
	selectedTaskType: TaskType;

	model: Task;
	showNewTaskForm: boolean = false;
	saving: boolean = false;

	@Input() title: string = 'Task';
	@Input() load: (pageNumber: number) => Promise<PagedDataModel<Task>>;
	@Input() save: (task: Task) => Promise<Task>;
	@Input() complete: (task: Task, complete: boolean) => Promise<Task>;
	@Input() delete: (task: Task) => Promise<void>;

	@Input() clientName: string;

	@ViewChild('tasksAsideWidget', { static: false })
	tasksAsideWidget: AsideWidgetComponent;

	currentWorkerID: number;
	currentWorker: PortalUserLookup;

	/* ============================ lookups ============================ */
	profileSubscription: Subscription;

	taskTemplatesLookup: TaskTemplatesLookup[];
	taskTemplatesLookupLoading: boolean = true;
	taskTemplatesSubscription: Subscription;
	taskTemplatesGroupped: TaskTemplateGroup[];

	loadingWorkersLookup: boolean = true;
	workersLookup: PortalUserLookup[];
	workersLookupSubscription: Subscription;
	selectedWorker: PortalUserLookup;

	constructor(
		private modalService: BsModalService,
		private toastrService: ToastrService,
		private lookupService: LookupService,
		private profileService: ProfileService
	) {
	}

	ngOnInit() {
		this.profileSubscription = this.profileService.getUserProfile()
			.pipe(
				tap(profile => this.currentWorkerID = profile.portalUserID),
				catchError(error => {
					this.toastrService.error(Messages.ErrorRetry, `Failed to load user profile`);
					return throwError(() => error);
				})
			)
			.subscribe();

		this.taskTemplatesSubscription = this.lookupService.getLookup<TaskTemplatesLookup>(LookupCodes.TaskTemplate)
			.pipe(
				tap(() => this.taskTemplatesLookupLoading = false),
				catchError(error => {
					this.taskTemplatesLookupLoading = false;
					return this.showLookupErrorToast('Task Templates', error);
				})
			)
			.subscribe(taskTemplates => {
				this.taskTemplatesLookup = taskTemplates

				this.taskTemplatesGroupped = taskTemplates.reduce((groups: TaskTemplateGroup[], taskTemplate: TaskTemplatesLookup) => {
					let group = groups.find(group => group.name == taskTemplate.categoryName);

					if (!group) {
						group = {
							name: taskTemplate.categoryName,
							taskTemplates: []
						};

						groups.push(group);
					}

					group.taskTemplates.push(taskTemplate);

					group.taskTemplates.sort((a, b) => {
						if (a.sortOrder > b.sortOrder) return 1;
						if (a.sortOrder < b.sortOrder) return -1;
						return 0
					});

					return groups;
				}, []);
			});

		this.workersLookupSubscription = this.lookupService.getLookup<PortalUserLookup>(LookupCodes.PortalUsers)
			.pipe(
				tap(() => setTimeout(() => this.loadingWorkersLookup = false, 0)),
				catchError(error => {
					setTimeout(() => this.loadingWorkersLookup = false, 0);
					return this.showLookupErrorToast('Workers', error);
				})
			)
			.subscribe(workers => {
				this.currentWorker = workers.find(m => m.portalUserID == this.currentWorkerID);
				this.workersLookup = workers.filter(m => m.portalUserID != this.currentWorkerID);
			});

		this.onLoadTasks();
	}

	ngOnDestroy() {
		this.profileSubscription?.unsubscribe();
		this.taskTemplatesSubscription?.unsubscribe();
		this.workersLookupSubscription?.unsubscribe();
	}

	private doProcessTasks() {
		this.tasks.forEach(task => {
			let newLineMatches = task.taskDetails.match(/\r\n|\n\r|\n|\r/g);

			task.hasTrimedDetails = task.taskDetails.length > 250 || (newLineMatches && newLineMatches.length > 5);
			task.trimedDetails = task.taskDetails.substring(0, 200) + '...';
		})

		this.openTasks = this.tasks
			.filter(task => task.isOpen)
			.sort((a: Task, b: Task) => {
				if (!a.isUrgent && b.isUrgent) return 1;
				if (a.isUrgent && !b.isUrgent) return -1;
				return 0;
			})
			.sort((a: Task, b: Task) => {
				if (a.dueDate > b.dueDate) return 1;
				if (a.dueDate < b.dueDate) return -1;
				return 0;
			});

		this.closedTasks = this.tasks
			.filter(task => !task.isOpen)
			.sort((a: Task, b: Task) => {
				if (a.dateCompleted < b.dateCompleted) return 1;
				if (a.dateCompleted > b.dateCompleted) return -1;
				return 0;
			});

	}

	private showLookupErrorToast(lookupName: string, error: any) {
		this.toastrService.error(Messages.ErrorRetry, `Failed to Load '${lookupName}'`);
		return throwError(() => error);
	}

	onTemplateSelect(templ: TaskTemplatesLookup) {
		if (this.model) {
			this.model.taskTemplateCode = templ.code;
			this.model.taskTemplateName = templ.name;

			// replace/presert only the previous template text if typed in something else
			let newTaskDetails = this.model.taskDetails?.trim() ?? '';
			let replacedOther = false;
			for (let otherTempl of this.taskTemplatesLookup) {
				if (newTaskDetails.indexOf(otherTempl.templateText) >= 0) {
					newTaskDetails = newTaskDetails.replace(otherTempl.templateText, templ.templateText);
					replacedOther = true;
					break;
				}
			}
			if (replacedOther) {
				this.model.taskDetails = newTaskDetails;
			} else {
				// prepend to newTaskDetails with newline inbetween if not empty and no newline
				this.model.taskDetails = templ.templateText + (newTaskDetails.startsWith('\r') ? newTaskDetails : '\r\n' + newTaskDetails);
			}
		}
	}

	reloadTaskRecords() {
		this.currentPage = 1;
		this.tasks = [];
		this.openTasks = [];
		this.closedTasks = [];
		this.totalTasks = 0;
		this.hasMore = false;
		this.loadingMore = false;
		this.onLoadTasks();
	}

	async onLoadTasks() {
		try {
			this.loadingTasks = !this.loadingMore;
			this.loadingTasksError = false;

			let response = await this.load(this.currentPage);

			this.hasMore = this.currentPage < response.totalPages;
			this.totalTasks = response.totalRecords;

			this.tasks = [...(this.loadingMore ? this.tasks : []), ...response.pageData];

			this.doProcessTasks();
		} catch (error: any) {
			console.error(error);
			this.loadingTasksError = true;
		} finally {
			this.loadingTasks = false;
			this.loadingMore = false;
		}
	}

	async onLoadMore() {
		this.currentPage = this.currentPage + 1;
		this.loadingMore = true;

		this.onLoadTasks();
	}

	async onShowAllCompletedTasks() {
		this.showAllCompletedTasks = true;
	}

	async onShowNewTaskForm() {
		this.model = new Task();
		this.model.assignedToUserID = this.currentWorker.portalUserID;
		console.log('NEW TASK', this.model);
		this.tasksAsideWidget.collapsed = false;
		this.showNewTaskForm = true;
	}

	async onHideNewTaskForm() {
		this.showNewTaskForm = false;
	}

	async onEditTask(task: Task) {
		this.model = { ...task };
		task.editing = true;
	}

	async onCloseEditTask() {
		this.openTasks.forEach(m => m.editing = false);
		this.closedTasks.forEach(m => m.editing = false);
	}

	async onToogleComplete(originalTask: Task) {
		try {
			this.saving = true;
			originalTask.completing = true;

			const updatedTask = await this.complete(originalTask, originalTask.isOpen);

			this.toastrService.success(`Task successfully ${(originalTask.isOpen ? 'completed' : 'uncompleted')}.`);

			let taskIndex = this.tasks.findIndex(m => m.taskID == originalTask.taskID);
			this.tasks[taskIndex] = updatedTask;

			this.doProcessTasks();
		} catch (error: any) {
			this.toastrService.error(Messages.ErrorRetry, `Failed to ${(originalTask.isOpen ? 'Complete' : 'Uncomplete')} Task`);
		} finally {
			this.saving = false;
			originalTask.completing = false;
		}
	}

	async onDeleteTask(template: TemplateRef<any>, task: Task) {
		this.taskToDelete = task;

		this.deleteConfirmationModalRef = this.modalService.show(template, {
			class: 'modal-md',
			ignoreBackdropClick: true,
			animated: false
		});

		this.deleteConfirmationModalRef.onHide.subscribe(async () => {
			this.taskToDelete = null;
		});
	}

	async onDeleteTaskConfirmed() {
		try {
			this.taskToDelete.deleting = true;

			await this.delete(this.taskToDelete);

			this.toastrService.success(`Task deleted successfully`);

			this.deleteConfirmationModalRef.hide();

			let taskIndex = this.tasks.findIndex(m => m.taskID == this.taskToDelete.taskID);
			this.tasks.splice(taskIndex, 1);

			this.doProcessTasks();
		} catch (error: any) {
			this.toastrService.error(Messages.ErrorRetry, `Failed to Delete Task`);
		} finally {
			this.taskToDelete.deleting = false;
		}
	}

	async onIsUrgentChanged(event: SwitchToggleEvent) {
		event.saved(true);
	}

	async onSave() {
		try {
			this.saving = true;

			let updatedTask = await this.save(this.model);

			this.toastrService.success(`Task saved successfully.`);

			this.onHideNewTaskForm();

			let taskIndex = this.tasks.findIndex(m => m.taskID == this.model.taskID);

			if (taskIndex == -1) {
				this.tasks.push(updatedTask);
			} else {
				this.tasks[taskIndex] = updatedTask;
			}

			this.doProcessTasks();
		} catch (err: any) {
			if (isValidationError(err)) {
				const validationErrors = getValidationErrors(err);
				this.toastrService.warning(`Unable to save the task because of the following validation errors:<br/><br/>${validationErrors.join('<br/>')}`, null, { enableHtml: true });
			} else {
				this.toastrService.error(Messages.ErrorRetry, `Failed to Save Task`);
			}
		} finally {
			this.saving = false;
		}
	}

	async onDueDateChange(originalTask: Task, dueDate: string) {
		if (originalTask.dueDate == dueDate) {
			return;
		}

		try {
			originalTask.updatingDueDate = true;

			let updatedTask = await this.save({
				...originalTask,
				dueDate: dueDate
			});

			let taskIndex = this.tasks.findIndex(m => m.taskID == originalTask.taskID);
			this.tasks = [
				...this.tasks.slice(0, taskIndex),
				updatedTask,
				...this.tasks.slice(taskIndex + 1)
			];

			this.doProcessTasks();

			this.toastrService.success(`Task due date changed to ${formatDate(dueDate, 'EEE, MMM d', 'en-US')}.`);
		} catch (error: any) {
			console.error(error);
			this.toastrService.error(Messages.ErrorRetry, `Failed to update due date`);
		} finally {
			originalTask.updatingDueDate = false;
		}
	}

	async onAssignedToChange(originalTask: Task, worker: PortalUserLookup) {
		if (originalTask.assignedToUserID == worker?.portalUserID) {
			return;
		}

		try {
			originalTask.updatingAssignedTo = true;

			let updatedTask = await this.save({
				...originalTask,
				assignedToUserID: worker?.portalUserID,
				assignedToUserName: worker?.fullName,
				assignedToUserImageURL: worker?.profileImageUrl
			});

			let taskIndex = this.tasks.findIndex(m => m.taskID == originalTask.taskID);
			this.tasks = [
				...this.tasks.slice(0, taskIndex),
				updatedTask,
				...this.tasks.slice(taskIndex + 1)
			];

			this.doProcessTasks();

			this.toastrService.success(`Task reassigned to ${worker?.fullName}.`);
		} catch (error: any) {
			console.error(error);
			this.toastrService.error(Messages.ErrorRetry, `Failed to assign task to ${worker.fullName}`);
		} finally {
			originalTask.updatingAssignedTo = false;
		}
	}
}
