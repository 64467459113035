<h6 class="d-flex justify-content-between align-items-center p-3 mb-0">
	<div class="d-flex align-items-center c-pointer" (click)="collapsed = !collapsed">
		<fa-icon class="text-secondary me-2" [class.mt-n1]="!collapsed"
				 [icon]="collapsed ? faCaretRight : faCaretDown"></fa-icon>
		<span>{{ title }}</span>
		<app-spinner-wheel class="ms-1" *ngIf="loading"></app-spinner-wheel>
		<span class="text-secondary ms-1" *ngIf="!loading && count >= 0">({{ count }})</span>
	</div>

	<ng-container *ngIf="showSearch">
		<div class="col-auto">
			<div *ngIf="!isSearch" class="text-muted search-icon align-self-center">
				<fa-icon (click)="onClickSearch()" [icon]="searchIcon"></fa-icon>
			</div>
			<div *ngIf="isSearch" class="col-auto ms-2">
				<div class="input-group">
					<input type="search" class="form-control form-control-search search-input" placeholder="search..."
						   [(ngModel)]="searchQuery" (ngModelChange)="searchQueryChange.next($event)">
					<div *ngIf="!hideClose" class="input-group-append">
						<button class="btn btn-outline-primary search-close" (click)="onSearchClose()"
								type="button">
							X
						</button>
					</div>
				</div>
			</div>
		</div>
	</ng-container>

	<ng-container *ngTemplateOutlet="actionsTemplate"></ng-container>
</h6>

<ng-container *ngIf="!loading && error">
	<app-error [message]="'Failed to load ' + title.toLowerCase()" [vertical]="true">
		<button class="btn btn-danger w-100 mt-2" (click)="retry.emit()">
			Try Again
		</button>
	</app-error>
</ng-container>

<ng-container *ngIf="!loading && !error">
	<div [collapse]="loading || collapsed" [isAnimated]="true">
		<ng-content></ng-content>
	</div>
</ng-container>
