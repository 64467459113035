import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-barchart-placeholder',
	templateUrl: 'barchart-placeholder.component.html',
	styleUrls: ['barchart-placeholder.component.scss']
})
export class BarChartPlaceholderComponent {
    // @Input() value: number;
	@Input() class: string = '';

    randomPercentages: number[] = Array.from({ length: 12 }, () => Math.floor(Math.random() * 71) + 15);
}
