import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'shortCurrency'
})
export class ShortCurrencyPipe implements PipeTransform {
	transform(value: number, currencySymbol: string = '$', showThousandsSymbol: boolean = true): string {
		if (value >= 1000000) {
			return `${currencySymbol}${(value / 1000000).toFixed(2)}${showThousandsSymbol ? 'M' : ''}`;
		} else if (value >= 1000) {
			return `${currencySymbol}${(value / 1000).toFixed(1)}${showThousandsSymbol ? 'K' : ''}`;
		} else {
			return `${currencySymbol}${value}`;
		}
	}
}
