import { Component, Input, Output, EventEmitter } from '@angular/core';

import { faCalendar } from '@fortawesome/pro-regular-svg-icons';

@Component({
	selector: 'app-inline-datepicker',
	templateUrl: 'inline-datepicker.component.html',
	styleUrls: ['inline-datepicker.component.scss']
})
export class InlineDatepickerComponent {
	faCalendar = faCalendar;

	private innerDate: Date;
	private innerValue: string;

	get valueDate(): Date {
		return this.innerDate;
	}

	set valueDate(value: Date) {
		this.innerDate = value;
		this.model = this.localDateToDateOnlyString(this.innerDate);
	}

	@Input()
	get model(): string {
		return this.innerValue;
	}

	set model(value: string) {
		if (value == this.innerValue) {
			return;
		}

		this.innerValue = value;
		this.innerDate = this.dateOnlyStringToLocalDate(value);

		this.modelChange.emit(value);
	}

	@Output()
	modelChange: EventEmitter<string> = new EventEmitter<string>();

	@Input()
	min: Date = null;

	@Input()
	max: Date = null;

	@Input()
	format: string = 'MM/dd/yyyy';

	@Input()
	disabled: boolean = false;

	private dateOnlyStringToLocalDate(date: Date | string): Date {
		if (date) {
			date = new Date(date);

			let timezoneOffset = date.getTimezoneOffset();
			let timezoneOffsetJanuary = new Date(date.getFullYear(), 0, 15).getTimezoneOffset();
			let timezoneOffsetJuly = new Date(date.getFullYear(), 6, 15).getTimezoneOffset();
			let timezoneDaylightSaving = Math.max(timezoneOffsetJanuary, timezoneOffsetJuly) !== timezoneOffset;
			let timezoneOffsetHours = Math.floor(timezoneOffset / 60);
			let timezoneOffsetMinutes = timezoneOffset - (timezoneOffsetHours * 60);

			let utcDate = new Date(Date.UTC(
				date.getUTCFullYear(),
				date.getUTCMonth(),
				date.getUTCDate(),
				timezoneOffsetHours + (timezoneDaylightSaving ? 1 : 0),
				timezoneOffsetMinutes
			));

			let localDate = new Date(
				utcDate.getFullYear(),
				utcDate.getMonth(),
				utcDate.getDate()
			);

			return localDate;
		}

		return undefined;
	}

	private localDateToDateOnlyString(userDate: Date | string) {
		if (userDate) {
			userDate = new Date(userDate);

			let year = userDate.getFullYear();
			let month = (userDate.getMonth() + 1);
			let date = userDate.getDate();

			return `${year}-${month < 10 ? '0' + month : month}-${date < 10 ? '0' + date : date}`;
		}

		return undefined;
	}
}
