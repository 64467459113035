import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';

import { lastValueFrom, catchError, tap } from 'rxjs';

import { IntegrationStatusViewModel, IntegraitonIssueViewModel, ClientIntegrationDetailsViewModel } from '@app/data/clients/models';
import { handleHttpError } from '@app/shared/functions';

import { environment } from '@app/environment';

@Injectable({ providedIn: 'root' })
export class IntegrationsService {
    protected get baseApiUrl() {
        return `${environment.api.host}/api/v1/clients`;
    }

    constructor(
        private http: HttpClient
    ) {
    }

    async getClientIntegrationDetails(clientID: number) {
        let options = {
            params: { clientID: clientID }
        };

        return lastValueFrom(
            this.http.get<ClientIntegrationDetailsViewModel>(`${this.baseApiUrl}/${clientID}/integrations`, options).pipe(
                catchError(handleHttpError)
            )
        );
    }

    async getClientIntegrationProcessingDetails(clientIntegrationBaseURL: string, apiKey: string, apiSecret: string) {
        let options = {
            headers: new HttpHeaders({
                'x-api-key': apiKey,
                'x-api-secret': apiSecret
            }),
            params: { deep: true }
        };

        return lastValueFrom(
            this.http.get<any>(`${clientIntegrationBaseURL}/ping`, options).pipe(
                catchError(handleHttpError)
            )
        );
    }

    async getIntegrationStatus(clientID: number) {
        let options = {
            params: { clientID: clientID }
        };

        return lastValueFrom(
            this.http.get<IntegrationStatusViewModel[]>(`${this.baseApiUrl}/integrations/status`, options).pipe(
                catchError(handleHttpError)
            )
        );
    }

    async getIntegrationIssues(clientID: number, sortCode: string | null, sortDirection: string | null, pageNumber: number) {
        let options = {
            params: {
                clientID: clientID,
                sortCode: sortCode,
                sortDir: sortDirection,
                page: pageNumber
            }
        };

        return lastValueFrom(
            this.http.get<IntegraitonIssueViewModel[]>(`${this.baseApiUrl}/integrations/issues`, options).pipe(
                tap(data => {
                    data.forEach(issue => {
                        if (issue.messageBody) {
                            issue.messageBodyObject = JSON.parse(issue.messageBody);
                        }

                        if (issue.isTemporarilyFailing) {
                            issue.status = 'Failing';
                            issue.severity = 'warning';
                        } else if (issue.isPermanentlyFailed) {
                            issue.status = 'Failed';
                            issue.severity = 'danger';
                        } else if (issue.isStuckOrphaned) {
                            issue.status = 'Stuck';
                            issue.severity = 'danger';
                        } else if (issue.isSameObjectChange) {
                            issue.status = 'Duplicate';
                            issue.severity = 'medium';
                        } else if (issue.isProcessed) {
                            issue.status = 'Processed';
                            issue.severity = 'success';
                        } else if (issue.isUnprocessed) {
                            issue.status = 'Pending';
                            issue.severity = 'warning';
                        } else {
                            issue.status = 'Unknown';
                            issue.severity = 'dark';
                        }
                    });
                }),
                catchError(handleHttpError)
            )
        );
    }

    async resetIntegrationIssue(messageQueueID: number) {
        let options = {
            params: {  }
        };

        return lastValueFrom(
            this.http.put(`${this.baseApiUrl}/integrations/issues/${messageQueueID}/reset`, options).pipe(
                catchError(handleHttpError)
            )
        );
    }

    async ignoreIntegrationIssue(messageQueueID: number) {
        let options = {
            params: {  }
        };

        return lastValueFrom(
            this.http.put(`${this.baseApiUrl}/integrations/issues/${messageQueueID}/ignore`, options).pipe(
                catchError(handleHttpError)
            )
        );
    }    
}
