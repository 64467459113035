import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { lastValueFrom, catchError, throwError, of } from 'rxjs';

import { EmailCategory, EmailTemplate } from '@app/settings/models';

import { environment } from '@app/environment';
import { handleHttpError } from '@app/functions';

@Injectable({ providedIn: 'root' })
export class EmailsService {
	protected get baseApiUrl() {
		return `${environment.api.host}/api/v1/emails`;
	}

	constructor(
		private http: HttpClient
	) {
	}

	async getTemplates() {
		return lastValueFrom(
			this.http.get<EmailTemplate[]>(`${this.baseApiUrl}`).pipe(
				catchError(handleHttpError)
			)
		);
	}

	async createTemplate(template: EmailTemplate) {
		return lastValueFrom(
			this.http.post<EmailTemplate>(`${this.baseApiUrl}`, template).pipe(
				catchError(handleHttpError)
			)
		);
	}

	async updateTemplate(template: EmailTemplate) {
		return lastValueFrom(
			this.http.put<EmailTemplate>(`${this.baseApiUrl}/${template.emailTemplateID}`, template).pipe(
				catchError(handleHttpError)
			)
		);
	}

	async deleteTemplate(template: EmailTemplate) {
		return lastValueFrom(
			this.http.delete<void>(`${this.baseApiUrl}/${template.emailTemplateID}`, { body: template }).pipe(
				catchError(handleHttpError)
			)
		);
	}

	async getCategories() {
		return of<EmailCategory[]>([
			{ emailCategoryID: 1, emailCategoryName: 'Member Services' },
			{ emailCategoryID: 2, emailCategoryName: 'Other Category' }
		]).toPromise();

		//return lastValueFrom(
		//	this.http.get<ResourceResponse<EmailCategory[]>>(`${this.baseApiUrl}/categories`).pipe(
		//		catchError(handleHttpError)
		//	)
		//);
	}

	async getLanguages(): Promise<any[]> {
		return of<any[]>([
			{ id: 1, name: 'English' },
			{ id: 2, name: 'Spanish' }
		]).toPromise();

		//return lastValueFrom(
		//	this.http.get<ResourceResponse<any[]>>(`${this.baseApiUrl}/languages`).pipe(
		//		catchError(handleHttpError)
		//	)
		//);
	}

	async sendTestEmail(template: EmailTemplate) {
		return lastValueFrom(
			this.http.delete<void>(`${this.baseApiUrl}/test`, { body: template }).pipe(
				catchError(handleHttpError)
			)
		);
	}
}
