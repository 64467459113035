import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';

import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';

@Component({
	templateUrl: 'file-rename-modal.component.html',
	styleUrls: ['file-rename-modal.component.scss']
})
export class FileRenameModalComponent implements OnInit {
	faSpinnerThird = faSpinnerThird;

	fileName: string;
	rename: (name: string) => Promise<boolean>;
	renamed: boolean = false;
	renaming: boolean = false;

	@ViewChild('fileNameInput', { static: true })
	fileNameInput: ElementRef<HTMLInputElement>;

	constructor(
		private modalRef: BsModalRef
	) {
	}

	async ngOnInit() {
		setTimeout(() => {
			this.fileNameInput.nativeElement.focus();
		}, 0);
	}

	async onRename() {
		try {
			this.renaming = true;
			this.renamed = await this.rename(this.fileName);

			if (this.renamed) {
				this.onClose();
			}
		} finally {
			this.renaming = false;
		}
	}

	async onClose() {
		this.modalRef.hide();
	}
}
