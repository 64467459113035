import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { MembersService } from '@app/data/members/services';
import { MemberPlan } from '@app/data/members/models';
import { ActionConfirmationComponent } from '@app/shared/components/action-confirmation/action-confirmation.component';

import { LookupService } from '@app/settings/services';
import { LookupValue } from '@app/settings/models';

import { isValidationError, getValidationErrors, scrollToElementById } from '@app/functions';
import { Messages } from '@app/constants';

import { faSpinnerThird } from '@fortawesome/pro-regular-svg-icons';
import { NgForm } from '@angular/forms';

@Component({
	selector: 'app-member-plans-form',
	templateUrl: 'member-plans-form.component.html',
	styleUrls: ['member-plans-form.component.scss'],
	exportAs: 'memberPlansForm'
})
export class MemberPlansFormComponent implements OnInit {
	faSpinnerThird = faSpinnerThird;

	loadingCoverageTypes: boolean = false;
	coverageTypes: LookupValue[] = [];

	loadingMemberPlan: boolean = false;
	loadingMemberPlanError: boolean = false;
	isNew: boolean = true;
	saving: boolean = false;
	validationErrors: string[];

	@Input()
	clientMemberID: number;

	@Input()
	model: MemberPlan;

	@Output()
	save: EventEmitter<MemberPlan> = new EventEmitter<MemberPlan>();

	@Output()
	close: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private membersService: MembersService,
		private lookupService: LookupService,
		private modalService: BsModalService,
		private toastrService: ToastrService
	) {
	}

	async ngOnInit() {
		this.isNew = !(this.model && this.model.planID > 0);

		this.onReloadCoverageTypes();

		if (this.isNew) {
			this.model = new MemberPlan();
		} else {
			await this.onReloadMemberPlan();
		}
	}

	async onReloadMemberPlan() {
		try {
			this.loadingMemberPlan = true;
			this.loadingMemberPlanError = false;

			let response = await this.membersService.getMemberPlan(this.clientMemberID, this.model.planID);

			this.model = {
				...response,
				clientID: this.clientMemberID
			};
		} catch (error: any) {
			console.error(error);
			this.loadingMemberPlanError = true;
		} finally {
			this.loadingMemberPlan = false;
		}
	}

	async onReloadCoverageTypes() {
		try {
			this.loadingCoverageTypes = true;

			this.coverageTypes = await this.lookupService.getLookupValues('CoverageType');
		} catch (error: any) {
			this.toastrService.error(Messages.ErrorRetry, `Failed to Load Coverage Types`);
		} finally {
			this.loadingCoverageTypes = false;
		}
	}

	onClientPlanChange(selection: { clientID?: number, clientPlanID?: number }) {
		this.model.clientID = selection.clientID;
		this.model.planID = selection.clientPlanID;
	}

	async onSave() {
		try {
			this.saving = true;
			this.validationErrors = null;

			let response = this.isNew ?
				await this.membersService.createMemberPlan(this.clientMemberID, this.model) :
				await this.membersService.updateMemberPlan(this.clientMemberID, this.model);

			this.toastrService.success(`Successfully ${this.isNew ? 'created' : 'updated'} ${response.planName} plan.`);

			this.save.emit(response);
			this.close.emit();
		} catch (err: any) {
			if (isValidationError(err)) {
				this.validationErrors = getValidationErrors(err);
				this.toastrService.warning('Unable to save the member plan because of validation errors. Please correct all validation errors and try saving again.');
			} else {
				this.toastrService.error(Messages.ErrorRetry, `Failed to Create Member Plan`);
			}
		} finally {
			this.saving = false;
		}
	}

	onClose(form: NgForm) {
		if (form && form.dirty) {
			this.modalService.show<ActionConfirmationComponent>(ActionConfirmationComponent, {
				class: 'modal-md',
				ignoreBackdropClick: true,
				animated: false,
				initialState: {
					title: Messages.DiscardChangesModalTitle,
					messageHtml: Messages.DiscardChangesModalMessage,
					actionTitle: Messages.DiscardChangesConfirmButtonText,
					actionButtonClass: Messages.DiscardChangesConfirmButtonClass,
					actionFunc: async () => {
						this.close.emit();
						return true;
					},
					cancelTitle: Messages.DiscardChangesCancelButtonText,
					cancelButtonClass: Messages.DiscardChangesCancelButtonClass
				}
			});
		} else {
			this.close.emit();
		}
	}
}
