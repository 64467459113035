<div class="provider-input-container d-flex align-items-center gap-2">
	<input type="search" class="provider-input form-control"
		   [class.no-clear]="providersLoading"
		   [ngClass]="(!inputSize ? '' : 'form-control-'+inputSize)"
		   [(ngModel)]="providerName"
		   [typeahead]="providers$"
		   [typeaheadAsync]="true"
		   [typeaheadWaitMs]="300"
		   [typeaheadMinLength]="3"
		   typeaheadOptionField="name"
		   [typeaheadOptionsLimit]="999"
		   [typeaheadScrollable]="true"
		   [typeaheadOptionsInScrollableView]="5"
		   [typeaheadSelectFirstItem]="false"
		   [typeaheadIsFirstItemActive]="false"
		   [typeaheadItemTemplate]="providerItemTemplate"
		   [placeholder]="placeholder"
		   [isAnimated]="false"
		   [adaptivePosition]="false"
		   (typeaheadLoading)="onProviderTypeaheadLoading($event)"
		   (typeaheadOnSelect)="onTypeaheadSelect($event)"
		   autocomplete="off" />

	<app-spinner-wheel class="provider-input-spinner" *ngIf="providersLoading"></app-spinner-wheel>
</div>

<ng-template #providerItemTemplate let-provider="item" let-index="index">
	<div class="d-flex align-items-start justify-content-between gap-2 small">
		<div>
			<div class="d-flex align-items-center">
				<span class="fw-bold">{{ provider.name }}</span>
				<span class="badge text-bg-warning rounded-pill ms-2" *ngIf="provider.isHighQuality">High Quality</span>
				<span class="badge text-bg-success rounded-pill ms-2" *ngIf="provider.isGreatPrice">Great Price</span>
			</div>
			<div>{{ provider.address1 }}<span class="me-1" *ngIf="provider.address1 && provider.address2">,</span>{{ provider.address2 }}</div>
			<div>{{ provider.city }}, {{ provider.stateCode }} {{ provider.zipCode }}</div>
		</div>

		<div class="text-muted">
			{{ provider.milesAway }}mi
		</div>
	</div>
</ng-template>
