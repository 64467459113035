import { Component, Input } from '@angular/core';

import { faInfoCircle } from '@fortawesome/pro-duotone-svg-icons';

@Component({
	selector: 'app-info-icon',
	templateUrl: 'info-icon.component.html',
	styleUrls: ['info-icon.component.scss']
})
export class InfoIconComponent {
	infoIcon = faInfoCircle;

	@Input()
	popoverTemplate: any;

	@Input()
	class: string = '';
}
