<div class="btn-group" container="body" [attr.placement]="rightAligned ? 'bottom right' : ''" dropdown>
	<button id="{{ id }}Button" class="btn dropdown-toggle" [ngClass]="class" type="button" dropdownToggle
		[attr.aria-controls]="id + 'Dropdown'" [disabled]="disabled || updating">
		{{ dropdownText }}
		<app-spinner-wheel class="ms-1" *ngIf="loadingWorkersLookup || updating"></app-spinner-wheel>
	</button>

	<ul id="{{ id }}Dropdown" class="dropdown-menu dropdown-overflow" [class.dropdown-menu-end]="rightAligned"
		role="menu" [attr.aria-labelledby]="id + 'Button'" *dropdownMenu>
		<ng-container *ngFor="let worker of workersLookup$ | async; let isLast = last">
			<li>
				<a type="button" class="dropdown-item" (click)="onSelectWorker(worker)">
					<div class="d-flex align-items-center">
						<img width="20" height="20" class="bg-light rounded-circle me-2"
							[ngSrc]="worker.profileImageUrl" alt="worker logo" *ngIf="worker.profileImageUrl" />
						<strong>{{ worker.fullName }}</strong>
					</div>
					<div class="small">
						<div>
							<label class="form-label mb-0 me-2">Open cases/bills:</label>
							<span>{{ worker.openCaseCount
								}} / {{ worker.openBillCount }} ({{ worker.openBillAmount |
								shortCurrency }})</span>
						</div>
						<div>
							<label class="form-label mb-0 me-2">Bills assigned/week:</label>
							<span>{{ worker.billsAssignedPerWeek }}</span>
						</div>
					</div>
				</a>
			</li>
			<li class="divider dropdown-divider my-1" *ngIf="!isLast"></li>
		</ng-container>
	</ul>
</div>
