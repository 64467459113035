import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';

import { lastValueFrom, catchError, throwError } from 'rxjs';

import { User, Role, Permission, Auth0User } from '@app/settings/models';

import { environment } from '@app/environment';
import { handleHttpError } from '@app/functions';

@Injectable({ providedIn: 'root' })
export class SecurityService {
	protected get baseApiUrl() {
		return `${environment.api.host}/api/v1/security`;
	}

	constructor(
		private http: HttpClient
	) {
	}

	async getUsers() {
		return lastValueFrom(
			this.http.get<User[]>(`${this.baseApiUrl}/users`).pipe(
				catchError(handleHttpError)
			)
		);
	}

	async getUser(userID: number) {
		return lastValueFrom(
			this.http.get<User>(`${this.baseApiUrl}/users/${userID}`).pipe(
				catchError(handleHttpError)
			)
		);
	}

	async createUser(user: User) {
		return lastValueFrom(
			this.http.post<User>(`${this.baseApiUrl}/users`, user).pipe(
				catchError(handleHttpError)
			)
		);
	}

	async updateUser(user: User) {
		return lastValueFrom(
			this.http.put<User>(`${this.baseApiUrl}/users/${user.portalUserID}`, user).pipe(
				catchError(handleHttpError)
			)
		);
	}

	async deleteUser(user: User) {
		let options = {
			params: new HttpParams({
				fromObject: {
					version: user.version
				}
			})
		};

		return lastValueFrom(
			this.http.delete<void>(`${this.baseApiUrl}/users/${user.portalUserID}`, options).pipe(
				catchError(handleHttpError)
			)
		);
	}

	async getRoles() {
		return lastValueFrom(
			this.http.get<Role[]>(`${this.baseApiUrl}/roles`).pipe(
				catchError(handleHttpError)
			)
		);
	}

	async getRole(roleCode: string) {
		return lastValueFrom(
			this.http.get<Role>(`${this.baseApiUrl}/roles/${roleCode}`).pipe(
				catchError(handleHttpError)
			)
		);
	}

	async createRole(role: Role) {
		return lastValueFrom(
			this.http.post<Role>(`${this.baseApiUrl}/roles`, role).pipe(
				catchError(handleHttpError)
			)
		);
	}

	async updateRole(role: Role) {
		return lastValueFrom(
			this.http.put<Role>(`${this.baseApiUrl}/roles/${role.code}`, role).pipe(
				catchError(handleHttpError)
			)
		);
	}

	async deleteRole(role: Role) {
		let options = {
			params: new HttpParams({
				fromObject: {
					version: role.version
				}
			})
		};

		return lastValueFrom(
			this.http.delete<void>(`${this.baseApiUrl}/roles/${role.code}`, options).pipe(
				catchError(handleHttpError)
			)
		);
	}

	async getPermissions() {
		return lastValueFrom(
			this.http.get<Permission[]>(`${this.baseApiUrl}/permissions`).pipe(
				catchError(handleHttpError)
			)
		);
	}

	async getPermission(permissionCode: string) {
		return lastValueFrom(
			this.http.get<Permission>(`${this.baseApiUrl}/permissions/${permissionCode}`).pipe(
				catchError(handleHttpError)
			)
		);
	}

	async createPermission(permission: Permission) {
		return lastValueFrom(
			this.http.post<Permission>(`${this.baseApiUrl}/permissions`, permission).pipe(
				catchError(handleHttpError)
			)
		);
	}

	async updatePermission(permission: Permission) {
		return lastValueFrom(
			this.http.put<Permission>(`${this.baseApiUrl}/permissions/${permission.code}`, permission).pipe(
				catchError(handleHttpError)
			)
		);
	}

	async deletePermission(permission: Permission) {
		let options = {
			params: new HttpParams({
				fromObject: {
					version: permission.version
				}
			})
		};

		return lastValueFrom(
			this.http.delete<void>(`${this.baseApiUrl}/permissions/${permission.code}`, options).pipe(
				catchError(handleHttpError)
			)
		);
	}

	async getAuth0Users(unusedOnly: boolean) {
		let options = {
			params: new HttpParams({
				fromObject: {
					unusedOnly: unusedOnly ? 'true' : 'false'
				}
			})
		};

		return lastValueFrom(
			this.http.get<Auth0User[]>(`${this.baseApiUrl}/users/auth0`, options).pipe(
				catchError(handleHttpError)
			)
		);
	}
}
