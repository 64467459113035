import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { BsModalService } from 'ngx-bootstrap/modal';

import { MembersService } from '@app/data/members/services';
import { MemberDependent } from '@app/data/members/models';

import { faCheck, faXmark, faInfoCircle, faEllipsisVertical, faHouse, faMobileScreen, faEnvelope } from '@fortawesome/pro-solid-svg-icons';

@Component({
	selector: 'app-member-dependents-list',
	templateUrl: 'member-dependents-list.component.html',
	styleUrls: ['member-dependents-list.component.scss'],
	exportAs: 'memberDependents'
})
export class MemberDependentsListComponent implements OnInit {
	faCheck = faCheck;
	faXmark = faXmark;
	faInfoCircle = faInfoCircle;
	faEllipsisVertical = faEllipsisVertical;
	faHouse = faHouse;
	faMobileScreen = faMobileScreen;
	faEnvelope = faEnvelope;

	/* =================== inputs/outputs ================== */
	@Input()
	get clientMemberID() { return this._clientMemberID; }
	set clientMemberID(value: number) {
		if (this._clientMemberID !== value) {
			this._clientMemberID = value;
			this.onReloadMemberDependents();
		}
	}
	private _clientMemberID: number;
	@Input() clientID: number;
	@Input() clientPlanID: number;
	@Input() memberDependents: MemberDependent[] = [];

	/* =================== internal state ================== */
	loadingMemberPlans: boolean = false;
	loadingMemberPlansError: boolean = false;
	showNewPlanForm: boolean = false;
	saving: boolean = false;

	constructor(
		private membersService: MembersService,
		private modalService: BsModalService
	) {
	}

	ngOnInit() {
	}

	async onReloadMemberDependents() {
		try {
			this.loadingMemberPlans = true;
			this.loadingMemberPlansError = false;

			this.memberDependents = await this.membersService.getMemberDependents(this.clientMemberID);
		} catch (error: any) {
			this.loadingMemberPlansError = true;
		} finally {
			this.loadingMemberPlans = false;
		}
	}

	async onEditMemberDependent(memberDependent: MemberDependent) {
		this.memberDependents.forEach(m => m.editing = false);

		memberDependent.editing = true;
	}

	async onMemberDependentEditClosed() {
		this.memberDependents.forEach(m => m.editing = false);
	}

	async onDeactivateMemberDependent(memberDependent: MemberDependent) {

	}

	async onDeleteMemberDependent(memberDependent: MemberDependent) {

	}
}
