import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';

import { Observable, Observer, switchMap, map, of, catchError } from 'rxjs';
import { TypeaheadConfig, TypeaheadMatch } from 'ngx-bootstrap/typeahead';

import { ProvidersService } from '@app/settings/services';
import { Provider } from '@app/settings/models';

export function getTypeaheadConfig(): TypeaheadConfig {
	return Object.assign(new TypeaheadConfig(), { cancelRequestOnFocusLost: true });
}

@Component({
	selector: 'app-provider-input',
	templateUrl: 'provider-input.component.html',
	styleUrls: ['provider-input.component.scss'],
	providers: [{ provide: TypeaheadConfig, useFactory: getTypeaheadConfig }]
})
export class ProviderInputComponent implements OnInit {
	/* ======================== inputs/outputs ======================== */
	@Input() providerType: 'Individual' | 'Organization' = 'Individual';
	@Input() zipCode: string;
	@Input() placeholder: string = 'search providers';
	@Input() inputSize: 'sm' | 'lg';

	@Input()
	get providerName() { return this._providerName; }
	set providerName(value: string) {
		if (this._providerName === value) return;

		if (!value) {
			this.selectedProvider = null;
		}
		this._providerName = value;
		this.providerNameChange.emit(value);
	}
	private _providerName: string;

	@Output() providerNameChange = new EventEmitter<string>();
	@Output() providerChange = new EventEmitter<Provider>();


	/* ======================== internal state ======================== */
	providers$: Observable<Provider[]>;
	providersLoading: boolean = false;
	private get selectedProvider() { return this._selectedProvider; }
	private set selectedProvider(value: Provider) {
		if (this._selectedProvider === value) return;

		this._selectedProvider = value;
		this.providerChange.emit(value);
	}
	private _selectedProvider: Provider;

	constructor(
		private providersService: ProvidersService
	) {
	}

	ngOnInit() {
		this.providers$ = new Observable((observer: Observer<string>) => observer.next(this.providerName)).pipe(
			switchMap((query: string) => {
				this.selectedProvider = null;

				if (!query) {
					return of([]);
				}

				return this.providersService.getProviders$(query, this.providerType, this.zipCode, 1).pipe(
					catchError(err => {
						this.providersLoading = false;
						return of(null);
					}),
					map((providersResponse: PagedDataModel<Provider>) => providersResponse?.pageData || [])
				);
			})
		)
	}

	onProviderTypeaheadLoading(loading: boolean) {
		this.providersLoading = loading;
	}

	onTypeaheadSelect(e: TypeaheadMatch) {
		this.selectedProvider = e.item;
	}
}
