import { ApplicationRef, Component, OnInit, OnDestroy, ViewChild, ElementRef, VERSION } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';

import { Subscription, filter, map, first } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { faMessageBot, faMessageQuestion, faPaperPlaneTop } from '@fortawesome/pro-regular-svg-icons';
import { faUser, faEraser } from '@fortawesome/pro-solid-svg-icons';
import { AuthService } from '@auth0/auth0-angular';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { ToastrService } from 'ngx-toastr';

import { environment } from '../environments/environment';
import { GoogleMapsService } from "@app/navigation/services/googleMaps.service";
import { UpdateComponent } from '@app/shared/components';
import { AssistantService, ProfileService } from '@app/shared/services';
import { AssistantMessageModel } from '@app/shared/models';
import { PortalRoles } from '@app/constants';

import * as version from '../version.json';

declare var bootstrap: any;

@Component({
	selector: 'app-root',
	templateUrl: 'app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
	assistantIcon = faMessageBot;
	userIcon = faUser;
	sendIcon = faPaperPlaneTop;
	clearHistoryIcon = faEraser;

	angularVersion = VERSION.full;
	appVersion = version;

	private isAuthenticatedSubscription: Subscription;

	private idleStart$: any = null;
	private idleTimeout$: any = null;
	private idleEnd$: any = null;
	private idleToastrID: any = null;

	private appStable = false;

	private updatesInterval: any;

	@ViewChild('noInternetToast', { static: true })
	noInternetToastElement?: ElementRef<HTMLInputElement>;
	noInternetToast: any;
	noInternetDate?: Date;

	constructor(
		appRef: ApplicationRef,
		private updates: SwUpdate,
		public authService: AuthService,
		private idle: Idle,
		private toastr: ToastrService,
		// TODO: MOVE GOOGLEMAPS INTO MODULE THAT USES IT
		private googleMapsService: GoogleMapsService,
		private modalService: BsModalService,
		private router: Router,
		private titleService: Title,
		private assistantService: AssistantService,
		private profileService: ProfileService
	) {
		// NOTE: APP IS NEVER ISSTABLE BECAUSE OF ACTIVE INTERVALS
		// appRef.isStable.pipe(first(stable => stable)).subscribe(() => {
		// 	this.appStable = true;
		// 	console.log('********** APP IS STABLE **********');

		// 	if (environment.pwaEnabled) {
		// 		this.handleUpdates();
		// 	} else {
		// 		console.warn('Service worker is not enabled. Updates will not be checked for.');
		// 	}
		// });
	}

	ngOnInit() {
		this.isAuthenticatedSubscription = this.authService.isAuthenticated$.subscribe(isAuthenticated => {
			if (isAuthenticated && !this.idle.isRunning()) {
				console.log('starting idle timer');
				this.startIdleTimer();
			} else {
				console.log('stopping idle timer');
				this.stopIdleTimer();
			}
		});

		// TODO: REMOVE ONCE NOT NEEDED FOR KB ASSISTANCE
		this.profileSubscription = this.profileService.getUserProfile().subscribe(profile => {
			this.isSystemAdministrator = this.profileService.hasRole(PortalRoles.SystemAdministrator);
		});

		this.googleMapsService.initGoogleMaps();

		if (environment.pwaEnabled) {
			this.handleUpdates();
		} else {
			console.warn('Service worker is not enabled. Updates will not be checked for.');
		}
		this.handleInternetConnection();
		this.handleTitleChanges();
	}

	ngOnDestroy() {
		this.isAuthenticatedSubscription?.unsubscribe();

		// TODO: REMOVE ONCE NOT NEEDED FOR KB ASSISTANCE
		this.profileSubscription?.unsubscribe();

		clearInterval(this.updatesInterval);
	}

	private handleUpdates() {
		if (!this.updates.isEnabled) {
			console.warn('Service worker is not enabled. Updates will not be checked for.');
			return;
		}

		this.updates.versionUpdates
			.pipe(filter((event): event is VersionReadyEvent => event.type === 'VERSION_READY'))
			.subscribe(async event => {
				console.log('new version is available', event);

				let activated = await this.updates.activateUpdate();

				if (activated) {
					this.modalService.show(UpdateComponent, {
						ignoreBackdropClick: true
					});
				}
			});

		this.updates.unrecoverable.subscribe(event => {
			console.log('An error occurred that we cannot recover from:\n' + event.reason);
			document.location.reload();
		});

		// check for updates on every route change
		this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.updates.checkForUpdate();
			}
		});

		// check for updates every five minutes
		this.updatesInterval = setInterval(() => this.updates.checkForUpdate(), 5 * 1000 * 60);
	}

	private handleInternetConnection() {
		window.addEventListener('online', () => {
			if (this.noInternetToast) {
				this.noInternetDate = undefined;
				this.noInternetToast.hide();
			}
		});

		window.addEventListener('offline', () => {
			if (!this.noInternetToastElement && !this.noInternetToast) {
				return;
			}

			this.noInternetToast = new bootstrap.Toast(this.noInternetToastElement!.nativeElement, { autohide: false });
			this.noInternetDate = new Date();
			this.noInternetToast.show();
		});
	}

	private handleTitleChanges() {
		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				map(() => {
					let route: ActivatedRoute = this.router.routerState.root;

					let titleParts = [];
					do {
						const title = route.snapshot.data['title'];
						if (title && titleParts.indexOf(title) === -1) {
							titleParts.unshift(title);
						}

						route = route.firstChild;
					} while (route);

					return titleParts.join(' - ');
				})
			)
			.subscribe((title: string) => {
				if (title) {
					this.titleService.setTitle(`${title} - Point Healthtech Service Portal`);
				} else {
					this.titleService.setTitle(`Point Healthtech Service Portal`);
				}
			});
	}

	/**
	 * Start watching for user inactivity and performs logout
	 */
	private startIdleTimer(): void {
		this.idle.setIdle(environment.inactivity.idle);
		this.idle.setTimeout(environment.inactivity.timeout);
		this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

		let timeout = (seconds: number): string => {
			let result = '';

			if (seconds / 60 > 1) {
				result += `${seconds / 60} minute`;
			}

			return result;
		};

		let toastrOptions = {
			disableTimeOut: true
		};

		if (this.idleToastrID) {
			this.toastr.clear(this.idleToastrID);
		}

		// Performs action when user is inactive for {environment.inactivity.idle} seconds
		this.idleStart$ = this.idle.onIdleStart.subscribe(() => {
			if (this.idleToastrID) {
				this.toastr.clear(this.idleToastrID);
			}

			this.idleToastrID = this.toastr
				.warning(`You will be automatically logged out in a couple of minutes due to inactivity.`, null, toastrOptions)
				.toastId;
		});

		// Performs action when user is inactive for {environment.inactivity.idle + environment.inactivity.timeout} seconds
		this.idleTimeout$ = this.idle.onTimeout.subscribe(() => {
			if (this.idleToastrID) {
				this.toastr.clear(this.idleToastrID);
			}

			this.idleToastrID = this.toastr
				.warning(`You were automatically logged out due to ${(environment.inactivity.idle / 60).toFixed(0)} minutes of inactivity.`, null, toastrOptions)
				.toastId;

			this.router.navigateByUrl('/account/sign-out');
		});

		// Performs action when user is back active
		this.idleEnd$ = this.idle.onIdleEnd.subscribe(() => {
			if (this.idleToastrID) {
				this.toastr.clear(this.idleToastrID);
			}
		});

		this.idle.watch();
	}

	/**
	 * Stops watching for user inactivity
	 */
	private stopIdleTimer(): void {
		this.idle.stop();
		this.idleStart$?.unsubscribe();
		this.idleStart$ = null;
		this.idleTimeout$?.unsubscribe();
		this.idleTimeout$ = null;
		this.idleEnd$?.unsubscribe();
		this.idleEnd$ = null;
	}

	// TODO: MOVE TO ASSISTANT COMPONENT
	isSystemAdministrator: boolean = false;
	profileSubscription: Subscription;

	assistantSending = false;
	assistantError = false;
	assistantMessages: AssistantMessageModel[] = [];
	assistantMessageRequest = '';

	async onSendAssistantMessage() {
		// if (!this.assistantMessageRequest) {
		// 	return;
		// }

		try {
			if (this.assistantMessages.length === 0 || this.assistantMessages[this.assistantMessages.length - 1].message != this.assistantMessageRequest) {
				this.assistantMessages.push({ role: 'user', message: this.assistantMessageRequest });
			}

			this.assistantSending = true;
			this.assistantError = false;

			const response = await this.assistantService.sendChatRequest(this.assistantMessageRequest);

			response.forEach(m => {
				// find and replace [doc1] string within m.message with a <a> tag
				let doc1 = m.message.match(/\[doc\d+\]/g);
				if (doc1) {
					doc1.forEach(doc => {
						let docNum = doc.match(/\d+/g);
						if (docNum) {
							// docNum[0]
							m.message = m.message.replace(doc, ` [<a href="${m.citations?.[0].url}">${m.citations?.[0].title}</a>] `);
						}
					});
				}
			});

			this.assistantMessages = [...this.assistantMessages, ...response];
			this.assistantMessageRequest = '';
		} catch (err) {
			console.error(err);
			this.assistantError = true;
		} finally {
			this.assistantSending = false;
		}
	}

	onClearAssistantMessages() {
		this.assistantMessages = [];
	}
}
