import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, Optional } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

import { Observable, tap, catchError, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { Lookup } from '@app/shared/models';
import { CaseBillNegociation } from '@app/data/cases/models';
import { LookupService } from '@app/shared/services';
import { Messages, LookupCodes, HousingTypes } from '@app/constants';

@Component({
	selector: 'app-create-bill-negotiation',
	templateUrl: 'create-bill-negotiation.component.html',
	styleUrls: ['create-bill-negotiation.component.scss'],
	viewProviders: [{
		provide: ControlContainer,
		deps: [[Optional, NgForm]],
		useFactory: (ngForm: NgForm) => ngForm
	}]
})
export class CreateBillNegotiationCaseComponent implements OnInit, OnDestroy {
	HousingTypes = HousingTypes;

	/* =================== state ================== */
	@Input()
	case: CaseBillNegociation;
	@Output()
	caseChange: EventEmitter<CaseBillNegociation> = new EventEmitter<CaseBillNegociation>();

	/* =================== lookups ================== */
	housingTypesLookup$: Observable<Lookup[]>;
	loadingHousingTypesLookup: boolean = true;
	propertiesLookup$: Observable<Lookup[]>;
	loadingPropertiesLookup: boolean = true;

	constructor(
		private toastrService: ToastrService,
		private lookupService: LookupService,
	) {
	}

	ngOnInit() {
		this.connectLookups();
	}

	ngOnDestroy() {
	}

	onHousingTypeChange() {
		if (this.case.housingTypeCode !== HousingTypes.Other) {
			this.case.otherHousingType = null;
		}
	}

	private connectLookups() {
		this.housingTypesLookup$ = this.lookupService.getLookup<Lookup>(LookupCodes.HousingType)
			.pipe(
				tap(() => setTimeout(() => this.loadingHousingTypesLookup = false, 0)),
				catchError(err => {
					setTimeout(() => this.loadingHousingTypesLookup = false, 0);
					return this.showLookupErrorToast('Housing Types', err);
				})
			);
		this.propertiesLookup$ = this.lookupService.getLookup<Lookup>(LookupCodes.PropertyType)
			.pipe(
				tap(() => setTimeout(() => this.loadingPropertiesLookup = false, 0)),
				catchError(err => {
					setTimeout(() => this.loadingPropertiesLookup = false, 0);
					return this.showLookupErrorToast('Properties', err);
				})
			);
	}

	private showLookupErrorToast(lookupName: string, error: any) {
		this.toastrService.error(Messages.ErrorRetry, `Failed to Load '${lookupName}'`);
		return throwError(() => error);
	}
}
