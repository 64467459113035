import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';

import { ReplaySubject, Subscription, interval, switchMap, catchError, throwError } from 'rxjs';
import * as Sentry from "@sentry/angular-ivy";

import { UserProfile } from '@app/shared/models';
import { handleHttpError } from '@app/functions';
import { environment } from '@app/environment';

@Injectable({ providedIn: 'root' })
export class ProfileService implements OnDestroy {
	hasSomeRole(...roleCodes: string[]) {
		return roleCodes.some(roleCode => this.hasRole(roleCode));
	}
	hasRole(roleCode: string) {
		return this.userProfile?.roleCodes?.includes(roleCode);
	}


	hasPermissions(permissionCode: string) {
		return this.userProfile?.permissionCodes?.includes(permissionCode);
	}

	/* ===================== internal state ===================== */
	private get baseApiUrl() {
		return `${environment.api.host}/api/v1/security`;
	}

	private userProfileSubject = new ReplaySubject<UserProfile>(1);
	private userProfile: UserProfile;
	private userProfileLoaded = false;

	private intervalSubscription: Subscription;

	constructor(
		private http: HttpClient
	) {
	}

	ngOnDestroy() {
		this.intervalSubscription?.unsubscribe();
	}

	public getUserProfile() {
		this.loadUserProfile();
		return this.userProfileSubject.asObservable();
	}

	private loadUserProfile() {
		if (!this.userProfileLoaded) {
			this.fetchUserProfile()
				.pipe(catchError(err => {
					// propagate error to subject subscribers
					this.userProfileSubject.error(err);
					return throwError(() => err);
				}))
				.subscribe(data => {
					console.log('ProfileService.loadUserProfile');
					// save and propagate data to subject subscribers
					this.userProfile = data;
					this.userProfileSubject.next(data);
					this.userProfileLoaded = true;
				});

			// start reloading the user profile data every 5 minutes after the first request
			this.intervalSubscription = interval(5 * 60 * 1000)
				.pipe(
					switchMap(() => this.fetchUserProfile()),
					catchError(err => {
						// propagate error to subject subscribers
						this.userProfileSubject.error(err);
						return throwError(() => err);
					})
				)
				.subscribe(data => {
					console.log('ProfileService.userProfileReloader');
					this.userProfileSubject.next(data);
				});
		}
	}

	private fetchUserProfile() {
		return this.http.get<UserProfile>(`${this.baseApiUrl}/users/profile`)
			.pipe(catchError(handleHttpError));
	}
}
