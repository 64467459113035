import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'dateOnly'
})
export class DateOnlyPipe implements PipeTransform {

	// TODO: ADD SUPPORT FOR FORMATTING => , format: string
	// M/dd/yyyy for now
	transform(value: string): string {
		if (!value) return '';

		const date = new Date(value + 'T00:00:00');
		const year = date.getFullYear();
		const month = date.getMonth() + 1;
		const day = this.padNumber(date.getDate());

		// m/dd/yyyy
		return `${month}/${day}/${year}`;
	}

	padNumber(value: number): string {
		return value < 10 ? `0${value}` : `${value}`;
	}
}
