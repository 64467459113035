<div dropdown #dropdown="bs-dropdown" class="dropdown" [autoClose]="false" [insideClick]="true" [placement]="align">
	<div *ngIf="style === 'button'" class="btn-group" role="group">
		<button *ngIf="showNavigation" type="button" class="btn btn-link" (click)="onSelectPrevDateRange()">
			<fa-icon [icon]="faChevronLeft"></fa-icon>
		</button>

		<button dropdownToggle type="button" class="btn btn-tertiary rounded rounded-pill font-weight-bold text-nowrap px-3" [disabled]="disabled">
			<span>{{ dateRangeDisplay }}</span>
			<fa-icon class="ms-2" [icon]="faAngleDown"></fa-icon>
		</button>

		<button *ngIf="showNavigation" type="button" class="btn btn-link" (click)="onSelectNextDateRange()" [disabled]="selectedDateRange[1] > today">
			<fa-icon [icon]="faChevronRight"></fa-icon>
		</button>
	</div>

	<div *ngIf="style === 'select'" role="group">
		<button dropdownToggle type="button" class="d-flex justify-content-between form-control font-weight-bold text-nowrap px-3" [disabled]="disabled">
			<span>{{ dateRangeDisplay }}</span>
			<fa-icon style="color: #999;" class="ms-2" [icon]="faCaretDown"></fa-icon>
		</button>
	</div>

	<div *dropdownMenu class="dropdown-menu" [class.dropdown-menu-right]="align == 'right'">
		<div class="date-range-presets">
			<div class="d-none d-sm-block list-group list-group-flush">
				<ng-container *ngFor="let dateRangePreset of dateRangePresets">
					<a class="list-group-item" href="javascript:void(0);" [class.active]="dateRangePreset.selected" (click)="onSelectDateRangePreset(dateRangePreset)">
						<span class="d-inline-block me-auto">
							{{ dateRangePreset.label }}
						</span>

						<ng-container *ngIf="dateRangePreset.selected">
							<fa-icon [icon]="faCheck"></fa-icon>
						</ng-container>
					</a>
				</ng-container>
			</div>

			<div class="d-block d-sm-none mb-3">
				<select class="custom-select" (change)="onSelectDateRangePresetByLabel($event)">
					<ng-container *ngFor="let dateRangePreset of dateRangePresets">
						<option [value]="dateRangePreset.label" [selected]="dateRangePreset.selected">
							{{ dateRangePreset.label }}
						</option>
					</ng-container>
				</select>
			</div>
		</div>

		<div class="date-range-calendar">
			<owl-date-time-inline [pickerType]="'calendar'"
								  [selectMode]="'range'"
								  [max]="maxDate"
								  [(ngModel)]="selectedDateRange"
								  (ngModelChange)="onDateRangeChange($event)">
			</owl-date-time-inline>

			<div class="border-top text-end">
				<button type="button" class="btn btn-link text-dark me-2 my-2" (click)="onCancel()">
					Cancel
				</button>

				<button type="button" class="btn btn-primary rounded rounded-pill me-2 my-2" (click)="onApply()">
					Apply
				</button>
			</div>
		</div>
	</div>
</div>
