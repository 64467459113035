<div class="modal-header">
	<h5 class="modal-title">
		{{ title }}
	</h5>

	<button type="button" class="btn-close close" (click)="onClose()" aria-label="Close">
		<span aria-hidden="true" class="visually-hidden">&times;</span>
	</button>
</div>

<div class="modal-body">
	<div [innerHTML]="message"></div>
</div>

<div class="modal-footer">
	<button type="button" class="btn" [ngClass]="cancelButtonClass" (click)="onClose()">
		{{ cancelButtonText }}
	</button>

	<button type="button" class="btn" [ngClass]="confirmButtonClass" (click)="onConfirm()">
		{{ confirmButtonText }}
	</button>
</div>
