export const environment = {
	production: false,
	pwaEnabled: true,

	inactivity: {
		idle: 1800,
		timeout: 300,
		ping: 120
	},

	auth0: {
		domain: 'cbd-pointhealth-internal.us.auth0.com',
		clientID: 'MbOUqRE52uOf09RnvoDxrU8ydSWkMPGP',
		// request access to this audience (api) at user authentication time
		audience: 'https://apidev.pointhealth.com' // note, this is id, not url
	},

	api: {
		host: 'https://portalapidev.pointhealthtech.com',
		other: [
			'https://portalapidev.pointhealthtech.com',
			'https://pointhealth-portalapi-dev.azurewebsites.net'
		]
	},

	sites: {
		memberWeb: 'https://appdev.pointhealthtech.com',
	},

	tiny: {
		apiKey: 'wpf8b9ehbbxr74dvl7rqghkr66371jc4gw7mh0433cxhtqbv'
	},

	google: {
		staticMapsApiKey: 'AIzaSyDL2nDUzvjcmjXUQx-v6sJdcYF9_ppLEQQ',
		javaScriptMapsApiKey: 'AIzaSyBrymw05KO29N0loLeiEeEwOMV4oI8tOW8'
	},

	sentry: {
		dsn: 'https://98e1eb8df9854eedba4a20ead02498be@o493298.ingest.sentry.io/4504838276448256',
		tracePropagationTargets: ['localhost', 'portalapidev.pointhealthtech.com', /^\//],
		tracesSampleRate: 0.5,
		environment: 'DEV'
	},

	// used for some one-off client-specific customizations
	clientCodes: {
		SederaClientCode: 'CL-62BF44DD'
	}
};
