import { Injectable } from '@angular/core';

import { PDFDocument } from 'pdf-lib';

@Injectable({ providedIn: 'root' })
export class PDFService {
	async createDocument(): Promise<PDFDocument> {
		return await PDFDocument.create();
	}

	async getDocument(source: string | Uint8Array | ArrayBuffer): Promise<PDFDocument> {
		return await PDFDocument.load(source, { ignoreEncryption: true });
	}

	async getPageThumbnail(doc: PDFDocument, pageNumber: number = 0): Promise<string> {
		let page = doc.getPage(pageNumber);

		// TODO: Get page thumbnail
		return '';
	}

	async addFileAsPage(doc: PDFDocument, file: File, pageNumber: number = 0) {
		switch (file.type) {
			case 'image/jpeg': {
				let embeddedJPG = await doc.embedJpg(new Uint8Array(await file.arrayBuffer()));
				let page = doc.insertPage(pageNumber);

				let { width, height } = embeddedJPG.scaleToFit(page.getWidth(), page.getHeight());

				page.drawImage(embeddedJPG, {
					x: page.getWidth() / 2 - width / 2,
					y: page.getHeight() / 2 - height / 2,
					width: width,
					height: height,
				});
			} break;

			case 'image/png': {
				let embeddedPNG = await doc.embedPng(new Uint8Array(await file.arrayBuffer()));
				let page = doc.insertPage(pageNumber);

				let { width, height } = embeddedPNG.scaleToFit(page.getWidth(), page.getHeight());

				page.drawImage(embeddedPNG, {
					x: page.getWidth() / 2 - width / 2,
					y: page.getHeight() / 2 - height / 2,
					width: width,
					height: height,
				});
			} break;

			case 'application/pdf': {
				let source = await PDFDocument.load(new Uint8Array(await file.arrayBuffer()), { ignoreEncryption: true });
				let sourcePages = await doc.copyPages(source, source.getPageIndices());

				sourcePages.forEach(page => doc.addPage(page));
			} break;
		}

		return doc;
	}

	async addFilesAsPages(doc: PDFDocument, files: FileList, pageNumber: number = 0) {
		for (let index = 0; index < files.length; index++) {
			let file = files[index];

			this.addFileAsPage(doc, file, pageNumber);
		}
	}
}
