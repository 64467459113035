import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';

import { lastValueFrom, catchError, map, throwError } from 'rxjs';

import { Specialty, Procedure, Provider, ProviderDetails, Medication, MedicationDetails } from '@app/settings/models';

import { handleHttpError, removeEmptyParams } from '@app/functions';
import { environment } from '@app/environment';

// TODO: MOVE THIS SERVICE INTO SHARED MODULE - NOTHING TO DO WITH SETTINGS
@Injectable({ providedIn: 'root' })
export class ProvidersService {
	protected get baseApiUrl() {
		return `${environment.api.host}/api/v1`;
	}

	constructor(
		private http: HttpClient
	) {
	}

	getSpecialties$(query: string, popularOnly: boolean = false) {
		let options = {
			params: removeEmptyParams(new HttpParams({
				fromObject: {
					query: query,
					popular: popularOnly
				}
			}))
		};

		return this.http
			.get<Specialty[]>(`${this.baseApiUrl}/providers/specialities`, options)
			.pipe(catchError(handleHttpError));
	}

	getSpecialties(query: string) {
		return lastValueFrom(this.getSpecialties$(query));
	}

	getProcedures$(query: string = '', page: number = 1) {
		let options = {
			params: removeEmptyParams(new HttpParams({
				fromObject: {
					query: query,
					page: page
				}
			}))
		};

		return this.http
			.get<PagedDataModel<Procedure>>(`${this.baseApiUrl}/procedures/pricing/autosuggest`, options)
			.pipe(
				catchError(handleHttpError),
				map(response => {
					// convert all items into actual Procedure objects so
					// computed props/getters work
					response.pageData = response.pageData.map(procedure => new Procedure(procedure));
					return response;
				})
			);
	}

	getProcedures(query: string = '', page: number = 1) {
		return lastValueFrom(this.getProcedures$(query, page));
	}

	getProviders$(query: string, entityType: 'Individual' | 'Organization', zipCode: string, page: number = 1) {
		let options = {
			params: removeEmptyParams(new HttpParams({
				fromObject: {
					query: query,
					entityType: entityType,
					zip: zipCode,
					page: page
				}
			}))
		};

		return this.http
			.get<PagedDataModel<Provider>>(`${this.baseApiUrl}/providers`, options)
			.pipe(catchError(handleHttpError));
	}

	getProviders(query: string, entityType: 'Individual' | 'Organization', zipCode: string, page: number = 1) {
		return lastValueFrom(this.getProviders$(query, entityType, zipCode, page));
	}

	async getProviderDetails(providerID: string) {
		return lastValueFrom(
			this.http.get<ProviderDetails>(`${this.baseApiUrl}/providers/${providerID}`).pipe(
				catchError(handleHttpError)
			)
		);
	}

	getMedications$(query: string, page: number = 1) {
		let options = {
			params: removeEmptyParams(new HttpParams({
				fromObject: {
					query: query,
					page: page
				}
			}))
		};

		return this.http
			.get<PagedDataModel<Medication>>(`${this.baseApiUrl}/rx/medications/search`, options)
			.pipe(catchError(handleHttpError));
	}

	getMedications(query: string, page: number = 1) {
		return lastValueFrom(this.getMedications$(query, page));
	}

	async getMedicationDetails(id: string) {
		return lastValueFrom(
			this.http.get<MedicationDetails>(`${this.baseApiUrl}/rx/medications/${id}`).pipe(
				catchError(handleHttpError)
			)
		);
	}
}
