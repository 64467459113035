export class Messages {
	static readonly ErrorRetry = 'Please try again and let us know if the issue persists.';

	static readonly DiscardChangesModalTitle = 'Unsaved Changes';
	static readonly DiscardChangesModalMessage = `Would you like to leave this form and discard all unsaved changes?`;
	static readonly DiscardChangesConfirmButtonText = 'Discard Changes';
	static readonly DiscardChangesConfirmButtonClass = 'btn-danger';
	static readonly DiscardChangesCancelButtonText = 'Keep Editing';
	static readonly DiscardChangesCancelButtonClass = 'btn-primary';
}

/* ============================== lookup constants ============================== */
export class PortalRoles {
	static readonly SystemAdministrator = 'system_administrator';
	static readonly PatientAdvocate = 'patient_advocate';
	static readonly PatientAdvocateManager = 'patient_advocate_manager';
	static readonly Navigator = 'navigator';
	static readonly NavigationManager = 'navigation_manager';
}

export class PortalPermissions {

}

export class SystemSettingCodes {
	static readonly WorkerLabel = 'worker_label';
}

export class LookupCodes {
	// generic lookups
	static readonly ActivityEntity = 'ActivityEntity';
	static readonly ActivityType = 'ActivityType';
	static readonly BillChangeType = 'BillChangeType';
	static readonly BillChangeTypeDetail = 'BillChangeTypeDetail';
	static readonly BillReopenedReason = 'BillReopenedReason';
	static readonly BillSort = 'BillSort';
	static readonly BillStatus = 'BillStatus';
	static readonly BillStatusDetail = 'BillStatusDetail';
	static readonly CaseBillsFilter = 'CaseBillsFilter';
	static readonly CaseFilter = 'CaseFilter';
	static readonly CaseOrigin = 'CaseOrigin';
	static readonly CaseSort = 'CaseSort';
	static readonly CaseStatus = 'CaseStatus';
	static readonly CaseStatusDetail = 'CaseStatusDetail';
	static readonly CaseType = 'CaseType';
	static readonly CaseTypeCategory = 'CaseTypeCategory';
	static readonly CommunicationType = 'CommunicationType';
	static readonly ComparisonPriority = 'ComparisonPriority';
	static readonly ContactRelationshipType = 'ContactRelationshipType';
	static readonly CoverageType = 'CoverageType';
	static readonly DependentRelationshipType = 'DependentRelationshipType';
	static readonly FacilityType = 'FacilityType';
	static readonly FeeType = 'FeeType';
	static readonly Gender = 'Gender';
	static readonly HousingType = 'HousingType';
	static readonly KnowledgeTopic = 'KnowledgeTopic';
	static readonly Language = 'Language';
	static readonly MaritalStatus = 'MaritalStatus';
	static readonly MemberAppService = 'MemberAppService';
	static readonly MemberFilter = 'MemberFilter';
	static readonly MemberSort = 'MemberSort';
	static readonly PlanType = 'PlanType';
	static readonly PreBillNegotiationChangeType = 'PreBillNegotiationChangeType';
	static readonly PropertyType = 'PropertyType';
	static readonly State = 'State';
	static readonly TaskCreationTrigger = 'TaskCreationTrigger';
	static readonly TaskTemplate = 'TaskTemplate';
	static readonly TaskTemplateCategory = 'TaskTemplateCategory';
	static readonly Webhook = 'Webhook';
	static readonly UnassignedCaseSort = 'UnassignedCaseSort';

	// custom lookups
	static readonly Clients = 'clients';
	static readonly ClientPlans = 'client-plans';
	static readonly PortalUsers = 'portal-users';
	static readonly Cases = 'cases';
	static readonly Bills = 'bills';
	static readonly ClientPlanMembers = 'client-plan-members';
}

export class ActivityTypes {
	static readonly NoteUpdate = 'update';
	static readonly Call = 'call';
	static readonly VoiceMail = 'voicemail';
	static readonly Email = 'email';
	static readonly Fax = 'fax';
	static readonly Mail = 'mail';
	static readonly Meeting = 'meeting';
	static readonly Other = 'other';
	static readonly AuditLog = 'audit_log';
}

export class TaskGroupByFields {
	static readonly DueDate = 'duedate';
	static readonly Worker = 'worker';
}

export class SortDirections {
	static readonly Asc = 'asc';
	static readonly Desc = 'desc';
}

export class UnassignedCaseSorts {
	static readonly CaseStatus = 'case_status';
	static readonly CaseType = 'case_type';
	static readonly CaseAge = 'case_age';
	static readonly ClientPlan = 'client_plan';
	static readonly Patient = 'patient';
	static readonly OpenedDate = 'opened_date';
	static readonly LastActivity = 'last_activity';
}

export class WorkplaceTaskSections {
	static readonly Urgent = 'urgent';
	static readonly Overdue = 'overdue';
	static readonly Today = 'today';
	static readonly LaterThisWeek = 'later_this_week';
	static readonly NextWeek = 'next_week';
	static readonly Later = 'later';
}

export class CaseTypes {
	static readonly BillNegotiation = 'bill_negotiation';
	static readonly PreNegotiation = 'pre_negotiation';
	static readonly ItemizedStatements = 'itemized_statements';
	static readonly FacilityProvider = 'facility_provider';
	static readonly Prescription = 'prescription';
	static readonly Scheduling = 'scheduling';
	static readonly Transportation = 'transportation';
	static readonly PolicyPlanGuidance = 'policy_and_plan_guidance';
	static readonly Chaplaincy = 'chaplaincy';
	static readonly MemberAppSupport = 'member_app_support';
	static readonly GeneralSupport = 'general_support';
}

export class CaseOrigins {
	static readonly Phone = 'phone';
	static readonly Email = 'email';
	static readonly Web = 'web';
	static readonly ClientIntegration = 'client_integration';
	static readonly BillNegotiationService = 'bns';
}

export class CaseStatuses {
	static readonly Received = 'received';
	static readonly Verifying = 'verifying';
	static readonly Approved = 'approved';
	static readonly Working = 'working';
	static readonly UnableToWork = 'unable_to_work';
	static readonly Resolved = 'resolved';
}

export class CaseStatusDetails {
	static readonly Unabletocontact = 'unable_to_contact';
	static readonly Duplicate = 'duplicate';
}

export class PreNegotiationChangeTypes {
	static readonly CollectedAmount = 'collected';
	static readonly PatientResponsibilityChange = 'patient_responsibility';
	static readonly UpfrontPaymentChange = 'upfront';
}

export class BillStatuses {
	static readonly Received = 'received';
	static readonly Verifying = 'verifying';
	static readonly Working = 'working';
	static readonly UnableToWork = 'unable_to_work';
	static readonly Resolved = 'resolved';
}

export class BillChangeOfferStatuses {
	static readonly Pending = 'pending';
	static readonly Accepted = 'accepted';
	static readonly Rejected = 'rejected';
}

export class HousingTypes {
	static readonly Mortgage = 'mortgage';
	static readonly Own = 'own';
	static readonly Rent = 'rent';
	static readonly Parsonage = 'parsonage';
	static readonly Other = 'other';
}


/* ============================== style mappings ============================== */
export const CaseStatusTextColorMap: { [key: string]: string } = {
	'received': 'text-dark',
	'verifying': 'text-primary',
	'approved': 'text-dark',
	'working': 'text-warning',
	'unable_to_work': 'text-dark',
	'resolved': 'text-success'
}
export const CaseStatusButtonColorMap: { [key: string]: string } = {
	'received': 'btn-dark',
	'verifying': 'btn-primary',
	'approved': 'btn-dark',
	'working': 'btn-warning',
	'unable_to_work': 'btn-medium',
	'resolved': 'btn-success'
}
export const CaseStatusBadgeColorMap: { [key: string]: string } = {
	'received': 'text-bg-dark',
	'verifying': 'text-bg-primary',
	'approved': 'text-bg-dark',
	'working': 'text-bg-warning',
	'unable_to_work': 'text-bg-medium',
	'resolved': 'text-bg-success'
}

export const BillStatusTextColorMap: { [key: string]: string } = {
	'received': 'text-dark',
	'verifying': 'text-primary',
	'working': 'text-warning',
	'unable_to_work': 'text-dark',
	'resolved': 'text-success'
}
export const BillStatusButtonColorMap: { [key: string]: string } = {
	'received': 'btn-dark',
	'verifying': 'btn-primary',
	'working': 'btn-warning',
	'unable_to_work': 'btn-medium',
	'resolved': 'btn-success'
}
export const BillStatusBadgeColorMap: { [key: string]: string } = {
	'received': 'text-bg-dark',
	'verifying': 'text-bg-primary',
	'working': 'text-bg-warning',
	'unable_to_work': 'text-bg-medium',
	'resolved': 'text-bg-success'
}

export const TasksChartColorMap: { [key: string]: string } = {
	'normal-tasks': 'text-bg-secondary',
	'completed-tasks': 'text-bg-success',
	'overdue-tasks': 'text-bg-warning',
	'urgent-tasks': 'text-bg-danger',
}

export const CasesChartColorMap: { [key: string]: string } = {
	'normal-cases': 'text-bg-secondary',
	'old-cases': 'text-bg-warning',
	'no-activity-cases': 'text-bg-danger',
}

export const BillsChartColorMap: { [key: string]: string } = {
	'received-bills': 'text-bg-info-subtle',
	'verifying-bills': 'text-bg-info',
	'approved-bills': 'text-bg-primary',
	'working-bills': 'text-bg-warning'
}

export const TotalsChartColorMap: { [key: string]: string } = {
	'total': 'text-bg-primary'
}

export const CompletedChartColorMap: { [key: string]: string } = {
	'resolved': 'text-bg-success',
	'unable-to-work': 'text-bg-secondary'
}
