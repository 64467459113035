<h6 class="mb-4">
	New Dependent
</h6>

<form #form="ngForm">
	<fieldset [disabled]="saving">
		<div class="row mb-2 field-required">
			<div class="col-16">
				<label class="form-label" for="clientPlanSelect">Healthcare plan</label>
				<app-client-plans-select id="clientPlanSelect"
										 name="clientPlanSelect"
										 [showAllOption]="false"
										 [showSelectedPlanClient]="true"
										 [canSelectClient]="false"
										 [filterClientID]="clientID"
										 [(clientID)]="clientID"
										 [(clientPlanID)]="clientPlanID"
										 (clientPlanChange)="onClientPlanChange($event)">
				</app-client-plans-select>
			</div>
		</div>

		<div class="row mb-2 field-required">
			<div class="col-sm-12">
				<label class="form-label" for="dependentSelect">Dependent member</label>
				<app-member-select #dependentSelect
									   id="dependentSelect"
									   name="dependentSelect"
									   placeholder="Dependent member"
									   [clientID]="clientID"
									   [clientPlanID]="clientPlanID"
									   [closeable]="false"
									   (memberChange)="onDependentChange($event)"
									   [disabled]="!clientID">
				</app-member-select>
			</div>
		</div>

		<div class="row mb-2 field-required">
			<div class="col-8">
				<label class="form-label" for="relationshipSelect">
					Relationship to primary member
				</label>
				<ng-select #selectPlanTypes
						   class="custom-ng-select form-control rounded-end p-0"
						   id="relationshipSelect"
						   name="relationshipSelect"
						   [items]="relationshipsLookup$ | async"
						   bindLabel="name"
						   bindValue="code"
						   [(ngModel)]="model.relationshipTypeCode"
						   [clearable]="false"
						   [multiple]="false"
						   [searchable]="false"
						   [loading]="loadingRelationshipsLookup"
						   loadingText="loading relationships..."
						   placeholder="Relationship to primary"
						   [disabled]="saving">
				</ng-select>
			</div>
		</div>

		<hr />

		<div class="row">
			<div class="col-24">
				<button type="submit" class="btn btn-primary" (click)="onSave()">
					Save Dependent
					<app-spinner-wheel class="ms-1" *ngIf="saving"></app-spinner-wheel>
				</button>

				<button type="button" class="btn btn-outline-primary ms-2" (click)="onClose(form)">
					Cancel
				</button>
			</div>
		</div>
	</fieldset>
</form>
