export class ClientPlan {
	clientID: number;
	clientName: string;
	clientLogoUrl: string;
	isClientActive: boolean;
	plans: {
		clientPlanID: number,
		clientPlanName: string,
		isPlanActive: boolean,
	}[];
}
