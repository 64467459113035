export class FileViewModel {
	fileID: number;
	dateCreated?: string;
	entityTypeCode?: string;
	entityID?: number;
	entityCode?: string;
	fileName?: string;
	fileDate?: string;
	originalFileName?: string;
	mimeType?: string;
	fileType?: string;
	sizeKB?: number;
	version?: string;
}
