<div class="alert alert-danger mb-0" [ngClass]="class">
	<!-- TODO: REMOVE vertical VARIABLE AND USE RESPONSIVE LAYOUT -->
	<div class="d-flex justify-content-between" [class.align-items-center]="!vertical" [class.flex-column]="vertical">
		<div class="d-flex align-items-center">
			<fa-icon *ngIf="!shortTemplate" class="alert-icon" [icon]="faExclamationCircle"></fa-icon>

			<div>
				<div class="mb-0" [class.h5]="!shortTemplate" [class.h6]="shortTemplate">{{ message }}</div>
				<div *ngIf="!shortTemplate">Please try again and let us know if the issue persists.</div>
			</div>
		</div>

		<!-- TODO: ADD "TRY AGAIN" BUTTON AND EXPOSE EVENEMITTER PROP TO RECEIVE THE TRYAGAINCLICK EVENT -->
		<div class="col-auto">
			<ng-content></ng-content>
		</div>
	</div>
</div>
