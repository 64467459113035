<div class="card h-100">
	<div class="row g-0 h-100">
		<div class="col-md-7 h-100 card-cover">
			<h5 class="mb-0">Itemization Request</h5>
		</div>

		<div class="col-md-17 h-100 px-3">
			<div class="card-body">
				<div class="row mb-3">
					<label class="col-9 col-form-label" [ngClass]="{ 'col-24 border-bottom' : case.providers?.length > 0 }">
						Providers
					</label>

					<div [class.col-15]="!case.providers || case.providers.length == 0 && !showAddProviderForm" [class.col-24]="case.providers?.length > 0 || showAddProviderForm">
						<ng-container *ngIf="case.providers?.length > 0">
							<table class="table table-sm">
								<thead>
									<tr>
										<th>Name</th>
										<th>Location</th>
										<th style="max-width: 160px;">Contacts</th>
										<th style="width: 1px;"></th>
									</tr>
								</thead>

								<tbody class="border-bottom">
									<ng-container *ngFor="let provider of case.providers">
										<tr>
											<td class="small">
												{{ provider.providerName }}
											</td>

											<td class="small">
												{{ provider.facilityName }}
												<br *ngIf="provider.facilityName" />
												{{ provider.facilityAddress1 }} {{ provider.facilityAddress2 }}
												<br *ngIf="provider.facilityAddress1 || provider.facilityAddress2" />
												{{ provider.facilityCity ? provider.facilityCity + ',' : '' }} {{ provider.facilityStateCode }} {{ provider.facilityZipCode }}
											</td>

											<td class="small">
												<ng-container *ngIf="provider.facilityPhoneNumber">
													<div class="d-flex flex-nowrap align-items-center text-truncate" style="max-width: 160px;">
														<span class="text-secondary">Phone:</span>
														<a class="d-inline-flex align-items-center ms-1" [href]="'tel:+1' + provider.facilityPhoneNumber">
															{{ provider.facilityPhoneNumber | phone }}
														</a>
													</div>
												</ng-container>

												<ng-container *ngIf="provider.facilityFaxNumber">
													<div class="d-flex flex-nowrap align-items-center text-truncate" style="max-width: 160px;">
														<span class="text-secondary">Fax:</span>
														<a class="d-inline-flex align-items-center ms-1" [href]="'tel:+1' + provider.facilityFaxNumber">
															{{ provider.facilityFaxNumber | phone }}
														</a>
													</div>
												</ng-container>

												<ng-container *ngIf="provider.facilityEmailAddress">
													<div class="d-flex flex-nowrap align-items-center text-truncate" style="max-width: 160px;">
														<span class="text-secondary">Email:</span>
														<a class="d-inline-flex align-items-center ms-1" [href]="'mailto:' + provider.facilityEmailAddress">
															{{ provider.facilityEmailAddress }}
														</a>
													</div>
												</ng-container>
											</td>

											<td class="px-0">
												<button type="button" class="btn btn-icon-only px-1" (click)="onRemoveProvider(provider)">
													<fa-icon [icon]="faXmark"></fa-icon>
												</button>
											</td>
										</tr>
									</ng-container>
								</tbody>
							</table>
						</ng-container>

						<ng-container *ngIf="!showAddProviderForm">
							<button type="button" class="btn btn-sm btn-outline-primary" (click)="onShowAddProviderForm()">
								<fa-icon class="me-2" [icon]="faPlus"></fa-icon> Add Provider
							</button>
						</ng-container>

						<ng-container *ngIf="showAddProviderForm">
							<div class="card mt-2">
								<div class="card-body bg-light rounded">
									<app-provider-facility-select [showProviderSelect]="true"
																  [showFacilitySelect]="true"
																  [searchNearZipCode]="case.memberZipCode"
																  [(providerNPI)]="addProviderModel.providerNPI"
																  [(providerName)]="addProviderModel.providerName"
																  [(facilityNPI)]="addProviderModel.facilityNPI"
																  [(facilityName)]="addProviderModel.facilityName"
																  [(address1)]="addProviderModel.facilityAddress1"
																  [(address2)]="addProviderModel.facilityAddress2"
																  [(city)]="addProviderModel.facilityCity"
																  [(stateCode)]="addProviderModel.facilityStateCode"
																  [(zipCode)]="addProviderModel.facilityZipCode"
																  [(phoneNumber)]="addProviderModel.facilityPhoneNumber"
																  [(faxNumber)]="addProviderModel.facilityFaxNumber"
																  [(emailAddress)]="addProviderModel.facilityEmailAddress">
									</app-provider-facility-select>

									<hr />

									<div class="row">
										<div class="col">
											<button type="button" class="btn btn-primary" (click)="onAddProvider()">
												Add Provider
											</button>

											<button type="button" class="btn btn-outline-primary ms-2" (click)="onHideAddProviderForm()">
												Cancel
											</button>
										</div>
									</div>
								</div>
							</div>
						</ng-container>
					</div>
				</div>

				<hr />

				<div class="row mt-3">
					<label class="col-9 col-form-label" for="totalChargesAmount">
						Total charges amount
					</label>

					<div class="col-6">
						<input id="totalChargesAmount"
							   name="totalChargesAmount"
							   class="form-control"
							   type="text"
							   currencyMask
							   [(ngModel)]="case.totalChargesAmount"
							   autocomplete="off"
							   placeholder="$ 0.00"
							   required />
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
