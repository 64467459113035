<div class="btn-group" container="body" dropdown>
	<button class="btn btn-primary dropdown-toggle" type="button" dropdownToggle [disabled]="loadingCaseTypesLookup">
		New Case
		<app-spinner-wheel class="ms-1" *ngIf="loadingCaseTypesLookup"></app-spinner-wheel>
		<span class="caret" *ngIf="!loadingCaseTypesLookup"></span>
		<!-- *ngIf="!loadingCaseTypesLookup" -->
		<!-- <fa-icon class="ms-1" [icon]="faAngleDown" *ngIf="!loadingCaseTypesLookup"></fa-icon> -->
	</button>

	<ng-container *ngIf="!loadingCaseTypesLookup && caseTypeCategories?.length > 0">
		<!--  -->
		<div class="dropdown-menu dropdown-menu-end dropdown-overflow" *dropdownMenu>
			<ng-container *ngFor="let category of caseTypeCategories">
				<h6 class="dropdown-header text-uppercase">
					{{ category }}
				</h6>

				<ng-container *ngFor="let caseType of groupedCaseTypes[category]">
					<button class="dropdown-item" type="button" (click)="onOpenModal(caseType)">
						{{ caseType.name }}
					</button>
				</ng-container>
			</ng-container>
		</div>
	</ng-container>
</div>
