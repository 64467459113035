import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, of } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { environment } from "@app/environment";

@Injectable({
	providedIn: 'root',
})
export class GoogleMapsService {
	mapsAPIkey = environment.google.javaScriptMapsApiKey;
	googleMapsLoading = new BehaviorSubject(false);
	googleMapsError = new BehaviorSubject(null);

	constructor(
		private httpClient: HttpClient,
	) {
	}

	initGoogleMaps() {
		this.googleMapsLoading.next(true);

		this.httpClient.jsonp('https://maps.googleapis.com/maps/api/js?key=' + this.mapsAPIkey, 'callback')
			.pipe(
				map(() => {
					this.googleMapsLoading.next(false);
				}),
				catchError(error => {
					console.error(error);
					this.googleMapsError.next(new Error('Google maps failed to load.'));
					this.googleMapsLoading.next(false);
					return of(false);
				}),
			).subscribe();
	}

}
