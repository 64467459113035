import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';

import { Observable, tap, catchError, throwError } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { ClientPlanMemberLookup } from '@app/shared/models';
import { MembersService } from '@app/data/members/services';
import { CreateDependentModel } from '@app/data/members/models';

import { LookupService } from '@app/shared/services';
import { ActionConfirmationComponent } from '@app/shared/components/action-confirmation/action-confirmation.component';
import { Lookup } from '@app/shared/models';

import { Messages, LookupCodes } from '@app/constants';

@Component({
	selector: 'app-member-dependents-form',
	templateUrl: 'member-dependents-form.component.html',
	styleUrls: ['member-dependents-form.component.scss'],
	exportAs: 'memberDependentsForm'
})
export class MemberDependentsFormComponent implements OnInit {
	/* ========================== inputs/outputs ========================== */
	@Input() clientID: number;
	@Input() clientPlanID: number;
	@Input() clientMemberID: number;

	@Output()
	save: EventEmitter<CreateDependentModel> = new EventEmitter<CreateDependentModel>();
	@Output()
	close: EventEmitter<void> = new EventEmitter<void>();

	/* ========================== internal state ========================== */
	model: CreateDependentModel = new CreateDependentModel();
	saving: boolean = false;

	/* ============================== lookups ============================== */
	relationshipsLookup$: Observable<Lookup[]>;
	loadingRelationshipsLookup: boolean = true;

	constructor(
		private toastr: ToastrService,
		private lookupService: LookupService,
		private membersService: MembersService,
		private modalService: BsModalService,
	) {
	}

	ngOnInit() {
		this.relationshipsLookup$ = this.lookupService.getLookup<Lookup>(LookupCodes.DependentRelationshipType)
			.pipe(
				tap(() => this.loadingRelationshipsLookup = false),
				catchError(err => {
					this.loadingRelationshipsLookup = false;
					return this.showLookupErrorToast('Relationships', err);
				})
			);
	}

	onDependentChange(member: ClientPlanMemberLookup) {
		this.model.clientMemberPlanID = member?.clientMemberPlanID;
	}

	onClientPlanChange(selection: { clientID?: number, clientPlanID?: number }) {
		this.clientID = selection.clientID;
		this.clientPlanID = selection.clientPlanID;

		// TODO: REMOVE ONCE memberChange FIRES CORRECTLY ON CLIENT/PLAN CHANGE
		this.model.clientMemberPlanID = null;
		this.model.planID = selection.clientPlanID;
	}

	async onSave() {
		try {
			this.saving = true;

			//let response = this.isNew ?
			//	await this.membersService.createMemberPlan(this.clientMemberID, this.model) :
			//	await this.membersService.updateMemberPlan(this.clientMemberID, this.model);

			const result = await this.membersService.makeMemberDependent(
				this.model.clientMemberPlanID,
				this.clientMemberID,
				this.model.planID,
				this.model.relationshipTypeCode);

			this.toastr.success(`Successfully saved dependent.`);

			this.save.emit();
			this.close.emit();
		} catch (error: any) {
			this.toastr.error(Messages.ErrorRetry, `Failed to Save Dependent`);
		} finally {
			this.saving = false;
		}
	}

	onClose(form: NgForm) {
		if (form && form.dirty) {
			this.modalService.show<ActionConfirmationComponent>(ActionConfirmationComponent, {
				class: 'modal-md',
				ignoreBackdropClick: true,
				animated: false,
				initialState: {
					title: Messages.DiscardChangesModalTitle,
					messageHtml: Messages.DiscardChangesModalMessage,
					actionTitle: Messages.DiscardChangesConfirmButtonText,
					actionButtonClass: Messages.DiscardChangesConfirmButtonClass,
					actionFunc: async () => {
						this.close.emit();
						return true;
					},
					cancelTitle: Messages.DiscardChangesCancelButtonText,
					cancelButtonClass: Messages.DiscardChangesCancelButtonClass
				}
			});
		} else {
			this.close.emit();
		}
	}

	private showLookupErrorToast(lookupName: string, error: any) {
		this.toastr.error(Messages.ErrorRetry, `Failed to Load '${lookupName}'`);
		return throwError(() => error);
	}
}
