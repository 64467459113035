<div class="modal-header px-4">
	<h5 class="modal-title">
		Delete File
	</h5>

	<button type="button" class="btn-close" (click)="onClose()">
		<span class="visually-hidden">&times;</span>
	</button>
</div>

<div class="modal-body px-4 py-3">
	Are you sure you want to delete <strong><em>{{ fileName }}</em></strong> file?
</div>

<div class="modal-footer bg-white px-4">
	<button type="button" class="btn btn-outline-primary" (click)="onClose()" [disabled]="deleting">
		Cancel
	</button>

	<button type="submit" class="btn btn-danger" (click)="onDelete()" [disabled]="deleting">
		<fa-icon *ngIf="deleting" class="me-2" [icon]="faSpinnerThird" [spin]="true"></fa-icon>
		Delete
	</button>
</div>
