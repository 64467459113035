// import { MemberSelectComponent } from './components/member-select/member-select.component';
import { MemberCreateModalComponent } from './components/member-create-modal/member-create-modal.component';
import { MemberPlansListComponent } from './components/member-plans-list/member-plans-list.component';
import { MemberPlansFormComponent } from './components/member-plans-form/member-plans-form.component';
import { MemberPlanDeactivateModalComponent } from './components/member-plan-deactivate-modal/member-plan-deactivate-modal.component';
import { MemberDependentsListComponent } from './components/member-dependents-list/member-dependents-list.component';
import { MemberDependentsFormComponent } from './components/member-dependents-form/member-dependents-form.component';
import { MemberCasesListComponent } from './components/member-cases-list/member-cases-list.component';

// export { MemberSelectComponent } from './components/member-select/member-select.component';
export { MemberCreateModalComponent } from './components/member-create-modal/member-create-modal.component';
export { MemberPlansListComponent } from './components/member-plans-list/member-plans-list.component';
export { MemberPlansFormComponent } from './components/member-plans-form/member-plans-form.component';
export { MemberPlanDeactivateModalComponent } from './components/member-plan-deactivate-modal/member-plan-deactivate-modal.component';
export { MemberDependentsListComponent } from './components/member-dependents-list/member-dependents-list.component';
export { MemberDependentsFormComponent } from './components/member-dependents-form/member-dependents-form.component';
export { MemberCasesListComponent } from './components/member-cases-list/member-cases-list.component';

export const COMPONENTS: any[] = [
	// MemberSelectComponent,
	MemberCreateModalComponent,
	MemberPlansListComponent,
	MemberPlansFormComponent,
	MemberPlanDeactivateModalComponent,
	MemberDependentsListComponent,
	MemberDependentsFormComponent,
	MemberCasesListComponent
];
