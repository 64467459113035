<div class="card h-100">
	<div class="row g-0 h-100">
		<div class="col-md-7 h-100 card-cover">
			<h5 class="mb-0">Guidance<br />Request</h5>
		</div>

		<div class="col-md-17 h-100 px-3">
			<div class="card-body">
				<div class="row mb-3">
					<label class="col-9 col-form-label" for="networkName">
						Network name
					</label>

					<div class="col-15">
						<input type="text" class="form-control" id="networkName" name="networkName" [(ngModel)]="case.networkName" placeholder="Network name" autocomplete="off" />
					</div>
				</div>

				<div class="row mb-3">
					<label class="col-9 col-form-label" for="specialtyCode">
						Specialties
					</label>

					<div class="col-15">
						<app-specialties-select [closeable]="false" (change)="onSpecialtiesChange($event)">
						</app-specialties-select>
					</div>
				</div>

				<div class="row mb-3 mt-4">
					<label class="col-9 col-form-label"
						[ngClass]="{ 'col-24 border-bottom' : case.providers?.length > 0 }">
						Providers
					</label>

					<div [class.col-15]="!case.providers || case.providers.length == 0 && !showAddProviderForm"
						[class.col-24]="case.providers?.length > 0 || showAddProviderForm">
						<ng-container *ngIf="case.providers?.length > 0">
							<table class="table table-sm">
								<thead>
									<tr>
										<th>Name</th>
										<th>Location</th>
										<th style="max-width: 160px;">Contacts</th>
										<th style="width: 1px;"></th>
									</tr>
								</thead>

								<tbody class="border-bottom">
									<ng-container *ngFor="let provider of case.providers">
										<tr>
											<td class="small">
												{{ provider.providerName }}
											</td>

											<td class="small">
												{{ provider.facilityAddress1 }} {{ provider.facilityAddress2 }}
												<br *ngIf="provider.facilityAddress1 || provider.facilityAddress2" />
												{{ provider.facilityCity ? provider.facilityCity + ',' : '' }} {{
												provider.facilityStateCode }} {{ provider.facilityZipCode }}
											</td>

											<td class="small">
												<ng-container *ngIf="provider.facilityPhoneNumber">
													<div class="d-flex flex-nowrap align-items-center text-truncate"
														style="max-width: 160px;">
														<span class="text-secondary">Phone:</span>
														<a class="d-inline-flex align-items-center ms-1"
															[href]="'tel:+1' + provider.facilityPhoneNumber">
															{{ provider.facilityPhoneNumber | phone }}
														</a>
													</div>
												</ng-container>

												<ng-container *ngIf="provider.facilityFaxNumber">
													<div class="d-flex flex-nowrap align-items-center text-truncate"
														style="max-width: 160px;">
														<span class="text-secondary">Fax:</span>
														<a class="d-inline-flex align-items-center ms-1"
															[href]="'tel:+1' + provider.facilityFaxNumber">
															{{ provider.facilityFaxNumber | phone }}
														</a>
													</div>
												</ng-container>

												<ng-container *ngIf="provider.facilityEmailAddress">
													<div class="d-flex flex-nowrap align-items-center text-truncate"
														style="max-width: 160px;">
														<span class="text-secondary">Email:</span>
														<a class="d-inline-flex align-items-center ms-1"
															[href]="'mailto:' + provider.facilityEmailAddress">
															{{ provider.facilityEmailAddress }}
														</a>
													</div>
												</ng-container>
											</td>

											<td class="px-0">
												<button type="button" class="btn btn-icon-only px-1"
													(click)="onRemoveProvider(provider)">
													<fa-icon [icon]="faXmark"></fa-icon>
												</button>
											</td>
										</tr>
									</ng-container>
								</tbody>
							</table>
						</ng-container>

						<ng-container *ngIf="!showAddProviderForm">
							<button type="button" class="btn btn-sm btn-outline-primary"
								(click)="onShowAddProviderForm()">
								<fa-icon class="me-2" [icon]="faPlus"></fa-icon> Add Provider
							</button>
						</ng-container>

						<ng-container *ngIf="showAddProviderForm">
							<div class="card mt-2">
								<div class="card-body bg-light rounded">
									<app-provider-facility-select [showProviderSelect]="true"
										[showFacilitySelect]="true" [searchNearZipCode]="case.memberZipCode"
										[(providerNPI)]="addProviderModel.providerNPI"
										[(providerName)]="addProviderModel.providerName"
										[(facilityNPI)]="addProviderModel.facilityNPI"
										[(facilityName)]="addProviderModel.facilityName"
										[(address1)]="addProviderModel.facilityAddress1"
										[(address2)]="addProviderModel.facilityAddress2"
										[(city)]="addProviderModel.facilityCity"
										[(stateCode)]="addProviderModel.facilityStateCode"
										[(zipCode)]="addProviderModel.facilityZipCode"
										[(phoneNumber)]="addProviderModel.facilityPhoneNumber"
										[(faxNumber)]="addProviderModel.facilityFaxNumber"
										[(emailAddress)]="addProviderModel.facilityEmailAddress">
									</app-provider-facility-select>

									<hr />

									<div class="row">
										<div class="col">
											<button type="button" class="btn btn-primary" (click)="onAddProvider()">
												Add Provider
											</button>

											<button type="button" class="btn btn-outline-primary ms-2"
												(click)="onHideAddProviderForm()">
												Cancel
											</button>
										</div>
									</div>
								</div>
							</div>
						</ng-container>
					</div>
				</div>

				<div class="row mb-3 mt-4">
					<label class="col-9 col-form-label"
						[ngClass]="{ 'col-24 border-bottom' : case.facilities?.length > 0 }">
						Facilities
					</label>

					<div [class.col-15]="!case.facilities || case.facilities.length == 0 && !showAddFacilityForm"
						[class.col-24]="case.facilities?.length > 0 || showAddFacilityForm">
						<ng-container *ngIf="case.facilities?.length > 0">
							<table class="table table-sm">
								<thead>
									<tr>
										<th>Name</th>
										<th>Location</th>
										<th style="max-width: 160px;">Contacts</th>
										<th style="width: 1px;"></th>
									</tr>
								</thead>

								<tbody class="border-bottom">
									<ng-container *ngFor="let facility of case.facilities">
										<tr>
											<td class="small">
												{{ facility.facilityName }}
											</td>

											<td class="small">
												{{ facility.facilityAddress1 }} {{ facility.facilityAddress2 }}
												<br *ngIf="facility.facilityAddress1 || facility.facilityAddress2" />
												{{ facility.facilityCity ? facility.facilityCity + ',' : '' }} {{
												facility.facilityStateCode }} {{ facility.facilityZipCode }}
											</td>

											<td class="small">
												<ng-container *ngIf="facility.facilityPhoneNumber">
													<div class="d-flex flex-nowrap align-items-center text-truncate"
														style="max-width: 160px;">
														<span class="text-secondary">Phone:</span>
														<a class="d-inline-flex align-items-center ms-1"
															[href]="'tel:+1' + facility.facilityPhoneNumber">
															{{ facility.facilityPhoneNumber | phone }}
														</a>
													</div>
												</ng-container>

												<ng-container *ngIf="facility.facilityFaxNumber">
													<div class="d-flex flex-nowrap align-items-center text-truncate"
														style="max-width: 160px;">
														<span class="text-secondary">Fax:</span>
														<a class="d-inline-flex align-items-center ms-1"
															[href]="'tel:+1' + facility.facilityFaxNumber">
															{{ facility.facilityFaxNumber | phone }}
														</a>
													</div>
												</ng-container>

												<ng-container *ngIf="facility.facilityEmailAddress">
													<div class="d-flex flex-nowrap align-items-center text-truncate"
														style="max-width: 160px;">
														<span class="text-secondary">Email:</span>
														<a class="d-inline-flex align-items-center ms-1"
															[href]="'mailto:' + facility.facilityEmailAddress">
															{{ facility.facilityEmailAddress }}
														</a>
													</div>
												</ng-container>
											</td>

											<td class="px-0">
												<button type="button" class="btn btn-icon-only px-1"
													(click)="onRemoveFacility(facility)">
													<fa-icon [icon]="faXmark"></fa-icon>
												</button>
											</td>
										</tr>
									</ng-container>
								</tbody>
							</table>
						</ng-container>

						<ng-container *ngIf="!showAddFacilityForm">
							<button type="button" class="btn btn-sm btn-outline-primary"
								(click)="onShowAddFacilityForm()">
								<fa-icon class="me-2" [icon]="faPlus"></fa-icon> Add Facility
							</button>
						</ng-container>

						<ng-container *ngIf="showAddFacilityForm">
							<div class="card mt-2">
								<div class="card-body bg-light rounded">
									<app-provider-facility-select [showProviderSelect]="false"
										[showFacilitySelect]="true" [searchNearZipCode]="case.memberZipCode"
										[(facilityNPI)]="addFacilityModel.facilityNPI"
										[(facilityName)]="addFacilityModel.facilityName"
										[(address1)]="addFacilityModel.facilityAddress1"
										[(address2)]="addFacilityModel.facilityAddress2"
										[(city)]="addFacilityModel.facilityCity"
										[(stateCode)]="addFacilityModel.facilityStateCode"
										[(zipCode)]="addFacilityModel.facilityZipCode"
										[(phoneNumber)]="addFacilityModel.facilityPhoneNumber"
										[(faxNumber)]="addFacilityModel.facilityFaxNumber"
										[(emailAddress)]="addFacilityModel.facilityEmailAddress">
									</app-provider-facility-select>

									<hr />

									<div class="row">
										<div class="col">
											<button type="button" class="btn btn-primary" (click)="onAddFacility()">
												Add Facility
											</button>

											<button type="button" class="btn btn-outline-primary ms-2"
												(click)="onHideAddFacilityForm()">
												Cancel
											</button>
										</div>
									</div>
								</div>
							</div>
						</ng-container>
					</div>
				</div>

				<div class="row mb-3">
					<label class="col-9 col-form-label" for="procedures">
						Procedures
					</label>

					<div class="col-15">
						<app-procedure-input (procedureChange)="onProcedureChange($event)">

						</app-procedure-input>
					</div>
				</div>

				<div class="row mb-3">
					<label class="col-9 col-form-label" for="preferredGenderCode">
						Preferred gender
					</label>

					<div class="col-6">
						<app-gender-select [id]="'preferredGenderCode'" [name]="'preferredGenderCode'"
							[(code)]="case.preferredGenderCode" [closeable]="false" [placeholder]="'Preferred gender'">
						</app-gender-select>
					</div>
				</div>

				<div class="row mt-3">
					<label class="col-9 col-form-label" for="otherPreferences">
						Other preferences
					</label>

					<div class="col-15">
						<textarea class="form-control" id="otherPreferences" name="otherPreferences" [(ngModel)]="case.otherPreferences" placeholder="Other preferences" rows="4" autocomplete="off"></textarea>
					</div>
				</div>

				<hr />

				<div class="row">
					<label class="col-9 col-form-label" for="zipCode">
						Zip code
					</label>
					<div class="col-5">
						<app-zipcode-input [id]="'zipCode'" [name]="'zipCode'" [(ngModel)]="case.zipCode">
						</app-zipcode-input>
					</div>
				</div>

				<div class="row mt-3">
					<label class="col-9 col-form-label" for="canTravelDistanceMiles">
						Travel distance (mi)
					</label>
					<div class="col-8">
						<input type="number" class="form-control" id="canTravelDistanceMiles" name="canTravelDistanceMiles" [(ngModel)]="case.canTravelDistanceMiles" placeholder="Travel distance" autocomplete="off" />
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
