<div class="input-group">
	<ng-select class="custom-ng-select specialty-select"
			   [id]="id"
			   [name]="name"
			   [items]="specialities"
			   [bindLabel]="'name'"
			   [(ngModel)]="selectedSpecialties"
			   (ngModelChange)="onSpecialtiesChange($event)"
			   [clearable]="false"
			   [multiple]="true"
			   [searchable]="true"
			   [loading]="loadingSpecialties"
			   [loadingText]="'loading specialities...'"
			   [notFoundText]="'no specialty matching your search'"
			   [readonly]="readonly"
			   [placeholder]="placeholder">

		<ng-template ng-option-tmp let-specialty="item">
			<div class="py-2">
				<div class="text-nowrap text-truncate" [title]="specialty.name">
					{{ specialty.name }}
				</div>

				<div class="text-muted text-nowrap small">
					{{ specialty.code }}
				</div>
			</div>
		</ng-template>

	</ng-select>

	<ng-container *ngIf="closeable">
		<button type="button" class="btn btn-outline-secondary" (click)="onSave()">
			<fa-icon [icon]="faCheck"></fa-icon>
		</button>

		<button type="button" class="btn btn-outline-secondary" (click)="onClose()">
			<fa-icon [icon]="faXmark"></fa-icon>
		</button>
	</ng-container>
</div>
