import { Component, OnInit, Input, Output, EventEmitter, Optional } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';

import { CaseTransportation } from '@app/data/cases/models';

import { LookupService } from '@app/settings/services';
import { State } from '@app/settings/models';

import { Messages } from '@app/constants';

@Component({
	selector: 'app-create-transportation',
	templateUrl: 'create-transportation.component.html',
	styleUrls: ['create-transportation.component.scss'],
	viewProviders: [{
		provide: ControlContainer,
		deps: [[Optional, NgForm]],
		useFactory: (ngForm: NgForm) => ngForm
	}]
})
export class CaseCreateTransportationComponent implements OnInit {
	loadingStates: boolean = false;
	states: State[];

	@Input()
	case: CaseTransportation;

	@Output()
	caseChange: EventEmitter<CaseTransportation> = new EventEmitter<CaseTransportation>();

	constructor(
		private lookupService: LookupService,
		private toastrService: ToastrService
	) {
	}

	ngOnInit() {
		this.onReloadStates();
	}

	async onReloadStates() {
		try {
			this.loadingStates = true;

			this.states = await this.lookupService.getStates();
		} catch (error: any) {
			this.toastrService.error(Messages.ErrorRetry, `Failed to Load States`);
		} finally {
			this.loadingStates = false;
		}
	}
}
