export class User {
	portalUserID: number;
	firstName: string;
	lastName: string;
	fullName: string;
	userImageURL: string;
	clientID: number;
	clientName: string;
	roleNames: string[];
	roleCodes: string[];
	emailAddress: string;
	timeZoneIana: string;
	auth0UserID: string;
	version: string;

	// UI Only
	loading: boolean = false;
	deleting: boolean = false;
}

export class Role {
	code: string;
	name: string;
	description: string;
	permissions: string;
	permissionNames: string[];
	permissionCodes: string[];
	version: string;

	// UI Only
	loading: boolean = false;
	deleting: boolean = false;
}

export class Permission {
	code: string;
	name: string;
	description: string;
	version: string;

	// UI Only
	loading: boolean = false;
	deleting: boolean = false;
}

export class Client {
	clientID: number;
	fullName: string;
	shortName: string;
	clientLogoUrl: string;
	isClientActive: boolean;
}

export class Auth0User {
	userID: string;
	emailAddress: string;
	fullName: string;
	client: string;
}
