import { Component, OnInit, Input, Output, EventEmitter, ViewChild, Optional } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';

import { ClientPlanMemberLookup } from '@app/shared/models';
import { CaseTypes } from '@app/data/cases/models';

import { MembersService } from '@app/data/members/services';
import { MemberSelectComponent } from '@app/shared/components/member-select/member-select.component';
import { DefaultMemberModel } from '@app/shared/models/member.models';
import { MemberDashboard, Member, MemberDependent } from '@app/data/members/models';

import { LookupService } from '@app/settings/services';
import { LookupValue } from '@app/settings/models';

import { Messages } from '@app/constants';

@Component({
	selector: 'app-create-patient-information',
	templateUrl: 'create-patient-information.component.html',
	styleUrls: ['create-patient-information.component.scss'],
	viewProviders: [{
		provide: ControlContainer,
		deps: [[Optional, NgForm]],
		useFactory: (ngForm: NgForm) => ngForm
	}]
})
export class CaseCreatePatientInformationComponent implements OnInit {
	@Input()
	case: CaseTypes;
	@Input()
	defaultMember: DefaultMemberModel;

	@Output()
	caseChange: EventEmitter<CaseTypes> = new EventEmitter<CaseTypes>();

	@ViewChild('patientSelect', { static: false })
	patientSelect: MemberSelectComponent;

	// selectedPatient: Member;
	selectedPatient: ClientPlanMemberLookup;
	selectedPatientFullName: string;
	loadingSelectedPatiendDetails: boolean = false;

	selectedPatientDependents: MemberDependent[] = [];
	selectedPatientContact: MemberDependent;
	loadingSelectedPatientDependents: boolean = false;
	selectedPatientContactIsOther: boolean = false;

	constructor(
		private membersService: MembersService,
		private lookupService: LookupService,
		private toastrService: ToastrService
	) {
	}

	ngOnInit() {
	}

	loadingGenders: boolean = false;
	genders: LookupValue[];
	async onReloadGenders() {
		try {
			this.loadingGenders = true;

			this.genders = await this.lookupService.getLookupValues('Gender');
		} catch (error: any) {
			this.toastrService.error(Messages.ErrorRetry, `Failed to Load Genders`);
		} finally {
			this.loadingGenders = false;
		}
	}

	loadingLanguages: boolean = false;
	languages: LookupValue[];
	async onReloadLanguages() {
		try {
			this.loadingLanguages = true;

			this.languages = await this.lookupService.getLookupValues('Language');
		} catch (error: any) {
			this.toastrService.error(Messages.ErrorRetry, `Failed to Load Languages`);
		} finally {
			this.loadingLanguages = false;
		}
	}

	loadingRelationshipTypes: boolean = false;
	relationshipTypes: LookupValue[];
	async onReloadRelationshipTypes() {
		try {
			this.loadingRelationshipTypes = true;

			this.relationshipTypes = await this.lookupService.getLookupValues('ContactRelationshipType');
		} catch (error: any) {
			this.toastrService.error(Messages.ErrorRetry, `Failed to Load Relationship Types`);
		} finally {
			this.loadingRelationshipTypes = false;
		}
	}

	async onClientPlanChange(selection: { clientID?: number, clientPlanID?: number }) {
		console.log('***onClientPlanChange', selection);

		if (this.case.clientID !== selection.clientID ||
			this.case.clientPlanID !== selection.clientPlanID
		) {
			this.case.clientID = selection.clientID;
			// this.case.clientName = selection.clientName;
			this.case.clientPlanID = selection.clientPlanID;
			// this.case.clientPlanName = selection.clientPlanName;

			// clear selected patient details and reload members for the new client/plan
			this.selectedPatient = null;
			this.selectedPatientFullName = null;
			this.selectedPatientDependents = [];
			this.selectedPatientContact = null;

			this.patientSelect.onReloadMembers();

			this.caseChange.emit(this.case);
		}
	}

	async onPatientChange(member: ClientPlanMemberLookup) {
		console.log('***onPatientChange', member);

		this.selectedPatient = member;
		this.selectedPatientFullName = member.fullName;
		this.selectedPatientContact = null;

		this.case.clientMemberPlanID = member.clientMemberPlanID;
		this.case.memberFirstName = this.selectedPatient.firstName;
		this.case.memberLastName = this.selectedPatient.lastName;
		this.case.memberFullName = this.selectedPatient.fullName;
		this.case.memberZipCode = this.selectedPatient.zipCode;
		this.case.memberIsDeceased = this.selectedPatient.isDeceased;

		this.caseChange.emit(this.case);

		this.onReloadSelectedPatientDependents();

		// TODO: REMOVE
		// try {
		// 	this.loadingSelectedPatiendDetails = true;

		// 	// this.selectedPatient = await this.membersService.getMember(member.clientMemberID);
		// 	this.selectedPatient = member;
		// 	this.selectedPatientContact = null;

		// 	this.case.clientMemberPlanID = member.clientMemberPlanID;
		// 	this.case.memberFirstName = this.selectedPatient.firstName;
		// 	this.case.memberLastName = this.selectedPatient.lastName;
		// 	this.case.memberFullName = this.selectedPatient.fullName;
		// 	this.case.memberZipCode = this.selectedPatient.zipCode;
		// 	this.case.memberIsDeceased = this.selectedPatient.isDeceased;

		// 	this.caseChange.emit(this.case);

		// 	this.onReloadSelectedPatientDependents();
		// } catch (error: any) {
		// 	this.toastrService.error(Messages.ErrorRetry, `Failed to load patient info.`);
		// } finally {
		// 	this.loadingSelectedPatiendDetails = false;
		// }
	}

	async onReloadSelectedPatientDependents() {
		try {
			this.loadingSelectedPatientDependents = true;

			this.selectedPatientDependents = await this.membersService.getMemberDependents(this.selectedPatient.clientMemberID);

			this.selectedPatientDependents.push({
				...new MemberDependent(),
				fullName: 'Other'
			});
		} catch (error: any) {
			this.toastrService.error(Messages.ErrorRetry, `Failed to Load Dependents`);
		} finally {
			this.loadingSelectedPatientDependents = false;
		}
	}

	async onPatientContactChange(member: MemberDependent) {
		this.selectedPatientContactIsOther = member.fullName == 'Other';

		if (this.selectedPatientContactIsOther) {
			if (!this.genders) {
				this.onReloadGenders();
			}

			if (!this.languages) {
				this.onReloadLanguages();
			}

			if (!this.relationshipTypes) {
				this.onReloadRelationshipTypes();
			}
		}
	}
}
