<div class="row">
	<div class="col-24">
		<ng-container *ngIf="!memberCases || memberCases?.length == 0">
			<app-empty class="bg-light rounded" [message]="'no cases'"></app-empty>
		</ng-container>

		<ng-container *ngIf="memberCases && memberCases.length > 0">
			<table class="table table-hover small">
				<thead class="table-light">
					<tr>
						<th style="width: 100px;">Status/<br />Case ID</th>
						<th class="text-nowrap">Case Type</th>
						<th>Subject</th>
						<th class="text-center text-nowrap">Assigned to</th>
						<th class="text-center text-nowrap">Case dates</th>
						<th class="text-center text-nowrap">Last Activity</th>
						<th style="width: 1px;"></th>
					</tr>
				</thead>

				<tbody>
					<ng-container *ngFor="let memberCase of openMemberCases">
						<ng-container *ngTemplateOutlet="memberCaseTemplate; context: { 'memberCase': memberCase }"></ng-container>
					</ng-container>

					<ng-container *ngIf="closedMemberCases?.length > 0">
						<tr class="heading-table-row">
							<td colspan="8">
								<h6 class="mb-0">
									Closed Cases
								</h6>
							</td>
						</tr>

						<ng-container *ngFor="let memberCase of closedMemberCases">
							<ng-container *ngTemplateOutlet="memberCaseTemplate; context: { 'memberCase': memberCase }"></ng-container>
						</ng-container>
					</ng-container>
				</tbody>
			</table>
		</ng-container>
	</div>
</div>

<ng-template #memberCaseTemplate let-memberCase="memberCase">
	<tr class="table-row-clickable align-top" [class.opacity-50]="!memberCase.isOpen" (click)="onNavigateToCaseDetails(memberCase)">
		<td class="text-center px-2">
			<div class="badge bg-success rounded-pill w-100">
				{{ memberCase.caseStatusName }}
			</div>

			<div class="text-secondary text-center text-nowrap">
				{{ memberCase.caseCode }}
			</div>
		</td>

		<td class="text-center text-nowrap px-2">
			{{ memberCase.caseTypeName }}
		</td>

		<td class="px-2">
			<div>
				{{ memberCase.subject }}
			</div>

			<div class="text-secondary">
				{{ memberCase.description }}
			</div>
		</td>

		<td class="text-center px-2">
			{{ memberCase.assignedToName }}
		</td>

		<td class="text-center text-nowrap">
			<ng-container *ngIf="memberCase.isOpen">
				<div>{{ memberCase.dateOpened | date:'M/d/yyyy' }}</div>
				<div class="text-muted">({{ memberCase.ageDays }} days old)</div>
			</ng-container>

			<ng-container *ngIf="!memberCase.isOpen">
				<div>{{ memberCase.dateOpened | date:'M/d/yyyy' }}</div>
				<div style="line-height: 4px;">-</div>
				<div class="text-muted">{{ memberCase.dateClosed | date:'M/d/yyyy' }}</div>
			</ng-container>
		</td>

		<td class="text-center text-nowrap px-2">
			{{ memberCase.lastActivityDate | amTimeAgo }}
		</td>

		<td class="text-center px-0">
			<a class="btn btn-icon-only px-0">
				<fa-icon [icon]="faChevronRight"></fa-icon>
			</a>
		</td>
	</tr>
</ng-template>
