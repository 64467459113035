import { Component, Input } from '@angular/core';

import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';

@Component({
	selector: 'app-spinner-wheel',
	templateUrl: 'spinner-wheel.component.html',
	styleUrls: ['spinner-wheel.component.scss']
})
export class SpinnerWheelComponent {
	spinnerIcon = faSpinnerThird;

	@Input()
	message: string;

	@Input()
	class: string = '';
}
