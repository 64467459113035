import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-worker-value',
	templateUrl: 'worker-value.component.html',
	styleUrls: ['worker-value.component.scss']
})
export class WorkerValueComponent {
	@Input() class: string;
	@Input() size: number = 20;
	@Input() fullName: string;
	@Input() profileImageSrc: string;
}
