<div class="input-group">
	<ng-select class="custom-ng-select provider-select"
			   [id]="id"
			   [name]="name"
			   [items]="genders"
			   [bindLabel]="'name'"
			   [bindValue]="'code'"
			   [(ngModel)]="code"
			   (ngModelChange)="codeChange.emit($event)"
			   [clearable]="true"
			   [multiple]="false"
			   [searchable]="false"
			   [loading]="loadingGenders"
			   [loadingText]="'loading genders...'"
			   [placeholder]="placeholder">
	</ng-select>

	<ng-container *ngIf="closeable">
		<button type="button" class="btn btn-outline-secondary" (click)="close.emit()">
			<fa-icon [icon]="faXmark"></fa-icon>
		</button>
	</ng-container>
</div>
