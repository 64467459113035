<nav aria-label="breadcrumb">
	<ol class="breadcrumb py-0 my-0">
		<ng-container *ngFor="let breadcrumb of breadcrumbs; let i = index">
			<li *ngIf="breadcrumb.label" class="breadcrumb-item" [class.active]="isCurrent(i)">
				<a *ngIf="!isCurrent(i)" [routerLink]="breadcrumb.route">{{ breadcrumb.label }}</a>
				<span *ngIf="isCurrent(i)">{{ breadcrumb.label }}</span>
			</li>
		</ng-container>
	</ol>
</nav>
