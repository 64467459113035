<div class="modal-header px-4">
    <h5 class="modal-title">
        Digital Authorization (MIRF) <span class="badge rounded-pill" [class.text-bg-success]="!model.isExpired"
            [class.text-bg-warning]="model.isExpired">{{ model.isExpired ? 'Expired' : 'Active' }}</span>
    </h5>

    <button type="button" class="btn-close" (click)="onClose()">
        <span class="visually-hidden">&times;</span>
    </button>
</div>

<div class="modal-body px-4 py-3">
    <ul class="list-group">
        <li class="list-group-item d-flex justify-content-between align-items-center" *ngIf="model.customName">
            <span class="field-label">Authorization name</span>
            <span class="field-value">{{ model.customName }}</span>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center">
            <span class="field-label">Is generic authorization text?</span>
            <span class="field-value">{{ model.isGeneric ? 'Yes' : 'No' }}</span>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center">
            <span class="field-label">Expiration date</span>
            <span class="field-value"><span class="badge rounded-pill" [class.text-bg-success]="!model.isExpired"
                    [class.text-bg-warning]="model.isExpired">{{ model.expirationDate }}</span></span>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center">
            <span class="field-label">Language</span>
            <span class="field-value">{{ model.languageName }}</span>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center" *ngIf="model.providerName">
            <span class="field-label">Provider (NPI)</span>
            <span class="field-value">{{ model.providerName }} <span *ngIf="model.providerNPI">({{ model.providerNPI
                    }})</span></span>
        </li>
        <li class="list-group-item" *ngIf="model.customText">
            <div class="field-label mb-3">Custom text</div>
            <div class="field-value" [innerHTML]="model.customText"></div>
        </li>
    </ul>

    <h6 class="mt-4 mb-3">Digital signature</h6>
    <ul class="list-group">
        <li class="list-group-item d-flex justify-content-between align-items-center">
            <span class="field-label">Full name</span>
            <span class="field-value">{{ model.fullName }}</span>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center">
            <span class="field-label">Personal representative</span>
            <span class="field-value" [class.value-empty]="!model.personalRepresentative">{{
                model.personalRepresentative || 'empty' }}</span>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center">
            <span class="field-label">Phone number</span>
            <span class="field-value" [class.value-empty]="!model.phoneNumber">{{ (model.phoneNumber || 'empty') | phone }}</span>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center">
            <span class="field-label">Email address</span>
            <span class="field-value" [class.value-empty]="!model.emailAddress">{{ model.emailAddress || 'empty' }}</span>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center">
            <span class="field-label">Date signed</span>
            <span class="field-value">{{ model.dateSigned }}</span>
        </li>
    </ul>

    <ul class="list-group mt-3">
        <li class="list-group-item d-flex justify-content-between align-items-center">
            <span class="field-label">Signature</span>
            <img height="100" class="img-thumbnail" src="data:image/png;base64,{{ model.signatureBase64 }}" alt="digital signature" />
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center">
            <span class="field-label">Digital signature code</span>
            <span class="field-value">{{ model.digitalSignatureCode }}</span>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center">
            <span class="field-label">IP address</span>
            <span class="field-value" [class.value-empty]="!model.ipAddress">{{ model.ipAddress || 'empty' }}</span>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center">
            <span class="field-label">Device info</span>
            <button class="btn btn-sm btn-primary rounded-pill" [popover]="deviceInfoPopover" data-bs-custom-class=""
                container="body" triggers="mouseenter:mouseleave" *ngIf="model.deviceInfo"><fa-icon [icon]="deviceInfoIcon"></fa-icon></button>
            <span class="field-value value-empty" *ngIf="!model.deviceInfo">empty</span>
        </li>
    </ul>

    <h6 class="mt-4 mb-3" *ngIf="model.dateFirstRequested">Authorization request</h6>
    <ul class="list-group">
        <li class="list-group-item d-flex justify-content-between align-items-center" *ngIf="model.dateFirstRequested">
            <span class="field-label">Date first requested</span>
            <span class="field-value">{{ model.dateFirstRequested }}</span>
        </li>
        <li class="list-group-item d-flex justify-content-between align-items-center" *ngIf="model.dateLastRequested">
            <span class="field-label">Date last requested</span>
            <span class="field-value">{{ model.dateLastRequested }}</span>
        </li>
    </ul>
</div>

<div class="modal-footer bg-white px-4">
    <button type="submit" class="btn btn-primary" (click)="onClose()">
        Close
    </button>
</div>

<ng-template #deviceInfoPopover>
    <div class="small">
        <h6>Signing device info</h6>
        <pre class="text-wrapped-spaced">
            {{ deviceInfoObject | json }}
        </pre>
    </div>
</ng-template>