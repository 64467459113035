<div class="card">
	<div class="card-body p-0">
		<app-aside-widget #tasksAsideWidget [title]="title" [loading]="loadingTasks" [count]="totalTasks" (retry)="onLoadTasks()">
			<ng-template #asideWidgetActions>
				<button type="button" class="btn btn-sm btn-primary" (click)="onShowNewTaskForm()">
					New Task
				</button>
			</ng-template>

			<!-- Filters will go here -->
			<ng-content></ng-content>

			<ng-container *ngIf="showNewTaskForm">
				<div class="mx-3">
					<ng-container *ngTemplateOutlet="editTaskForm"></ng-container>
				</div>
			</ng-container>

			<ng-container *ngIf="tasks?.length == 0">
				<div class="mx-3 mb-3">
					<app-empty class="bg-light rounded" [message]="'no ' + title.toLowerCase()"></app-empty>
				</div>
			</ng-container>

			<ng-container *ngIf="tasks?.length > 0">
				<hr />

				<ng-container *ngFor="let task of openTasks">
					<ng-container *ngIf="!task.editing">
						<div class="mx-3 mb-3">
							<div class="d-flex justify-content-between align-items-center">
								<div class="d-flex flex-fill align-items-start">
									<div class="flex-grow-0">
										<div class="form-check form-check-inline me-0" *ngIf="!task.completing">
											<input class="form-check-input" type="checkbox" [checked]="!task.isOpen" (change)="onToogleComplete(task)" />
										</div>
										<fa-icon class="me-2" [icon]="spinnerIcon" [spin]="true" *ngIf="task.completing"></fa-icon>
									</div>

									<div class="d-flex flex-column flex-grow-1 small">
										<div class="mb-2" [ngClass]="{ 'bg-light p-3': task.showMoreDetails }">
											<span class="badge text-bg-danger rounded-pill float-start me-2" *ngIf="task.isUrgent">Urgent</span>
											<span class="d-inline me-1" [class.text-wrapped-spaced]="(task.hasTrimedDetails && task.showMoreDetails) || !task.hasTrimedDetails"
												  [innerHTML]="task.hasTrimedDetails && !task.showMoreDetails ? task.trimedDetails : task.taskDetails"></span>

											<a [ngClass]="{ 'd-inline-block' : !task.showMoreDetails, 'd-block mt-2' : task.showMoreDetails }"
											   href="javascript:void(0);"
											   (click)="task.showMoreDetails = !task.showMoreDetails"
											   *ngIf="task.hasTrimedDetails">
												{{ task.showMoreDetails ? 'Show less' : 'Show more' }}
											</a>
										</div>

										<div class="d-flex align-items-center text-secondary">
											<fa-icon class="me-2" [icon]="spinnerIcon" [spin]="true" *ngIf="task.updatingDueDate"></fa-icon>

											<app-inline-datepicker class="text-nowrap"
																   format="EEE, MMM d"
																   [model]="task.dueDate"
																   (modelChange)="onDueDateChange(task, $event)"
																   [disabled]="task.updatingDueDate">
											</app-inline-datepicker>

											<span class="badge text-bg-warning rounded-pill ms-2" *ngIf="task.isOverdue">
												Overdue
											</span>

											<app-field-separator></app-field-separator>

											<div class="d-flex align-items-center gray-700">
												<fa-icon class="me-2" [icon]="spinnerIcon" [spin]="true" *ngIf="task.updatingAssignedTo"></fa-icon>

												<div dropdown class="btn-group align-baseline" [isDisabled]="task.updatingAssignedTo">
													<button dropdownToggle type="button" class="btn btn-sm btn-link inline-edit-value d-flex align-items-center gap-1">
														<div *ngIf="task.assignedToUserImageURL" class="worker-avatar">
															<img class="img-fluid" [src]="task.assignedToUserImageURL" />
														</div>

														<div class="text-nowrap">
															{{ task.assignedToUserName }}
														</div>
													</button>

													<div *dropdownMenu class="dropdown-menu dropdown-menu-end" style="max-height: 20rem; overflow-y: auto;">
														<button type="button" class="dropdown-item" (click)="onAssignedToChange(task, currentWorker)">
															<span *ngIf="currentWorker.profileImageUrl" class="worker-avatar">
																<img class="img-fluid" [src]="currentWorker.profileImageUrl" />
															</span>

															<span class="text-nowrap">
																{{ currentWorker.fullName }} (me)
															</span>
														</button>

														<div class="dropdown-divider"></div>

														<ng-container *ngFor="let worker of workersLookup">
															<button type="button" class="dropdown-item" (click)="onAssignedToChange(task, worker)">
																<span *ngIf="worker.profileImageUrl" class="worker-avatar">
																	<img class="img-fluid" [src]="worker.profileImageUrl" />
																</span>

																<span class="text-nowrap">
																	{{ worker.fullName }}
																</span>
															</button>
														</ng-container>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div class="flex-grow-0">
										<div dropdown class="btn-group">
											<button dropdownToggle type="button" class="btn btn-icon-only px-2">
												<fa-icon [icon]="faEllipsisVertical"></fa-icon>
											</button>

											<div *dropdownMenu class="dropdown-menu dropdown-menu-end">
												<button type="button" class="dropdown-item" (click)="onEditTask(task)">Edit</button>
												<button class="dropdown-item text-danger"
														(click)="onDeleteTask(deleteConfirmationModal, task)">
													Delete
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</ng-container>

					<ng-container *ngIf="task.editing">
						<ng-container *ngTemplateOutlet="editTaskForm"></ng-container>
					</ng-container>
				</ng-container>

				<hr *ngIf="openTasks?.length > 0 && closedTasks?.length > 0" />

				<ng-container *ngFor="let task of closedTasks; let index = index;">
					<ng-container *ngIf="index < 3 || showAllCompletedTasks">
						<div class="d-flex flex-fill align-items-start opacity-75 mx-3 mb-2">
							<span class="me-2" style="margin-top: 2px;">
								<fa-icon [icon]="spinnerIcon" [spin]="true" *ngIf="task.completing"></fa-icon>
								<input type="checkbox" class="form-check-input" [disabled]="task.completing" [(ngModel)]="!task.isOpen" (ngModelChange)="onToogleComplete(task)" style="width: 18px;" *ngIf="!task.completing" />
							</span>

							<span [ngClass]="{ 'd-block flex-grow-1 bg-light p-3': task.showMoreDetails }">
								<span class="small" *ngIf="task.isUrgent"><span class="badge text-bg-danger rounded-pill me-1">Urgent</span></span>

								<span class="d-inline me-1"
									  [class.text-wrapped-spaced]="task.showMoreDetails"
									  [innerHTML]="task.hasTrimedDetails && !task.showMoreDetails ? task.trimedDetails : task.taskDetails"
									  style="word-break: break-all; "></span>

								<a [ngClass]="{ 'd-inline-block' : !task.showMoreDetails, 'd-block mt-2' : task.showMoreDetails }"
								   href="javascript:void(0);"
								   (click)="task.showMoreDetails = !task.showMoreDetails"
								   *ngIf="task.hasTrimedDetails">
									{{ task.showMoreDetails ? 'Show less' : 'Show more' }}
								</a>

								<app-field-separator *ngIf="!task.showMoreDetails"></app-field-separator>
								<span class="small">{{ task.dueDate | date:'EEE, MMM d' }}</span>
								<app-field-separator></app-field-separator>
								<span class="small">{{ task.assignedToUserName }}</span>
							</span>
						</div>
					</ng-container>
				</ng-container>

				<ng-container *ngIf="!showAllCompletedTasks && closedTasks.length > 3">
					<div class="small mx-3 mt-2 mb-3">
						<a class="d-inline-block" href="javascript:void(0);" (click)="onShowAllCompletedTasks()">
							Show all {{ closedTasks.length }} completed tasks...
						</a>
					</div>
				</ng-container>

				<ng-container *ngIf="showAllCompletedTasks && hasMore">
					<div class="small mx-3 mt-2 mb-3">
						<ng-container *ngIf="!loadingMore">
							<a class="d-inline-block" href="javascript:void(0);" (click)="onLoadMore()">
								Load more...
							</a>
						</ng-container>

						<ng-container *ngIf="loadingMore">
							<span class="d-inline-block text-muted">
								<fa-icon class="me-2" [icon]="spinnerIcon" [spin]="true"></fa-icon>
								Loading more...
							</span>
						</ng-container>
					</div>
				</ng-container>
			</ng-container>
		</app-aside-widget>
	</div>
</div>

<ng-template #editTaskForm>
	<div class="card bg-light mb-3">
		<div class="card-body">
			<div class="d-flex justify-content-between align-items-center mb-3">
				<h6 class="mb-0">
					{{ model.version ? 'Edit Task' : 'New Task' }}
				</h6>

				<div class="btn-group" placement="bottom right" dropdown>
					<button id="templatesButton" class="btn btn-sm btn-outline-primary dropdown-toggle" dropdownToggle type="button" aria-controls="templatesDropdown"
							[disabled]="taskTemplatesLookupLoading">
						<ng-container *ngIf="!taskTemplatesLookupLoading">
							<fa-icon [icon]="templateLeftIcon"></fa-icon><fa-icon [icon]="templateRightIcon"></fa-icon>
						</ng-container>
						<app-spinner-wheel class="ms-2"
										   *ngIf="taskTemplatesLookupLoading"></app-spinner-wheel>
					</button>

					<ul class="dropdown-menu dropdown-overflow dropdown-menu-end c-pointer" id="templatesDropdown" role="menu" aria-labelledby="templatesButton" *dropdownMenu>
						<ng-container *ngFor="let taskTemplateGroup of taskTemplatesGroupped">
							<li>
								<h6 class="dropdown-header text-uppercase">
									{{ taskTemplateGroup.name }}
								</h6>
							</li>

							<li role="menuitem" *ngFor="let taskTemplate of taskTemplateGroup.taskTemplates">
								<a class="dropdown-item" (click)="onTemplateSelect(taskTemplate)">
									{{ taskTemplate.templateText }}
								</a>
							</li>
						</ng-container>
					</ul>
				</div>
			</div>

			<form #form="ngForm">
				<fieldset [disabled]="saving">
					<div class="field-required row mb-3">
						<div class="col">
							<textarea class="form-control" id="taskDetails" name="taskDetails"
									  [(ngModel)]="model.taskDetails" [required]="true" placeholder="Task details"
									  rows="4"></textarea>
						</div>
					</div>

					<hr />

					<div class="field-required row g-2 mb-2">
						<label class="col-8 col-form-label small" for="assignedToUserID">
							Assigned to
						</label>

						<div class="col-16">
							<app-worker-select [id]="'assignedToUserID'" [name]="'assignedToUserID'"
											   [closeable]="false" [(workerID)]="model.assignedToUserID">
							</app-worker-select>
						</div>
					</div>

					<div class="field-required row g-2 mb-2">
						<label class="col-8 col-form-label small" for="dueDate">
							Due date
						</label>

						<div class="col-16">
							<input type="date" id="dueDate" name="dueDate" class="form-control" [(ngModel)]="model.dueDate" required />
						</div>
					</div>

					<div class="row g-2">
						<label class="col-8 col-form-label small" for="isUrgent">
							Urgent
						</label>

						<div class="col-16">
							<app-switch id="isUrgent" name="isUrgent" [(value)]="model.isUrgent"
										(save)="onIsUrgentChanged($event)">
							</app-switch>
						</div>
					</div>

					<hr />

					<div class="row">
						<div class="col-24">
							<button type="submit" class="btn btn-sm btn-primary" (click)="onSave()">
								<fa-icon *ngIf="saving" class="me-2" [icon]="spinnerIcon" [spin]="saving"></fa-icon>
								{{ model.version ? 'Save' : 'Save New' }}
							</button>

							<button type="button" class="btn btn-sm btn-outline-primary ms-2"
									(click)="model.version ? onCloseEditTask() : onHideNewTaskForm()">
								Cancel
							</button>
						</div>
					</div>
				</fieldset>
			</form>
		</div>
	</div>
</ng-template>

<ng-template #deleteConfirmationModal>
	<div class="modal-header px-4">
		<h5 class="modal-title">
			Delete Task
		</h5>

		<button type="button" class="btn-close" (click)="deleteConfirmationModalRef.hide()">
			<span class="visually-hidden">&times;</span>
		</button>
	</div>

	<div class="modal-body px-4 py-3">
		<p class="mb-0">
			Are you sure you want to delete ‘{{ taskToDelete.taskDetails.split(' ').slice(0, 5).join(' ') }}’ task?
		</p>
	</div>

	<div class="modal-footer bg-white px-4">
		<button type="button" class="btn btn-outline-primary" (click)="deleteConfirmationModalRef.hide()"
				[disabled]="taskToDelete?.deleting">
			Cancel
		</button>

		<button type="submit" class="btn btn-danger" (click)="onDeleteTaskConfirmed()"
				[disabled]="taskToDelete?.deleting">
			Delete
		</button>
	</div>
</ng-template>
