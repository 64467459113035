<div class="card h-100">
	<div class="row g-0 h-100">
		<div class="col-md-7 h-100 card-cover">
			<h5 class="mb-0">Case Summary</h5>
		</div>

		<div class="col-md-17 h-100 px-3">
			<div class="card-body">
				<div class="row mb-3 field-required">
					<label class="col-9 col-form-label" for="caseTypeCode">
						Case type
					</label>

					<div class="col-10">
						<ng-select class="custom-ng-select" id="caseTypeCode" name="caseTypeCode" [items]="caseTypes"
							[bindLabel]="'name'" [bindValue]="'code'" [(ngModel)]="case.caseTypeCode"
							[clearable]="false" [multiple]="false" [searchable]="false" [loading]="loadingCaseTypes"
							[loadingText]="'loading case types...'" [notFoundText]="'no case type matching your search'"
							[placeholder]="'Case type'" [readonly]="true" disabled>

							<!-- [disabled]="true" -->
						</ng-select>
					</div>
				</div>

				<div class="row mb-3 field-required">
					<label class="col-9 col-form-label" for="caseTypeCode">
						Case status
					</label>

					<div class="col-10">
						<ng-select class="custom-ng-select" id="caseStatusCode" name="caseStatusCode"
							[items]="caseStatuses" [bindLabel]="'name'" [bindValue]="'code'"
							[(ngModel)]="case.caseStatusCode" [clearable]="false" [multiple]="false"
							[searchable]="false" [loading]="loadingCaseStatuses"
							[loadingText]="'loading case statuses...'"
							[notFoundText]="'no case status matching your search'" [placeholder]="'Case status'">
						</ng-select>
					</div>
				</div>

				<div class="row mb-3">
					<label class="col-9 col-form-label" for="assignedToPortalUserID">
						Assigned to
					</label>
					<div class="col-10">
						<app-worker-select class="w-100" id="assignedToPortalUserID" name="assignedToPortalUserID"
							[closeable]="false" [clearable]="true" [(workerID)]="case.assignedToPortalUserID">
						</app-worker-select>
					</div>
				</div>

				<div class="row mb-3 field-required">
					<label class="col-9 col-form-label" for="caseOriginCode">Origin</label>
					<div class="col-8">
						<ng-select class="custom-ng-select" id="caseOriginCode" name="caseOriginCode"
							[items]="caseOrigins" [bindLabel]="'name'" [bindValue]="'code'"
							[(ngModel)]="case.caseOriginCode" [clearable]="false" [multiple]="false"
							[searchable]="false" [loading]="loadingCaseOrigins"
							[loadingText]="'loading case origins...'"
							[notFoundText]="'no case origin matching your search'" [placeholder]="'Case origin'">
						</ng-select>
					</div>
				</div>

				<div class="row mb-3 field-required">
					<label class="col-9 col-form-label" for="subject">Subject</label>
					<div class="col-15">
						<input type="text" class="form-control" id="subject" name="subject" [(ngModel)]="case.subject" placeholder="Subject" autocomplete="off" />
					</div>
				</div>

				<div class="row mb-3">
					<label class="col-9 col-form-label" for="description">
						Description
					</label>

					<div class="col-15">
						<textarea class="form-control" id="description" name="description"
							[(ngModel)]="case.description" placeholder="Description" autocomplete="off"></textarea>
					</div>
				</div>

				<div class="row">
					<label class="col-9 col-form-label" for="description">
						Case files
					</label>

					<div class="col-15">
						<div class="alert alert-info" role="alert">
							You will be able to upload case files once you create the case.
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
