import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';

import { Observable, Observer, switchMap, map, catchError, of } from 'rxjs';
import { TypeaheadConfig, TypeaheadMatch } from 'ngx-bootstrap/typeahead';

import { ProvidersService } from '@app/settings/services';
import { Specialty } from '@app/settings/models';

export function getTypeaheadConfig(): TypeaheadConfig {
	return Object.assign(new TypeaheadConfig(), { cancelRequestOnFocusLost: true });
}

@Component({
	selector: 'app-specialty-input',
	templateUrl: 'specialty-input.component.html',
	styleUrls: ['specialty-input.component.scss'],
	providers: [{ provide: TypeaheadConfig, useFactory: getTypeaheadConfig }]
})
export class SpecialtyInputComponent implements OnInit {
	/* ======================== inputs/outputs ======================== */
	@Input() placeholder: string = 'search specialties';
	@Input() inputSize: 'sm' | 'lg';

	@Input()
	get specialtyName() { return this._specialtyName; }
	set specialtyName(value: string) {
		if (this._specialtyName === value) return;

		if (!value) {
			this.selectedSpecialty = null;
		}
		this._specialtyName = value;
		this.specialtyNameChange.emit(value);
	}
	private _specialtyName: string;

	@Output() specialtyNameChange = new EventEmitter<string>();
	@Output() specialtyChange = new EventEmitter<Specialty>();


	/* ======================== internal state ======================== */
	specialties$: Observable<Specialty[]>;
	specialtiesLoading: boolean = false;
	private get selectedSpecialty() { return this._selectedSpecialty; }
	private set selectedSpecialty(value: Specialty) {
		if (this._selectedSpecialty === value) return;

		this._selectedSpecialty = value;
		this.specialtyChange.emit(value);
	}
	private _selectedSpecialty: Specialty;

	constructor(
		private providersService: ProvidersService
	) {
	}

	ngOnInit() {
		this.specialties$ = new Observable((observer: Observer<string>) => observer.next(this.specialtyName)).pipe(
			switchMap((query: string) => {
				this.selectedSpecialty = null;

				// show all popular specialties for empty query
				return this.providersService.getSpecialties$(query, !query).pipe(
					catchError(err => {
						this.specialtiesLoading = false;
						return of(null);
					}),
					map((specialtiesResponse: Specialty[]) => specialtiesResponse || [])
				);
			})
		)
	}

	onSpecialtyTypeaheadLoading(loading: boolean) {
		this.specialtiesLoading = loading;
	}

	onTypeaheadSelect(e: TypeaheadMatch) {
		this.selectedSpecialty = e.item;
	}
}
