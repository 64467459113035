import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';

import { PDFService } from '@app/shared/services';

import { faFileLines, faTimes, faTriangleExclamation, faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';

@Component({
	templateUrl: 'file-create-modal.component.html',
	styleUrls: ['file-create-modal.component.scss']
})
export class FileCreateModalComponent implements OnInit {
	faTimes = faTimes;
	faFileLines = faFileLines;
	faTriangleExclamation = faTriangleExclamation;
	faSpinnerThird = faSpinnerThird;

	@ViewChild('fileBrowser', { static: true })
	fileBrowser: ElementRef<HTMLInputElement>;

	fileName: string;
	fileDate: string;
	save: (name: string, date: string, type: string, bytes: Uint8Array) => Promise<boolean>;
	saving: boolean = false;

	selectedFiles: SelectedFileInfo[] = [];

	constructor(
		private pdfService: PDFService,
		private modalRef: BsModalRef
	) {
	}

	ngOnInit() {
		this.fileBrowser.nativeElement.addEventListener('change', async (event: any) => {
			for (let file of event.target.files) {
				this.setFileInfo(file);
			}
		});
	}

	onDragOver(event: any) {
		event.preventDefault();
		event.stopPropagation();
	}

	onDrop(event: any) {
		event.preventDefault();
		event.stopPropagation();
		for (let file of Array.from(event.dataTransfer.files)) {
			this.setFileInfo(file as File);
		}
	}

	async onSelectFiles() {
		this.fileBrowser.nativeElement.click();
	}

	async onRemoveSelectedFile(file: SelectedFileInfo) {
		this.selectedFiles.splice(this.selectedFiles.indexOf(file), 1);
	}

	async onSave() {
		try {
			this.saving = true;

			// NOTE: REMOVED MERGING FUNCTIONALITY FOR NOW SINCE DOESN'T WORK RELIABLY
			//	FOR VARIOUS TYPES OF PDF FILES
			// let mergeableFileTypes: string[] = []; // ['image/jpg', 'image/jpeg', 'image/png', 'application/pdf'];

			// let mergeableFiles = this.selectedFiles.filter(m => mergeableFileTypes.includes(m.type));
			// if (mergeableFiles) {
			// 	let doc = await this.pdfService.createDocument();
			// 	for (let selectedFileInfo of mergeableFiles) {
			// 		doc = await this.pdfService.addFileAsPage(doc, selectedFileInfo.source);
			// 	}

			// 	let fileBytes = await doc.save();

			// 	let saved = await this.save(this.fileName, this.fileDate, 'application/pdf', fileBytes)

			// 	mergeableFiles.forEach(file => this.selectedFiles.find(m => m.source == file.source).saved = saved);
			// }

			// let unmergeableFiles = this.selectedFiles.filter(m => !mergeableFileTypes.includes(m.type));
			if (this.selectedFiles) {
				for (let selectedFileInfo of this.selectedFiles) {
					let buffer = await selectedFileInfo.source.arrayBuffer();
					let saved = await this.save(this.fileName, this.fileDate, selectedFileInfo.type, new Uint8Array(buffer));

					selectedFileInfo.saved = saved;
					// unmergeableFiles.forEach(file => this.selectedFiles.find(m => m.source == file.source).saved = saved);
				}
			}

			if (this.selectedFiles.every(m => m.saved)) {
				this.onClose();
			}
		} finally {
			this.saving = false;
		}
	}

	async onClose() {
		this.modalRef.hide();
	}

	private setFileInfo(file: File) {
		let fileInfo = this.getFileInfo(file);

		this.selectedFiles.push(fileInfo);

		if (!this.fileName) {
			this.fileName = fileInfo.name;
		}
		if (!this.fileDate && fileInfo.lastModified) {
			this.fileDate = new Date(fileInfo.lastModified).toISOString().slice(0, 10);
		}
	}

	private getFileInfo(file: File) {
		let fileSizeKB = file.size / 1024;
		let fileType = file.type;
		let pagesCount = 1;

		let selectedFileInfo: SelectedFileInfo = {
			source: file,
			name: file.name,
			lastModified: file.lastModified,
			size: fileSizeKB,
			type: fileType,
			pages: pagesCount,
			thumbnail: null,
			saved: false
		};

		let reader = new FileReader();
		reader.addEventListener('load', async () => {
			if (fileType == 'image/jpg' || fileType == 'image/jpeg' || fileType == 'image/png' || fileType == 'SVG') {
				selectedFileInfo.thumbnail = reader.result as string;
			} else if (fileType == 'PDF') {
				let doc = await this.pdfService.getDocument(reader.result as string);

				selectedFileInfo.thumbnail = await this.pdfService.getPageThumbnail(doc, 0);
			}
		}, false);
		reader.readAsDataURL(file);

		return selectedFileInfo;
	}
}

class SelectedFileInfo {
	source: File;
	name: string;
	lastModified: number;
	size: number;
	type: string;
	pages: number;
	thumbnail: string;
	saved: boolean;
}
