export class Task {
	taskID: number;
	createdByUserID: number;
	createdByUserName: string;
	assignedToUserID: number;
	assignedToUserName: string;
	assignedToUserImageURL: string;
	dueDate: string;
	taskDetails: string;
	dateCompleted: string;
	completedByUserID: number;
	completedByUserName: string;
	isOpen: boolean;
	isOverdue: boolean;
	taskTemplateCode: string;
	taskTemplateName: string;
	isUrgent: boolean;
	entityID: number;
	entityCode: string;
	entityLabel: string;
	entityTypeCode: string;
	entityTypeName: string;
	entityValueLabel: string;
	clientID: number;
	clientName: string;
	clientLogoURL: string;
	memberID: number;
	memberName: string;
	memberTimeZone: string;
	version: string;

	// UI Only
	editing: boolean = false;
	completing: boolean = false;
	deleting: boolean = false;
	updatingDueDate: boolean = false;
	updatingAssignedTo: boolean = false;
	showMoreDetails: boolean = false;
	hasTrimedDetails: boolean = false;
	trimedDetails: string;
}
