<ng-container *ngIf="isObject(object)">
    <div class="card" [ngClass]="class">
        <ul class="list-group list-group-flush">
            <li class="list-group-item d-flex justify-content-between align-items-start"
                *ngFor="let key of getObjectKeys(object)">
                <ng-container *ngIf="isPrimitive(object[key]); else complexField">
                    <label class="form-label me-2">{{ key }}</label>
                    <span class="text-truncate">{{ object[key] }}</span>
                </ng-container>
                <ng-template #complexField>
                    <div class="card">
                        <div class="card-header">{{ key }}</div>
                        <div class="card-body">
                            <ng-container *ngIf="isArray(object[key])">
                                <div class="table-responsive">
                                    <table class="table table-sm table-hover">
                                        <thead class="table-light">
                                            <tr>
                                                <th *ngFor="let header of getObjectKeys(object[key][0])">{{ getNameForKey(header) }}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of object[key]">
                                                <td *ngFor="let field of getObjectKeys(item)">
                                                    <app-object-viewer [object]="item[field]"></app-object-viewer>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="isObject(object[key]) && !isArray(object[key])">
                                <app-object-viewer [object]="object[key]"></app-object-viewer>
                            </ng-container>
                        </div>
                    </div>
                </ng-template>
            </li>
        </ul>
    </div>
</ng-container>

<ng-container *ngIf="isPrimitive(object)">
    {{ object }}
</ng-container>