import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'time'
})
export class TimePipe implements PipeTransform {
	transform(value: string): string {
		if (!value) {
			return value;
		}

		let hours = parseInt(value.split(':')[0]);
		let minutes = parseInt(value.split(':')[1]);
		let period = hours >= 12 ? 'pm' : 'am';

		if (hours == 0) {
			hours = 12;
		} else if (hours > 12) {
			hours -= 12;
		}

		return `${hours}:${(minutes < 10 ? '0' : '') + minutes}${period}`;
	}
}
