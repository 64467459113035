import { Component, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';

import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { MembersService } from '@app/data/members/services';
import { MemberPlan } from '@app/data/members/models';

import { Messages } from '@app/constants';

import { faSpinnerThird } from '@fortawesome/pro-solid-svg-icons';

@Component({
	templateUrl: 'member-plan-deactivate-modal.component.html',
	styleUrls: ['member-plan-deactivate-modal.component.scss']
})
export class MemberPlanDeactivateModalComponent implements OnInit {
	faSpinnerThird = faSpinnerThird;

	memberPlan: MemberPlan;
	clientMemberID: number;
	memberName: string;
	terminationDate: string;
	deactivating: boolean = false;
	deactivated: boolean = false;

	constructor(
		private membersService: MembersService,
		private modalRef: BsModalRef,
		private toastr: ToastrService
	) {
	}

	ngOnInit() {
		this.terminationDate = formatDate(new Date(), 'yyyy-MM-dd', 'en-US');
	}

	async onDeactivate() {
		try {
			this.deactivating = true;

			this.memberPlan.terminationDate = this.terminationDate;

			await this.membersService.deactivateMemberPlan(this.clientMemberID, {
				...this.memberPlan,
				terminationDate: this.terminationDate
			});

			this.deactivated = true;

			this.toastr.success(`Successfully deactivated ${this.memberPlan.planName} plan.`);

			this.onClose();
		} catch (error: any) {
			this.toastr.error(Messages.ErrorRetry, `Failed to Deactivate ${this.memberPlan.planName} plan`);
		} finally {
			this.deactivating = false;
		}
	}

	async onClose() {
		this.modalRef.hide();
	}
}
