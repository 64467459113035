import { Component, Input } from '@angular/core';

import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';

@Component({
	selector: 'app-error',
	templateUrl: 'error.component.html',
	styleUrls: ['error.component.scss']
})
export class ErrorComponent {
	faExclamationCircle = faExclamationCircle;

	@Input()
	message: string = 'Error fetching data';

	@Input()
	class: string = '';

	@Input()
	shortTemplate: boolean = false;

	@Input()
	vertical: boolean = false;
}
