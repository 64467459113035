<form #form="ngForm" class="needs-validation" novalidate>
	<fieldset [disabled]="saving">
		<div class="modal-header px-4">
			<h4 class="modal-title">
				New Case <span class="text-secondary">({{ case.caseTypeName }})</span>
			</h4>

			<button type="button" class="btn-close" (click)="onClose(form)">
				<span class="visually-hidden">&times;</span>
			</button>
		</div>

		<div class="modal-body bg-light px-0 py-4">
			<div class="container">
				<div class="row justify-content-center">
					<div class="col-20">
						<div class="card h-100">
							<div class="row g-0 h-100">
								<div class="col-md-7 h-100 card-cover">

								</div>

								<div class="col-md-17 h-100 px-3">
									<div class="card-body">
										<div class="row">
											<label class="col-9 col-form-label" for="proBono">
												Pro Bono
											</label>

											<div class="col-8">
												<app-switch [id]="'proBono'" [name]="'proBono'"
															[(value)]="case.isProBono" (save)="onProBonoChange($event)">
												</app-switch>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="col-20 mt-4">
						<app-create-patient-information [case]="$any(case)" [defaultMember]="defaultMember" (caseChange)="onCaseChange($event)"></app-create-patient-information>
					</div>

					<div class="col-20 mt-4">
						<app-create-case-summary [case]="$any(case)" (caseChange)="onCaseChange($event)"></app-create-case-summary>
					</div>

					<div class="col-20 mt-4">
						<ng-container [ngSwitch]="case.caseTypeCode">
							<ng-container *ngSwitchCase="'bill_negotiation'">
								<app-create-bill-negotiation [case]="$any(case)" (caseChange)="onCaseChange($event)"></app-create-bill-negotiation>
							</ng-container>

							<ng-container *ngSwitchCase="'pre_negotiation'">
								<app-create-pre-negotiation [case]="$any(case)" (caseChange)="onCaseChange($event)"></app-create-pre-negotiation>
							</ng-container>

							<ng-container *ngSwitchCase="'itemized_statements'">
								<app-create-itemized-statements [case]="$any(case)" (caseChange)="onCaseChange($event)"></app-create-itemized-statements>
							</ng-container>

							<ng-container *ngSwitchCase="'facility_provider'">
								<app-create-facility-provider [case]="$any(case)" (caseChange)="onCaseChange($event)"></app-create-facility-provider>
							</ng-container>

							<ng-container *ngSwitchCase="'prescription'">
								<app-create-prescription-request [case]="$any(case)" (caseChange)="onCaseChange($event)"></app-create-prescription-request>
							</ng-container>

							<ng-container *ngSwitchCase="'scheduling'">
								<app-create-scheduling [case]="$any(case)" (caseChange)="onCaseChange($event)"></app-create-scheduling>
							</ng-container>

							<ng-container *ngSwitchCase="'transportation'">
								<app-create-transportation [case]="$any(case)" (caseChange)="onCaseChange($event)"></app-create-transportation>
							</ng-container>

							<ng-container *ngSwitchCase="'policy_and_plan_guidance'">
								<app-create-policy-and-plan-guidance [case]="$any(case)" (caseChange)="onCaseChange($event)"></app-create-policy-and-plan-guidance>
							</ng-container>

							<ng-container *ngSwitchCase="'chaplaincy'">
								<!-- no special fields -->
							</ng-container>

							<ng-container *ngSwitchCase="'member_app_support'">
								<app-create-member-app-support [case]="$any(case)" (caseChange)="onCaseChange($event)"></app-create-member-app-support>
							</ng-container>

							<ng-container *ngSwitchCase="'general_support'">
								<!-- no special fields -->
							</ng-container>
						</ng-container>
					</div>
				</div>
				<!-- validation errors alert -->
				<div class="row justify-content-center mt-4">
					<div class="col-sm-20">
						<app-validation [errors]="validationErrors"></app-validation>
					</div>
				</div>
			</div>
		</div>

		<div class="modal-footer bg-white px-4">
			<button type="button" class="btn btn-outline-primary" (click)="onClose(form)" [disabled]="saving">
				Cancel
			</button>

			<button type="submit" class="btn btn-primary" (click)="onSave(form)" [disabled]="saving">
				Save Case <app-spinner-wheel class="ms-1" *ngIf="saving"></app-spinner-wheel>
			</button>
		</div>
	</fieldset>
</form>
