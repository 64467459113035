<div class="card">
	<div class="row g-0 h-100">
		<div class="col-md-7 card-cover">
			<h5>Patient Financials</h5>
		</div>

		<div class="col-md-17 px-3">
			<div class="card-body">
				<div class="row mb-3">
					<label class="col-sm-9 col-form-label" for="householdSizeInput">
						Household size
					</label>
					<div class="col-sm-7">
						<input id="householdSizeInput" name="householdSizeInput" class="form-control" type="number"
							[(ngModel)]="case.householdSize">
					</div>
				</div>
				<div class="row mb-3">
					<label class="col-sm-9 col-form-label" for="dependentCountInput">
						Dependent count
					</label>
					<div class="col-sm-7">
						<input id="dependentCountInput" name="dependentCountInput" class="form-control" type="number"
							[(ngModel)]="case.dependentCount">
					</div>
				</div>
				<div class="mx-n3 px-3 rounded" [class.bg-light]="case.housingTypeCode === HousingTypes.Other">
					<div class="row mb-3" [class.pt-3]="case.housingTypeCode === HousingTypes.Other">
						<label class="col-sm-9 col-form-label" for="housingTypeSelect">
							Housing type
						</label>
						<div class="col-sm-8">
							<ng-select id="housingTypeSelect" name="housingTypeSelect" class="custom-ng-select"
								[items]="housingTypesLookup$ | async" bindLabel="name" bindValue="code"
								[(ngModel)]="case.housingTypeCode" [searchable]="false"
								[loading]="loadingHousingTypesLookup" loadingText="loading housing types..."
								notFoundText="no housing types" placeholder="Housing type"
								(change)="onHousingTypeChange()"></ng-select>
						</div>
					</div>
					<ng-container *ngIf="case.housingTypeCode === HousingTypes.Other">
						<div class="row mb-3 pb-3">
							<label class="col-sm-9 col-form-label" for="otherHousingTypeInput">
								Housing information
							</label>
							<div class="col-sm-15">
								<input id="otherHousingTypeInput" name="otherHousingTypeInput" class="form-control"
									type="text" placeholder="Housing information" [(ngModel)]="case.otherHousingType">
							</div>
						</div>
					</ng-container>
				</div>
				<div class="row mb-3">
					<label class="col-sm-9 col-form-label" for="otherPropertiesTypeInput">
						Properties
					</label>
					<div class="col-sm-15">
						<ng-select class="custom-ng-select ng-select-multiple form-control rounded-end h-100"
								   id="otherPropertiesTypeInput" name="otherPropertiesTypeInput"
								   [items]="propertiesLookup$ | async" bindLabel="name" bindValue="code"
								   [(ngModel)]="case.propertyTypeCodes" [multiple]="true" [closeOnSelect]="false"
								   [loading]="loadingPropertiesLookup" [loadingText]="'loading properties...'"
								   [searchable]="false" placeholder="Properties">
						</ng-select>
					</div>
				</div>

				<hr />

				<div class="row mb-3">
					<label class="col-sm-9 col-form-label" for="adjustedGrossIncomeInput">
						Adjusted gross income (AGI)
					</label>
					<div class="col-sm-7">
						<input id="adjustedGrossIncomeInput" name="adjustedGrossIncomeInput" class="form-control"
							type="text" placeholder="$ 0.00" [(ngModel)]="case.adjustedGrossIncome" currencyMask
							autocomplete="off">
					</div>
				</div>
				<div class="row mb-3">
					<label class="col-sm-9 col-form-label" for="savingsAmountInput">
						Savings amount
					</label>
					<div class="col-sm-7">
						<input id="savingsAmountInput" name="savingsAmountInput" class="form-control" type="text"
							placeholder="$ 0.00" [(ngModel)]="case.savingsAmount" currencyMask autocomplete="off">
					</div>
				</div>
				<div class="row mb-3">
					<label class="col-sm-9 col-form-label" for="otherInvestmentsInput">
						Other investments
					</label>
					<div class="col-sm-15">
						<input id="otherInvestmentsInput" name="otherInvestmentsInput" class="form-control" type="text"
							placeholder="Other investments" [(ngModel)]="case.otherInvestments" autocomplete="off">
					</div>
				</div>
				<div class="row mb-3">
					<label class="col-sm-9 col-form-label" for="otherInsuranceInput">
						Other insurance
					</label>
					<div class="col-sm-15">
						<input id="otherInsuranceInput" name="otherInsuranceInput" class="form-control" type="text"
							placeholder="Other insurance" [(ngModel)]="case.otherInsurance" autocomplete="off">
					</div>
				</div>
				<div class="row mb-3">
					<label class="col-sm-9 col-form-label" for="fundsAvailableForSettlementInput">
						Funds available for settlement
					</label>
					<div class="col-sm-7">
						<input id="fundsAvailableForSettlementInput" name="fundsAvailableForSettlementInput"
							class="form-control" type="text" placeholder="$ 0.00"
							[(ngModel)]="case.fundsAvailableForSettlement" currencyMask autocomplete="off">
					</div>
				</div>
				<div class="row mb-3">
					<label class="col-sm-9 col-form-label" for="fundsAvailableForMonthlyPaymentInput">
						Funds available for monthly payment
					</label>
					<div class="col-sm-7">
						<input id="fundsAvailableForMonthlyPaymentInput" name="fundsAvailableForMonthlyPaymentInput"
							class="form-control" type="text" placeholder="$ 0.00"
							[(ngModel)]="case.fundsAvailableForMonthlyPayment" currencyMask autocomplete="off">
					</div>
				</div>
				<div class="row mb-3">
					<label class="col-sm-9 col-form-label" for="financialNotesInput">
						Financial notes
					</label>
					<div class="col-sm-15">
						<textarea id="financialNotesInput" name="financialNotesInput" class="form-control"
							placeholder="Financial notes" rows="4" [(ngModel)]="case.financialNotes"
							autocomplete="off"></textarea>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="card mt-4">
	<div class="row g-0 h-100">
		<div class="col-md-7 card-cover">
			<h5>Bill Details</h5>
		</div>

		<div class="col-md-17 px-3">
			<div class="card-body">
				<div class="row mb-3">
					<label class="col-sm-9 col-form-label" for="firstDateOfServiceInput">
						First date of service
					</label>
					<div class="col-sm-7">
						<input id="firstDateOfServiceInput" name="firstDateOfServiceInput" class="form-control"
							type="date" [(ngModel)]="case.firstDateOfService">
					</div>
				</div>
				<div class="row mb-3">
					<label class="col-sm-9 col-form-label" for="approximateBillsTotalInput">
						Approximate bills total
					</label>
					<div class="col-sm-7">
						<input id="approximateBillsTotalInput" name="approximateBillsTotalInput" class="form-control"
							type="text" placeholder="$ 0.00" [(ngModel)]="case.approximateBillsTotal" currencyMask
							autocomplete="off">
					</div>
				</div>
				<div class="row mb-3">
					<label class="col-sm-9 col-form-label" for="otherInsurancePaidAmountInput">
						Other insurance paid amount
					</label>
					<div class="col-sm-7">
						<input id="otherInsurancePaidAmountInput" name="otherInsurancePaidAmountInput"
							class="form-control" type="text" placeholder="$ 0.00"
							[(ngModel)]="case.otherInsurancePaidAmount" currencyMask autocomplete="off">
					</div>
				</div>
				<div class="row">
					<label class="col-sm-9 col-form-label" for="details">
						Bill files
					</label>
					<div id="details" class="col-sm-15">
						You will be able to add individual bills once you create the case.
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
