import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { DefaultMemberModel } from '@app/shared/models/member.models';
import { CasesService } from '@app/shared/services/cases.service';
import { ActionConfirmationComponent } from '@app/shared/components/action-confirmation/action-confirmation.component';
import { CaseTypes } from '@app/data/cases/models';

import { SwitchToggleEvent } from '@app/shared/components/switch/switch.component';

import { isValidationError, getValidationErrors, scrollToElementById } from '@app/functions';
import { Messages } from '@app/constants';

@Component({
	templateUrl: 'new-case-modal.component.html',
	styleUrls: ['new-case-modal.component.scss']
})
export class NewCaseModalComponent implements OnInit {
	case: CaseTypes;
	defaultMember: DefaultMemberModel;

	saving: boolean = false;
	created: boolean = false;
	proBono: boolean = false;

	validationErrors: string[];

	constructor(
		private casesService: CasesService,
		private modalRef: BsModalRef,
		private modalService: BsModalService,
		private toastrService: ToastrService,
		private router: Router
	) {
	}

	ngOnInit() {
	}

	async onProBonoChange(event: SwitchToggleEvent) {
		event.saved(true);
	}

	onCaseChange(updatedCase: CaseTypes) {
		this.case = updatedCase;
	}

	async onSave(form: NgForm) {
		console.log('Saving case:', this.case);

		try {
			this.saving = true;
			this.validationErrors = null;

			let response = await this.casesService.createCase(this.case);

			this.toastrService.success(`New ${response.caseTypeName} case successfully created.`);

			this.router.navigate([`/data/cases/${response.caseID}`]);

			this.onClose(null);
		} catch (err: any) {
			if (isValidationError(err)) {
				this.validationErrors = getValidationErrors(err);
				this.toastrService.warning('Unable to save the case because of validation errors. Please correct all validation errors and try saving again.');
				scrollToElementById('validationAlert');
			} else {
				this.toastrService.error(Messages.ErrorRetry, `Failed to Create ${this.case.caseTypeName} Case`);
			}
		} finally {
			this.saving = false;
		}
	}

	async onClose(form: NgForm) {
		if (form && form.dirty) {
			this.modalService.show<ActionConfirmationComponent>(ActionConfirmationComponent, {
				class: 'modal-md',
				ignoreBackdropClick: true,
				animated: false,
				initialState: {
					title: Messages.DiscardChangesModalTitle,
					messageHtml: Messages.DiscardChangesModalMessage,
					actionTitle: Messages.DiscardChangesConfirmButtonText,
					actionButtonClass: Messages.DiscardChangesConfirmButtonClass,
					actionFunc: async () => {
						this.modalRef.hide();
						return true;
					},
					cancelTitle: Messages.DiscardChangesCancelButtonText,
					cancelButtonClass: Messages.DiscardChangesCancelButtonClass
				}
			});
		} else {
			this.modalRef.hide();
		}
	}
}
