export class CaseDashboard {
	caseID: number;
	caseCode: string;
	caseStatusCode: string;
	caseStatusName: string;
	assignedToWorkerID: number | null;
	assignedToWorkerName: string | null;
	assignedToWorkerImageURL: string | null;
	caseTypeCode: string;
	caseTypeName: string;
	subject: string | null;
	patientMemberID: number;
	patientMemberName: string;
	clientID: number;
	clientName: string;
	clientLogoURL: string | null;
	clientMemberPlanID: number;
	planName: string;
	openedDate: string;
	lastActivityDate: string;
	caseAgeDays: number;
}

export class Case {
	caseID: number;
	caseCode: string;
	externalID: string;
	externalCaseWorkerName: string | null;
	caseTypeCode: string;
	caseTypeName: string;
	caseOriginCode: string;
	caseOriginName: string;
	caseStatusCode: string;
	caseStatusName: string;
	caseStatusDetailCode: string;
	caseStatusDetailName: string;
	subject: string;
	description: string;
	resolution: string;
	clientMemberPlanID: number;
	clientMemberID: number;
	memberFirstName: string;
	memberLastName: string;
	memberFullName: string;
	memberZipCode: string;
	memberIsDeceased: boolean;
	stripeCustomerID: string | null;
	contactClientMemberPlanID: number;
	contactExternalID: number;
	contactName: string | null;
	contactPhoneNumber: string | null;
	contactMobileNumber: string | null;
	contactEmailAddress: string | null;
	contactRelationshipTypeName: string | null;
	contactGenderName: string | null;
	contactPreferredLanguageName: string | null;
	otherContactName: string;
	otherContactPhoneNumber: string;
	otherContactEmail: string;
	otherContactRelationshipTypeCode: string;
	otherContactRelationshipTypeName: string;
	otherContactPreferredLanguageCode: string;
	otherContactPreferredLanguageName: string;
	otherContactGenderCode: string;
	otherContactGenderName: string;
	assignedToPortalUserID: number;
	assignedToPortalUserName: string;
	dateAssigned: string;
	openedByPortalUserID: number;
	openedByPortalUserName: string;
	openedDate: string;
	closedDate: string;
	ageDays: number;
	lastActivityDate: string;
	clientID: number;
	clientCode: string;
	clientName: string;
	clientLogoURL: string;
	clientPlanID: number;
	planCode: string;
	planName: string;
	isProBono: boolean;

	version: string;
	typeDetailsID: number;
	typeDetailsVersion: string;

	zipCode: string;
	canTravelDistanceMiles: number;
}

export class CaseBillNegociation extends Case {
	householdSize: number | null | undefined;
	dependentCount: number | null | undefined;
	housingTypeCode: string | null | undefined;
	housingTypeName: string | null | undefined;
	otherHousingType: string | null | undefined;
	propertyTypeCodes: string[] | null | undefined;
	properties: BillNegotiationProperty[] | null | undefined;
	adjustedGrossIncome: number | null | undefined;
	savingsAmount: number | null | undefined;
	otherInvestments: string | null | undefined;
	otherInsurance: string | null | undefined;
	fundsAvailableForSettlement: number | null | undefined;
	fundsAvailableForMonthlyPayment: number | null | undefined;
	financialNotes: string | null | undefined;
	firstDateOfService: Date | null | undefined;
	approximateBillsTotal: number | null | undefined;
	otherInsurancePaidAmount: number | null | undefined;
}

export class CasePreNegotiation extends Case {
	procedureCPTCode: string;
	procedureName: string;
	providerNPI: number;
	providerName: string;
	facilityNPI: number;
	facilityName: string;
	facilityAddress1: string;
	facilityAddress2: string;
	facilityCity: string;
	facilityStateCode: string;
	facilityZipCode: string;
	facilityPhoneNumber: string;
	facilityFaxNumber: string;
	facilityEmailAddress: string;
	billingContactName: string;
	billingPhoneNumber: string;
	ableToPayAmount: number;
	totalPatientResponsibility: number;
	upfrontPaymentAmount: number;
	postProcedurePaymentAmount: number;
	totalAdjustedPatientResponsibility: number;
	adjustedUpfrontPaymentAmount: number;
	adjustedPostProcedurePaymentAmount: number;
	upfrontPaymentDueDate: string;
}

export class CaseItemizedStatements extends Case {
	providers: CaseItemizedStatementsProvider[] = [];
	totalChargesAmount: number;
}

export class CaseItemizedStatementsProvider {
	providerNPI: number;
	providerName: string;
	facilityNPI: number;
	facilityName: string;
	facilityAddress1: string;
	facilityAddress2: string;
	facilityCity: string;
	facilityStateCode: string;
	facilityZipCode: string;
	facilityPhoneNumber: string;
	facilityFaxNumber: string;
	facilityEmailAddress: string;
}

export class CaseFacilityProvider extends Case {
	facilityTypeCode: string;
	facilityTypeName: string;
	specialtyCode: string;
	specialtyName: string;
	cptCode: string;
	procedureName: string;
	preferredGenderCode: string;
	preferredGenderName: string;
	otherPreferences: string;
	priorities: CaseProviderPriority[];
	highestEstimate: number;
	lowestEstimate: number;
	options: any[];
}

export class CasePrescription extends Case {
	ndc: string;
	drugName: string;
	brandName: string;
	form: string;
	dosage: string;
	quantity: string;
	brandNameOnly: boolean;
	frequency: string;
	highestEstimate: number;
	lowestEstimate: number;
}

export class CaseScheduling extends Case {
	providerNPI: number;
	providerName: string;
	facilityNPI: number;
	facilityName: string;
	facilityAddress1: string;
	facilityAddress2: string;
	facilityCity: string;
	facilityStateCode: string;
	facilityZipCode: string;
	facilityPhoneNumber: string;
	facilityFaxNumber: string;
	facilityEmailAddress: string;
	preferredTime: string;
	bookedAppointmentDate: string;
	bookedAppointmentTime: string;
}

export class CaseTransportation extends Case {
	appointmentDate: string;
	appointmentTime: string;
	pickupAddress1: string;
	pickupAddress2: string;
	pickupCity: string;
	pickupStateCode: string;
	pickupZipCode: string;
	destinationAddress1: string;
	destinationAddress2: string;
	destinationCity: string;
	destinationStateCode: string;
	destinationZipCode: string;
	preferences: string;
	highestEstimate: number;
	lowestEstimate: number;
}

export class CasePolicyPlanGuidance extends Case {
	networkName: string;
	specialties: CasePolicyPlanGuidanceSpecialty[];
	procedures: CasePolicyPlanGuidanceProcedure[];
	providers: CasePolicyPlanGuidanceProvider[];
	facilities: CasePolicyPlanGuidanceFacility[];
	preferredGenderCode: string;
	otherPreferences: string;
}

export class CasePolicyPlanGuidanceSpecialty {
	specialtyCode: string;
	specialtyName: string;
}

export class CasePolicyPlanGuidanceProcedure {
	procedureCode: string;
	procedureName: string;
}

export class CasePolicyPlanGuidanceProvider {
	providerNPI: number;
	providerName: string;
	facilityNPI: number;
	facilityName: string;
	facilityAddress1: string;
	facilityAddress2: string;
	facilityCity: string;
	facilityStateCode: string;
	facilityZipCode: string;
	facilityPhoneNumber: string;
	facilityFaxNumber: string;
	facilityEmailAddress: string;
}

export class CasePolicyPlanGuidanceFacility {
	facilityNPI: number;
	facilityName: string;
	facilityAddress1: string;
	facilityAddress2: string;
	facilityCity: string;
	facilityStateCode: string;
	facilityZipCode: string;
	facilityPhoneNumber: string;
	facilityFaxNumber: string;
	facilityEmailAddress: string;
}

export class CaseChaplaincy extends Case {
}

export class CaseMemberAppSupport extends Case {
	details: string;
}

export class CaseGeneralSupport extends Case {
}

export class CaseLowHighAmounts {
	lowestEstimate: number | null;
	highestEstimate: number | null;
}

export class CasePreNegotiationAmounts {
	totalPatientResponsibility: number;
	upfrontPaymentAmount: number;
	postProcedurePaymentAmount: number;
	totalAdjustedPatientResponsibility: number;
	adjustedUpfrontPaymentAmount: number;
	adjustedPostProcedurePaymentAmount: number;
}

export class CasePreNegotiationChange {
	changeID: number;
	changeTypeCode: string;
	changeTypeName: string;
	changeAmount: number;
	changeDate: string;
	version: string;
}

export class CaseBill {
	billID: number;
	billCode: string;
	billStatusCode: string;
	billStatusName: string;
	billStatusDetailCode: string | null | undefined;
	billStatusDetailName: string | null | undefined;
	isReopened: boolean;
	reopenedReason: string | null | undefined;
	isEscalated: boolean;
	dateReceived: string;
	providerNPI: string | null | undefined;
	providerName: string | null | undefined;
	dateOfService: string;
	dateClosed: string | null | undefined;
	lastActivityDate: string;
	billAmount: number | null | undefined;
	currentAmountDue: number | null | undefined;
	totalSavingsAmount: number | null | undefined;
}

export class BillNegotiationProperty {
	propertyCode: string;
	propertyName: string;
}

export class CaseProviderPriority {
	priorityCode: string;
	priorityName: string;
	priorityOrder: number;
}

export class CaseProviderResolutionOption {
	caseID: number;
	optionID: number;
	providerNPI: number;
	providerName: string;
	facilityNPI: number;
	facilityName: string;
	facilityAddress1: string;
	facilityAddress2: string;
	facilityCity: string;
	facilityStateCode: string;
	facilityStateName: string;
	facilityZipCode: string;
	facilityPhoneNumber: string;
	facilityFaxNumber: string;
	facilityEmailAddress: string;
	availability: string;
	publicNotes: string;
	internalNotes: string;
	priceFees: CaseProviderResolutionOptionFee[];
	distanceMiles: number;
	isItemized: boolean;
	totalLowRangeAmount: number;
	totalAmount: number;
	version: string;

	// UI Only
	editing: boolean;
	deleting: boolean;
}

export class CaseProviderResolutionOptionFee {
	feeID: number;
	feeTypeCode: string;
	feeTypeName: string;
	isTotalAmount: boolean;
	isAmountRange: boolean;
	lowRangeAmount: number;
	amount: number;
}

export class CasePrescriptionResolutionOption {
	caseID: number;
	optionID: number;
	pharmacyName: string;
	pharmacyAddress1: string;
	pharmacyAddress2: string;
	pharmacyCity: string;
	pharmacyStateCode: string;
	pharmacyStateName: string;
	pharmacyZipCode: string;
	pharmacyPhoneNumber: string;
	pharmacyEmailAddress: string;
	couponURL: string;
	distanceMiles: number;
	isAmountRange: boolean;
	lowRangeAmount: number;
	amount: number;
	publicNotes: string;
	internalNotes: string;
	version: string;

	// UI Only
	editing: boolean;
	deleting: boolean;
}

export class CaseTransportationResolutionOption {
	transportationProviderID: number;
	optionID: number;
	providerName: string;
	providerWebsite: string;
	providerPhoneNumber: string;
	providerEmailAddress: string;
	amount: number;
	publicNotes: string;
	internalNotes: string;
	version: string;

	// UI Only
	editing: boolean;
	deleting: boolean;
}

export class ContactEditModel {
	contactClientMemberPlanID: number;

	otherContactName: string;
	otherContactPhoneNumber: string;
	otherContactEmail: string;
	otherContactRelationshipTypeCode: string;
	otherContactPreferredLanguageCode: string;
	otherContactGenderCode: string;
}

export class CasePaymentViewModel {
	stripePaymentID: number;
	stripePaymentIntentID: string;
	stripeStatus: string;
	paymentMethod: string;
	chargeType: string;
	amount: number;
	paymentIntentJson: string;
	dateCreated: string;
	dateUpdated: string;
}
