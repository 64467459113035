import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';

import { ToastrService } from 'ngx-toastr';

import { LookupService } from '@app/settings/services';
import { LookupValue } from '@app/settings/models';

import { Messages } from '@app/constants';

import { faXmark } from '@fortawesome/pro-regular-svg-icons';

@Component({
	selector: 'app-gender-select',
	templateUrl: 'gender-select.component.html',
	styleUrls: ['gender-select.component.scss'],
	encapsulation: ViewEncapsulation.None,
	exportAs: 'genderSelect'
})
export class GenderSelectComponent implements OnInit {
	faXmark = faXmark;

	@Input()
	id: string;

	@Input()
	name: string;

	@Input()
	code: string;

	@Output()
	codeChange: EventEmitter<string> = new EventEmitter<string>();

	@Input()
	placeholder: string = 'Gender';

	@Input()
	closeable: boolean = true;

	@Output()
	close: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private lookupService: LookupService,
		private toastrService: ToastrService
	) {
	}

	async ngOnInit() {
		this.onReloadGenders();
	}

	loadingGenders: boolean = false;
	genders: LookupValue[];
	async onReloadGenders() {
		try {
			this.loadingGenders = true;

			this.genders = await this.lookupService.getLookupValues('Gender');
		} catch (error: any) {
			this.toastrService.error(Messages.ErrorRetry, `Failed to Load Genders`);
		} finally {
			this.loadingGenders = false;
		}
	}
}
