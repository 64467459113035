import { ProfileService } from './services/profile.service';
import { FilesService } from './services/files.service';
import { SettingsService } from './services/settings.service';
import { LookupService } from './services/lookup.service';
import { PDFService } from './services/pdf.service';
import { AssistantService } from './services/assistant.service';;
import { IntegrationsService } from './services/integrations.service';;

export { ProfileService } from './services/profile.service';
export { FilesService } from './services/files.service';
export { SettingsService } from './services/settings.service';
export { LookupService } from './services/lookup.service';
export { PDFService } from './services/pdf.service';
export { AssistantService } from './services/assistant.service';
export { IntegrationsService } from './services/integrations.service';;

export const SERVICES: any[] = [
	ProfileService,
	FilesService,
	SettingsService,
	LookupService,
	PDFService,
	AssistantService,
	IntegrationsService
];
