import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-stacked-bar-chart-value',
	templateUrl: 'stacked-bar-chart-value.component.html',
	styleUrls: ['stacked-bar-chart-value.component.scss']
})
export class StackedBarChartValueComponent {
	@Input()
	name: string;

	@Input()
	percent: number;

	@Input()
	class: any;
}
