import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-validation',
	templateUrl: 'validation.component.html',
	styleUrls: ['validation.component.scss']
})
export class ValidationComponent {
	@Input()
	id: string = 'validationAlert';
	@Input()
	errors: string[] = null;
}
