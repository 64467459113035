<div class="card mt-3">
	<div class="card-body p-0">
		<app-aside-widget #activityAsideWidget [title]="title" [loading]="loadingActivityRecords" [count]="totalActivityRecords" (retry)="onLoadActivityRecords()">
			<ng-template #asideWidgetActions>
				<button type="button" class="btn btn-sm btn-primary" (click)="onShowNewActivityForm()">
					New Activity
				</button>
			</ng-template>

			<!-- Filters will go here -->
			<ng-content></ng-content>

			<ng-container *ngIf="showNewActivityForm">
				<div class="card bg-light mx-3 mb-3">
					<div class="card-body">
						<h6 class="mb-3">
							New Activity
						</h6>

						<form #form="ngForm">
							<fieldset [disabled]="saving">
								<div class="row align-items-center g-2 mb-3">
									<div class="col">
										<ng-select #selectActivityType
												   class="custom-ng-select"
												   id="activityTypeCode"
												   name="activityTypeCode"
												   [items]="activityTypes"
												   [bindLabel]="'name'"
												   [bindValue]="'code'"
												   [(ngModel)]="model.activityTypeCode"
												   (ngModelChange)="onSelectActivityType($event)"
												   [clearable]="false"
												   [multiple]="false"
												   [searchable]="false"
												   [loading]="loadingActivityTypes"
												   [loadingText]="'loading...'"
												   [disabled]="saving"
												   [placeholder]="'Type'">
										</ng-select>
									</div>

									<ng-container *ngIf="selectedActivityType?.hasActivityEntity">
										<div class="col-auto">
											with
										</div>

										<div class="col">
											<ng-select #selectActivityType
													   class="custom-ng-select"
													   id="activityEntityCode"
													   name="activityEntityCode"
													   [items]="activityEntities"
													   [bindLabel]="'name'"
													   [bindValue]="'code'"
													   [(ngModel)]="model.activityEntityCode"
													   [clearable]="false"
													   [multiple]="false"
													   [searchable]="false"
													   [loading]="loadingActivityEntities"
													   [loadingText]="'loading...'"
													   [disabled]="saving"
													   [placeholder]="'Entity'">
											</ng-select>
										</div>
									</ng-container>
								</div>

								<hr />

								<div class="row mb-3">
									<div class="col">
										<textarea class="form-control" id="content" name="content" [(ngModel)]="model.content" [required]="true" placeholder="Activity details" rows="4"></textarea>
									</div>
								</div>

								<div class="row mb-3">
									<div class="col">
										<app-switch [id]="'isVisibleToClient'" [name]="'isVisibleToClient'" [(value)]="model.isVisibleToClient" (save)="onVisibleToClientChanged($event)">
											<span [tooltip]="clientName">Visible to client?</span>
										</app-switch>
									</div>
								</div>

								<hr />

								<div class="row">
									<div class="col-24">
										<button type="submit" class="btn btn-sm btn-primary" (click)="onSave()">
											<fa-icon *ngIf="saving" class="me-2" [icon]="faSpinnerThird" [spin]="saving"></fa-icon>
											Save New
										</button>

										<button type="button" class="btn btn-sm btn-outline-primary ms-2" (click)="onHideNewActivityForm()">
											Cancel
										</button>
									</div>
								</div>
							</fieldset>
						</form>
					</div>
				</div>
			</ng-container>

			<ng-container *ngIf="activityRecords?.length == 0">
				<div class="mx-3 mb-3">
					<app-empty class="bg-light rounded" [message]="'no ' + title.toLowerCase()"></app-empty>
				</div>
			</ng-container>

			<ng-container *ngIf="activityRecords?.length > 0">
				<accordion class="custom-accordion mx-3 mb-3" [isAnimated]="true">
					<ng-container *ngFor="let kvp of activityRecordsGroupped | keyvalue; let firstGroup = first;">
						<accordion-group class="mt-2" [heading]="kvp.key" [isOpen]="true" [class.mt-0]="firstGroup">
							<div class="card border-0">
								<ul class="list-group list-group-flush">
									<li class="list-group-item px-2" [class.pb-2]="isFirst" [class.py-2]="!isFirst" *ngFor="let activityRecord of kvp.value; let isFirst = first" [class.bg-light]="!activityRecord.isImportant">
										<div class="d-flex justify-content-between align-items-center small">
											<div class="d-flex align-items-center">
												<div class="badge rounded-pill" [class.text-bg-medium]="!activityRecord.isImportant" [class.text-bg-light]="activityRecord.isImportant">
													{{ activityRecord.activityDate | date:'MMM d @ h:mm a' }}
												</div>
												<div class="mx-2">•</div>
												<app-worker-value [fullName]="activityRecord.portalUserName" [profileImageSrc]="activityRecord.portalUserImageURL"></app-worker-value>
											</div>

											<div class="badge bg-warning rounded-pill" [tooltip]="clientName" *ngIf="activityRecord.isVisibleToClient">
												Visible to client
											</div>
										</div>

										<div class="text-dark d-flex align-items-center my-1">
											<img width="20" height="20" [ngSrc]="'/assets/activity/' + activityRecord.activityTypeCode + '.svg'" alt="{{ activityRecord.activityTypeName }} icon" class="me-2" />
											{{ activityRecord.activityTypeName }}
											<ng-container *ngIf="activityRecord.activityEntityCode">
												with {{ activityRecord.activityEntityName }}
											</ng-container>
										</div>

										<div class="small" *ngIf="activityRecord.content">
											<span class="d-inline me-1"
												  [class.text-wrapped-spaced]="(activityRecord.hasTrimedContent && activityRecord.showMoreContent) || !activityRecord.hasTrimedContent"
												  [innerHTML]="activityRecord.hasTrimedContent && !activityRecord.showMoreContent ? activityRecord.trimedContent : activityRecord.content"></span>

											<a [ngClass]="{ 'd-inline-block' : !activityRecord.showMoreContent, 'd-block mt-2' : activityRecord.showMoreContent }"
											   href="javascript:void(0);"
											   (click)="activityRecord.showMoreContent = !activityRecord.showMoreContent"
											   *ngIf="activityRecord.hasTrimedContent">
												{{ activityRecord.showMoreContent ? 'Show less' : 'Show more' }}
											</a>
										</div>
									</li>
								</ul>
							</div>
						</accordion-group>
					</ng-container>
				</accordion>

				<ng-container *ngIf="hasMore">
					<div class="row mt-2">
						<div class="col small">
							<ng-container *ngIf="!loadingMore">
								<a href="javascript:void(0);" (click)="onLoadMore()">
									Load more...
								</a>
							</ng-container>

							<ng-container *ngIf="loadingMore">
								<span class="text-muted">
									<fa-icon class="me-2" [icon]="faSpinnerThird" [spin]="true"></fa-icon>
									Loading more...
								</span>
							</ng-container>
						</div>
					</div>
				</ng-container>
			</ng-container>
		</app-aside-widget>
	</div>
</div>
