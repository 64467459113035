import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { formatDate } from '@angular/common';

import { ToastrService } from 'ngx-toastr';

import { Activity } from '@app/shared/models';
import { AsideWidgetComponent } from '@app/shared/components';
import { LookupService } from '@app/settings/services';
import { ActivityType, LookupValue } from '@app/settings/models';

import { SwitchToggleEvent } from '@app/shared/components/switch/switch.component';

import { Messages } from '@app/constants';

import { faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';

@Component({
	selector: 'app-activity-widget',
	templateUrl: 'activity-widget.component.html',
	styleUrls: ['activity-widget.component.scss'],
	exportAs: 'activityWidget'
})
export class ActivityWidgetComponent implements OnInit {
	faEllipsisVertical = faEllipsisVertical;
	faSpinnerThird = faSpinnerThird;

	loadingActivityRecords: boolean = false;
	loadingActivityRecordsError: boolean = false;
	activityRecords: Activity[] = [];
	activityRecordsGroupped: { [key: string]: Activity[] } = {};
	totalActivityRecords: number = 0;
	currentPage: number = 1;
	hasMore: boolean = false;
	loadingMore: boolean = false;

	loadingActivityTypes: boolean = false;
	loadingActivityTypesError: boolean = false;
	activityTypes: ActivityType[] = [];
	selectedActivityType: ActivityType;

	loadingActivityEntities: boolean = false;
	loadingActivityEntitiesError: boolean = false;
	activityEntities: LookupValue[] = [];

	model: Activity;
	showNewActivityForm: boolean = false;
	saving: boolean = false;

	@Input()
	title: string = 'Activity';

	@Input()
	loader: (pageNumber: number) => Promise<PagedDataModel<Activity>>;

	@Input()
	saver: (activity: Activity) => Promise<Activity>;

	@Input()
	clientName: string;

	@ViewChild('activityAsideWidget', { static: false })
	activityAsideWidget: AsideWidgetComponent;

	constructor(
		private lookupService: LookupService,
		private toastr: ToastrService
	) {
	}

	async ngOnInit() {
		this.onLoadActivityRecords();
	}

	reloadActivityRecords() {
		this.currentPage = 1;
		this.activityRecords = [];
		this.activityRecordsGroupped = {};
		this.totalActivityRecords = 0;
		this.hasMore = false;
		this.loadingMore = false;
		this.onLoadActivityRecords();
	}

	async onLoadActivityRecords() {
		try {
			this.loadingActivityRecords = !this.loadingMore;
			this.loadingActivityRecordsError = false;

			let response = await this.loader(this.currentPage);

			this.hasMore = this.currentPage < response.totalPages;
			this.totalActivityRecords = response.totalRecords;
			this.activityRecords = [...(this.loadingMore ? this.activityRecords : []), ...response.pageData];

			this.activityRecords.forEach(activity => {
				if (!activity.content) {
					return;
				}
				
				let newLineMatches = activity.content.match(/\r\n|\n\r|\n|\r/g);

				activity.hasTrimedContent = activity.content.length > 250 || (newLineMatches && newLineMatches.length > 5);
				activity.trimedContent = activity.content.substring(0, 180);
			})

			this.activityRecordsGroupped = {};
			this.activityRecords.forEach(activityRecord => {
				let groupKey = formatDate(activityRecord.activityDate, 'MMMM yyyy', 'en-US').toUpperCase();

				if (!this.activityRecordsGroupped[groupKey]) {
					this.activityRecordsGroupped[groupKey] = [];
				}

				this.activityRecordsGroupped[groupKey].push(activityRecord);
			});
		} catch (error: any) {
			console.error(error);
			this.loadingActivityRecordsError = true;
		} finally {
			this.loadingActivityRecords = false;
			this.loadingMore = false;
		}
	}

	async onLoadMore() {
		this.currentPage = this.currentPage + 1;
		this.loadingMore = true;

		this.onLoadActivityRecords();
	}

	async onReloadActivityTypes() {
		try {
			this.loadingActivityTypes = true;
			this.loadingActivityTypesError = false;

			this.activityTypes = await this.lookupService.getActivityTypes();
		} catch (error: any) {
			console.error(error);
			this.loadingActivityTypesError = true;
		} finally {
			this.loadingActivityTypes = false;
		}
	}

	async onReloadActivityEntities() {
		try {
			this.loadingActivityEntities = true;
			this.loadingActivityEntitiesError = false;

			this.activityEntities = await this.lookupService.getLookupValues('ActivityEntity');
		} catch (error: any) {
			console.error(error);
			this.loadingActivityEntitiesError = true;
		} finally {
			this.loadingActivityEntities = false;
		}
	}

	async onShowNewActivityForm() {
		this.model = new Activity();

		if (!this.activityTypes?.length) {
			this.onReloadActivityTypes();
		}

		if (!this.activityEntities?.length) {
			this.onReloadActivityEntities();
		}

		this.activityAsideWidget.collapsed = false;
		this.showNewActivityForm = true;
	}

	async onHideNewActivityForm() {
		this.showNewActivityForm = false;
	}

	async onSelectActivityType(activityTypeCode: any) {
		this.selectedActivityType = this.activityTypes.find(m => m.code == activityTypeCode);
	}

	async onVisibleToClientChanged(event: SwitchToggleEvent) {
		event.saved(true);
	}

	async onSave() {
		try {
			this.saving = true;

			await this.saver(this.model);

			this.toastr.success(`Successfully created activity record`);

			this.onHideNewActivityForm();
			// TODO: DONT RELOAD THE WHOLE THING - APPEND NEWLY ADDED ACTIVITY TO THE FRONT
			this.onLoadActivityRecords();
		} catch (error: any) {
			this.toastr.error(Messages.ErrorRetry, `Failed to Create Activity Record`);
		} finally {
			this.saving = false;
		}
	}
}
