import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';

import { Subscription, tap, catchError, throwError } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { Case } from '@app/data/cases/models';
import { NewCaseModalComponent } from '@app/shared/components/new-case/new-case-modal/new-case-modal.component';

import { Lookup } from '@app/shared/models';
import { DefaultMemberModel } from '@app/shared/models/member.models';
import { LookupService } from '@app/shared/services';
import { Messages, LookupCodes } from '@app/constants';

import { faSpinnerThird, faAngleDown } from '@fortawesome/pro-regular-svg-icons';

@Component({
	selector: 'app-new-case-button',
	templateUrl: 'new-case-button.component.html',
	styleUrls: ['new-case-button.component.scss']
})
export class NewCaseButtonComponent implements OnInit, OnDestroy {
	faSpinnerThird = faSpinnerThird;
	faAngleDown = faAngleDown;

	@Input()
	defaultPlan: { clientID: number, planID: number };

	@Input() defaultMember: DefaultMemberModel;
	// @Input()
	// clientMemberID: number;

	@Output()
	created: EventEmitter<Case> = new EventEmitter<Case>();

	caseTypeCategories: string[];
	groupedCaseTypes: { [key: string]: Lookup[] };
	loadingCaseTypesLookup: boolean = true;

	private modalRefSubscription: Subscription;
	private caseTypesLookupSubscription: Subscription;

	constructor(
		private lookupService: LookupService,
		private toastrService: ToastrService,
		private modalService: BsModalService,
	) {
	}

	ngOnInit() {
		this.caseTypesLookupSubscription = this.lookupService.getLookup<Lookup>(LookupCodes.CaseType)
			.pipe(
				tap(() => setTimeout(() => this.loadingCaseTypesLookup = false, 0)),
				catchError(err => {
					setTimeout(() => this.loadingCaseTypesLookup = false, 0);
					return this.showLookupErrorToast('Bill Statuses', err);
				}))
			.subscribe(caseTypes => {
				this.caseTypeCategories = [...new Set(caseTypes.map(item => item.categoryName))];
				this.groupedCaseTypes = caseTypes.reduce((map, item) => {
					const typeGroup = map[item.categoryName] || [];
					typeGroup.push(item);
					map[item.categoryName] = typeGroup;
					return map;
				}, {} as { [key: string]: Lookup[] });
			});
	}

	ngOnDestroy() {
		this.modalRefSubscription?.unsubscribe();
		this.caseTypesLookupSubscription?.unsubscribe();
	}

	private showLookupErrorToast(lookupName: string, error: any) {
		this.toastrService.error(Messages.ErrorRetry, `Failed to Load '${lookupName}'`);
		return throwError(() => error);
	}

	async onOpenModal(caseType: Lookup) {
		if (!caseType) {
			return;
		}

		let modalRef = this.modalService.show<NewCaseModalComponent>(NewCaseModalComponent, {
			class: 'modal-fullscreen',
			animated: true,
			initialState: {
				case: <Case>{
					clientID: this.defaultPlan?.clientID || null,
					clientPlanID: this.defaultPlan?.planID || null,
					// clientMemberID: this.clientMemberID || null,
					clientMemberID: this.defaultMember?.clientMemberID || null,
					clientMemberPlanID: this.defaultMember?.clientMemberPlanID || null,
					caseTypeCode: caseType.code,
					caseTypeName: caseType.name
				},
				defaultMember: this.defaultMember
			}
		});

		this.modalRefSubscription = modalRef.onHide.subscribe(() => {
			if (modalRef.content.created) {
				this.created.emit(modalRef.content.case);
			}
		});
	}
}
