<div class="modal-header px-4">
	<h5 class="modal-title">
		Deactivate Member's Plan
	</h5>

	<button type="button" class="btn-close" (click)="onClose()">
		<span class="visually-hidden">&times;</span>
	</button>
</div>

<div class="modal-body px-4 py-3">
	<p>
		Please enter the date when you want <strong>{{ memberPlan.planName }}</strong> to be deactivated for <strong>{{ memberName }}</strong>
	</p>

	<div class="row">
		<div class="col-12">
			<input type="date" id="terminationDate" name="terminationDate" class="form-control" [(ngModel)]="terminationDate" [disabled]="deactivating" />
		</div>
	</div>
</div>

<div class="modal-footer bg-white px-4">
	<button type="button" class="btn btn-outline-primary" (click)="onClose()" [disabled]="deactivating">
		Cancel
	</button>

	<button type="submit" class="btn btn-warning" (click)="onDeactivate()" [disabled]="deactivating">
		<fa-icon *ngIf="deactivating" class="me-2" [icon]="faSpinnerThird" [spin]="true"></fa-icon>
		Deactivate
	</button>
</div>
