import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { lastValueFrom, catchError, tap } from 'rxjs';

import { AssistantMessageModel } from '@app/shared/models';
import { handleHttpError } from '@app/shared/functions';

import { environment } from '@app/environment';

@Injectable({ providedIn: 'root' })
export class AssistantService {
	protected get baseApiUrl() {
        return `${environment.api.host}/api/v1/kb`;
    }

	constructor(
		private http: HttpClient
	) {
	}

    sendChatRequest(requestMessage: string) {
        return lastValueFrom(
            this.http
                .post<AssistantMessageModel[]>(`${this.baseApiUrl}/chat`, [{
                    role: 'user',
                    message: requestMessage
                }])
                .pipe(catchError(handleHttpError))
        );
    }
}