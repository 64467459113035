<ng-select id="{{ id }}" name="{{ name }}" class="custom-ng-select" [clearable]="false" [searchable]="true" notFoundText="no matching clients" loadingText="loading clients..." [loading]="clientPlansLoading" [readonly]="clientPlansLoading" [(ngModel)]="model" (ngModelChange)="onClientSelect($event)">
	<!-- all option -->
	<ng-option [value]="allClientsOption" *ngIf="showAllOption">
		<div class="fw-bold py-2 border-bottom-1">All Clients</div>
	</ng-option>
	<!-- other options -->
	<ng-option *ngFor="let client of clientPlansLookup" [value]="client">
		<div class="d-flex align-items-center py-1">
			<img width="20" height="auto" class="me-2" [src]="client.clientLogoUrl" *ngIf="client.clientLogoUrl" />
			<span>{{ client.clientName }}</span>
		</div>
	</ng-option>
</ng-select>
