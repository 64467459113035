import { Component, Input, Output, OnInit, OnDestroy, EventEmitter } from '@angular/core';

import { ToastrService } from "ngx-toastr";
import { Observable, Subscription, catchError, tap, throwError } from "rxjs";

import { LookupService } from "@app/shared/services";
import { ClientLookup } from "@app/shared/models";

import { Messages, LookupCodes } from "@app/constants";

@Component({
	selector: 'app-client-select',
	templateUrl: 'client-select.component.html',
	styleUrls: ['client-select.component.scss']
})
export class ClientSelectComponent implements OnInit, OnDestroy {
	static AllClientsOption = {
		clientID: null,
		clientName: 'All Clients'
	} as ClientLookup;
	allClientsOption = ClientSelectComponent.AllClientsOption;

	/* ============================== inputs/outputs ============================== */
	@Input() id: string;
	@Input() name: string;
	@Input() class: string;
	@Input() showAllOption: boolean = true;

	@Input() clientID: number;
	@Input() model: ClientLookup;
	@Output() modelChange: EventEmitter<ClientLookup> = new EventEmitter<ClientLookup>();

	/* ============================== lookups ============================== */
	clientPlansLookup: ClientLookup[];
	clientPlansLookupSubscription: Subscription;
	clientPlansLoading: boolean = true;

	constructor(
		private toastrService: ToastrService,
		private lookupService: LookupService,
	) {

	}

	ngOnInit() {
		this.clientPlansLookupSubscription = this.lookupService
			.getLookup<ClientLookup>(LookupCodes.ClientPlans)
			.pipe(
				tap(() => this.clientPlansLoading = false),
				catchError(err => {
					this.clientPlansLoading = false;
					return this.showLookupErrorToast('Clients', err);
				})
			)
			.subscribe(clients => {
				this.clientPlansLookup = clients;

				if (this.clientID && !this.model) {
					this.model = clients?.find(m => m.clientID == this.clientID);
				} else {
					this.model = this.allClientsOption;
				}
			});
	}

	ngOnDestroy() {
		this.clientPlansLookupSubscription?.unsubscribe();
	}

	onClientSelect(selectedClient: ClientLookup) {
		this.modelChange.emit(selectedClient);
	}

	private showLookupErrorToast(lookupName: string, error: any) {
		this.toastrService.error(Messages.ErrorRetry, `Failed to Load '${lookupName}'`);
		return throwError(() => error);
	}
}
