import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { UpdateComponent } from './components/update/update.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { SpinnerWheelComponent } from './components/spinner-wheel/spinner-wheel.component';
import { FieldSeparatorComponent } from './components/field-separator/field-separator.component';
import { InfoIconComponent } from './components/info-icon/info-icon.component';
import { StackedBarChartComponent } from './components/stacked-bar-chart/stacked-bar-chart.component';
import { StackedBarChartValueComponent } from './components/stacked-bar-chart/stacked-bar-chart-value.component';
import { DetailsPlaceholderComponent } from './components/details-placeholder/details-placeholder.component';
import { ActionConfirmationComponent } from './components/action-confirmation/action-confirmation.component';
import { ErrorComponent } from './components/error/error.component';
import { EmptyComponent } from './components/empty/empty.component';
import { ValidationComponent } from './components/validation/validation.component';
import { AsideWidgetComponent } from './components/aside-widget/aside-widget.component';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { InlineEditComponent } from './components/inline-edit/inline-edit.component';
import { InlineDatepickerComponent } from './components/inline-datepicker/inline-datepicker.component';
import { DateRangePickerComponent } from './components/daterange-picker/daterange-picker.component';
import { PhoneInputComponent } from './components/input-phone/input-phone.component';
import { ZipCodeInputComponent } from './components/input-zipcode/input-zipcode.component';
import { AddressEditComponent } from './components/address-edit/address-edit.component';
import { OrderEditComponent } from './components/order-edit/order-edit.component';
import { BreadcrumbComponent } from './components/breadcrumbs/breadcrumbs.component';
import { SwitchComponent } from './components/switch/switch.component';
import { MetricsNumberComponent } from './components/metrics-number/metrics-number.component';
import { VerticalLineComponent } from './components/vline/vline.component';
import { BarChartPlaceholderComponent } from './components/barchart-placeholder/barchart-placeholder.component';
import { ObjectViewerComponent } from './components/object-viewer/object-viewer.component';

import { FileViewerComponent } from './components/file-viewer/file-viewer.component';
import { TablePlaceholderComponent } from './components/table-placeholder/table-placeholder.component';
import { FileCreateModalComponent } from './components/file-create-modal/file-create-modal.component';
import { FileRenameModalComponent } from './components/file-rename-modal/file-rename-modal.component';
import { FileDeleteModalComponent } from './components/file-delete-modal/file-delete-modal.component';
import { FilesWidgetComponent } from './components/files-widget/files-widget.component';
import { TasksWidgetComponent } from './components/tasks-widget/tasks-widget.component';
import { ActivityWidgetComponent } from './components/activity-widget/activity-widget.component';
import { AuthorizationDigitalModalComponent } from './components/authorization-digital-modal/authorization-digital-modal.component';
import { AuthorizationsWidgetComponent } from './components/authorizations-widget/authorizations-widget.component';
import { AuthorizationCreateModalComponent } from './components/authorization-create-modal/authorization-create-modal.component';
import { AuthorizationRequestModalComponent } from './components/authorization-request-modal/authorization-request-modal.component';
import { ProviderInputComponent } from './components/provider-input/provider-input.component';
import { ProcedureInputComponent } from './components/procedure-input/procedure-input.component';
import { SpecialtyInputComponent } from './components/specialty-input/specialty-input.component';
import { GenderSelectComponent } from './components/gender-select/gender-select.component';
import { SpecialtiesSelectComponent } from './components/specialties-select/specialties-select.component';
import { ProviderFacilitySelectComponent } from './components/provider-facility-select/provider-facility-select.component';
import { MedicationSelectComponent } from './components/medication-select/medication-select.component';
import { WorkerDropdownComponent } from './components/worker-dropdown/worker-dropdown.component';
import { WorkerSelectComponent } from './components/worker-select/worker-select.component';
import { WorkerValueComponent } from './components/worker-value/worker-value.component';
import { ClientPlanValueComponent } from './components/client-plan-value/client-plan-value.component';
import { ClientSelectComponent } from './components/client-select/client-select.component';
import { ClientPlansSelectComponent } from './components/client-plans-select/client-plans-select.component';
import { MemberSelectComponent } from './components/member-select/member-select.component';

// new case related components
import { NewCaseButtonComponent } from './components/new-case/new-case-button/new-case-button.component';
import { NewCaseModalComponent } from './components/new-case/new-case-modal/new-case-modal.component';
import { CaseCreatePatientInformationComponent } from './components/new-case/create-patient-information/create-patient-information.component';
import { CaseCreateCaseSummaryComponent } from './components/new-case/create-case-summary/create-case-summary.component';
import { CreateBillNegotiationCaseComponent } from './components/new-case/create-bill-negotiation/create-bill-negotiation.component';
import { CaseCreatePrescriptionRequestComponent } from './components/new-case/create-prescription-request/create-prescription-request.component';
import { CaseCreateFacilityProviderComponent } from './components/new-case/create-facility-provider/create-facility-provider.component';
import { CaseCreateSchedulingComponent } from './components/new-case/create-scheduling/create-scheduling.component';
import { CaseCreateTransportationComponent } from './components/new-case/create-transportation/create-transportation.component';
import { CaseCreateMemberAppSupportComponent } from './components/new-case/create-member-app-support/create-member-app-support.component';
import { CaseCreatePolicyAndPlanGuidanceComponent } from './components/new-case/create-policy-and-plan-guidance/create-policy-and-plan-guidance.component';
import { CaseCreateItemizedStatementsComponent } from './components/new-case/create-itemized-statements/create-itemized-statements.component';
import { CaseCreatePreNegotiationComponent } from './components/new-case/create-pre-negotiation/create-pre-negotiation.component';


export { HeaderComponent } from './components/header/header.component';
export { FooterComponent } from './components/footer/footer.component';
export { UpdateComponent } from './components/update/update.component';
export { SpinnerComponent } from './components/spinner/spinner.component';
export { SpinnerWheelComponent } from './components/spinner-wheel/spinner-wheel.component';
export { FieldSeparatorComponent } from './components/field-separator/field-separator.component';
export { InfoIconComponent } from './components/info-icon/info-icon.component';
export { StackedBarChartComponent } from './components/stacked-bar-chart/stacked-bar-chart.component';
export { StackedBarChartValueComponent } from './components/stacked-bar-chart/stacked-bar-chart-value.component';
export { DetailsPlaceholderComponent } from './components/details-placeholder/details-placeholder.component';
export { ActionConfirmationComponent } from './components/action-confirmation/action-confirmation.component';
export { ErrorComponent } from './components/error/error.component';
export { EmptyComponent } from './components/empty/empty.component';
export { ValidationComponent } from './components/validation/validation.component';
export { AsideWidgetComponent } from './components/aside-widget/aside-widget.component';
export { ConfirmComponent } from './components/confirm/confirm.component';
export { InlineEditComponent } from './components/inline-edit/inline-edit.component';
export { InlineDatepickerComponent } from './components/inline-datepicker/inline-datepicker.component';
export { DateRangePickerComponent } from './components/daterange-picker/daterange-picker.component';
export { PhoneInputComponent } from './components/input-phone/input-phone.component';
export { ZipCodeInputComponent } from './components/input-zipcode/input-zipcode.component';
export { AddressEditComponent } from './components/address-edit/address-edit.component';
export { OrderEditComponent } from './components/order-edit/order-edit.component';
export { BreadcrumbComponent } from './components/breadcrumbs/breadcrumbs.component';
export { SwitchComponent } from './components/switch/switch.component';
export { MetricsNumberComponent } from './components/metrics-number/metrics-number.component';
export { VerticalLineComponent } from './components/vline/vline.component';
export { BarChartPlaceholderComponent } from './components/barchart-placeholder/barchart-placeholder.component';
export { ObjectViewerComponent } from './components/object-viewer/object-viewer.component';

export { FileViewerComponent } from './components/file-viewer/file-viewer.component';
export { TablePlaceholderComponent } from './components/table-placeholder/table-placeholder.component';
export { FileCreateModalComponent } from './components/file-create-modal/file-create-modal.component';
export { FileRenameModalComponent } from './components/file-rename-modal/file-rename-modal.component';
export { FileDeleteModalComponent } from './components/file-delete-modal/file-delete-modal.component';
export { FilesWidgetComponent } from './components/files-widget/files-widget.component';
export { TasksWidgetComponent } from './components/tasks-widget/tasks-widget.component';
export { ActivityWidgetComponent } from './components/activity-widget/activity-widget.component';
export { AuthorizationDigitalModalComponent } from './components/authorization-digital-modal/authorization-digital-modal.component';
export { AuthorizationsWidgetComponent } from './components/authorizations-widget/authorizations-widget.component';
export { AuthorizationCreateModalComponent } from './components/authorization-create-modal/authorization-create-modal.component';
export { AuthorizationRequestModalComponent } from './components/authorization-request-modal/authorization-request-modal.component';
export { ProviderInputComponent } from './components/provider-input/provider-input.component';
export { ProcedureInputComponent } from './components/procedure-input/procedure-input.component';
export { SpecialtyInputComponent } from './components/specialty-input/specialty-input.component';
export { GenderSelectComponent } from './components/gender-select/gender-select.component';
export { ProviderFacilitySelectComponent } from './components/provider-facility-select/provider-facility-select.component';
export { MedicationSelectComponent } from './components/medication-select/medication-select.component';
export { WorkerDropdownComponent } from './components/worker-dropdown/worker-dropdown.component';
export { WorkerSelectComponent } from './components/worker-select/worker-select.component';
export { WorkerValueComponent } from './components/worker-value/worker-value.component';
export { ClientPlanValueComponent } from './components/client-plan-value/client-plan-value.component';
export { ClientSelectComponent } from './components/client-select/client-select.component';

export { ClientPlansSelectComponent } from './components/client-plans-select/client-plans-select.component';
export { MemberSelectComponent } from './components/member-select/member-select.component';

// new case related components
export { NewCaseButtonComponent } from './components/new-case/new-case-button/new-case-button.component';
export { NewCaseModalComponent } from './components/new-case/new-case-modal/new-case-modal.component';
export { CaseCreatePatientInformationComponent } from './components/new-case/create-patient-information/create-patient-information.component';
export { CaseCreateCaseSummaryComponent } from './components/new-case/create-case-summary/create-case-summary.component';
export { CreateBillNegotiationCaseComponent } from './components/new-case/create-bill-negotiation/create-bill-negotiation.component';
export { CaseCreatePrescriptionRequestComponent } from './components/new-case/create-prescription-request/create-prescription-request.component';
export { CaseCreateFacilityProviderComponent } from './components/new-case/create-facility-provider/create-facility-provider.component';
export { CaseCreateSchedulingComponent } from './components/new-case/create-scheduling/create-scheduling.component';
export { CaseCreateTransportationComponent } from './components/new-case/create-transportation/create-transportation.component';
export { CaseCreateMemberAppSupportComponent } from './components/new-case/create-member-app-support/create-member-app-support.component';
export { CaseCreatePolicyAndPlanGuidanceComponent } from './components/new-case/create-policy-and-plan-guidance/create-policy-and-plan-guidance.component';
export { CaseCreateItemizedStatementsComponent } from './components/new-case/create-itemized-statements/create-itemized-statements.component';
export { CaseCreatePreNegotiationComponent } from './components/new-case/create-pre-negotiation/create-pre-negotiation.component';

export const COMPONENTS = [
	HeaderComponent,
	FooterComponent,
	UpdateComponent,
	SpinnerComponent,
	SpinnerWheelComponent,
	FieldSeparatorComponent,
	InfoIconComponent,
	StackedBarChartComponent,
	StackedBarChartValueComponent,
	DetailsPlaceholderComponent,
	ActionConfirmationComponent,
	ErrorComponent,
	EmptyComponent,
	ValidationComponent,
	AsideWidgetComponent,
	ConfirmComponent,
	InlineEditComponent,
	InlineDatepickerComponent,
	DateRangePickerComponent,
	PhoneInputComponent,
	ZipCodeInputComponent,
	AddressEditComponent,
	OrderEditComponent,
	BreadcrumbComponent,
	SwitchComponent,
	MetricsNumberComponent,
	VerticalLineComponent,
	BarChartPlaceholderComponent,
	ObjectViewerComponent,

	FileViewerComponent,
	TablePlaceholderComponent,
	FileCreateModalComponent,
	FileRenameModalComponent,
	FileDeleteModalComponent,
	FilesWidgetComponent,
	TasksWidgetComponent,
	ActivityWidgetComponent,
	AuthorizationDigitalModalComponent,
	AuthorizationsWidgetComponent,
	AuthorizationCreateModalComponent,
	AuthorizationRequestModalComponent,
	ProviderInputComponent,
	ProcedureInputComponent,
	SpecialtyInputComponent,
	GenderSelectComponent,
	SpecialtiesSelectComponent,
	ProviderFacilitySelectComponent,
	MedicationSelectComponent,
	WorkerDropdownComponent,
	WorkerSelectComponent,
	WorkerValueComponent,
	ClientPlanValueComponent,
	ClientSelectComponent,

	ClientPlansSelectComponent,
	MemberSelectComponent,

	// new case related components
	NewCaseButtonComponent,
	NewCaseModalComponent,

	CaseCreatePatientInformationComponent,
	CaseCreateCaseSummaryComponent,
	CreateBillNegotiationCaseComponent,
	CaseCreatePrescriptionRequestComponent,
	CaseCreateFacilityProviderComponent,
	CaseCreateSchedulingComponent,
	CaseCreateTransportationComponent,
	CaseCreateMemberAppSupportComponent,
	CaseCreatePolicyAndPlanGuidanceComponent,
	CaseCreateItemizedStatementsComponent,
	CaseCreatePreNegotiationComponent,

];
