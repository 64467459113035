import { Component, AfterViewInit, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl, ValidationErrors } from '@angular/forms';

import { getCountries, CountryCode, getCountryCallingCode } from 'libphonenumber-js';
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';

import { CustomFormInput } from '../custom-form-input.abstract';

declare var libphonenumber: any;

export const PHONE_VALUE_ACCESSOR = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => PhoneInputComponent),
	multi: true
};

export const PHONE_VALUE_VALIDATOR = {
	provide: NG_VALIDATORS,
	useExisting: forwardRef(() => PhoneInputComponent),
	multi: true,
};

@Component({
	selector: 'app-phone-input',
	templateUrl: 'input-phone.component.html',
	styleUrls: ['input-phone.component.scss'],
	providers: [PHONE_VALUE_ACCESSOR, PHONE_VALUE_VALIDATOR],
	exportAs: 'appPhoneNumberInput'
})
export class PhoneInputComponent extends CustomFormInput<string> implements AfterViewInit {
	// phoneMask: (string | RegExp)[];
	phoneMask: string;

	// override placeholder: string = '(___) ___-____';

	// phoneNumber: string;
	get phoneNumber(): string {
		return this.value
	}
	set phoneNumber(value: string) {
		let digitsOnly = (this.usOnly ? '' : '+') + value.replace(/[^\d]/g, '');

		if (this.value != digitsOnly) {
			this.value = digitsOnly;
			this.touch();
		}
	}

	selectedCountry = 'US';
	countryCode = '+1';
	// only allow US and IN (india) for now
	countries = getCountries().filter(c => c === 'US' || c === 'IN');

	@Input() usOnly: boolean = true;

	override validate(control: FormControl): ValidationErrors | null {
		let digitsOnly = control.value?.replace(/[^\d]/g, '') || '';

		if (this.required && digitsOnly.length < 10) {
			return {
				length: true
			}
		}

		return null;
	}

	override ngOnInit() {
		super.ngOnInit();

		if (!this.usOnly && this.phoneNumber) {
			const phoneUtil = PhoneNumberUtil.getInstance();
			const phone = phoneUtil.parse(this.phoneNumber);
			this.selectedCountry = phoneUtil.getRegionCodeForNumber(phone);
			this.countryCode = `+${phone.getCountryCode()}`;

			console.log(this.selectedCountry, this.countryCode, this.phoneNumber);
		}

		this.onCountryChange(false);
	}

	ngAfterViewInit() {

	}

	getCountryCode(country: string): string {
		return getCountryCallingCode(country as CountryCode);
	}

	onCountryChange(clearValue: boolean) {
		this.countryCode = getCountryCallingCode(this.selectedCountry as CountryCode);
		setTimeout(() => {
			if (clearValue ||
				(this.usOnly && this.phoneNumber?.startsWith('+')) ||
				(!this.usOnly && !this.phoneNumber?.startsWith('+' + this.countryCode))) {
				this.phoneNumber = this.usOnly ? '' : ('+' + this.countryCode);
			}
		}, 10);

		const phoneUtil = PhoneNumberUtil.getInstance();
		let exampleNumber: string;
		const examplePhoneNumber = phoneUtil.getExampleNumber(this.selectedCountry);
		if (this.selectedCountry === 'US') {
			exampleNumber = (this.usOnly ? '' : '+1 ') + phoneUtil.format(examplePhoneNumber, PhoneNumberFormat.NATIONAL);
		} else {
			exampleNumber = phoneUtil.format(examplePhoneNumber, PhoneNumberFormat.INTERNATIONAL);
		}

		this.placeholder = (this.usOnly ? '' : ('+' + this.countryCode)) +
			exampleNumber.replace('+' + this.countryCode, '').replace(/[\d]/g, '0');

		this.phoneMask = this.convertToMaskArray(exampleNumber);
	}

	onEnsureStartsWithCountryCode = (conformedValue: string) => {
		if (this.usOnly || !conformedValue) {
			return conformedValue;
		}

		const valuePart = conformedValue.substr(0, this.countryCode.length + 1);
		if (!('+' + this.countryCode).startsWith(valuePart)) {
			return false;
		}

		return conformedValue;
	}

	convertToMaskArray(formattedNumber: string): string {
		const mask = formattedNumber.replace(/[\d]/g, '0');

		console.log('MASK', formattedNumber, mask);

		return mask;
		// const maskArray: (string | RegExp)[] = [];

		// for (const char of formattedNumber) {
		// 	if (char === '+') {
		// 		maskArray.push('+');
		// 	} else if (char === ' ') {
		// 		maskArray.push(' ');
		// 	} else if (isNaN(Number(char))) {
		// 		maskArray.push(char);
		// 	} else {
		// 		maskArray.push(/\d/);
		// 	}
		// }
		// return maskArray;
	}
}
