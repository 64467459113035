import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';

import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

import { MembersService } from '@app/data/members/services';
import { Member, MemberDashboard } from '@app/data/members/models';

import { LookupService } from '@app/settings/services';
import { LookupValue, State } from '@app/settings/models';
import { ClientPlanMemberLookup } from '@app/shared/models';

import { isValidationError, getValidationErrors, scrollToElementById } from '@app/functions';
import { Messages } from '@app/constants';

import { ActionConfirmationComponent } from '@app/shared/components/action-confirmation/action-confirmation.component';
import { SwitchToggleEvent } from '@app/shared/components/switch/switch.component';

import { faLinkSimple, faTimes, faCalendar } from '@fortawesome/pro-regular-svg-icons';

@Component({
	templateUrl: 'member-create-modal.component.html',
	styleUrls: ['member-create-modal.component.scss']
})
export class MemberCreateModalComponent implements OnInit {
	faLinkSimple = faLinkSimple;
	faTimes = faTimes;
	faCalendar = faCalendar;

	loadingGenders: boolean = false;
	genders: LookupValue[];

	loadingLanguages: boolean = false;
	languages: LookupValue[];

	loadingStates: boolean = false;
	states: State[];

	loadingCoverageTypes: boolean = false;
	coverageTypes: LookupValue[];

	loadingRelationshipTypes: boolean = false;
	relationshipTypes: LookupValue[];

	loadingMembers: boolean = false;
	members: MemberDashboard[];

	model: Member = new Member();
	clientID: number = null;
	saving: boolean = false;
	created: boolean = false;
	isDependentMember: boolean = false;
	validationErrors: string[];

	get isExternalCodeRequired(): boolean {
		return true;
		// TODO: REQUIRED FOR NOT TEMP PLANS
		// this.model.clientPlanID
	}

	constructor(
		private membersService: MembersService,
		private lookupService: LookupService,
		private modalRef: BsModalRef,
		private toastrService: ToastrService,
		private modalService: BsModalService,
		private router: Router
	) {
	}

	ngOnInit() {
		this.onReloadGenders();
		this.onReloadLanguages();
		this.onReloadStates();
		this.onReloadCoverageTypes();
	}

	async onReloadGenders() {
		try {
			this.loadingGenders = true;

			this.genders = await this.lookupService.getLookupValues('Gender');
		} catch (error: any) {
			this.toastrService.error(Messages.ErrorRetry, `Failed to Load Genders`);
		} finally {
			this.loadingGenders = false;
		}
	}

	async onReloadLanguages() {
		try {
			this.loadingLanguages = true;

			this.languages = await this.lookupService.getLookupValues('Language');
		} catch (error: any) {
			this.toastrService.error(Messages.ErrorRetry, `Failed to Load Languages`);
		} finally {
			this.loadingLanguages = false;
		}
	}

	async onReloadStates() {
		try {
			this.loadingStates = true;

			this.states = await this.lookupService.getStates();
		} catch (error: any) {
			this.toastrService.error(Messages.ErrorRetry, `Failed to Load States`);
		} finally {
			this.loadingStates = false;
		}
	}

	async onReloadCoverageTypes() {
		try {
			this.loadingCoverageTypes = true;

			this.coverageTypes =  await this.lookupService.getLookupValues('CoverageType');
		} catch (error: any) {
			this.toastrService.error(Messages.ErrorRetry, `Failed to Load Coverage Types`);
		} finally {
			this.loadingCoverageTypes = false;
		}
	}

	async onReloadRelationshipTypes() {
		try {
			this.loadingRelationshipTypes = true;

			this.relationshipTypes = await this.lookupService.getLookupValues('DependentRelationshipType');
		} catch (error: any) {
			this.toastrService.error(Messages.ErrorRetry, `Failed to Load Relationship Types`);
		} finally {
			this.loadingRelationshipTypes = false;
		}
	}

	async onReloadMembers() {
		try {
			this.loadingMembers = true;

			let response = await this.membersService.getMemberDashboard(
				'',
				null,
				this.clientID,
				this.model.clientPlanID,
				null,
				'asc',
				1
			);

			this.members = response.pageData;
		} catch (error: any) {
			this.toastrService.error(Messages.ErrorRetry, `Failed to Load Members`);
		} finally {
			this.loadingMembers = false;
		}
	}

	onClientPlanChange(selection: { clientID?: number, clientPlanID?: number }) {
		this.clientID = selection.clientID;
		this.model.clientPlanID = selection.clientPlanID;
	}

	onPrimaryChange(primaryMember: ClientPlanMemberLookup) {
		this.model.primaryClientMemberPlanID = primaryMember?.clientMemberPlanID;
	}

	async onSave(form: NgForm) {
		try {
			this.saving = true;
			this.validationErrors = null;

			// TODO: Remove next line together with fullName field whenever API gets fixed and doesn't require fullName
			this.model.fullName = `${this.model.firstName} ${this.model.lastName}`;
			// TODO: REMOVE ONCE PHONES UNIFIED
			if (this.model.phoneNumber?.length <= 3) {
				this.model.phoneNumber = null;
			} else if (this.model.phoneNumber && !this.model.phoneNumber.startsWith('+')) {
				this.model.phoneNumber = '+1' + this.model.phoneNumber;
			}
			if (this.model.mobileNumber?.length <= 3) {
				this.model.mobileNumber = null;
			} else if (this.model.mobileNumber && !this.model.mobileNumber.startsWith('+')) {
				this.model.mobileNumber = '+1' + this.model.mobileNumber;
			}

			let response = await this.membersService.createMember(this.model);

			this.created = true;

			this.toastrService.success(`Successfully created ${response.firstName} ${response.lastName}.`);

			this.router.navigate([`/data/members/${response.clientMemberID}`]);

			this.onClose(null);
		} catch (err: any) {
			if (isValidationError(err)) {
				this.validationErrors = getValidationErrors(err);
				this.toastrService.warning('Unable to save the bill because of validation errors. Please correct all validation errors and try saving again.');
				scrollToElementById('validationAlert');
			} else {
				this.toastrService.error(Messages.ErrorRetry, `Failed to Create Member`);
			}
		} finally {
			this.saving = false;
		}
	}

	onIsDependentMemberChange(event: SwitchToggleEvent) {
		event.saved(true);

		if (this.isDependentMember) {
			this.onReloadMembers();
			this.onReloadRelationshipTypes();
		}
	}

	onClose(form: NgForm) {
		if (form && form.dirty) {
			this.modalService.show<ActionConfirmationComponent>(ActionConfirmationComponent, {
				class: 'modal-md',
				ignoreBackdropClick: true,
				animated: false,
				initialState: {
					title: Messages.DiscardChangesModalTitle,
					messageHtml: Messages.DiscardChangesModalMessage,
					actionTitle: Messages.DiscardChangesConfirmButtonText,
					actionButtonClass: Messages.DiscardChangesConfirmButtonClass,
					actionFunc: async () => {
						this.modalRef.hide();
						return true;
					},
					cancelTitle: Messages.DiscardChangesCancelButtonText,
					cancelButtonClass: Messages.DiscardChangesCancelButtonClass
				}
			});
		} else {
			this.modalRef.hide();
		}
	}
}
