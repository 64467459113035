import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';

import { lastValueFrom, catchError, throwError } from 'rxjs';

import { LookupType, LookupValue, State, BillChangeType, Client, ActivityType, ClientPlan, PortalUser } from '@app/settings/models';

import { environment } from '@app/environment';
import { handleHttpError } from '@app/functions';

@Injectable({ providedIn: 'root' })
export class LookupService {
	protected get baseApiUrl() {
		return `${environment.api.host}/api/v1/lookups`;
	}

	constructor(
		private http: HttpClient
	) {
	}

	async getLookupTypes() {
		return lastValueFrom(
			this.http.get<LookupType[]>(`${this.baseApiUrl}`).pipe(
				catchError(handleHttpError)
			)
		);
	}

	async getLookupValues(lookupTableName: string) {
		return lastValueFrom(
			this.http.get<LookupValue[]>(`${this.baseApiUrl}/${lookupTableName}`).pipe(
				catchError(handleHttpError)
			)
		);
	}

	async getLookupValue(lookupTableName: string, lookup: LookupValue) {
		return lastValueFrom(
			this.http.get<LookupValue>(`${this.baseApiUrl}/${lookupTableName}/${lookup.code}`).pipe(
				catchError(handleHttpError)
			)
		);
	}

	async createLookupValue(lookupTableName: string, lookup: LookupValue) {
		return lastValueFrom(
			this.http.post<LookupValue>(`${this.baseApiUrl}/${lookupTableName}`, lookup).pipe(
				catchError(handleHttpError)
			)
		);
	}

	async updateLookupValue(lookupTableName: string, lookup: LookupValue) {
		return lastValueFrom(
			this.http.put<LookupValue>(`${this.baseApiUrl}/${lookupTableName}/${lookup.code}`, lookup).pipe(
				catchError(handleHttpError)
			)
		);
	}

	async deleteLookupValue(lookupTableName: string, lookup: LookupValue) {
		let options = {
			params: new HttpParams({
				fromObject: {
					version: lookup.version
				}
			})
		};

		return lastValueFrom(
			this.http.delete<void>(`${this.baseApiUrl}/${lookupTableName}/${lookup.code}`, options).pipe(
				catchError(handleHttpError)
			)
		);
	}

	async getStates() {
		return lastValueFrom(
			this.http.get<State[]>(`${this.baseApiUrl}/states`).pipe(
				catchError(handleHttpError)
			)
		);
	}

	async getBillChangeTypes() {
		return lastValueFrom(
			this.http.get<BillChangeType[]>(`${this.baseApiUrl}/bill-change-types`).pipe(
				catchError(handleHttpError)
			)
		);
	}

	async getClients() {
		return lastValueFrom(
			this.http.get<Client[]>(`${this.baseApiUrl}/clients`).pipe(
				catchError(handleHttpError)
			)
		);
	}

	async getActivityTypes() {
		return lastValueFrom(
			this.http.get<ActivityType[]>(`${this.baseApiUrl}/ActivityType`).pipe(
				catchError(handleHttpError)
			)
		);
	}

	async getClientPlans() {
		return lastValueFrom(
			this.http.get<ClientPlan[]>(`${this.baseApiUrl}/client-plans`).pipe(
				catchError(handleHttpError)
			)
		);
	}

	async getPortalUsers() {
		return lastValueFrom(
			this.http.get<PortalUser[]>(`${this.baseApiUrl}/portal-users`).pipe(
				catchError(handleHttpError)
			)
		);
	}
}
