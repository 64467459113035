import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, HostListener } from '@angular/core';
import { formatDate } from '@angular/common';

import { BsDropdownDirective } from 'ngx-bootstrap/dropdown';
import * as moment from 'moment';

import { faChevronLeft, faChevronRight, faAngleDown, faCheck, faCaretDown } from '@fortawesome/pro-solid-svg-icons';

interface DateRangePreset {
	label: string,
	interval: string,
	dateRange: DateRange,
	selected?: boolean
}

const anyTimeLabel = 'Any Time';

@Component({
	selector: 'app-daterange-picker',
	templateUrl: 'daterange-picker.component.html',
	styleUrls: ['daterange-picker.component.scss'],
	exportAs: 'appDateRangePicker'
})
export class DateRangePickerComponent implements OnInit {
	faChevronLeft = faChevronLeft;
	faChevronRight = faChevronRight;
	faAngleDown = faAngleDown;
	faCaretDown = faCaretDown;
	faCheck = faCheck;

	private _loading: boolean = false;
	private _disabled: boolean = false;

	@ViewChild('dropdown', { static: false })
	dropdown: BsDropdownDirective;

	@Input()
	get disabled(): boolean {
		return this._disabled;
	};
	set disabled(disabled: boolean) {
		if (this._disabled == disabled) {
			return;
		}

		setTimeout(() => this.dropdown?.hide(), 0);
		this._disabled = disabled;
	}

	@Input()
	showNavigation: boolean = true

	@Input()
	align: 'left' | 'right' = 'left';

	@Input()
	dateRange: DateRange = { start: null, end: null };

	@Input()
	style: 'button' | 'select' = 'button';

	@Output()
	dateRangeChange: EventEmitter<DateRange> = new EventEmitter<DateRange>();

	dateRangeDisplay: string = '';
	dateRangePresets: DateRangePreset[] = [{
		label: anyTimeLabel,
		interval: null,
		dateRange: {
			start: null,
			end: null
		}
	}, {
		label: 'Today',
		interval: 'day',
		dateRange: {
			start: moment(new Date()).toDate(),
			end: moment(new Date()).toDate(),
		}
	}, {
		label: 'Yesterday',
		interval: 'day',
		dateRange: {
			start: moment(new Date()).add(-1, 'day').toDate(),
			end: moment(new Date()).add(-1, 'day').toDate()
		}
	}, {
		label: 'This Week',
		interval: 'week',
		dateRange: {
			start: moment().startOf('week').toDate(),
			end: moment().endOf('week').toDate()
		}
	}, {
		label: 'Last Week',
		interval: 'week',
		dateRange: {
			start: moment().add(-1, 'week').startOf('week').toDate(),
			end: moment().add(-1, 'week').endOf('week').toDate()
		}
	}, {
		label: 'This Month',
		interval: 'month',
		dateRange: {
			start: moment().startOf('month').toDate(),
			end: moment().endOf('month').toDate()
		}
	}, {
		label: 'Last Month',
		interval: 'month',
		dateRange: {
			start: moment().add(-1, 'month').startOf('month').toDate(),
			end: moment().add(-1, 'month').endOf('month').toDate()
		}
	}, {
		label: 'This Quarter',
		interval: 'quarter',
		dateRange: {
			start: moment().startOf('quarter').toDate(),
			end: moment().endOf('quarter').toDate()
		}
	}, {
		label: 'Last Quarter',
		interval: 'quarter',
		dateRange: {
			start: moment().add(-1, 'quarter').startOf('quarter').toDate(),
			end: moment().add(-1, 'quarter').endOf('quarter').toDate()
		}
	}, {
		label: 'This Year',
		interval: 'year',
		dateRange: {
			start: moment().startOf('year').toDate(),
			end: moment().endOf('year').toDate()
		}
	}, {
		label: 'Last Year',
		interval: 'year',
		dateRange: {
			start: moment().add(-1, 'year').startOf('year').toDate(),
			end: moment().add(-1, 'year').endOf('year').toDate()
		}
	}];
	selectedDateRange: Date[] = [null, null];
	maxDate: Date = null;
	weekStartingDay: number = 0;

	get today(): Date {
		return new Date();
	}

	constructor(
		private element: ElementRef
	) {
	}

	private getDateOnly(date: Date | string) {
		return formatDate(date, 'yyyy-MM-dd', 'en-US');
	}

	private findDateRangePresetByDate(dateRange: DateRange) {
		let dateRangePreset = null;

		if (dateRange.start && dateRange.end) {
			dateRangePreset = this.dateRangePresets.find(m =>
				m.dateRange.start != null &&
				m.dateRange.end != null &&
				this.getDateOnly(m.dateRange.start) == this.getDateOnly(dateRange.start) &&
				this.getDateOnly(m.dateRange.end) == this.getDateOnly(dateRange.end)
			);
		}

		if (!dateRangePreset) {
			if (!dateRange.start && !dateRange.end) {
				dateRangePreset = this.dateRangePresets.find(m =>
					m.label == anyTimeLabel
				);
			}
		}

		if (!dateRangePreset) {
			dateRangePreset = this.dateRangePresets.find(m =>
				m.label == 'Custom'
			);
		}

		return dateRangePreset;
	}

	private setDateRangeDisplayValue() {
		if (this.dateRange.start != null && this.dateRange.end == null) {
			this.dateRangeDisplay = `From ${formatDate(this.dateRange.start, 'MMM d', 'en-US')}`;
			return;
		}

		if (this.dateRange.start == null && this.dateRange.end != null) {
			this.dateRangeDisplay = `To ${formatDate(this.dateRange.end, 'MMM d', 'en-US')}`;
			return;
		}

		if (!this.dateRange.start && !this.dateRange.end) {
			this.dateRangeDisplay = anyTimeLabel;
			return;
		}

		if (this.getDateOnly(this.dateRange.start) == this.getDateOnly(this.dateRange.end)) {
			this.dateRangeDisplay = formatDate(this.dateRange.start, 'MMM d', 'en-US');
			return;
		}

		let dateRangePreset = this.findDateRangePresetByDate({
			start: this.selectedDateRange[0],
			end: this.selectedDateRange[1]
		});

		if (dateRangePreset && dateRangePreset.dateRange.start && dateRangePreset.dateRange.end) {
			this.dateRangeDisplay = dateRangePreset.label;
			return;
		}

		let dateRangeStartMonthYear = `${this.dateRange.start?.getMonth()}-${this.dateRange.start?.getFullYear()}`;
		let dateRangeEndMonthYear = `${this.dateRange.end?.getMonth()}-${this.dateRange.end?.getFullYear()}`;

		if (dateRangeStartMonthYear === dateRangeEndMonthYear) {
			this.dateRangeDisplay = `${formatDate(this.dateRange.start, 'MMM d', 'en-US')} - ${formatDate(this.dateRange.end, 'd', 'en-US')}`;
		} else {
			this.dateRangeDisplay = `${formatDate(this.dateRange.start, 'MMM d', 'en-US')} - ${formatDate(this.dateRange.end, 'MMM d', 'en-US')}`;
		}
	}

	ngOnInit() {
		this.onDateRangeChange([this.dateRange.start, this.dateRange.end]);
		this.setDateRangeDisplayValue();
	}

	onDateRangeChange(selection: Date[]) {
		let dateRangePreset = this.findDateRangePresetByDate({
			start: selection[0],
			end: selection[1]
		});

		if (!dateRangePreset && !selection[0] && !selection[1]) {
			dateRangePreset = this.dateRangePresets.find(m => m.label == anyTimeLabel);
		}

		this.onSelectDateRangePreset(dateRangePreset);
	}

	onSelectDateRangePreset(selection: DateRangePreset) {
		this.dateRangePresets.forEach((dateRangePreset, index) => {
			dateRangePreset.selected = this.dateRangePresets.indexOf(selection) == index;
		});

		if (selection && (selection.dateRange.start && selection.dateRange.end || selection.label == anyTimeLabel)) {
			this.onSelectDateRange([selection.dateRange.start, selection.dateRange.end]);
		}
	}

	onSelectDateRange(selection: Date[]) {
		this.selectedDateRange = [
			selection[0] ? moment(selection[0]).startOf('day').toDate() : null,
			selection[1] ? moment(selection[1]).endOf('day').toDate() : null
		];
	}

	onSelectDateRangePresetByLabel(event: Event) {
		let dateRangePresetLabel = (event.target as HTMLInputElement).value
		let dateRangePreset = this.dateRangePresets.find(m => m.label === dateRangePresetLabel);

		if (dateRangePreset) {
			this.onSelectDateRangePreset(dateRangePreset);
		}
	}

	onSelectPrevDateRange() {
		let dateRangePreset = this.dateRangePresets.find(m => m.selected);

		if (dateRangePreset && dateRangePreset.interval) {
			let startDate = moment(this.selectedDateRange[0])
				.add(-1, dateRangePreset.interval as moment.unitOfTime.DurationConstructor)
				.startOf(dateRangePreset.interval as moment.unitOfTime.StartOf)
				.toDate();

			let endDate = moment(this.selectedDateRange[0])
				.add(-1, dateRangePreset.interval as moment.unitOfTime.DurationConstructor)
				.endOf(dateRangePreset.interval as moment.unitOfTime.StartOf)
				.toDate();

			this.onSelectDateRange([startDate, endDate]);
		} else {
			let timeDifferenceInCurrentPreset = this.selectedDateRange[1].getTime() - this.selectedDateRange[0].getTime();
			let daysDifferenceInCurrentPreset = Math.round(timeDifferenceInCurrentPreset / (24 * 60 * 60 * 1000));

			let endDate = moment(this.selectedDateRange[0]).add(-1, 'day').toDate();
			let startDate = moment(endDate).add(-daysDifferenceInCurrentPreset, 'day').toDate();

			this.onSelectDateRange([startDate, endDate]);
		}

		this.onApply();
	}

	onSelectNextDateRange() {
		let dateRangePreset = this.dateRangePresets.find(m => m.selected);

		if (dateRangePreset && dateRangePreset.interval) {
			let startDate = moment(this.selectedDateRange[0])
				.add(1, dateRangePreset.interval as moment.unitOfTime.DurationConstructor)
				.startOf(dateRangePreset.interval as moment.unitOfTime.StartOf)
				.toDate();

			let endDate = moment(this.selectedDateRange[0])
				.add(1, dateRangePreset.interval as moment.unitOfTime.DurationConstructor)
				.endOf(dateRangePreset.interval as moment.unitOfTime.StartOf)
				.toDate();

			if (startDate < new Date()) {
				this.onSelectDateRange([startDate, endDate]);
			}
		} else {
			let timeDifferenceInCurrentPreset = this.selectedDateRange[1].getTime() - this.selectedDateRange[0].getTime();
			let daysDifferenceInCurrentPreset = Math.round(timeDifferenceInCurrentPreset / (24 * 60 * 60 * 1000));

			let endDate = moment(this.selectedDateRange[0]).add(1, 'day').toDate();
			let startDate = moment(endDate).add(daysDifferenceInCurrentPreset, 'day').toDate();

			if (startDate < new Date()) {
				this.onSelectDateRange([startDate, endDate]);
			}
		}

		this.onApply();
	}

	onApply() {
		if (this.dateRange.start !== this.selectedDateRange[0] || this.dateRange.end !== this.selectedDateRange[1]) {
			this.dateRange = {
				start: this.selectedDateRange[0],
				end: this.selectedDateRange[1]
			};

			this.setDateRangeDisplayValue();
			this.dateRangeChange.emit(this.dateRange);
		}

		this.dropdown?.hide();
	}

	onCancel() {
		this.dropdown?.hide();
	}

	@HostListener('document:click', ['$event'])
	onClick(event: any) {
		if (!this.element?.nativeElement?.contains(event.target) && event?.target?.classList?.toString()?.indexOf('owl-dt-calendar') === -1) {
			this.dropdown.hide();
		}
	}
}

interface DateRange {
	start: Date;
	end: Date;
}
