export class AuthorizationViewModel {
	authorizationID: number;
	clientMemberID: number;
	languageCode: string;
	languageName: string;
	isGeneric: boolean;
	customName: string;
	customText: string;
	personalRepresentative: string;
	providerNPI: number;
	providerName: string;
	contactClientMemberID: number;
	phoneNumber: string;
	emailAddress: string;
	dateFirstRequested: string;
	dateLastRequested: string;
	fullName: string;
	dateSigned: string;
	fileID: number;
	isExpired: boolean;
	expirationDate: string;
	dateCreated: string;
	dateUpdated: string;

	digitalSignatureCode: string | null;
	signatureBase64: string | null;
	deviceInfo: string | null;
	ipAddress: string | null;

	version: string;

	// UI only
	isAboutToExpire: boolean;
	expireInDaysAmount: number;
	expireInMonthsAmount: number;
}
export class AuthorizationEditModel {
	clientMemberID: number;
	languageCode: string;
	isGeneric: boolean;
	customName: string;
	customText: string;
	personalRepresentative: string;
	providerNPI: number;
	providerName: string;
	phoneNumber: string;
	emailAddress: string;
	fullName: string;
	dateSigned: string;
	fileID: number;


	version: string;

	// UI only
	authName: string;
	authFile: Uint8Array;
	authFileName: string;
	authFileType: string;
}
