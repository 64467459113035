import { Pipe, PipeTransform } from '@angular/core';
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';

@Pipe({
	name: 'phone'
})
export class PhonePipe implements PipeTransform {
	transform(value: string): string {
		if (!value) {
			return '';
		}

		if (!value.startsWith('+') && value.length === 10) {
			return value.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
		}

		const phoneUtil = PhoneNumberUtil.getInstance();
		let number;
		try {
			number = phoneUtil.parseAndKeepRawInput(value);
			if (!phoneUtil.isValidNumber(number)) {
				return value; // return original value if it's not a valid number
			}
		} catch (e) {
			return value; // return original value if parsing fails
		}

		return phoneUtil.format(number, PhoneNumberFormat.INTERNATIONAL);
	}
}
