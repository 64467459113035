<div class="specialty-input-container d-flex align-items-center gap-2">
	<input type="search" class="specialty-input form-control"
		   [class.no-clear]="specialtiesLoading"
		   [ngClass]="(!inputSize ? '' : 'form-control-'+inputSize)"
		   [(ngModel)]="specialtyName"
		   [typeahead]="specialties$"
		   [typeaheadAsync]="true"
		   [typeaheadWaitMs]="400"
		   [typeaheadMinLength]="0"
		   typeaheadOptionField="name"
		   [typeaheadOptionsLimit]="999"
		   [typeaheadScrollable]="true"
		   [typeaheadOptionsInScrollableView]="7"
		   [typeaheadSelectFirstItem]="false"
		   [typeaheadIsFirstItemActive]="false"
		   [typeaheadItemTemplate]="specialtyItemTemplate"
		   [placeholder]="placeholder"
		   [isAnimated]="false"
		   [adaptivePosition]="false"
		   (typeaheadLoading)="onSpecialtyTypeaheadLoading($event)"
		   (typeaheadOnSelect)="onTypeaheadSelect($event)"
		   autocomplete="off" />

	<app-spinner-wheel class="specialty-input-spinner" *ngIf="specialtiesLoading"></app-spinner-wheel>
</div>

<ng-template #specialtyItemTemplate let-specialty="item" let-index="index">
	<div class="text-wrap">
		{{ specialty.name }} (<span class="text-muted">{{ specialty.code }}</span>)
	</div>
</ng-template>
