<div class="procedure-input-container d-flex align-items-center gap-2">
	<input type="search" class="procedure-input form-control"
		   [class.no-clear]="proceduresLoading"
		   [ngClass]="(!inputSize ? '' : 'form-control-'+inputSize)"
		   [(ngModel)]="procedureName"
		   [typeahead]="procedures$"
		   [typeaheadAsync]="true"
		   [typeaheadWaitMs]="400"
		   [typeaheadMinLength]="3"
		   typeaheadOptionField="name"
		   [typeaheadOptionsLimit]="999"
		   [typeaheadScrollable]="true"
		   [typeaheadOptionsInScrollableView]="7"
		   [typeaheadSelectFirstItem]="false"
		   [typeaheadIsFirstItemActive]="false"
		   [typeaheadItemTemplate]="procedureItemTemplate"
		   [placeholder]="placeholder"
		   [isAnimated]="false"
		   [adaptivePosition]="false"
		   (typeaheadLoading)="onProcedureTypeaheadLoading($event)"
		   (typeaheadOnSelect)="onTypeaheadSelect($event)"
		   autocomplete="off" />

	<app-spinner-wheel class="procedure-input-spinner" *ngIf="proceduresLoading"></app-spinner-wheel>
</div>

<ng-template #procedureItemTemplate let-procedure="item" let-index="index">
	<div class="text-wrap">
		<strong class="me-1">{{ procedure.cptCode }}</strong> - {{ procedure.name }}
	</div>
</ng-template>
