<h6 class="mb-4">
	{{ isNew ? 'New Plan' : 'Edit Plan' }}
</h6>

<form #form="ngForm">
	<fieldset [disabled]="saving">
		<ng-container *ngIf="!isNew && loadingMemberPlan && !loadingMemberPlanError">
			<div class="text-center my-5">
				<app-spinner [message]="'loading client plan'"></app-spinner>
			</div>
		</ng-container>

		<ng-container *ngIf="!isNew && !loadingMemberPlan && loadingMemberPlanError">
			<app-error [message]="'Failed to load member plan'">
				<button class="btn btn-danger" (click)="onReloadMemberPlan()">
					Try Again
				</button>
			</app-error>
		</ng-container>

		<ng-container *ngIf="isNew || (!loadingMemberPlan && !loadingMemberPlanError)">
			<div class="row g-3 mb-3">
				<div class="col-16">
					<label class="form-label" for="planName">
						Client / Plan *
					</label>

					<app-client-plans-select id="clientPlan"
											 name="clientPlan"
											 [showAllOption]="false"
											 [canSelectClient]="false"
											 (clientPlanChange)="onClientPlanChange($event)">
					</app-client-plans-select>
				</div>

				<div class="col-8">
					<label class="form-label" for="externalMemberCode">
						Plan member number *
					</label>

					<input type="text" class="form-control" id="externalMemberCode" name="externalMemberCode" [(ngModel)]="model.externalMemberCode" [required]="true" autocomplete="off" />
				</div>
			</div>

			<div class="row mb-3">
				<div class="col-6">
					<label class="form-label" for="effectiveDate">
						Effective date *
					</label>

					<input type="date" class="form-control" id="effectiveDate" name="effectiveDate" [(ngModel)]="model.effectiveDate" [required]="true" autocomplete="off" />
				</div>
			</div>

			<div class="row mb-3">
				<div class="col-8">
					<label class="form-label" for="coverageTypeCode">
						Coverage type *
					</label>

					<ng-select #selectPlanTypes
							   class="custom-ng-select form-control rounded-end p-0"
							   id="coverageTypeCode"
							   name="coverageTypeCode"
							   [items]="coverageTypes"
							   [bindLabel]="'name'"
							   [bindValue]="'code'"
							   [(ngModel)]="model.coverageTypeCode"
							   [clearable]="false"
							   [multiple]="false"
							   [searchable]="false"
							   [loading]="loadingCoverageTypes"
							   [loadingText]="'loading coverage types...'"
							   [placeholder]="'Coverage type'"
							   [disabled]="saving">
					</ng-select>
				</div>
			</div>

			<div class="row">
				<div class="col-16 mb-3">
					<label class="form-label" for="groupName">
						Group
					</label>

					<div class="row g-3">
						<div class="col-16">
							<input type="text" class="form-control" id="groupName" name="groupName" [(ngModel)]="model.groupName" placeholder="Group name" autocomplete="off" />
						</div>

						<div class="col-8">
							<input type="text" class="form-control" id="groupID" name="groupID" [(ngModel)]="model.groupID" placeholder="Group ID" autocomplete="off" />
						</div>
					</div>
				</div>
			</div>

			<div class="row mb-3">
				<div class="col-4 mb-3">
					<label class="form-label" for="copay">
						Copay
					</label>

					<input type="text"
						   class="form-control text-end"
						   id="copay"
						   name="copay"
						   currencyMask
						   [(ngModel)]="model.copay"
						   [required]="true"
						   placeholder="$ 0.00"
						   autocomplete="off" />
				</div>
			</div>

			<hr />

			<ng-container *ngIf="validationErrors?.length > 0">
				<app-validation [errors]="validationErrors"></app-validation>
				<hr />
			</ng-container>

			<div class="row">
				<div class="col-24">
					<button type="submit" class="btn btn-primary" (click)="onSave()">
						<fa-icon *ngIf="saving" class="me-2" [icon]="faSpinnerThird" [spin]="true"></fa-icon>
						Save Plan
					</button>

					<button type="button" class="btn btn-outline-primary ms-2" (click)="onClose(form)">
						Cancel
					</button>
				</div>
			</div>
		</ng-container>
	</fieldset>
</form>
