export class EmailCategory {
	emailCategoryID: number;
	emailCategoryName: string;
}

export class EmailTemplate {
	emailTemplateID: number;
	emailTemplateName: string;
	emailCategoryID: number;
	emailCategoryName: string;
	description: string;
	languages: { [key: string]: string };
	timesUsed: number;
	lastTimeUsed: Date;
	rowVersion: string;

	// UI Only
	deleting: boolean = false;
}
