import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';

import { ReplaySubject, catchError, throwError, lastValueFrom, map } from 'rxjs';
import * as Sentry from "@sentry/angular-ivy";

import { ClientPlanMemberLookup, CaseLookup, BillLookup } from '@app/shared/models';
import { environment } from '@app/environment';
import { removeEmptyParams, handleHttpError } from '@app/functions';

@Injectable({ providedIn: 'root' })
export class LookupService implements OnDestroy {
	private get baseApiUrl() {
		return `${environment.api.host}/api/v1/lookups`;
	}

	// map of behavior subjects for each lookup type and their load status
	private lookupSubjects: { [key: string]: ReplaySubject<any[]> } = {};
	private lookupLoaded: { [key: string]: boolean } = {};

	constructor(
		private http: HttpClient
	) {
	}

	ngOnDestroy() {
	}

	/**
	 * Fetches values for specified lookup (code) and returns an Observable of T[].
	 * All simple lookups use the Lookup data type. For complex lookups, use their complex
	 * data type (e.g. ClientLookup, PortalUserLookup, etc).
	 * @param lookupCode Code for the lookup to retrieve (use LookupCodes constants from \@app/constants - don't hardcode strings).
	 */
	public getLookup<T>(lookupCode: string) {
		this.loadLookup<T[]>(lookupCode);
		return (this.lookupSubjects[lookupCode] as ReplaySubject<T[]>).asObservable();
	}

	// **** NOTE: Don't add custom lookup methods for every lookup - use generics with getLookup<T>, where T is the data type of the lookup ****

	public getClientPlanMembersLookup(
		clientID: number | null | undefined,
		clientPlanID: number | null | undefined,
		activePlansOnly: boolean,
		activeMembersOnly: boolean,
		query: string | null | undefined,
		page: number
	) {
		let options = {
			params: removeEmptyParams(new HttpParams({
				fromObject: {
					clientID: clientID,
					clientPlanID: clientPlanID,
					activePlansOnly: activePlansOnly,
					activeMembersOnly: activeMembersOnly,
					query: query,
					page: page
				}
			}))
		};

		return lastValueFrom(
			this.http
				// TODO: CHANGE TO PAGED ONCE PAGED => PagedDataModel<ClientPlanMemberLookup>
				.get<ClientPlanMemberLookup[]>(`${this.baseApiUrl}/client-plan-members`, options)
				.pipe(catchError(handleHttpError))
		);
	}

	public getClientPlanMembersLookup$(
		clientID: number | null | undefined,
		clientPlanID: number | null | undefined,
		activePlansOnly: boolean,
		activeMembersOnly: boolean,
		query: string | null | undefined,
		page: number
	) {
		let options = {
			params: removeEmptyParams(new HttpParams({
				fromObject: {
					clientID: clientID,
					clientPlanID: clientPlanID,
					activePlansOnly: activePlansOnly,
					activeMembersOnly: activeMembersOnly,
					query: query,
					page: page
				}
			}))
		};

		return this.http
			// TODO: CHANGE TO PAGED ONCE PAGED => PagedDataModel<ClientPlanMemberLookup>
			.get<ClientPlanMemberLookup[]>(`${this.baseApiUrl}/client-plan-members`, options)
			.pipe(catchError(handleHttpError));
	}

	public getCasesLookup(
		activeCasesOnly: boolean,
		assignedToWorkerID: number | null | undefined,
		clientID: number | null | undefined,
		clientPlanID: number | null | undefined,
		query: string | null | undefined,
		page: number,
		caseTypeCode: string | null = null
	) {
		let options = {
			params: removeEmptyParams(new HttpParams({
				fromObject: {
					activeCasesOnly: activeCasesOnly,
					assignedToWorkerID: assignedToWorkerID,
					clientID: clientID,
					clientPlanID: clientPlanID,
					query: query,
					page: page,
					caseTypeCode: caseTypeCode
				}
			}))
		};

		return lastValueFrom(
			this.http
				// TODO: CHANGE TO PAGED ONCE PAGED => PagedDataModel<CaseLookup>
				.get<CaseLookup[]>(`${this.baseApiUrl}/cases`, options)
				.pipe(
					map(cases => {
						cases.forEach(caseLookup => {
							caseLookup.selectLabel = `${caseLookup.caseCode} - ${caseLookup.caseTypeName}${(caseLookup.fullName ? `(${caseLookup.fullName})` : ``)}`
						});

						return cases;
					}),
					catchError(handleHttpError)
				)
		);
	}

	public getBillsLookup(
		activeBillsOnly: boolean,
		assignedToWorkerID: number | null | undefined,
		clientID: number | null | undefined,
		clientPlanID: number | null | undefined,
		caseID: number | null | undefined,
		query: string | null | undefined,
		page: number
	) {
		let options = {
			params: removeEmptyParams(new HttpParams({
				fromObject: {
					activeBillsOnly: activeBillsOnly,
					assignedToWorkerID: assignedToWorkerID,
					clientID: clientID,
					clientPlanID: clientPlanID,
					caseID: caseID,
					query: query,
					page: page
				}
			}))
		};

		return lastValueFrom(
			this.http
				// TODO: CHANGE TO PAGED ONCE PAGED => PagedDataModel<BillLookup>
				.get<BillLookup[]>(`${this.baseApiUrl}/bills`, options)
				.pipe(catchError(handleHttpError))
		);
	}

	private loadLookup<T>(lookupCode: string) {
		if (this.lookupLoaded[lookupCode] || this.lookupSubjects[lookupCode]) {
			return;
		}

		this.lookupSubjects[lookupCode] = new ReplaySubject<T[]>(1);

		this.fetchLookup<T>(lookupCode)
			.pipe(catchError(err => {
				handleHttpError(err);
				// propagate error to subject subscribers
				this.lookupSubjects[lookupCode].error(err);
				// rethrow
				return throwError(() => err);
			}))
			.subscribe(data => {
				console.log(`LookupService.loadLookup(${lookupCode})`);
				// propagate data to subject subscribers
				this.lookupSubjects[lookupCode].next(data);
				this.lookupLoaded[lookupCode] = true;
			});
	}

	private fetchLookup<T>(lookupCode: string) {
		return this.http.get<T[]>(`${this.baseApiUrl}/${lookupCode}`);
	}
}
