import { Component, Input, Output, OnInit, EventEmitter, ContentChild, TemplateRef } from '@angular/core';

import { faCaretRight, faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { faSearch } from "@fortawesome/pro-regular-svg-icons";
import { debounceTime, Subject } from "rxjs";

@Component({
	selector: 'app-aside-widget',
	templateUrl: 'aside-widget.component.html',
	styleUrls: ['aside-widget.component.scss']
})
export class AsideWidgetComponent implements OnInit {
	searchQueryChange = new Subject<string>();
	isSearch: boolean = false;
	searchQuery: string = '';
	faSpinnerThird = faSpinnerThird;
	faCaretRight = faCaretRight;
	faCaretDown = faCaretDown;
	searchIcon = faSearch;

	@Input()
	title: string = 'ASIDE TITLE';

	@Input()
	loading: boolean = false;

	@Input()
	error: string = null;

	@Input()
	get count() {
		return this._count;
	}
	set count(value: number) {
		this._count = value;
		this.collapsed = !this._count;
	}
	_count: number = 0;

	@Input()
	collapsed: boolean = false;

	@Input()
	showSearch: boolean = false;

	@Input()
	hideClose: boolean = false;

	@ContentChild('asideWidgetActions', { static: false })
	actionsTemplate: TemplateRef<any>;

	@Output()
	retry: EventEmitter<void> = new EventEmitter<void>();

	@Output()
	onSearch: EventEmitter<string> = new EventEmitter<string>();

	constructor() {
		this.searchQueryChange.pipe(
			debounceTime(500)
		).subscribe(() => {
			this.onSearchQuery();
		});
	}

	ngOnInit() {
		this.collapsed = !this._count;
	}

	onClickSearch() {
		this.isSearch = true;
	}

	onSearchClose() {
		this.searchQuery = null;
		this.isSearch = false;
		this.retry.emit();
	}

	onSearchQuery() {
		this.onSearch.emit(this.searchQuery);
	}
}
