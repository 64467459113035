<div class="card">
	<div class="card-body p-0">
		<app-aside-widget #asideWidget [title]="title" [loading]="loadingAuthorizations" [count]="authorizations?.length ?? 0" (retry)="onReloadAuthorizations()">
			<ng-template #asideWidgetActions>
				<button type="button" class="btn btn-sm btn-primary" (click)="onShowNewAuthorizationModal()">
					New Auth
				</button>
			</ng-template>

			<ng-container *ngIf="!loadingAuthorizations && !loadingAuthorizationsError">
				<ng-container *ngIf="activeAuthorizations?.length == 0">
					<div class="mx-3 mb-3">
						<app-empty class="bg-light rounded" [message]="'no active authorizations'"></app-empty>
					</div>
				</ng-container>

				<ng-container *ngIf="authorizations?.length > 0">
					<div class="card border-0">
						<ng-container>
							<ng-container *ngTemplateOutlet="authorizationTemplate; context: { authorizations: activeAuthorizations }"></ng-container>
						</ng-container>

						<div class="bg-light rounded-bottom pb-3 mt-2 mb-n3" *ngIf="expiredAuthorizations.length > 0">
							<div class="text-secondary fw-bold px-3 pt-3 mb-1">
								Expired Authorizations
							</div>

							<ng-container *ngTemplateOutlet="authorizationTemplate; context: { authorizations: expiredAuthorizations }"></ng-container>
						</div>
					</div>
				</ng-container>
			</ng-container>
		</app-aside-widget>
	</div>
</div>

<ng-template #authorizationTemplate let-authorizations="authorizations">
	<div class="list-group list-group-flush">
		<div class="list-group-item bg-transparent bg-light-hover c-pointer px-3" [class.pt-2]="!first || last" [class.pb-2]="first || !last" *ngFor="let authorization of authorizations; let first = first; let last = last" (click)="onViewAuthorization(authorization)">
			<div class="d-flex align-items-start">
				<div class="flex-grow-1 text-truncate">
					{{ authorization.customName }}
				</div>

				<!-- TODO: ADD SUPPORT FOR NON FILE AUTHORIZATIONS -->

				<div>
					<div dropdown class="btn-group">
						<button type="button" class="btn btn-sm btn-link text-decoration-none p-0 me-2" (click)="onViewAuthorization(authorization); $event.stopPropagation()">
							View
						</button>

						<button class="btn btn-icon-only px-1 ms-1" type="button" dropdownToggle (click)="$event.stopPropagation()">
							<fa-icon [icon]="spinnerIcon"></fa-icon>
						</button>

						<div class="dropdown-menu dropdown-menu-end" *dropdownMenu>
							<!--<button class="dropdown-item" type="button" (click)="onRequestAuthorization(authorization); $event.stopPropagation()" *ngIf="!authorization.dateFirstRequested">Request</button>-->
							<button class="dropdown-item" type="button" (click)="onDownloadAuthorization(authorization); $event.stopPropagation()">Download</button>
							<button class="dropdown-item" type="button" (click)="onEditAuthorization(authorization); $event.stopPropagation()">Edit</button>
							<button class="dropdown-item text-danger" type="button" (click)="onDeleteAuthorization(authorization); $event.stopPropagation()">Delete</button>
						</div>
					</div>
				</div>
			</div>

			<div class="text-muted small">
				<!--
				<div class="text-danger" *ngIf="!authorization.dateFirstRequested">
					Never requested
				</div>

				<div class="text-secondary" *ngIf="!authorization.isExpired && authorization.dateLastRequested">
					Requested {{ authorization.dateLastRequested | amDifference:today:'days' }} days ago (viewed)
				</div>
				-->

				<div class="text-warning" [class.text-warning]="authorization.isAboutToExpire" [class.text-success]="!authorization.isAboutToExpire" *ngIf="!authorization.isExpired">
					<ng-container *ngIf="authorization.expireInDaysAmount <= 1">
						<fa-icon class="me-1" [icon]="authExpireOkIcon" *ngIf="!authorization.isAboutToExpire"></fa-icon>
						<fa-icon class="me-1" [icon]="authExpireWarningIcon" *ngIf="authorization.isAboutToExpire"></fa-icon>
						Expires tomorrow
					</ng-container>

					<ng-container *ngIf="authorization.expireInDaysAmount > 1 && authorization.expireInMonthsAmount == 0">
						<fa-icon class="me-1" [icon]="authExpireOkIcon" *ngIf="!authorization.isAboutToExpire"></fa-icon>
						<fa-icon class="me-1" [icon]="authExpireWarningIcon" *ngIf="authorization.isAboutToExpire"></fa-icon>
						Expires in {{ authorization.expirationDate | amDifference:today:'days' }} days
					</ng-container>

					<ng-container *ngIf="authorization.expireInMonthsAmount > 0">
						<fa-icon class="me-1" [icon]="authExpireOkIcon" *ngIf="!authorization.isAboutToExpire"></fa-icon>
						<fa-icon class="me-1" [icon]="authExpireWarningIcon" *ngIf="authorization.isAboutToExpire"></fa-icon>
						Expires in {{ authorization.expirationDate | amDifference:today:'months' }} months
					</ng-container>
				</div>

				<div class="text-danger" *ngIf="authorization.isExpired">
					<fa-icon class="me-1" [icon]="authExpiredIcon"></fa-icon>
					Expired {{ authorization.expirationDate | amTimeAgo }}
				</div>
			</div>
		</div>
	</div>
</ng-template>
