import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';

import { lastValueFrom, catchError, throwError } from 'rxjs';

import { TimeZone } from '@app/settings/models';

import { environment } from '@app/environment';
import { handleHttpError } from '@app/functions';

@Injectable({ providedIn: 'root' })
export class DataService {
	protected get baseApiUrl() {
		return `${environment.api.host}/api/v1/data`;
	}

	constructor(
		private http: HttpClient
	) {
	}

	async getTimeZones() {
		return lastValueFrom(
			this.http.get<TimeZone[]>(`${this.baseApiUrl}/timezones`).pipe(
				catchError(handleHttpError)
			)
		);
	}
}
