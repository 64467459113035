import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, ChangeDetectorRef } from '@angular/core';

import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { Subject, distinctUntilChanged, debounceTime, switchMap, map, tap } from 'rxjs';

import { LookupCodes } from '@app/constants';
import { LookupService } from '@app/shared/services';
import { ClientPlanMemberLookup } from '@app/shared/models';
// import { MembersService } from '@app/data/members/services';
import { MemberCreateModalComponent } from '@app/data/members/components';
// import { MemberDashboard } from '@app/data/members/models';
import { DefaultMemberModel } from '@app/shared/models/member.models';

import { faXmark, faPlus } from '@fortawesome/pro-regular-svg-icons';

@Component({
	selector: 'app-member-select',
	templateUrl: 'member-select.component.html',
	styleUrls: ['member-select.component.scss'],
	encapsulation: ViewEncapsulation.None,
	exportAs: 'memberSelect'
})
export class MemberSelectComponent implements OnInit {
	closeIcon = faXmark;
	plusIcon = faPlus;

	/* ==================== inputs/outputs ==================== */
	@Input() id: string;
	@Input() name: string;
	@Input() placeholder: string;
	@Input() closeable: boolean = true;
	@Input() disabled: boolean = false;

	@Input('clientID')
	set clientIDInput(value: number) {
		console.log('***clientIDInput', value);
		this.clientID = value;

		// clear default member if clientID changed and is different
		if (this.defaultMember?.clientID !== this.clientID) {
			this.defaultMember = null;
		}

		this.onReloadMembers();
	}
	private clientID: number = null;

	@Input('clientPlanID')
	set clientPlanIDInput(value: number) {
		console.log('***clientPlanIDInput', value);
		this.clientPlanID = value;

		// clear default member if clientID changed and is different
		if (this.defaultMember?.clientPlanID !== this.clientPlanID) {
			this.defaultMember = null;
		}

		this.onReloadMembers();
	}
	private clientPlanID: number = null;

	@Input() defaultMember: DefaultMemberModel;
	// @Input() get memberID() { return this._memberID; }
	// set memberID(value: number) {
	// 	console.log('***memberID', value);
	// 	if (this._memberID === value) {
	// 		return;
	// 	}

	// 	this._memberID = value;
	// 	const search = this._memberID > 0 ? `${this._memberID}` : null;
	// 	this.onReloadMembers(true, search);
	// }
	// private _memberID: number;

	@Output()
	memberChange: EventEmitter<ClientPlanMemberLookup> = new EventEmitter<ClientPlanMemberLookup>();

	@Output()
	close: EventEmitter<void> = new EventEmitter<void>();

	/* ==================== internal state ==================== */
	membersTypeahead = new Subject<string>(); //new EventEmitter<string>();
	lastSearchTerm: string;
	currentPage = 1;
	loadingMembers: boolean = false;
	filteredMembers: ClientPlanMemberLookup[] = [];
	selectedMember: ClientPlanMemberLookup;

	constructor(
		private toastr: ToastrService,
		private modalService: BsModalService,
		private lookupService: LookupService,
		private cdr: ChangeDetectorRef
	) {
	}

	ngOnInit() {
		// hookup typeahead
		this.membersTypeahead.pipe(
			// NOTE: trigger on same string to force reload because other params changed
			// distinctUntilChanged(),
			debounceTime(300),
			switchMap(term => {
				console.log('***membersTypeahead', term || 'EMPTY', this.lastSearchTerm);
				this.loadingMembers = true;
				this.lastSearchTerm = term;
				this.currentPage = 1;
				return this.lookupService.getClientPlanMembersLookup$(
					this.clientID,
					this.clientPlanID,
					true,
					true,
					term,
					this.currentPage);
			}))
			.subscribe(data => {
				this.currentPage++;
				this.loadingMembers = false;
				this.filteredMembers = data;

				// always have default member at the top of the list and preselected
				if (this.defaultMember && this.filteredMembers?.length > 0 &&
					this.filteredMembers[0].clientMemberPlanID !== this.defaultMember.clientMemberPlanID) {
					this.filteredMembers.unshift(this.defaultMember as ClientPlanMemberLookup);
					this.selectedMember = this.defaultMember as ClientPlanMemberLookup;
					this.onMemberChange(this.selectedMember);
				}
			}, (err) => {
				// TODO: SHOW ERROR TOAST
				this.loadingMembers = false;
				this.filteredMembers = [];
			});

		// trigger initial load (TODO: MAY NEED TO REMOVE)
		this.membersTypeahead.next('');
	}

	private async loadMoreMembers() {
		console.log('***loadMoreMembers', this.loadingMembers);
		if (this.loadingMembers) {
			return;
		}

		try {
			this.loadingMembers = true;
			const moreMembers = await this.lookupService.getClientPlanMembersLookup(
				this.clientID,
				this.clientPlanID,
				true,
				true,
				this.lastSearchTerm,
				this.currentPage);

			console.log('***loadMoreMembers ==', moreMembers?.length);

			// append non-duplicate members
			this.filteredMembers = this.filteredMembers.concat(moreMembers
				.filter(m => !this.filteredMembers.find(f => f.clientMemberPlanID === m.clientMemberPlanID)));

			// always have default member at the top of the list and preselected
			if (this.defaultMember && this.filteredMembers?.length > 0 &&
				this.filteredMembers[0].clientMemberPlanID !== this.defaultMember.clientMemberPlanID) {
				this.filteredMembers.unshift(this.defaultMember as ClientPlanMemberLookup);
				this.selectedMember = this.defaultMember as ClientPlanMemberLookup;
				this.onMemberChange(this.selectedMember);
			}

			console.log('***loadMoreMembers ++', this.filteredMembers?.length);

			// increment page if more members were returned
			if (moreMembers?.length > 0) {
				this.currentPage++;
			}
		} catch (error: any) {
		} finally {
			this.loadingMembers = false;
		}
	}

	onAddNewMember() {
		let modalRef = this.modalService.show<MemberCreateModalComponent>(MemberCreateModalComponent, {
			class: 'modal-fullscreen',
			animated: true
		});

		modalRef.onHide.subscribe(() => {
			if (modalRef.content.created) {
				this.onReloadMembers(false);
			}
		});
	}

	async onReloadMembers(clearSearch: boolean = true, defaultSearch: string = '') {
		if (clearSearch) {
			this.lastSearchTerm = defaultSearch;
		}
		console.log('***onReloadMembers', this.lastSearchTerm);
		this.filteredMembers = [];
		this.currentPage = 1;
		this.selectedMember = null;
		this.membersTypeahead.next(this.lastSearchTerm);
	}

	async onDropdownScrollToEnd() {
		this.loadMoreMembers();
	}

	// { end }
	onDropdownScroll(event: any) {
		console.log('***onDropdownScroll', event);
		// if (this.loading || this.photos.length <= this.photosBuffer.length) {
		//     return;
		// }

		// if (end + this.numberOfItemsFromEndBeforeFetchingMore >= this.photosBuffer.length) {
		//     this.fetchMore();
		// }
	}

	onMemberChange(member: ClientPlanMemberLookup) {
		this.memberChange.emit(member);
	}

	async onClose() {
		this.close.emit();
	}
}
