import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';

import { Observable, Observer, switchMap, map, of, catchError } from 'rxjs';
import { TypeaheadConfig, TypeaheadMatch } from 'ngx-bootstrap/typeahead';

import { ProvidersService } from '@app/settings/services';
import { Medication, MedicationDetails, MedicationDetailsBrand, MedicationDetailsForm, MedicationDetailsDosage } from '@app/settings/models';
import { SwitchToggleEvent } from '@app/shared/components/switch/switch.component';

export function getTypeaheadConfig(): TypeaheadConfig {
	return Object.assign(new TypeaheadConfig(), { cancelRequestOnFocusLost: true });
}

@Component({
	selector: 'app-medication-select',
	templateUrl: 'medication-select.component.html',
	styleUrls: ['medication-select.component.scss'],
	encapsulation: ViewEncapsulation.None,
	exportAs: 'medicationSelect',
	providers: [{ provide: TypeaheadConfig, useFactory: getTypeaheadConfig }]
})
export class MedicationSelectComponent implements OnInit {
	medicationsLoading: boolean = false;
	medications$: Observable<Medication[]>;

	constructor(
		private providersService: ProvidersService
	) {
	}

	ngOnInit() {
		this.medications$ = new Observable((observer: Observer<string>) => observer.next(this.drugName)).pipe(
			switchMap((query: string) => {
				this.selectedDrug = null;

				this.drugBrands = [];
				this.drugForms = [];
				this.drugDosages = [];

				if (!query) {
					return of([]);
				}

				return this.providersService.getMedications$(query, 1).pipe(
					catchError(err => {
						this.medicationsLoading = false;
						return of(null);
					}),
					map((response: PagedDataModel<Medication>) => response?.pageData || [])
				);
			})
		);
	}

	private _drugName: string;
	private selectedDrug: Medication;

	@Input()
	get drugName() {
		return this._drugName;
	}
	set drugName(value: string) {
		if (this._drugName === value) {
			return;
		}

		if (!value) {
			this.selectedDrug = null;
			this.brandName = null;
			this.form = null;
			this.dosage = null;
		}

		this._drugName = value;
		this.drugNameChange.emit(value);
	}

	@Output()
	drugNameChange = new EventEmitter<string>();

	onDrugNameTypeaheadSelect(e: TypeaheadMatch) {
		this.selectedDrug = e.item;

		this.brandName = this.selectedDrug?.brandName || null;

		if (this.selectedDrug) {
			this.onReloadDrugDetails();
		}
	}

	onDrugNameTypeaheadLoading(loading: boolean) {
		this.medicationsLoading = loading;
	}

	private _brandName: string;
	private selectedBrand: MedicationDetailsBrand;

	@Input()
	get brandName() {
		return this._brandName;
	}
	set brandName(value: string) {
		if (this._brandName === value) {
			return;
		}

		if (!value) {
			this.selectedBrand = null;
			this.selectedForm = null;
			this.selectedDosage = null;

			this.form = null;
			this.dosage = null;

			this.harvestSuggestions();
		}

		this._brandName = value;
		this.brandNameChange.emit(value);
	}

	@Output()
	brandNameChange = new EventEmitter<string>();

	onBrandNameTypeaheadSelect(e: TypeaheadMatch) {
		this.selectedBrand = e.item;
		this.selectedForm = null;
		this.selectedDosage = null;

		this.harvestSuggestions();
	}

	private _form: string;
	private selectedForm: MedicationDetailsForm;

	@Input()
	get form() {
		return this._form;
	}
	set form(value: string) {
		if (this._form === value) {
			return;
		}

		if (!value) {
			this.selectedForm = null;
			this.selectedDosage = null;

			this.dosage = null;

			this.harvestSuggestions();
		}

		this._form = value;
		this.formChange.emit(value);
	}

	@Output()
	formChange = new EventEmitter<string>();

	onFormTypeaheadSelect(e: TypeaheadMatch) {
		this.selectedForm = e.item;
		this.selectedDosage = null;

		this.harvestSuggestions();
	}

	private _dosage: string;
	private selectedDosage: MedicationDetailsDosage;

	@Input()
	get dosage() {
		return this._dosage;
	}
	set dosage(value: string) {
		if (this._dosage === value) {
			return;
		}

		if (!value) {
			this.selectedDosage = null;
		}

		this._dosage = value;
		this.dosageChange.emit(value);
	}

	@Output()
	dosageChange = new EventEmitter<string>();

	onDosageTypeaheadSelect(e: TypeaheadMatch) {
		this.selectedDosage = e.item;
	}

	private _quantity: string;

	@Input()
	get quantity() {
		return this._quantity;
	}
	set quantity(value: string) {
		if (this._quantity === value) {
			return;
		}

		this._quantity = value;
		this.quantityChange.emit(value);
	}

	@Output()
	quantityChange = new EventEmitter<string>();

	@Input()
	get frequency() {
		return this._frequency;
	}
	set frequency(value: string) {
		if (this._frequency === value) {
			return;
		}

		this._frequency = value;
		this.frequencyChange.emit(value);
	}
	@Output() frequencyChange = new EventEmitter<string>();
	private _frequency: string;

	@Input()
	brandNameOnly: boolean;

	@Output()
	brandNameOnlyChange: EventEmitter<boolean> = new EventEmitter<boolean>();

	onBrandNameOnlyChange(event: SwitchToggleEvent) {
		event.saved(true);

		this.brandNameOnly = event.value;
		this.brandNameOnlyChange.emit(this.brandNameOnly);
	}

	loadingDrugDetails: boolean = false;
	private drugDetails: MedicationDetails;
	private async onReloadDrugDetails() {
		try {
			if (!this.selectedDrug || !this.selectedDrug.id) {
				return;
			}

			this.loadingDrugDetails = true;

			this.drugDetails = await this.providersService.getMedicationDetails(this.selectedDrug.id);

			if (this.brandName && !this.selectedBrand) {
				this.selectedBrand = this.drugDetails?.brands.find((m: any) => m.name == this.brandName);

				if (this.form && !this.selectedForm) {
					this.selectedForm = this.selectedBrand.forms.find((m: any) => m.name == this.form);

					if (this.dosage && !this.selectedDosage) {
						this.selectedDosage = this.selectedForm.dosages.find((m: any) => m.name == this.dosage);
					}
				}
			}

			this.harvestSuggestions();

			if (this.drugDetails.brands?.length == 1) {
				let theOnlyBrand = this.drugDetails.brands[0];
				this.brandName = theOnlyBrand.name;

				if (theOnlyBrand.forms?.length == 1) {
					let theOnlyForm = theOnlyBrand.forms[0];
					this.form = theOnlyForm.name;

					if (theOnlyForm.dosages?.length == 1) {
						let theOnlyDosage = theOnlyForm.dosages[0];
						this.dosage = theOnlyDosage.name;
					}
				}
			}
		} catch (error: any) {
			console.log(error);
		} finally {
			this.loadingDrugDetails = false;
		}
	}

	drugBrands: any[] = [];
	drugForms: any[] = [];
	drugDosages: any[] = [];

	private harvestSuggestions() {
		this.drugBrands = this.drugDetails ? this.drugDetails.brands.filter(m => m.name) : [];
		this.drugForms = this.selectedBrand ? this.selectedBrand.forms.filter(m => m.name) : [];
		this.drugDosages = this.selectedForm ? this.selectedForm.dosages.filter(m => m.name) : [];
	}
}
