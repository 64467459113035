import { Component, OnInit, Input, Output, EventEmitter, Optional } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';

import { CaseScheduling } from '@app/data/cases/models';

import { Provider } from '@app/settings/models';

import { LookupService } from '@app/settings/services';
import { State } from '@app/settings/models';

import { Messages } from '@app/constants';

@Component({
	selector: 'app-create-scheduling',
	templateUrl: 'create-scheduling.component.html',
	styleUrls: ['create-scheduling.component.scss'],
	viewProviders: [{
		provide: ControlContainer,
		deps: [[Optional, NgForm]],
		useFactory: (ngForm: NgForm) => ngForm
	}]
})
export class CaseCreateSchedulingComponent implements OnInit {
	selectedProvider: Provider;
	selectedFacility: Provider;

	loadingStates: boolean = false;
	states: State[];

	@Input()
	case: CaseScheduling;

	@Output()
	caseChange: EventEmitter<CaseScheduling> = new EventEmitter<CaseScheduling>();

	constructor(
		private lookupService: LookupService,
		private toastrService: ToastrService
	) {
	}

	ngOnInit() {
		this.onReloadStates();
	}

	async onReloadStates() {
		try {
			this.loadingStates = true;

			this.states = await this.lookupService.getStates();
		} catch (error: any) {
			this.toastrService.error(Messages.ErrorRetry, `Failed to Load States`);
		} finally {
			this.loadingStates = false;
		}
	}

	async onProviderSelect(provider: Provider) {
		this.selectedProvider = provider;

		this.case.providerNPI = provider?.npi || null;

		if (!this.selectedFacility) {
			this.case = {
				...this.case,
				facilityAddress1: provider?.address1 || this.case.facilityAddress1 || null,
				facilityAddress2: provider?.address2 || this.case.facilityAddress2 || null,
				facilityCity: provider?.city || this.case.facilityCity || null,
				facilityStateCode: provider?.stateCode || this.case.facilityStateCode || null,
				facilityZipCode: provider?.zipCode || this.case.facilityZipCode || null,
				facilityPhoneNumber: provider?.phoneNumber || this.case.facilityPhoneNumber || null,
				facilityFaxNumber: provider?.faxNumber || this.case.facilityFaxNumber || null
			};
		}

		this.caseChange.emit(this.case);
	}

	async onFacilitySelect(facility: Provider) {
		this.selectedFacility = facility;

		this.case = {
			...this.case,
			facilityNPI: facility?.npi || null,
			facilityName: facility?.fullName || facility?.organizationName || null,
			facilityAddress1: facility?.address1 || this.case.facilityAddress1 || null,
			facilityAddress2: facility?.address2 || this.case.facilityAddress2 || null,
			facilityCity: facility?.city || this.case.facilityCity || null,
			facilityStateCode: facility?.stateCode || this.case.facilityStateCode || null,
			facilityZipCode: facility?.zipCode || this.case.facilityZipCode || null,
			facilityPhoneNumber: facility?.phoneNumber || this.case.facilityPhoneNumber || null,
			facilityFaxNumber: facility?.faxNumber || this.case.facilityFaxNumber || null
		};

		this.caseChange.emit(this.case);
	}
}
