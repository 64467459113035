import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { lastValueFrom, catchError, tap } from 'rxjs';
import * as Sentry from "@sentry/angular-ivy";

import { handleHttpError } from '@app/shared/functions';

import { environment } from '@app/environment';

@Injectable({ providedIn: 'root' })
export class FilesService {
	constructor(
		private http: HttpClient
	) {
	}

	async downloadFile(fileUrl: string, fileName: string) {
		let downloadLink: HTMLAnchorElement;
		try {
			const data = await lastValueFrom(this.http.get(fileUrl, { responseType: 'blob' }));

			const blob = new Blob([data], { type: data.type });
			downloadLink = document.createElement('a');
			downloadLink.href = URL.createObjectURL(blob);
			downloadLink.download = fileName;
			downloadLink.click();
		} catch (error: any) {
			console.error('Error downloading the file:', error);
			Sentry.captureException(error);
		} finally {
			if (downloadLink?.href) {
				URL.revokeObjectURL(downloadLink.href);
			}
		}
	}
}
