import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from "@sentry/angular-ivy";
import { BrowserTracing } from "@sentry/tracing";

Sentry.init({
	dsn: environment.sentry.dsn,
	environment: environment.sentry.environment,
	tracesSampleRate: environment.sentry.tracesSampleRate,
	integrations: [
		new BrowserTracing({
			// where to propagate trace headers (for frontend/backend tracing)
			tracePropagationTargets: environment.sentry.tracePropagationTargets,
			routingInstrumentation: Sentry.routingInstrumentation,
			beforeNavigate: (context) => {
				return {
					...context,
					// replace url route ids with <id> placeholder so requests are grouped
					name: location.pathname
						.replace(/\/\d+/g, "/<id>"),
				};
			}
		}),
	]
});

if (environment.production) {
	enableProdMode();
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.then(success => console.log(`App bootstrap successful.`))
	.catch(err => console.error(err));
