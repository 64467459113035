<div class="row">
	<div class="col-24">
		<ng-container *ngIf="!memberDependents || memberDependents?.length == 0">
			<app-empty class="bg-light rounded" [message]="'no plans'"></app-empty>
		</ng-container>

		<ng-container *ngIf="memberDependents && memberDependents.length > 0">
			<table class="table table-hover small">
				<thead class="table-light">
					<tr>
						<th>Dependent</th>
						<th>Relationship</th>
						<th class="text-nowrap">DoB</th>
						<th class="text-nowrap">Plans</th>
						<th class="text-nowrap">Contact Information</th>
						<th style="width: 1px;"></th>
					</tr>
				</thead>

				<tbody>
					<ng-container *ngFor="let memberDependent of memberDependents">
						<tr class="align-top">
							<td class="px-2">
								<a [routerLink]="['../', memberDependent.clientMemberID]">{{ memberDependent.fullName }}</a>
							</td>

							<td>
								<div class="d-flex">
									<div>{{ memberDependent.relationships }}</div>
								</div>
							</td>

							<td class="text-center">
								{{ memberDependent.dateOfBirth | date:'M/dd/yyyy' }}
							</td>

							<td>
								<div class="d-flex align-items-center">
									<ng-container *ngIf="memberDependent.clientLogoURL">
										<figure class="client-logo me-2">
											<img class="img-fluid" [src]="memberDependent.clientLogoURL" alt="" />
										</figure>
									</ng-container>

									{{ memberDependent.clientName }}
								</div>

								<div class="text-muted">
									<ng-container *ngFor="let planName of memberDependent.planNames">
										<div>{{ planName }}</div>
									</ng-container>
								</div>
							</td>

							<td>
								<ng-container *ngIf="memberDependent.phoneNumber">
									<a class="d-flex align-items-center text-truncate" [href]="'tel:+1' + memberDependent.phoneNumber" (click)="$event.stopPropagation()">
										{{ memberDependent.phoneNumber | phone }} (H)
									</a>
								</ng-container>

								<ng-container *ngIf="memberDependent.mobileNumber">
									<a class="d-flex align-items-center text-truncate" [href]="'tel:+1' + memberDependent.mobileNumber" (click)="$event.stopPropagation()">
										{{ memberDependent.mobileNumber | phone }} (M)
									</a>
								</ng-container>

								<ng-container *ngIf="memberDependent.emailAddress">
									<a class="d-flex align-items-center text-truncate" [href]="'mailto:' + memberDependent.emailAddress" (click)="$event.stopPropagation()">
										{{ memberDependent.emailAddress }}
									</a>
								</ng-container>
							</td>

							<td class="text-center px-0">
								<div dropdown class="btn-group">
									<button dropdownToggle type="button" class="btn btn-icon-only px-2">
										<fa-icon [icon]="faEllipsisVertical"></fa-icon>
									</button>

									<div *dropdownMenu class="dropdown-menu dropdown-menu-end">
										<!-- <button type="button" class="dropdown-item" (click)="onEditMemberDependent(memberDependent)">Edit</button> -->
										<button class="dropdown-item text-danger" (click)="onDeleteMemberDependent(memberDependent)">Delete</button>
									</div>
								</div>
							</td>
						</tr>

						<ng-container *ngIf="memberDependent.editing">
							<tr>
								<td class="px-0 py-3" colspan="7">
									<div class="card bg-light">
										<div class="card-body">
											<app-member-dependents-form
											[clientID]="clientID"
											[clientPlanID]="clientPlanID"
											[clientMemberID]="clientMemberID"
											(save)="onReloadMemberDependents()" (close)="onMemberDependentEditClosed()"></app-member-dependents-form>
										</div>
									</div>
								</td>
							</tr>
						</ng-container>
					</ng-container>
				</tbody>
			</table>
		</ng-container>
	</div>
</div>
