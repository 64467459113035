import { Component, OnInit, Input, Output, EventEmitter, Optional } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';

import { CaseTypes } from '@app/data/cases/models';

import { Messages } from '@app/constants';

import { LookupService } from '@app/settings/services';
import { LookupValue } from '@app/settings/models';

@Component({
	selector: 'app-create-case-summary',
	templateUrl: 'create-case-summary.component.html',
	styleUrls: ['create-case-summary.component.scss'],
	viewProviders: [{
		provide: ControlContainer,
		deps: [[Optional, NgForm]],
		useFactory: (ngForm: NgForm) => ngForm
	}]
})
export class CaseCreateCaseSummaryComponent implements OnInit {
	@Input()
	case: CaseTypes;

	@Output()
	caseChange: EventEmitter<CaseTypes> = new EventEmitter<CaseTypes>();

	constructor(
		private lookupService: LookupService,
		private toastrService: ToastrService
	) {
	}

	ngOnInit() {
		this.onReloadCaseTypes();
		this.onReloadCaseStatuses();
		this.onReloadCaseOrigins();
		this.onReloadFacilityTypes();
	}

	loadingCaseTypes: boolean = false;
	caseTypes: LookupValue[];
	async onReloadCaseTypes() {
		try {
			this.loadingCaseTypes = true;

			this.caseTypes = await this.lookupService.getLookupValues('CaseType');
		} catch (error: any) {
			this.toastrService.error(Messages.ErrorRetry, `Failed to load case types.`);
		} finally {
			this.loadingCaseTypes = false;
		}
	}

	loadingCaseStatuses: boolean = false;
	caseStatuses: LookupValue[];
	async onReloadCaseStatuses() {
		try {
			this.loadingCaseStatuses = true;

			this.caseStatuses = await this.lookupService.getLookupValues('CaseStatus');
		} catch (error: any) {
			this.toastrService.error(Messages.ErrorRetry, `Failed to Load Case Statuses`);
		} finally {
			this.loadingCaseStatuses = false;
		}
	}

	loadingCaseOrigins: boolean = false;
	caseOrigins: LookupValue[];
	async onReloadCaseOrigins() {
		try {
			this.loadingCaseOrigins = true;

			this.caseOrigins = await this.lookupService.getLookupValues('CaseOrigin');
		} catch (error: any) {
			this.toastrService.error(Messages.ErrorRetry, `Failed to Load Case Statuses`);
		} finally {
			this.loadingCaseOrigins = false;
		}
	}

	loadingFacilityTypes: boolean = false;
	facilityTypes: LookupValue[];
	async onReloadFacilityTypes() {
		try {
			this.loadingFacilityTypes = true;

			this.facilityTypes = await this.lookupService.getLookupValues('FacilityType');
		} catch (error: any) {
			console.log(error);
			this.toastrService.error(Messages.ErrorRetry, `Failed to Load Facility Types`);
		} finally {
			this.loadingFacilityTypes = false;
		}
	}
}
