<form #form="ngForm">
	<div class="modal-header px-4">
		<h6 class="modal-title">
			Upload New File
		</h6>

		<button type="button" class="btn-close" (click)="onClose()" [disabled]="saving">
			<span class="visually-hidden">&times;</span>
		</button>
	</div>

	<div class="modal-body" (dragover)="onDragOver($event)" (drop)="onDrop($event)">
		<fieldset [disabled]="saving">
			<input #fileBrowser type="file" class="d-none" />

			<div class="container">
				<div class="row mb-3">
					<div class="col-24">
						<div class="files-list" [class.empty]="selectedFiles.length == 0">
							<ng-container *ngFor="let file of selectedFiles">
								<div class="files-list-file">
									<figure class="files-list-file-thumbnail">
										<img class="img-fluid" [src]="file.thumbnail" alt="" />
									</figure>

									<div class="files-list-file-info">
										<div>
											{{ file.name }}
										</div>

										<div class="small gray-700">
											{{ file.type }}
											<div class="d-inline-block mx-1">•</div>
											{{ file.pages }} {{ file.pages == 1 ? 'page' : 'pages' }}
											<div class="d-inline-block mx-1">•</div>
											{{ file.size | fileSize }}
										</div>
									</div>

									<button class="btn btn-icon-only align-self-start px-1" [tooltip]="'Remove'" (click)="onRemoveSelectedFile(file)">
										<fa-icon [icon]="faTimes"></fa-icon>
									</button>
								</div>
							</ng-container>

							<div class="files-list-placeholder" *ngIf="selectedFiles.length === 0">
								<fa-icon class="fs-3 gray-500" [icon]="faFileLines"></fa-icon>
								<span class="text-nowrap">Drag and drop here or <a href="javascript:void(0);" (click)="onSelectFiles()">select a file</a></span>
							</div>
						</div>
					</div>
				</div>

				<hr/>

				<div class="field-required row mb-3">
					<label class="col-4 col-form-label" for="fileName">
						File name
					</label>

					<div class="col-20">
						<input type="text" class="form-control" id="fileName" name="fileName" [(ngModel)]="fileName" [required]="true" placeholder="e.g. PlanPolicy" />
					</div>
				</div>

				<div class="field-required row mb-3">
					<label class="col-4 col-form-label" for="fileDate">
						File date
					</label>

					<div class="col-6">
						<input type="date" class="form-control" id="fileDate" name="fileDate" [(ngModel)]="fileDate" [required]="true" />
					</div>
				</div>

				<!-- <div class="row">
					<div class="col-24">
						<div class="alert alert-warning mb-0">
							<fa-icon class="text-warning me-2" [icon]="faTriangleExclamation"></fa-icon>
							Multiple files will be merged into a single, multi-page document.
						</div>
					</div>
				</div> -->
			</div>
		</fieldset>
	</div>

	<div class="modal-footer bg-white px-4">
		<button type="button" class="btn btn-outline-primary" (click)="onClose()" [disabled]="saving">
			Cancel
		</button>

		<button type="button" class="btn btn-primary" (click)="onSave()" [disabled]="form.invalid || selectedFiles.length == 0 || saving">
			<fa-icon *ngIf="saving" class="me-2" [icon]="faSpinnerThird" [spin]="true"></fa-icon>
			Save File
		</button>
	</div>
</form>
