export class LookupType {
	typeCode: string;
	typeName: string;
}

export class LookupValue {
	id: number;
	name: string;
	code: string;
	description: string;
	version: string;
	categoryName?: string;
	sortOrder?: number;

	// UI Only
	isNew: boolean = false;
	loading: boolean = false;
}
