<div class="modal-header">
	<h5 class="modal-title pull-left my-0">
		{{ title }}
	</h5>

	<button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
		<span aria-hidden="true" class="visually-hidden">&times;</span>
	</button>
</div>

<div class="modal-body file-viewer-body">
	<!-- <input #fileBrowser type="file" class="d-none" accept=".jpg, .jpeg, .png, .bmp, .tif, .pdf" /> -->

	<div class="row justify-content-between align-items-center">
		<div class="col-auto">
			<div class="mb-3 row">
				<label for="fileDropdown" class="col-auto col-form-label">File</label>
				<div class="col">
					<div class="btn-group" dropdown>
						<button id="fileDropdownButton" class="btn btn-outline-primary dropdown-toggle" type="button"
							aria-controls="fileDropdown" dropdownToggle [disabled]="downloadingFile">
							{{ selectedFile?.fileName }} <span class="caret"></span>
						</button>
						<div id="fileDropdown" class="dropdown-menu" role="menu" aria-labelledby="fileDropdownButton"
							*dropdownMenu>
							<ng-container *ngFor="let file of files">
								<button type="button" class="dropdown-item" [class.active]="file._selected"
									(click)="onSelectFile(file)">
									{{ file.fileName }}
								</button>
							</ng-container>
						</div>
					</div>
				</div>
			</div>
		</div>

		<ng-container *ngIf="selectedFile">
			<div class="col-auto">
				<div class="btn-toolbar p-0">
					<ng-container *ngIf="isSelectedFileAnImage()">
						<div class="btn-group ms-2">
							<button type="button" class="btn btn-outline-primary px-3" (click)="onZoomIn()"
								tooltip="Zoom in" container="body" [disabled]="downloadingFile">
								<fa-icon [icon]="faMagnifyingGlassPlus"></fa-icon>
							</button>

							<button type="button" class="btn btn-outline-primary px-3" (click)="onZoomOut()"
								tooltip="Zoom out" container="body" [disabled]="downloadingFile">
								<fa-icon [icon]="faMagnifyingGlassMinus"></fa-icon>
							</button>
						</div>

						<div class="btn-group ms-2">
							<button type="button" class="btn btn-outline-primary px-2" (click)="onReloadDocument()"
								tooltip="Reload file" container="body" [disabled]="downloadingFile">
								<fa-icon [icon]="faArrowsRotate"></fa-icon>
							</button>
						</div>
					</ng-container>
					<div class="btn-group ms-2">
						<button *ngIf="showEdit" type="button" class="btn btn-outline-primary px-2"
								(click)="onRenameFile()" tooltip="Rename file" container="body"
								[disabled]="downloadingFile">
							<fa-icon [icon]="faPencil"></fa-icon>
						</button>
					</div>

					<div class="btn-group ms-2">
						<button type="button" class="btn btn-outline-primary px-3" (click)="onDownloadFile()"
							tooltip="Download file" container="body" [disabled]="downloadingFile">
							<fa-icon [icon]="downloadIcon" *ngIf="!downloadingFile"></fa-icon>
							<app-spinner-wheel *ngIf="downloadingFile"></app-spinner-wheel>
						</button>
					</div>

					<div class="btn-group ms-2">
						<button *ngIf="showDelete" type="button" class="btn btn-outline-danger px-2"
								(click)="onDeleteFile()" tooltip="Delete file" container="body"
								[disabled]="downloadingFile">
							<fa-icon [icon]="faTrash"></fa-icon>
						</button>
					</div>
				</div>
			</div>
		</ng-container>
	</div>

	<ng-container *ngIf="!files || files.length == 0">
		<div class="file-viewer-container text-center py-6 my-6">
			<em class="text-muted">
				no files
			</em>
		</div>
	</ng-container>

	<hr/>

	<ng-container *ngIf="selectedFile">
		<div class="file-viewer-container mt-3">
			<ng-container *ngIf="!selectedFile._loading && selectedFile._path">
				<ng-container *ngIf="isSelectedFileAnImage()">
					<figure class="file-viewer-figure">
						<img class="mx-auto" [src]="selectedFile._path | safe:'resourceUrl'"
							[style]="{ 'height': zoomLevel + '%' }" alt="" />
					</figure>
				</ng-container>

				<ng-container *ngIf="!isSelectedFileAnImage()">
					<object class="file-viewer-frame" [attr.data]="selectedFile._path | safe:'resourceUrl'"></object>
				</ng-container>
			</ng-container>

			<ng-container *ngIf="selectedFile._loading || !selectedFile._path">
				<div class="overlay">
					<div class="overlay-message">
						loading {{ selectedFile.fileName }}...
					</div>
				</div>
			</ng-container>

			<ng-container *ngIf="overlayMessage">
				<div class="overlay">
					<div class="overlay-message">
						{{ overlayMessage }}
					</div>
				</div>
			</ng-container>
		</div>
	</ng-container>
</div>
