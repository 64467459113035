<div class="card">
	<div class="row d-flex align-items-stretch g-0">
		<div class="col-md-7 card-cover">
			<h5 class="mb-0">Procedure<br />Information</h5>
		</div>

		<div class="col-md-17 px-3">
			<div class="card-body">
				<div class="row mb-3">
					<label class="col-9 col-form-label" for="procedure">
						Procedure
					</label>

					<div class="col-15">
						<app-procedure-input [(procedureName)]="case.procedureName"
											 (procedureChange)="onProcedureChange($event)">
						</app-procedure-input>
					</div>
				</div>
			</div>

			<div class="card mt-2">
				<div class="card-body bg-light rounded">
					<app-provider-facility-select [showProviderSelect]="true"
												  [showFacilitySelect]="true"
												  [searchNearZipCode]="case.memberZipCode"
												  [(providerNPI)]="case.providerNPI"
												  [(providerName)]="case.providerName"
												  [(facilityNPI)]="case.facilityNPI"
												  [(facilityName)]="case.facilityName"
												  [(address1)]="case.facilityAddress1"
												  [(address2)]="case.facilityAddress2"
												  [(city)]="case.facilityCity"
												  [(stateCode)]="case.facilityStateCode"
												  [(zipCode)]="case.facilityZipCode"
												  [(phoneNumber)]="case.facilityPhoneNumber"
												  [(faxNumber)]="case.facilityFaxNumber"
												  [(emailAddress)]="case.facilityEmailAddress">
					</app-provider-facility-select>
				</div>
			</div>

			<div class="card-body">
				<div class="row mb-3">
					<label class="col-9 col-form-label" for="billingContactName">
						Billing contact
					</label>

					<div class="col-8">
						<input type="text"
							   class="form-control"
							   id="billingContactName"
							   name="billingContactName"
							   [(ngModel)]="case.billingContactName"
							   placeholder="Billing contact"
							   autocomplete="off" />
					</div>
				</div>

				<div class="row mt-3">
					<label class="col-9 col-form-label" for="billingPhoneNumber">
						Billing phone number
					</label>

					<div class="col-8">
						<app-phone-input [id]="'billingPhoneNumber'"
										 [name]="'billingPhoneNumber'"
										 [(ngModel)]="case.billingPhoneNumber">
						</app-phone-input>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<div class="card mt-4">
	<div class="row d-flex align-items-stretch g-0">
		<div class="col-md-7 card-cover">
			<h5 class="mb-0">Payment Information</h5>
		</div>

		<div class="col-md-17 px-3">
			<div class="card-body">
				<div class="row mb-3">
					<label class="col-9 col-form-label" for="upfrontPaymentAmount">
						Upfront payment amount
					</label>

					<div class="col-8">
						<input id="upfrontPaymentAmount" name="upfrontPaymentAmount" class="form-control" type="text" placeholder="$ 0.00" currencyMask autocomplete="off" [(ngModel)]="case.upfrontPaymentAmount" />
					</div>
				</div>

				<div class="row mb-3">
					<label class="col-9 col-form-label" for="postProcedurePaymentAmount">
						Post-procedure amount
					</label>

					<div class="col-8">
						<input id="postProcedurePaymentAmount" name="postProcedurePaymentAmount" class="form-control" type="text" placeholder="$ 0.00" currencyMask autocomplete="off" [(ngModel)]="case.postProcedurePaymentAmount" />
					</div>
				</div>

				<div class="row mb-3">
					<label class="col-9 col-form-label" for="ableToPayAmount">
						Able to pay amount
					</label>

					<div class="col-8">
						<input id="ableToPayAmount" name="ableToPayAmount" class="form-control" type="text" placeholder="$ 0.00" currencyMask autocomplete="off" [(ngModel)]="case.ableToPayAmount" />
					</div>
				</div>

				<div class="row mb-3">
					<label class="col-9 col-form-label" for="upfrontPaymentDueDate">
						Upfront payment due date
					</label>

					<div class="col-10">
						<input id="upfrontPaymentDueDate" name="upfrontPaymentDueDate" class="form-control" type="date"
							   [(ngModel)]="case.upfrontPaymentDueDate" />
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
