import { Component, OnInit, Input, Output, EventEmitter, Optional } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

import { CasePrescription } from '@app/data/cases/models';

@Component({
	selector: 'app-create-prescription-request',
	templateUrl: 'create-prescription-request.component.html',
	styleUrls: ['create-prescription-request.component.scss'],
	viewProviders: [{
		provide: ControlContainer,
		deps: [[Optional, NgForm]],
		useFactory: (ngForm: NgForm) => ngForm
	}]
})
export class CaseCreatePrescriptionRequestComponent implements OnInit {
	@Input()
	case: CasePrescription;

	@Output()
	caseChange: EventEmitter<CasePrescription> = new EventEmitter<CasePrescription>();

	constructor(
	) {
	}

	ngOnInit() {
	}
}
