<div class="spinner text-center text-secondary" [ngClass]="class">
	<div class="spinner-border" role="status">
		<span class="visually-hidden">
			{{ message }}
		</span>
	</div>

	<div class="spinner-text text-uppercase font-weight-light small">
		{{ message }}
	</div>
</div>
