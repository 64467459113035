<div class="card h-100">
	<div class="row g-0 h-100">
		<div class="col-md-7 h-100 card-cover">
			<h5 class="mb-0">Transportation<br />Request</h5>
		</div>

		<div class="col-md-17 h-100 px-3">
			<div class="card-body">
				<div class="row">
					<label class="col-9 col-form-label" for="appointmentDate">
						Appointment time
					</label>

					<div class="col-15">
						<div class="input-group">
							<input id="appointmentDateInput" name="appointmentDateInput" type="date" class="form-control rounded-end" [(ngModel)]="case.appointmentDate" />
							<span class="input-group-text border-0">@</span>
							<input id="appointmentTimeInput" name="appointmentTimeInput" type="time" class="form-control rounded-start" [(ngModel)]="case.appointmentTime" />
						</div>
					</div>
				</div>

				<div class="row mt-3">
					<label class="col-9 col-form-label" for="pickupAddress1">
						Pick-up address
					</label>

					<div class="col-15">
						<div class="row mb-2">
							<div class="col-24">
								<input type="text" class="form-control" id="pickupAddress1" name="pickupAddress1" [(ngModel)]="case.pickupAddress1" placeholder="Address 1" />
							</div>
						</div>

						<div class="row mb-2">
							<div class="col-24">
								<input type="text" class="form-control" name="pickupAddress2" [(ngModel)]="case.pickupAddress2" placeholder="Address 2" />
							</div>
						</div>

						<div class="row g-2">
							<div class="col-10">
								<input type="text" class="form-control" name="pickupCity" [(ngModel)]="case.pickupCity" placeholder="City" />
							</div>

							<div class="col-8">
								<ng-select class="custom-ng-select"
										   id="pickupStateCode"
										   name="pickupStateCode"
										   [items]="states"
										   [bindLabel]="'stateName'"
										   [bindValue]="'stateCode'"
										   [(ngModel)]="case.pickupStateCode"
										   [clearable]="true"
										   [multiple]="false"
										   [searchable]="true"
										   [loading]="loadingStates"
										   [loadingText]="'loading states...'"
										   [notFoundText]="'no state matching your search'"
										   [placeholder]="'State'">
								</ng-select>
							</div>

							<div class="col-6">
								<app-zipcode-input [id]="'pickupZipCode'"
												   [name]="'pickupZipCode'"
												   [(ngModel)]="case.pickupZipCode">
								</app-zipcode-input>
							</div>
						</div>
					</div>
				</div>

				<div class="row mt-3">
					<label class="col-9 col-form-label" for="destinationAddress1">
						Drop-off address
					</label>

					<div class="col-15">
						<div class="row mb-2">
							<div class="col-24">
								<input type="text" class="form-control" id="destinationAddress1" name="destinationAddress1" [(ngModel)]="case.destinationAddress1" placeholder="Address 1" />
							</div>
						</div>

						<div class="row mb-2">
							<div class="col-24">
								<input type="text" class="form-control" name="destinationAddress2" [(ngModel)]="case.destinationAddress2" placeholder="Address 2" />
							</div>
						</div>

						<div class="row g-2">
							<div class="col-10">
								<input type="text" class="form-control" name="destinationCity" [(ngModel)]="case.destinationCity" placeholder="City" />
							</div>

							<div class="col-8">
								<ng-select class="custom-ng-select"
										   id="destinationStateCode"
										   name="destinationStateCode"
										   [items]="states"
										   [bindLabel]="'stateName'"
										   [bindValue]="'stateCode'"
										   [(ngModel)]="case.destinationStateCode"
										   [clearable]="true"
										   [multiple]="false"
										   [searchable]="true"
										   [loading]="loadingStates"
										   [loadingText]="'loading states...'"
										   [notFoundText]="'no state matching your search'"
										   [placeholder]="'State'">
								</ng-select>
							</div>

							<div class="col-6">
								<app-zipcode-input [id]="'destinationZipCode'"
												   [name]="'destinationZipCode'"
												   [(ngModel)]="case.destinationZipCode">
								</app-zipcode-input>
							</div>
						</div>
					</div>
				</div>

				<div class="row mt-3">
					<label class="col-9 col-form-label" for="preferences">
						Other preferences
					</label>

					<div class="col-15">
						<textarea class="form-control"
								  id="preferences"
								  name="preferences"
								  [(ngModel)]="case.preferences"
								  placeholder="Other preferences"></textarea>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
