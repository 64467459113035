export class Specialty {
	specialityID: number;
	code: string;
	relatedCodes: string;
	name: string;
	isPopular: boolean;
}

export class Procedure {
	id: number;
	name: string;
	cptCode: string;
	cptCategoryID: string;
	cptCategoryName: string;
	isCategoryPopular: boolean;

	// computed properties
	get codedName() {
		return `${this.cptCode} - ${this.name}`;
	}

	constructor(data: any = null) {
		if (data) Object.assign(this, data);
	}
}

export class Provider {
	providerID: number;
	lastUpdateDate: string;
	npi: number;
	organizationName: string;
	lastName: string;
	firstName: string;
	middleName: null;
	prefix: string;
	suffix: null;
	credentials: string;
	name: string;
	fullName: string;
	genderName: string;
	primarySpecialtyCode: string;
	primarySpecialtyName: string;
	allSpecialtyNames: string;
	isGreatPrice: boolean;
	averageCostScore: number;
	isHighQuality: boolean;
	averageQualityScoreNoComplexity: number;
	averageQualityScoreFull: number;
	isPrimaryLocation: boolean;
	address1: string;
	address2: string;
	city: string;
	stateCode: string;
	zipCode: string;
	countryCode: string;
	fullAddress: string;
	phoneNumber: string;
	phoneExtension: string;
	faxNumber: string;
	emailAddress: string;
	latitude: number;
	longitude: number;
	milesAway: number;
	isNearby: boolean
}

export class ProviderDetails {
	providerID: number;
	npi: number;
	entityTypeID: number;
	entityTypeName: string;
	replacementNPI: string;
	deactivationDate: string;
	deactivationReasonID: number;
	deactivationReasonName: string;
	reactivationDate: string;
	certificationDate: string;
	enumerationDate: string;
	ein: string;
	lastUpdateDate: string;
	lastName: string;
	firstName: string;
	middleName: string;
	fullName: string;
	prefixID: number;
	prefixName: string;
	suffixID: number;
	suffixName: string;
	credentials: string;
	genderID: number;
	genderName: string;
	isSoleProprietor: boolean;
	organizationName: string;
	isOrganizationSubpart: boolean;
	parentTIN: string;
	parentLBN: string;
	otherLastName: string;
	otherFirstName: string;
	otherMiddleName: string;
	otherFullName: string;
	otherPrefixID: number;
	otherPrefixName: string;
	otherSuffixID: number;
	otherSuffixName: string;
	otherNameTypeID: number;
	otherNameTypeName: string;
	otherCredentials: string;
	authorizedLastName: string;
	authorizedFirstName: string;
	authorizedMiddleName: string;
	authorizedFullName: string;
	authorizedTitleOrPosition: string;
	authorizedPrefixID: number;
	authorizedPrefixName: string;
	authorizedSuffixID: number;
	authorizedSuffixName: string;
	authorizedCredentials: string;
	authorizedPhoneNumber: string;
	mailingAddress1: string;
	mailingAddress2: string;
	mailingCity: string;
	mailingStateCode: string;
	mailingZipCode: string;
	mailingCountryCode: string;
	mailingPhoneNumber: string;
	mailingFaxNumber: string;
	fullMailingAddress: string;
	deleted: boolean;
	createdByUserID: number;
	dateCreated: string;
	updatedByUserID: number;
	dateUpdated: string;
	rowVersion: string;
	isGreatPrice: boolean;
	averageCostScore: number;
	isHighQuality: boolean;
	averageQualityScoreNoComplexity: number;
	averageQualityScoreFull: number;
	specialties: {
		providerSpecialtyID: number;
		isPrimary: boolean;
		specialtyCode: string;
		specialtyName: string;
		licenseNumber: string;
		stateCode: string;
	}[];
	otherNames: any[];
	locations: ProviderDetailsLocation[];
	identifiers: {
		providerIdentifierID: number;
		identifier: string;
		identifierTypeID: number;
		identifierTypeName: string;
		stateCode: string;
		issuer: string;
	}[]
}

export class ProviderDetailsLocation {
	providerLocationID: number;
	isPrimary: boolean;
	fullAddress: string;
	address1: string;
	address2: string;
	city: string;
	stateCode: string;
	zipCode: string;
	countryCode: string;
	phoneNumber: string;
	phoneExtension: string;
	faxNumber: string;
	latitude: number;
	longitude: number;
}

export class Medication {
	id: string;
	drugName: string;
	brandName: string;
}

export class MedicationDetails {
	name: string;
	brands: MedicationDetailsBrand[];
}

export class MedicationDetailsBrand {
	name: string;
	forms: MedicationDetailsForm[];
}

export class MedicationDetailsForm {
	name: string;
	dosages: MedicationDetailsDosage[]
}

export class MedicationDetailsDosage {
	name: string;
	quantities: string[];
}
