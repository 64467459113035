import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { BsModalService } from 'ngx-bootstrap/modal';

import { MembersService } from '@app/data/members/services';
import { MemberPlan } from '@app/data/members/models';
import { MemberPlanDeactivateModalComponent } from '@app/data/members/components';

import { faCheck, faXmark, faInfoCircle, faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons';

@Component({
	selector: 'app-member-plans-list',
	templateUrl: 'member-plans-list.component.html',
	styleUrls: ['member-plans-list.component.scss'],
	exportAs: 'memberPlans'
})
export class MemberPlansListComponent implements OnInit {
	faCheck = faCheck;
	faXmark = faXmark;
	faInfoCircle = faInfoCircle;
	faEllipsisVertical = faEllipsisVertical;

	/* =================== inputs/outputs ================== */
	@Input()
	memberName: string;

	@Input()
	get clientMemberID() { return this._clientMemberID; }
	set clientMemberID(value: number) {
		if (this._clientMemberID !== value) {
			this._clientMemberID = value;
			this.onReloadMemberPlans();
		}
	}
	private _clientMemberID: number;
	@Output() memberPlansChange: EventEmitter<MemberPlan[]> = new EventEmitter<MemberPlan[]>();

	/* =================== internal state ================== */
	loadingMemberPlans: boolean = false;
	loadingMemberPlansError: boolean = false;
	memberPlans: MemberPlan[] = [];
	showNewPlanForm: boolean = false;
	saving: boolean = false;

	memberPlansWithPrimaryMembers: MemberPlan[] = [];

	constructor(
		private membersService: MembersService,
		private modalService: BsModalService
	) {
	}

	ngOnInit() {
	}

	async onReloadMemberPlans() {
		try {
			this.loadingMemberPlans = true;
			this.loadingMemberPlansError = false;

			this.memberPlans = await this.membersService.getMemberPlans(this.clientMemberID);

			this.memberPlansChange.emit(this.memberPlans);

			// TODO: WHAT IS THIS FOR??
			this.memberPlansWithPrimaryMembers = this.memberPlans.filter(memberPlan => !!memberPlan.primaryClientMemberID);
		} catch (error: any) {
			this.loadingMemberPlansError = true;
		} finally {
			this.loadingMemberPlans = false;
		}
	}

	async onEditMemberPlan(memberPlan: MemberPlan) {
		this.memberPlans.forEach(m => m.editing = false);

		memberPlan.editing = true;
	}

	async onMemberPlanEditClosed() {
		this.memberPlans.forEach(m => m.editing = false);
	}

	async onDeactivateMemberPlan(memberPlan: MemberPlan) {
		let modalRef = this.modalService.show<MemberPlanDeactivateModalComponent>(MemberPlanDeactivateModalComponent, {
			class: 'modal-md',
			ignoreBackdropClick: true,
			animated: false,
			initialState: {
				memberPlan: memberPlan,
				clientMemberID: this.clientMemberID,
				memberName: this.memberName
			}
		});

		modalRef.onHide.subscribe(async () => {
			if (modalRef.content.deactivated) {
				this.onReloadMemberPlans();
			}
		});
	}
}
