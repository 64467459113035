import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { lastValueFrom, catchError, tap } from 'rxjs';

import { CaseTypes, CaseFacilityProvider } from '@app/data/cases/models';
import { handleHttpError } from '@app/shared/functions';

import { environment } from '@app/environment';

@Injectable({ providedIn: 'root' })
export class CasesService {
	protected get baseApiUrl() {
		return `${environment.api.host}/api/v1/cases`;
	}

	constructor(
		private http: HttpClient
	) {
	}

	async createCase<TCase extends CaseTypes>(model: TCase) {
		let body: TCase = {
			...model,
			priorities: (model as CaseFacilityProvider)?.priorities?.map(priority => priority.priorityCode)
		};

		return lastValueFrom(
			this.http
				.post<CaseTypes>(`${this.baseApiUrl}`, body)
				.pipe(catchError(handleHttpError))
		);
	}
}
