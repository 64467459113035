import { Component, Input, Output, EventEmitter } from '@angular/core';

import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';

// TODO: THIS COMPONENT SHOULD WORK WITHOUT HAVING TO SUBSCRIBE TO THE SAVE EVENT (WITHOUT THAT IT SPINS FOREVER)

@Component({
	selector: 'app-switch',
	templateUrl: 'switch.component.html',
	styleUrls: ['switch.component.scss']
})
export class SwitchComponent {
	faSpinnerThird = faSpinnerThird;

	innerValue: boolean = false;
	saving: boolean = false;

	@Input() id: string;
	@Input() name: string;
	@Input() disabled: boolean = false;
	@Input() readonly: boolean = false;
	@Input() size: 'sm' | 'md' | 'lg' = 'md';
	@Input()
	set value(value: boolean) {
		this.innerValue = value;
	}

	@Output()
	valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output()
	save: EventEmitter<SwitchToggleEvent> = new EventEmitter<SwitchToggleEvent>(true);

	onToggle() {
		if (this.disabled) {
			return;
		}

		this.saving = true;

		this.save.emit({
			value: this.innerValue,
			saved: (success: boolean) => {
				this.saving = false;

				if (success) {
					this.valueChange.emit(this.innerValue);
				} else {
					this.innerValue = !this.innerValue;
				}
			}
		});
	}
}

export class SwitchToggleEvent {
	value: boolean;
	saved: (success: boolean) => void
}
