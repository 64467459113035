export class Lookup {
	code: string;
	name: string;
	description?: string;
	sortOrder: number;

	/* ======= LOOKUP SPECIFIC FIELDS ======= */
	// ActivityType
	hasActivityEntity?: boolean;

	// CaseType
	categoryName?: string;

	// BillChangeType
	isSavings?: boolean;
	canOffer?: boolean;
	isClaimable?: boolean;
	canClientClaim?: boolean;

	// CaseStatusDetail
	caseStatusCode?: string;

	// BillStatusDetail
	billStatusCode?: string;

	// BillChangeTypeDetail
	billChangeTypeCode?: string;
}

export class ClientLookup {
	clientID: number;
	fullName: string;
	shortName: string;
	clientLogoUrl?: string;
	isClientActive: boolean;
	clientName?: string;
	plans?: PlanLookup[];
}

export class PlanLookup {
	clientPlanID: number;
	clientPlanName: string;
	isPlanActive: boolean;
}

export class CaseLookup {
	caseID: number;
	caseCode: string;
	externalCaseCode: string | null;
	caseTypeName: string;
	firstName: string;
	lastName: string;
	fullName: string;

	// UI only
	selectLabel: string;
}

export class BillLookup {
	billID: number;
	billCode: string;
	externalBillCode: string | null;
	isOpen: boolean;
	providerName: string;
	caseID: number;
	caseCode: string;
	externalCaseCode: string | null;
	isCaseOpen: boolean;
	firstName: string;
	lastName: string;
	fullName: string;
}

export class ClientPlanMemberLookup {
	clientMemberPlanID: number;
	clientMemberID: number;
	isDependent: boolean;
	dependentRelationshipName: string | null;
	clientPlanID: number;
	effectiveDate: string;
	terminationDate: string | null;
	isMemberActive: boolean;
	planName: string;
	isPlanActive: boolean;
	clientID: number;
	memberCode: string;
	externalMemberCode: string | null;
	firstName: string;
	lastName: string;
	fullName: string;
	dateOfBirth: string | null;
	lastFourSSN: string | null;
	address1: string | null;
	address2: string | null;
	city: string | null;
	stateCode: string | null;
	zipCode: string | null;
	phoneNumber: string | null;
	mobileNumber: string | null;
	emailAddress: string | null;
	genderName: string | null;
	preferredLanguageName: string | null;
	isDeceased: boolean;
}

export class TaskTemplatesLookup {
	code: string;
	name: string;
	description: string;
	sortOrder: number;
	version: string;
	categoryName: string;
	categoryCode: string;
	templateText: string;
}

export class PortalUserLookup {
	portalUserID: number;
	firstName: string;
	lastName: string;
	fullName: string;
	shortName: string;
	initials: string;
	profileImageUrl?: string;
	openCaseCount: number;
	openBillCount: number;
	openBillAmount: number;
	billsAssignedPerWeek: number;
}

export class CaseTypeLookup {
	code: string;
	name: string;
	description?: string;
	sortOrder: number;
	version: string;
	categoryName: string;
}

export class ObjectLookup {
	code: string;
	name: string;
	description?: string;
	sortOrder: number;
	version: string;
}

export class SubjectsLookup {
	code: string;
	name: string;
	description?: string;
	sortOrder: number;
	version: string;
}

export class ActivityTypeLookup {
	code: string;
	name: string;
	description?: string;
	sortOrder: number;
	version: string;
	hasActivityEntity: boolean;
}
