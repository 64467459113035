<div class="card h-100">
	<div class="row g-0 h-100">
		<div class="col-md-7 h-100 card-cover">
			<h5 class="mb-0">Appointment<br />Request</h5>
		</div>

		<div class="col-md-17 h-100 px-3">
			<div class="card-body bg-light rounded-3 py-2 mt-3">
				<div class="row">
					<label class="col-9 col-form-label" for="provider">
						Provider
					</label>

					<div class="col-15">
						<app-provider-input [providerType]="'Individual'"
											[zipCode]="case.memberZipCode"
											[(providerName)]="case.providerName"
											(providerChange)="onProviderSelect($event)"
											[placeholder]="'Provider'">
						</app-provider-input>
					</div>
				</div>

				<hr />

				<div class="row mb-3">
					<div class="col">
						<app-provider-facility-select [showProviderSelect]="false"
													  [showFacilitySelect]="true"
													  [searchNearZipCode]="case.memberZipCode"
													  [(facilityNPI)]="case.facilityNPI"
													  [(facilityName)]="case.facilityName"
													  [(address1)]="case.facilityAddress1"
													  [(address2)]="case.facilityAddress2"
													  [(city)]="case.facilityCity"
													  [(stateCode)]="case.facilityStateCode"
													  [(zipCode)]="case.facilityZipCode"
													  [(phoneNumber)]="case.facilityPhoneNumber"
													  [(faxNumber)]="case.facilityFaxNumber"
													  [(emailAddress)]="case.facilityEmailAddress">
						</app-provider-facility-select>
					</div>
				</div>
			</div>

			<div class="card-body pb-2">
				<div class="row mb-3">
					<label class="col-9 col-form-label" for="preferredTime">
						Preferred time
					</label>

					<div class="col-15">
						<input type="text"
							   class="form-control"
							   id="preferredTime"
							   name="preferredTime"
							   [(ngModel)]="case.preferredTime"
							   placeholder="Preferred time" />
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
