import { Component, Input } from '@angular/core';

import { Breadcrumb } from '@app/shared/models';

@Component({
	selector: 'app-breadcrumbs',
	templateUrl: 'breadcrumbs.component.html',
	styleUrls: ['breadcrumbs.component.scss']
})
export class BreadcrumbComponent {
	@Input()
	breadcrumbs: Breadcrumb[];

	isCurrent(index: number) {
		return (index === (this.breadcrumbs.length - 1));
	}
}
