import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
	// workplace
	{
		path: 'workplace',
		loadChildren: () => import('@app/workspace/module').then(m => m.WorkplaceModule),
		data: { title: 'Workplace' }
	},
	// navigation
	{
		path: 'navigation',
		loadChildren: () => import('@app/navigation/module').then(m => m.NavigationModule),
		data: { title: 'Navigation' }
	},
	// knowledge
	{
		path: 'knowledge-base',
		loadChildren: () => import('@app/knowledge-base/module').then(m => m.KnowledgeBaseModule),
		data: { title: 'Knowledge Base' }
	},
	// data
	{
		path: 'data',
		loadChildren: () => import('@app/data/module').then(m => m.DataModule)
	},
	// surveys
	{
		path: 'surveys',
		loadChildren: () => import('@app/surveys/module').then(m => m.SurveysModule)
	},
	// analytics
	{
		path: 'analytics',
		loadChildren: () => import('@app/analytics/module').then(m => m.AnalyticsModule)
	},
	// settings
	{
		path: 'settings',
		loadChildren: () => import('@app/settings/module').then(m => m.SettingsModule),
		data: { title: 'Settings' }
	},
	// profile
	{
		path: 'account',
		loadChildren: () => import('@app/account/module').then(m => m.AccountModule),
		data: { title: 'Account' }
	},
	// dev tools
	{
		path: 'dev',
		loadChildren: () => import('./modules/dev/module').then(m => m.DevToolsModule),
		data: { title: 'Dev Tools' }
	},
	// all other - redirect to home
	{
		path: '**',
		redirectTo: 'workplace/home'
	}];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			initialNavigation: 'enabledNonBlocking'
		})
	],
	exports: [
		RouterModule
	]
})
export class AppRoutingModule {
}
