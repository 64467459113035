<form #form="ngForm">
	<div class="modal-header px-4">
		<h6 class="modal-title">
			Upload New Authorization
		</h6>

		<button type="button" class="btn-close" (click)="onClose()" [disabled]="requesting">
			<span class="visually-hidden">&times;</span>
		</button>
	</div>

	<div class="modal-body">
		<fieldset [disabled]="requesting">
			<div class="container">
				<div class="row mb-3">
					<label class="col-7 col-form-label" for="customName">
						Authorization name
					</label>

					<div class="col-9">
						<input type="text" class="form-control" id="customName" name="customName" [(ngModel)]="model.customName" placeholder="e.g. Our Lady of the Lake" autocomplete="off" />
					</div>
				</div>

				<div class="row mb-3">
					<label class="col-7 col-form-label" for="customText">
						Additional authorization text
					</label>

					<div class="col-17">
						<textarea class="form-control" id="customText" name="customText" [(ngModel)]="model.customText" rows="4" placeholder="This custom text will appear at the top of the authorization form, before the generic text included with all authorizations. Some providers require specific text on authorization forms."></textarea>
					</div>
				</div>

				<div class="row mb-3">
					<label class="col-7 col-form-label" for="languageCode">
						Authorization form language
					</label>

					<div class="col-6">
						<ng-select class="custom-ng-select"
								   id="languageCode"
								   name="languageCode"
								   bindLabel="name"
								   bindValue="code"
								   [items]="languages"
								   [(ngModel)]="model.languageCode"
								   [clearable]="false"
								   [multiple]="false"
								   [searchable]="false"
								   [loading]="loadingLanguages"
								   [disabled]="requesting"
								   loadingText="loading languages..."
								   placeholder="Authorization form language">
						</ng-select>
					</div>

					<div class="col-17 offset-7">
						<small class="text-muted">
							Member's preferred language is 'Spanish'.
						</small>
					</div>
				</div>

				<hr />

				<div class="row">
					<div class="col-24">
						<p>Select how you would like to send authorization form to <strong>{{ model.personalRepresentative }}</strong>:</p>

						<div class="form-check mt-2">
							<input class="form-check-input" type="checkbox" id="viaPhoneNumber" name="viaPhoneNumber" />
							<label class="form-check-label" for="viaPhoneNumber">
								{{ model.personalRepresentative }} &lt;{{ model.phoneNumber | phone }}&gt; <span class="text-secondary">(mobile phone on file)</span>
							</label>
						</div>

						<div class="form-check mt-2">
							<input class="form-check-input" type="checkbox" id="viaEmailAddress" name="viaEmailAddress" />
							<label class="form-check-label" for="viaEmailAddress">
								{{ model.personalRepresentative }} &lt;{{ model.emailAddress }}&gt; <span class="text-secondary">(email address on file)</span>
							</label>
						</div>

						<div class="row align-items-center mt-2">
							<div class="col-auto">
								<div class="form-check">
									<input class="form-check-input" type="checkbox" id="viaCustomContact" name="viaCustomContact" />
									<label class="form-check-label text-nowrap" for="viaCustomContact">
										Custom phone or email:
									</label>
								</div>
							</div>

							<div class="col-6">
								<input type="text" class="form-control" id="customContact" name="customContact" placeholder="Custom phone or email" />
							</div>
						</div>
					</div>
				</div>

				<div class="row my-3">
					<div class="col-24">
						<div class="alert alert-warning d-flex align-items-center mb-0">
							<fa-icon class="text-warning me-3" [icon]="warningIcon"></fa-icon>

							<div>
								<div>{{ model.personalRepresentative }} is a dependent (spouse) of Jessica Barret.</div>
								<div>You may send authorization request to the primary member if they are a better contact for Michael Barret.</div>
							</div>
						</div>
					</div>
				</div>

				<div class="form-check">
					<input class="form-check-input" type="checkbox" id="viaPhoneNumber" name="viaPhoneNumber" />
					<label class="form-check-label" for="viaPhoneNumber">
						Jessica Barret &lt;{{ '2243548810' | phone }}&gt; <span class="text-secondary">(mobile phone on file)</span>
					</label>
				</div>

				<div class="form-check mt-2">
					<input class="form-check-input" type="checkbox" id="viaEmailAddress" name="viaEmailAddress" />
					<label class="form-check-label" for="viaEmailAddress">
						Jessica Barret &lt;{{ 'JessBarret@gmail.com' }}&gt; <span class="text-secondary">(email address on file)</span>
					</label>
				</div>
			</div>
		</fieldset>
	</div>

	<div class="modal-footer bg-white px-4">
		<button type="button" class="btn btn-outline-primary" (click)="onClose()" [disabled]="requesting">
			Cancel
		</button>

		<button type="button" class="btn btn-primary" (click)="onRequest()" [disabled]="form.invalid || requesting">
			<fa-icon *ngIf="requesting" class="me-2" [icon]="spinnerIcon" [spin]="true"></fa-icon>
			Preview & Request
		</button>
	</div>
</form>
