import { Component, OnInit, Input, Output, EventEmitter, Optional } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';

import { LookupService } from '@app/settings/services';
import { State } from '@app/settings/models';

import { Messages } from '@app/constants';

@Component({
	selector: 'app-address-edit',
	templateUrl: 'address-edit.component.html',
	styleUrls: ['address-edit.component.scss'],
	viewProviders: [{
		provide: ControlContainer,
		deps: [[Optional, NgForm]],
		useFactory: (ngForm: NgForm) => ngForm
	}]
})
export class AddressEditComponent implements OnInit {
	loadingStates: boolean = false;
	states: State[];

	@Input()
	address1: string;

	@Output()
	address1Change: EventEmitter<string> = new EventEmitter<string>();

	@Input()
	address2: string;

	@Output()
	address2Change: EventEmitter<string> = new EventEmitter<string>();

	@Input()
	city: string;

	@Output()
	cityChange: EventEmitter<string> = new EventEmitter<string>();

	@Input()
	stateCode: string;

	@Output()
	stateCodeChange: EventEmitter<string> = new EventEmitter<string>();

	@Input()
	zipCode: string;

	@Output()
	zipCodeChange: EventEmitter<string> = new EventEmitter<string>();

	constructor(
		private lookupService: LookupService,
		private toastr: ToastrService
	) { }

	ngOnInit() {
		this.onReloadStates();
	}

	async onReloadStates() {
		try {
			this.loadingStates = true;

			this.states = await this.lookupService.getStates();
		} catch (error: any) {
			this.toastr.error(Messages.ErrorRetry, `Failed to Load States`);
		} finally {
			this.loadingStates = false;
		}
	}
}
