import { Component, AfterViewInit } from '@angular/core';

@Component({
	templateUrl: 'update.component.html'
})
export class UpdateComponent implements AfterViewInit {
	seconds: number = 5;

	constructor(
	) { }

	ngAfterViewInit() {
		setInterval(() => {
			if (this.seconds > 0) {
				this.seconds -= 1;
			} else {
				this.onReload();
			}
		}, 1000);
	}

	onReload() {
		location.reload();
	}
}
