export class AssistantMessageModel {
    role: string;
    message: string;
    citations?: AssistantMessageCitationModel[] | null;
}

export class AssistantMessageCitationModel {
    chunkID: string;
    id: any;
    title: string;
    filePath: string;
    url: string;
    content: string;
    metadata: string;
}
