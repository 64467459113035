import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';

import { Subscription, tap, catchError, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { LookupService } from '@app/shared/services';
import { ClientLookup, PlanLookup } from '@app/shared/models';

import { Messages, LookupCodes } from '@app/constants';

export class ClientPlanChangeModel {
	clientID?: number; 
	clientPlanID?: number;
	clientName?: string;
	clientPlanName?: string;
	clientLogoURL?: string;
}

@Component({
	selector: 'app-client-plans-select',
	templateUrl: 'client-plans-select.component.html',
	styleUrls: ['client-plans-select.component.scss']
})
export class ClientPlansSelectComponent implements OnInit, OnDestroy {
	static AllClientsPlansOption = {
		label: 'All Clients/Plans',

		clientID: null,
		planID: null
	} as FlatClientPlanLookup;
	allClientsPlansOption = ClientPlansSelectComponent.AllClientsPlansOption;

	/* ========================== inputs/outputs ========================== */
	@Input() id: string;
	@Input() name: string;
	@Input() class: string;
	@Input() placeholder: string;
	@Input() showAllOption: boolean = true;
	@Input() canSelectClient: boolean = true;
	@Input() showSelectedPlanClient: boolean = false;
	@Input() filterClientID: number | null = null;
	@Input() disabled: boolean = false;

	@Input() clientID: number | null = null;
	@Output() clientIDChange: EventEmitter<number | null> = new EventEmitter<number | null>();
	@Input() clientPlanID: number | null = null;
	@Output() clientPlanIDChange: EventEmitter<number | null> = new EventEmitter<number | null>();

	@Output()
	// clientPlanChange: EventEmitter<{ clientID?: number, clientPlanID?: number }> = new EventEmitter();
	clientPlanChange: EventEmitter<ClientPlanChangeModel> = new EventEmitter();

	/* ============================== internal state ============================== */
	selectedClientPlan: FlatClientPlanLookup = null;
	flatClientPlans: FlatClientPlanLookup[] = [];

	/* ============================== lookups ============================== */
	clientPlansLookup: ClientLookup[];
	clientPlansLookupSubscription: Subscription;
	clientPlansLoading: boolean = true;

	constructor(
		private lookupService: LookupService,
		private toastr: ToastrService
	) {
	}

	ngOnInit() {
		this.clientPlansLookupSubscription = this.lookupService
			.getLookup<ClientLookup>(LookupCodes.ClientPlans)
			.pipe(
				tap(() => this.clientPlansLoading = false),
				catchError(err => {
					this.clientPlansLoading = false;
					return this.showLookupErrorToast('Clients Plans', err);
				})
			)
			.subscribe(clientPlansLookup => {
				// filter clients by filter client id if specified
				this.clientPlansLookup = clientPlansLookup.filter(client => !this.filterClientID || client.clientID === this.filterClientID);

				// map client plans to flat array
				const mappedClientPlans: FlatClientPlanLookup[] = [];
				for (let client of this.clientPlansLookup) {
					mappedClientPlans.push({
						label: `${client.clientName} (all plans)`,

						clientID: client.clientID,
						clientName: client.clientName,
						clientLogoUrl: client.clientLogoUrl,
						isClientActive: client.isClientActive,

						planID: null
					} as FlatClientPlanLookup);

					for (let plan of client.plans) {
						mappedClientPlans.push({
							// label: `${client.clientName} - ${plan.clientPlanName}`,
							label: plan.clientPlanName,

							clientID: client.clientID,
							clientName: client.clientName,
							clientLogoUrl: client.clientLogoUrl,
							isClientActive: client.isClientActive,

							planID: plan.clientPlanID,
							planName: plan.clientPlanName,
							isPlanActive: plan.isPlanActive,
						} as FlatClientPlanLookup);
					}
				}

				this.flatClientPlans = mappedClientPlans;

				// preselect all or specific client/plan
				if (!this.clientID && !this.clientPlanID && this.showAllOption) {
					this.selectedClientPlan = this.allClientsPlansOption;
				} else {
					// auto-select first plan if cannot select client and plan not specified
					if (!this.canSelectClient && !this.clientPlanID && this.clientID) {
						const client = this.clientPlansLookup.find(c => c.clientID === this.clientID);
						if (client?.plans?.length > 0) {
							this.clientPlanID = client.plans[0].clientPlanID;
							this.clientPlanIDChange.emit(this.clientPlanID);
							this.clientPlanChange.emit({ 
								clientID: this.clientID, 
								clientPlanID: this.clientPlanID,
								clientName: client.clientName,
								clientPlanName: client.plans[0].clientPlanName,
								clientLogoURL: client.clientLogoUrl
							});
						}
					}

					// try to preselect client/plan
					this.selectedClientPlan = this.flatClientPlans.find(clientPlan => clientPlan.clientID === (this.clientID || null) && clientPlan.planID === (this.clientPlanID || null));
				}
			});
	}

	ngOnDestroy() {
		this.clientPlansLookupSubscription?.unsubscribe();
	}

	// define as arrow function to preserve 'this' context
	onSearch = (term: string, item: FlatClientPlanLookup) => {
		term = term.toLowerCase();

		// make sure client item is visible if one of the plans matches
		if (!item.planID) {
			// client item - check if either self matches or any of its plans match
			if (item.clientName?.toLowerCase().indexOf(term) > -1) {
				return true;
			} else {
				// check plans
				const client = this.clientPlansLookup.find(c => c.clientID === item.clientID);
				if (client && client.plans) {
					for (let plan of client.plans) {
						if (plan.clientPlanName?.toLowerCase().indexOf(term) > -1) {
							return true;
						}
					}
				}

				return false;
			}
		} else {
			// plan item
			return item.clientName?.toLowerCase().indexOf(term) > -1 ||
				item.planName?.toLowerCase().indexOf(term) > -1;
		}
	}

	onClientPlanChange(clientPlan: FlatClientPlanLookup) {
		console.log('onClientPlanChange', clientPlan);

		this.clientIDChange.emit(clientPlan.clientID);
		this.clientPlanIDChange.emit(clientPlan.planID);
		this.clientPlanChange.emit({
			clientID: clientPlan.clientID,
			clientPlanID: clientPlan.planID,
			clientName: clientPlan.clientName,
			clientPlanName: clientPlan.planName,
			clientLogoURL: clientPlan.clientLogoUrl
		});
	}

	private showLookupErrorToast(lookupName: string, error: any) {
		this.toastr.error(Messages.ErrorRetry, `Failed to Load '${lookupName}'`);
		return throwError(() => error);
	}
}

class FlatClientPlanLookup {
	label: string;

	clientID: number;
	clientName: string;
	clientLogoUrl?: string;
	isClientActive: boolean;

	planID?: number;
	planName?: string;
	isPlanActive?: boolean;
}
