import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';

import { faPencilAlt, faGripDotsVertical, faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';

@Component({
	selector: 'app-order-edit',
	templateUrl: 'order-edit.component.html',
	styleUrls: ['order-edit.component.scss']
})
export class OrderEditComponent implements OnInit {
	faPencilAlt = faPencilAlt;
	faGripDotsVertical = faGripDotsVertical;
	faCheck = faCheck;
	faTimes = faTimes;
	faSpinnerThird = faSpinnerThird;

	private originalItems: any[];

	showEditor: boolean = false;
	saving: boolean = false;

	@Input() items: any[];
	@Input() labelProperty: string;
	@Input() orderProperty: string;
	@Input() inlineEdit: boolean = true;
	@Input() vertical: boolean = false;
	@Input() readonly: boolean = false;

	@Output()
	save: EventEmitter<any> = new EventEmitter<any>();

	async onShowEditor() {
		this.showEditor = true;
	}

	async onHideEditor() {
		this.showEditor = false;
	}

	ngOnInit() {
		this.originalItems = [...(this.items || [])];
	}

	onDrop(event: CdkDragDrop<any[]>) {
		moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);

		this.items = event.container.data.map((item, index) => ({
			...item,
			[this.orderProperty]: index + 1
		}));
	}

	onSave() {
		this.saving = true;
		this.save.emit({
			items: [this.items],
			saved: (result: boolean) => {
				this.saving = false;
				if (result) {
					this.onHideEditor();
				} else {
					this.items = [...this.originalItems];
				}
			}
		});
	}
}
