<div class="input-group">
	<ng-select class="custom-ng-select" id="{{ id }}" name="{{ name }}" [(ngModel)]="selectedWorker"
		(ngModelChange)="onWorkerChange($event)" [clearable]="clearable" [multiple]="false" [searchable]="true"
		[loading]="loadingWorkersLookup" [loadingText]="'loading ' + (workerLabelSetting$ | async)?.value + 's...'"
		[notFoundText]="'no matching ' + (workerLabelSetting$ | async)?.value + 's'" [placeholder]="placeholder">
		<!-- [trackByFn]="workerTrackFunc" -->
		<!-- all option -->
		<ng-option [value]="allWorkersOption" *ngIf="showAllOption">
			<div class="py-2 fw-bold text-truncate">All {{ (workerLabelSetting$ | async)?.value }}s</div>
		</ng-option>
		<!-- other options -->
		<ng-option *ngFor="let worker of workersLookup" [value]="worker">
			<div class="d-flex align-items-center py-1">
				<img width="20" height="20" class="me-2" [ngSrc]="worker.profileImageUrl" alt="worker profile image"
					*ngIf="worker.profileImageUrl" />
				<span class="text-truncate">{{ worker.fullName }}</span>
			</div>
		</ng-option>
	</ng-select>

	<button type="button" class="btn btn-outline-secondary" (click)="onClose()" *ngIf="closeable">
		<fa-icon [icon]="closeIcon"></fa-icon>
	</button>
</div>
