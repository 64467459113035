<form #form="ngForm">
	<div class="modal-header px-4">
		<h5 class="modal-title">
			Rename File
		</h5>

		<button type="button" class="btn-close" (click)="onClose()">
			<span class="visually-hidden">&times;</span>
		</button>
	</div>

	<div class="modal-body px-4 py-3">
		<div class="row">
			<div class="col-24">
				<label class="form-label" for="fileName">
					New file name
				</label>

				<input #fileNameInput type="text" class="form-control" id="fileName" name="fileName" [(ngModel)]="fileName" [required]="true" placeholder="e.g. PlanPolicy" />
			</div>
		</div>
	</div>

	<div class="modal-footer bg-white px-4">
		<button type="button" class="btn btn-outline-primary" (click)="onClose()" [disabled]="renaming">
			Cancel
		</button>

		<button type="submit" class="btn btn-primary" (click)="onRename()" [disabled]="form.invalid || renaming">
			<fa-icon *ngIf="renaming" class="me-2" [icon]="faSpinnerThird" [spin]="true"></fa-icon>
			Rename
		</button>
	</div>
</form>
