<div [ngClass]="class">
    <div *ngIf="loading" class="placeholder-glow metrics-placeholder-glow">
        <div class="placeholder col-12"></div>
    </div>
    
    <div *ngIf="!loading" class="metrics-value pb-2">
        <span *ngIf="!isPercent && !isCurrency">{{ value | shortNumber }}</span>
        <span *ngIf="isPercent">{{ value }}%</span>
        <span *ngIf="isCurrency">{{ value | shortCurrency }}</span>        
    </div>
    
    <div class="metrics-label" [innerHtml]="label"></div>
</div>