import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { MembersService } from '@app/data/members/services';
import { MemberCase } from '@app/data/members/models';

import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';

@Component({
	selector: 'app-member-cases-list',
	templateUrl: 'member-cases-list.component.html',
	styleUrls: ['member-cases-list.component.scss'],
	exportAs: 'memberCases'
})
export class MemberCasesListComponent implements OnInit {
	faChevronRight = faChevronRight;

	/* =================== inputs/outputs ================== */
	@Input()
	get clientMemberID() { return this._clientMemberID; }
	set clientMemberID(value: number) {
		if (this._clientMemberID !== value) {
			this._clientMemberID = value;
			this.onReloadMemberCases();
		}
	}
	private _clientMemberID: number;

	/* =================== internal state ================== */
	loadingMemberCases: boolean = false;
	loadingMemberCasesError: boolean = false;
	memberCases: MemberCase[] = [];
	openMemberCases: MemberCase[] = [];
	closedMemberCases: MemberCase[] = [];
	showNewPlanForm: boolean = false;
	saving: boolean = false;

	constructor(
		private membersService: MembersService,
		private router: Router
	) {
	}

	async ngOnInit() {
	}

	async onReloadMemberCases() {
		try {
			this.loadingMemberCases = true;
			this.loadingMemberCasesError = false;

			this.memberCases = await this.membersService.getMemberCases(this.clientMemberID);
			this.openMemberCases = this.memberCases.filter(memberCase => memberCase.isOpen);
			this.closedMemberCases = this.memberCases.filter(memberCase => !memberCase.isOpen);
		} catch (error: any) {
			this.loadingMemberCasesError = true;
		} finally {
			this.loadingMemberCases = false;
		}
	}

	async onNavigateToCaseDetails(memberCase: MemberCase) {
		this.router.navigate(['/data/cases', memberCase.caseID]);
	}
}
