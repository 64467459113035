import { Component, OnInit, OnDestroy } from '@angular/core';

import { Subscription, Observable } from 'rxjs';

import { AuthService, User } from '@auth0/auth0-angular';

import { ProfileService } from '@app/shared/services';
import { UserProfile } from '@app/shared/models';

import { PortalRoles, PortalPermissions } from '@app/constants';

@Component({
	selector: 'app-header',
	templateUrl: 'header.component.html',
	styleUrls: ['header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
	private isAuthenticatedSubscription: Subscription;
	private userSubscription: Subscription;

	isAuthenticated: boolean = false;
	user: User;

	profile: UserProfile;
	isSystemAdministrator: boolean = false;
	profileSubscription: Subscription;

	constructor(
		private authService: AuthService,
		private profileService: ProfileService
	) {
	}

	ngOnInit() {
		this.profileSubscription = this.profileService.getUserProfile().subscribe(profile => {
			this.profile = profile;
			this.isSystemAdministrator = this.profileService.hasRole(PortalRoles.SystemAdministrator);
		});

		this.isAuthenticatedSubscription = this.authService.isAuthenticated$.subscribe(isAuthenticated => {
			this.isAuthenticated = isAuthenticated;
		});

		this.userSubscription = this.authService.user$.subscribe(user => {
			this.user = user;
		});
	}

	ngOnDestroy() {
		this.profileSubscription?.unsubscribe();
		this.isAuthenticatedSubscription?.unsubscribe();
		this.userSubscription?.unsubscribe();
	}
}
