<form #form="ngForm" class="needs-validation" novalidate>
	<fieldset [disabled]="saving">
		<div class="modal-header px-4">
			<h4 class="modal-title">
				New Member
			</h4>

			<button type="button" class="btn-close" (click)="onClose(form)">
				<span class="visually-hidden">&times;</span>
			</button>
		</div>

		<div class="modal-body bg-light px-0 py-4">
			<div class="container">
				<div class="row justify-content-center">
					<div class="col-20">
						<div class="card h-100">
							<div class="row g-0 h-100">
								<div class="col-md-7 h-100 card-cover">
									<h5 class="mb-0">Demographics</h5>
								</div>

								<div class="col-md-17 h-100 px-3">
									<div class="card-body">
										<div class="row mb-3"
											 [class.is-invalid]="firstName.touched && firstName.invalid">
											<label class="col-9 col-form-label" for="firstNameInput">
												First name *
											</label>

											<div class="col-15">
												<input #firstName="ngModel" type="text" class="form-control"
													   id="firstNameInput" name="firstNameInput" [(ngModel)]="model.firstName"
													   [required]="true" placeholder="First name" autocomplete="noautocomplete" />

												<div class="invalid-feedback">
													Member first name is required.
												</div>
											</div>
										</div>

										<div class="row mb-3" [class.is-invalid]="lastName.touched && lastName.invalid">
											<label class="col-9 col-form-label" for="lastNameInput">
												Last name *
											</label>

											<div class="col-15">
												<input #lastName="ngModel" type="text" class="form-control"
													   id="lastNameInput" name="lastNameInput" [(ngModel)]="model.lastName"
													   [required]="true" placeholder="Last name" autocomplete="noautocomplete" />

												<div class="invalid-feedback">
													Member last name is required.
												</div>
											</div>
										</div>

										<div class="row mb-3">
											<label class="col-9 col-form-label" for="dateOfBirthInput">
												Date of birth *
											</label>

											<div class="col-8">
												<input type="date" class="form-control"
													   id="dateOfBirthInput"
													   name="dateOfBirthInput"
													   [(ngModel)]="model.dateOfBirth"
													   [required]="true" />
											</div>
										</div>

										<div class="row mb-3">
											<label class="col-9 col-form-label" for="genderCode">
												Gender
											</label>

											<div class="col-8">
												<ng-select class="custom-ng-select" id="genderCode" name="genderCode"
														   [items]="genders" [bindLabel]="'name'" [bindValue]="'code'"
														   [(ngModel)]="model.genderCode" [clearable]="false"
														   [multiple]="false" [searchable]="false" [loading]="loadingGenders"
														   [loadingText]="'loading genders...'" [placeholder]="'Gender'">
												</ng-select>
											</div>
										</div>

										<div class="row mb-3">
											<label class="col-9 col-form-label" for="preferredLanguageCode">
												Preferred language
											</label>

											<div class="col-10">
												<ng-select class="custom-ng-select" id="preferredLanguageCode"
														   name="preferredLanguageCode" [items]="languages"
														   [bindLabel]="'name'" [bindValue]="'code'"
														   [(ngModel)]="model.preferredLanguageCode" [clearable]="false"
														   [multiple]="false" [searchable]="false" [loading]="loadingLanguages"
														   [loadingText]="'loading languages...'"
														   [placeholder]="'Preferred language'">
												</ng-select>
											</div>
										</div>

										<div class="row mb-3">
											<label class="col-sm-9 col-form-label" for="lastFourSSNInput">
												SSN (last 4)
											</label>

											<div class="col-sm-7">
												<div class="input-group">
													<span class="input-group-text">
														<span class="mx-2">•••</span><span class="mx-2">••</span>
													</span>
													<input id="lastFourSSNInput" name="lastFourSSNInput" type="text"
														   class="form-control" minlength="4" maxlength="4"
														   [(ngModel)]="model.lastFourSSN" placeholder="1234"
														   autocomplete="off" />
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="col-20 mt-4">
						<div class="card h-100">
							<div class="row g-0 h-100">
								<div class="col-md-7 h-100 card-cover">
									<h5 class="mb-0">Contact Information</h5>
								</div>

								<div class="col-md-17 h-100 px-3">
									<div class="card-body">
										<div class="row mb-2">
											<label class="col-9 col-form-label" for="address1Input">
												Address
											</label>

											<div class="col-15">
												<input type="text" class="form-control" id="address1Input" name="address1Input"
													   [(ngModel)]="model.address1" placeholder="Address 1"
													   autocomplete="noautocomplete" />
											</div>
										</div>

										<div class="row mb-2">
											<div class="col-15 offset-9">
												<input type="text" class="form-control" id="address2Input" name="address2Input"
													   [(ngModel)]="model.address2" placeholder="Address 2"
													   autocomplete="noautocomplete" />
											</div>
										</div>

										<div class="row mb-2">
											<div class="col-15 offset-9">
												<input type="text" class="form-control" id="cityInput" name="cityInput"
													   [(ngModel)]="model.city" placeholder="City" autocomplete="noautocomplete" />
											</div>
										</div>

										<div class="row mb-3">
											<div class="col-10 offset-9 pe-1">
												<ng-select class="custom-ng-select" id="stateSelect" name="stateSelect"
														   [items]="states" bindLabel="stateName" bindValue="stateCode"
														   [(ngModel)]="model.stateCode" [clearable]="false" [multiple]="false"
														   [searchable]="false" [loading]="loadingStates"
														   [loadingText]="'loading states...'"
														   notFoundText="no matching states"
														   placeholder="State">
												</ng-select>
											</div>

											<div class="col-5 ps-1">
												<app-zipcode-input id="zipCodeInput" name="zipCodeInput" [(ngModel)]="model.zipCode">
												</app-zipcode-input>
											</div>
										</div>

										<div class="row mb-3"
											 [class.is-invalid]="phoneNumber.touched && phoneNumber.invalid">
											<label class="col-9 col-form-label" for="phoneNumberInput">
												Phone number *
											</label>

											<div class="col-12">
												<app-phone-input #phoneNumber="ngModel" id="phoneNumberInput" name="phoneNumberInput" [usOnly]="false" [(ngModel)]="model.phoneNumber" [required]="true">
												</app-phone-input>

												<div class="invalid-feedback">
													Phone number is required.
												</div>
											</div>
										</div>

										<div class="row mb-3">
											<label class="col-9 col-form-label" for="mobileNumberInput">
												Mobile number
											</label>

											<div class="col-12">
												<app-phone-input #mobileNumber="ngModel" id="mobileNumberInput" name="mobileNumberInput" [usOnly]="false" [(ngModel)]="model.mobileNumber">
												</app-phone-input>
											</div>
										</div>

										<div class="row mb-3"
											 [class.is-invalid]="emailAddress.touched && emailAddress.invalid">
											<label class="col-9 col-form-label" for="emailAddressInput">
												Email address
											</label>

											<div class="col-15">
												<input #emailAddress="ngModel" type="text" class="form-control"
													   id="emailAddressInput" name="emailAddressInput"
													   [(ngModel)]="model.emailAddress"
													   placeholder="emailaddress@example.com" autocomplete="noautocomplete" />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="col-20 mt-4">
						<div class="card h-100">
							<div class="row g-0 h-100">
								<div class="col-md-7 h-100 card-cover">
									<h5 class="mb-0">Plan Information</h5>
								</div>

								<div class="col-md-17 h-100 px-3">
									<div class="card-body pb-2">
										<div class="row mb-3 field-required">
											<label class="col-9 col-form-label" for="clientPlanID">
												Healthcare plan
											</label>

											<div class="col-15">
												<app-client-plans-select [id]="'clientPlan'" [name]="'clientPlan'"
																		 [showAllOption]="false" [canSelectClient]="false"
																		 [showSelectedPlanClient]="true" placeholder="Healthcare plan"
																		 (clientPlanChange)="onClientPlanChange($event)">
												</app-client-plans-select>
											</div>
										</div>

										<div class="row mb-3" [class.field-required]="isExternalCodeRequired"
											 [class.is-invalid]="externalMemberCode.touched && externalMemberCode.invalid">
											<label class="col-9 col-form-label" for="externalMemberCode">
												Plan member number
											</label>

											<div class="col-9">
												<input #externalMemberCode="ngModel" type="text" class="form-control"
													   id="externalMemberCode" name="externalMemberCode"
													   [(ngModel)]="model.externalMemberCode"
													   [required]="isExternalCodeRequired" placeholder="3334444-121212"
													   autocomplete="off" />
												<div class="invalid-feedback">
													Plan member number is required.
												</div>
											</div>
										</div>

										<div class="row mb-3 field-required">
											<label class="col-9 col-form-label" for="effectiveDate">
												Effective date
											</label>

											<div class="col-8">
												<input type="date" class="form-control"
													   id="effectiveDate"
													   name="effectiveDate"
													   [(ngModel)]="model.effectiveDate"
													   [required]="true" />
											</div>
										</div>

										<div class="row mb-3">
											<label class="col-9 col-form-label" for="coverageTypeCode">
												Coverage type
											</label>

											<div class="col-10">
												<ng-select class="custom-ng-select" id="coverageTypeCode"
														   name="coverageTypeCode" [items]="coverageTypes" [bindLabel]="'name'"
														   [bindValue]="'code'" [(ngModel)]="model.coverageTypeCode"
														   [clearable]="false" [multiple]="false" [searchable]="false"
														   [loading]="loadingCoverageTypes"
														   [loadingText]="'loading coverage types...'"
														   [notFoundText]="'no coverage type matching your search'"
														   [placeholder]="'Coverage type'">
												</ng-select>
											</div>
										</div>

										<div class="row mb-2">
											<label class="col-9 col-form-label" for="groupName">
												Group (optional)
											</label>

											<div class="col-15">
												<input type="text" class="form-control" id="groupName" name="groupName"
													   [(ngModel)]="model.groupName" placeholder="Group name"
													   autocomplete="off" />
											</div>
										</div>

										<div class="row mb-3">
											<div class="col-9 offset-9">
												<input type="text" class="form-control" id="groupID" name="groupID"
													   [(ngModel)]="model.groupID" placeholder="Group ID"
													   autocomplete="off" />
											</div>
										</div>

										<div class="row">
											<label class="col-9 col-form-label" for="copay">
												Co-pay (optional)
											</label>

											<div class="col-8">
												<input type="text" class="form-control" id="copay" name="copay"
													   [(ngModel)]="model.copay" placeholder="$ 0.00" autocomplete="off"
													   currencyMask />
											</div>
										</div>
									</div>

									<div class="card-body rounded-3 py-2 mt-0 mb-3"
										 [class.bg-light]="isDependentMember">
										<div class="row mb-3">
											<label class="col-9 col-form-label" for="isDependentMember">
												Is dependent member?
											</label>

											<div class="col-8">
												<app-switch [id]="'isDependentMember'" [name]="'isDependentMember'"
															[(value)]="isDependentMember"
															(save)="onIsDependentMemberChange($event)"></app-switch>
											</div>
										</div>

										<ng-container *ngIf="isDependentMember">
											<div class="row mb-3 field-required">
												<label class="col-9 col-form-label" for="primaryClientMemberPlanID">
													Primary member
												</label>

												<div class="col-15">
													<app-member-select #primarySelect id="primarySelect"
																	   name="primarySelect" [clientID]="clientID"
																	   [clientPlanID]="model.clientPlanID" [closeable]="false"
																	   (memberChange)="onPrimaryChange($event)"
																	   placeholder="Primary member"
																	   aria-describedby="primarySelectWarning"
																	   [disabled]="!model.clientPlanID">
													</app-member-select>
													<div id="primarySelectWarning" class="form-text" *ngIf="!model.clientPlanID">Please select a 'Healthcare plan' for the new member to be able to link it to the primary member.</div>
												</div>
											</div>

											<div class="row field-required">
												<label class="col-9 col-form-label" for="dependentRelationshipTypeCode">
													Relationship to primary
												</label>
												<div class="col-9">
													<ng-select class="custom-ng-select"
															   id="dependentRelationshipTypeCode"
															   name="dependentRelationshipTypeCode" [items]="relationshipTypes"
															   [bindLabel]="'name'" [bindValue]="'code'"
															   [(ngModel)]="model.dependentRelationshipTypeCode"
															   [clearable]="false" [multiple]="false" [searchable]="false"
															   [loading]="loadingRelationshipTypes"
															   [loadingText]="'loading relationship types...'"
															   placeholder="Relationship to primary">
													</ng-select>
												</div>
											</div>
										</ng-container>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="col-20 mt-4">
						<div class="card h-100">
							<div class="row g-0 h-100">
								<div class="col-md-7 h-100 card-cover">
									<h5 class="mb-0">Other Services</h5>
								</div>

								<div class="col-md-17 h-100 px-3">
									<div class="card-body">
										<div class="row mb-3">
											<label class="col-9 col-form-label" for="includesCoverdell">
												Includes Coverdell?
											</label>

											<div class="col-8">
												<app-switch [id]="'includesCoverdell'" [name]="'includesCoverdell'"
															[(value)]="model.includesCoverdell" (save)="$event.saved(true)"></app-switch>
											</div>
										</div>

										<div class="row mb-3" [class.field-required]="model.includesCoverdell">
											<label class="col-9 col-form-label" for="externalMemberCode">
												Coverdell member ID
											</label>

											<div class="col-9">
												<input #coverdellMemberID="ngModel" id="coverdellMemberID" name="coverdellMemberID" type="text" class="form-control"
													   [(ngModel)]="model.coverdellMemberID"
													   [required]="model.includesCoverdell"
													   autocomplete="off" />
												<div class="invalid-feedback">
													Coverdell member ID is required.
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<!-- validation errors alert -->
				<div class="row justify-content-center mt-4">
					<div class="col-sm-20">
						<app-validation [errors]="validationErrors"></app-validation>
					</div>
				</div>
			</div>

			<!-- bottom spacer to prevent footer from overlapping dropdown content -->
			<div style="height: 75px;"></div>
		</div>


		<div class="modal-footer bg-white px-4">
			<button type="button" class="btn btn-outline-primary" (click)="onClose(form)" [disabled]="saving">
				Cancel
			</button>

			<button type="submit" class="btn btn-primary" (click)="onSave(form)" [disabled]="saving">
				Save Member <app-spinner-wheel class="ms-1" *ngIf="saving"></app-spinner-wheel>
			</button>
		</div>
	</fieldset>
</form>
