<ng-select id="{{ id }}" name="{{ name }}" class="custom-ng-select" [ngClass]="class" [placeholder]="placeholder" [clearable]="false" [searchable]="true" notFoundText="no matching clients/plans" loadingText="loading clients/plans..." [loading]="clientPlansLoading" [readonly]="clientPlansLoading || disabled" [(ngModel)]="selectedClientPlan" [searchFn]="onSearch" (ngModelChange)="onClientPlanChange($event)">
	<!-- all option -->
	<ng-option [value]="allClientsPlansOption" *ngIf="showAllOption">
		<div class="fw-bold py-2 border-bottom-1">All Clients/Plans</div>
	</ng-option>
	<!-- other options -->
	<ng-option [class.fw-bold]="!clientPlan.planID" [value]="clientPlan" *ngFor="let clientPlan of flatClientPlans; let isLast = last; let index = index" [disabled]="!clientPlan.planID && !canSelectClient">
		<div class="d-flex align-items-center py-1" [class.option-client]="!clientPlan.planID" [class.option-plan]="clientPlan.planID" [class.option-last]="isLast || !flatClientPlans[index+1].planID">
			<img width="20" height="auto" class="me-2" [src]="clientPlan.clientLogoUrl" *ngIf="!clientPlan.planID && clientPlan.clientLogoUrl" />
			<span [class.plan-name]="clientPlan.planID">{{ clientPlan.label }}</span>
		</div>
	</ng-option>

	<!-- selected item template -->
	<ng-template ng-label-tmp let-clientPlan="item">
		<div class="fw-bold py-1" *ngIf="!clientPlan.clientID && !clientPlan.planID">
			{{ clientPlan.label }}
		</div>
		<div class="d-flex align-items-center py-1" *ngIf="clientPlan.clientID || clientPlan.planID">
			<img width="20" height="auto" class="me-2" [src]="clientPlan.clientLogoUrl" *ngIf="clientPlan.clientLogoUrl" />
			<span *ngIf="!showSelectedPlanClient">{{ clientPlan.label }}</span>
			<span *ngIf="showSelectedPlanClient">{{ clientPlan.clientName }}<span *ngIf="!clientPlan.planName"> (all plans)</span><span class="plan-name text-muted" *ngIf="clientPlan.planName"> - {{ clientPlan.planName }}</span></span>
		</div>
	</ng-template>
</ng-select>
