import { Component, OnInit, Input, Output, EventEmitter, Optional } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';

import { CaseFacilityProvider } from '@app/data/cases/models';

import { LookupService } from '@app/settings/services';
import { LookupValue, Specialty, Procedure } from '@app/settings/models';
import { Messages } from '@app/constants';

@Component({
	selector: 'app-create-facility-provider',
	templateUrl: 'create-facility-provider.component.html',
	styleUrls: ['create-facility-provider.component.scss'],
	viewProviders: [{
		provide: ControlContainer,
		deps: [[Optional, NgForm]],
		useFactory: (ngForm: NgForm) => ngForm
	}]
})
export class CaseCreateFacilityProviderComponent implements OnInit {
	@Input()
	case: CaseFacilityProvider;

	@Output()
	caseChange: EventEmitter<CaseFacilityProvider> = new EventEmitter<CaseFacilityProvider>();

	constructor(
		private lookupService: LookupService,
		private toastrService: ToastrService
	) {
	}

	ngOnInit() {
		this.onReloadFacilityTypes();
		this.onReloadGenders();
		this.onReloadPriorities();
	}

	loadingFacilityTypes: boolean = false;
	facilityTypes: LookupValue[];
	async onReloadFacilityTypes() {
		try {
			this.loadingFacilityTypes = true;

			this.facilityTypes = await this.lookupService.getLookupValues('FacilityType');
		} catch (error: any) {
			console.log(error);
			this.toastrService.error(Messages.ErrorRetry, `Failed to Load Facility Types`);
		} finally {
			this.loadingFacilityTypes = false;
		}
	}

	loadingGenders: boolean = false;
	genders: LookupValue[];
	async onReloadGenders() {
		try {
			this.loadingGenders = true;

			this.genders = await this.lookupService.getLookupValues('Gender');
		} catch (error: any) {
			this.toastrService.error(Messages.ErrorRetry, `Failed to Load Genders`);
		} finally {
			this.loadingGenders = false;
		}
	}

	loadingPriorities: boolean = false;
	priorities: LookupValue[];
	async onReloadPriorities() {
		try {
			this.loadingPriorities = true;

			this.priorities = await this.lookupService.getLookupValues('ComparisonPriority');
			this.case.priorities = this.priorities.map(m => ({ priorityCode: m.code, priorityName: m.name, priorityOrder: m.sortOrder }));
		} catch (error: any) {
			this.toastrService.error(Messages.ErrorRetry, `Failed to Load Priorities`);
		} finally {
			this.loadingPriorities = false;
		}
	}

	async onSpecialtyChange(speciality: Specialty) {
		this.case.specialtyCode = speciality?.code || null;
	}

	async onProcedureChange(procedure: Procedure) {
		this.case.cptCode = procedure?.cptCode || null;
	}

	async onPrioritiesChange(event: any) {
		this.case.priorities = [...event.items];
	}
}
