import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';

import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription, tap, catchError, throwError } from 'rxjs';

import { LookupService } from '@app/shared/services/lookup.service';
import { PortalUserLookup } from '@app/shared/models/lookup.models';

import { Messages, LookupCodes } from '@app/constants';

@Component({
	selector: 'app-worker-dropdown',
	templateUrl: 'worker-dropdown.component.html',
	styleUrls: ['worker-dropdown.component.scss']
})
export class WorkerDropdownComponent implements OnInit, OnDestroy {
	@Input() id: string = 'workerDropdown';
	@Input() class: string;
	@Input() dropdownText: string;
	@Input() rightAligned: boolean = false;
	@Input() disabled: boolean = false;
	@Input() updating: boolean = false;

	@Output() select: EventEmitter<PortalUserLookup> = new EventEmitter<PortalUserLookup>();

	/* ======= lookups ===================================== */
	loadingWorkersLookup: boolean = false;
	workersLookup$: Observable<PortalUserLookup[]>;
	// workersLookup: PortalUserLookup[];
	// workersLookupSubscription: Subscription;

	constructor(
		private toastrService: ToastrService,
		private lookupService: LookupService,
	) {

	}

	ngOnInit() {
		// this.workersLookupSubscription =
		this.workersLookup$ = this.lookupService.getLookup<PortalUserLookup>(LookupCodes.PortalUsers)
			.pipe(
				tap(() => setTimeout(() => this.loadingWorkersLookup = false, 0)),
				catchError(err => {
					setTimeout(() => this.loadingWorkersLookup = false, 0);
					return this.showLookupErrorToast('Workers', err);
				}));
			// // fetch ahead of time so work around the late binding vs alignment bug on the dropdown
			// .subscribe(data => this.workersLookup = data);
	}

	ngOnDestroy() {
		// this.workersLookupSubscription?.unsubscribe();
	}

	onSelectWorker(worker: PortalUserLookup) {
		this.select?.emit(worker);
	}

	private showLookupErrorToast(lookupName: string, error: any) {
		this.toastrService.error(Messages.ErrorRetry, `Failed to Load '${lookupName}'`);
		return throwError(() => error);
	}
}
