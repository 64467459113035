<div class="order-edit">
	<ng-container *ngIf="!showEditor && inlineEdit">
		<div class="d-flex align-items-center c-pointer">
			<div class="inline-edit-value">
				<ng-container *ngFor="let item of items; let first = first">
					<span class="badge rounded-pill text-bg-light me-1" [class.ms-3]="!first">{{ item[orderProperty] }}</span> {{ item[labelProperty] }}
				</ng-container>
			</div>

			<button type="button" class="btn btn-sm btn-inline-edit ms-1" [tooltip]="'Edit'" (click)="onShowEditor()" [disabled]="readonly">
				<fa-icon [icon]="faPencilAlt"></fa-icon>
			</button>
		</div>
	</ng-container>

	<ng-container *ngIf="showEditor || !inlineEdit">
		<div class="input-group">
			<div class="form-control order-edit-container p-1" cdkDropList [cdkDropListData]="items" [cdkDropListOrientation]="vertical ? 'vertical' : 'horizontal'" [class.vertical]="vertical" (cdkDropListDropped)="onDrop($event)">
				<ng-container *ngFor="let item of items; let last = last">
					<div cdkDrag class="badge bg-light text-body" [class.me-1]="!last">
						<fa-icon [icon]="faGripDotsVertical"></fa-icon>
						{{ item[orderProperty] }}. {{ item[labelProperty] }}
					</div>
				</ng-container>
			</div>

			<ng-container *ngIf="inlineEdit">
				<button type="button" class="btn btn-sm btn-outline-primary py-0" (click)="onSave()" [disabled]="readonly">
					<fa-icon *ngIf="!saving" [icon]="faCheck"></fa-icon>
					<fa-icon *ngIf="saving" [icon]="faSpinnerThird" [spin]="true"></fa-icon>
				</button>

				<button type="button" class="btn btn-sm btn-outline-primary py-0" (click)="onHideEditor()">
					<fa-icon [icon]="faTimes"></fa-icon>
				</button>
			</ng-container>
		</div>
	</ng-container>
</div>
