import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-metrics-number',
	templateUrl: 'metrics-number.component.html',
	styleUrls: ['metrics-number.component.scss']
})
export class MetricsNumberComponent {
    @Input() label: string;
	@Input() value: number;
	@Input() isPercent: boolean = false;
	@Input() isCurrency: boolean = false;
	@Input() class: string = '';
    @Input() loading: boolean = false;	
}
