import { Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common';

@Pipe({
	name: 'fileSize'
})
export class FileSizePipe implements PipeTransform {
	transform(fileSize: number, fractionDigits: number = 1, inputSize: 'B' | 'KB' | 'MB' | 'GB' | 'TB' = 'B'): string {
		if (!fileSize) {
			return `${fileSize}`;
		}

		let fileSizeBytes = fileSize;
		if (inputSize == 'KB') {
			fileSizeBytes = fileSize * 1000;
		} else if (inputSize == 'MB') {
			fileSizeBytes = fileSize * 1000 * 1000;
		} else if (inputSize == 'GB') {
			fileSizeBytes = fileSize * 1000 * 1000 * 1000;
		} else if (inputSize == 'TB') {
			fileSizeBytes = fileSize * 1000 * 1000 * 1000 * 1000;
		}

		if (fileSizeBytes >= 1000 * 1000 * 1000 * 1000) {
			return `${formatNumber(fileSizeBytes / 1000 / 1000 / 1000 / 1000, 'en-US', `1.0-${fractionDigits}`)}TB`;
		}

		if (fileSizeBytes >= 1000 * 1000 * 1000) {
			return `${formatNumber(fileSizeBytes / 1000 / 1000 / 1000, 'en-US', `1.0-${fractionDigits}`)}GB`;
		}

		if (fileSizeBytes >= 1000 * 1000) {
			return `${formatNumber(fileSizeBytes / 1000 / 1000, 'en-US', `1.0-${fractionDigits}`)}MB`;
		}

		if (fileSizeBytes >= 1000) {
			return `${formatNumber(fileSizeBytes / 1000, 'en-US', `1.0-${fractionDigits}`)}KB`;
		}

		return `${formatNumber(fileSizeBytes, 'en-US', `1.0-${fractionDigits}`)}B`;
	}
}
