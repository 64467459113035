<div class="row g-0">
	<div class="col">
		<div class="row">
			<label class="col-9 col-form-label" for="drugName">
				Drug name
			</label>

			<div class="col-15">
				<div class="medication-select-container d-flex align-items-center gap-2">
					<input type="search" class="medication-select-input form-control" id="drugName" name="drugName"
						   [class.no-clear]="medicationsLoading"
						   [(ngModel)]="drugName"
						   [typeahead]="medications$"
						   [typeaheadAsync]="true"
						   [typeaheadWaitMs]="300"
						   [typeaheadMinLength]="3"
						   [typeaheadOptionField]="'drugName'"
						   [typeaheadScrollable]="true"
						   [typeaheadOptionsInScrollableView]="5"
						   [typeaheadSelectFirstItem]="false"
						   [typeaheadIsFirstItemActive]="false"
						   [typeaheadItemTemplate]="drugNameItemTemplate"
						   [typeaheadOptionsLimit]="999"
						   [isAnimated]="false"
						   [adaptivePosition]="false"
						   (typeaheadLoading)="onDrugNameTypeaheadLoading($event)"
						   (typeaheadOnSelect)="onDrugNameTypeaheadSelect($event)"
						   placeholder="Drug name"
						   autocomplete="off" />

					<app-spinner-wheel *ngIf="medicationsLoading" class="medication-select-input-spinner"></app-spinner-wheel>

					<ng-template #drugNameItemTemplate let-drug="item" let-index="index">
						<div class="text-nowrap text-truncate">
							{{ drug.drugName }} <span *ngIf="drug.brandName" class="text-muted">({{ drug.brandName }})</span>
						</div>
					</ng-template>
				</div>
			</div>
		</div>

		<div class="row mt-3">
			<label class="col-9 col-form-label" for="brandName">
				Brand
			</label>

			<div class="col-15">
				<div class="medication-select-container d-flex align-items-center gap-2">
					<input type="search" class="medication-select-input form-control" id="brandName" name="brandName"
						   [class.no-clear]="loadingDrugDetails"
						   [(ngModel)]="brandName"
						   [typeahead]="drugBrands"
						   [typeaheadMinLength]="0"
						   [typeaheadOptionField]="'name'"
						   [typeaheadScrollable]="true"
						   [typeaheadOptionsInScrollableView]="5"
						   [typeaheadSelectFirstItem]="false"
						   [typeaheadIsFirstItemActive]="false"
						   [typeaheadOptionsLimit]="999"
						   [isAnimated]="false"
						   [adaptivePosition]="false"
						   (typeaheadOnSelect)="onBrandNameTypeaheadSelect($event)"
						   placeholder="Brand"
						   autocomplete="off" />

					<app-spinner-wheel *ngIf="loadingDrugDetails" class="medication-select-input-spinner"></app-spinner-wheel>
				</div>
			</div>
		</div>

		<div class="row mt-3">
			<label class="col-9 col-form-label" for="form">
				Form
			</label>

			<div class="col-15">
				<div class="medication-select-container d-flex align-items-center gap-2">
					<input type="search" class="medication-select-input form-control" id="form" name="form"
						   [(ngModel)]="form"
						   [typeahead]="drugForms"
						   [typeaheadMinLength]="0"
						   [typeaheadOptionField]="'name'"
						   [typeaheadScrollable]="true"
						   [typeaheadOptionsInScrollableView]="5"
						   [typeaheadSelectFirstItem]="false"
						   [typeaheadIsFirstItemActive]="false"
						   [typeaheadOptionsLimit]="999"
						   [isAnimated]="false"
						   [adaptivePosition]="false"
						   (typeaheadOnSelect)="onFormTypeaheadSelect($event)"
						   placeholder="Form"
						   autocomplete="off" />

					<app-spinner-wheel *ngIf="loadingDrugDetails" class="medication-select-input-spinner"></app-spinner-wheel>
				</div>
			</div>
		</div>

		<div class="row mt-3">
			<label class="col-9 col-form-label" for="dosage">
				Dosage
			</label>

			<div class="col-15">
				<div class="medication-select-container d-flex align-items-center gap-2">
					<input type="search" class="medication-select-input form-control" id="dosage" name="dosage"
						   [(ngModel)]="dosage"
						   [typeahead]="drugDosages"
						   [typeaheadMinLength]="0"
						   [typeaheadOptionField]="'name'"
						   [typeaheadScrollable]="true"
						   [typeaheadOptionsInScrollableView]="5"
						   [typeaheadSelectFirstItem]="false"
						   [typeaheadIsFirstItemActive]="false"
						   [typeaheadOptionsLimit]="999"
						   [isAnimated]="false"
						   [adaptivePosition]="false"
						   placeholder="Dosage"
						   autocomplete="off" />

					<app-spinner-wheel *ngIf="loadingDrugDetails" class="medication-select-input-spinner"></app-spinner-wheel>
				</div>
			</div>
		</div>

		<div class="row mt-3">
			<label class="col-9 col-form-label" for="quantity">
				Quantity
			</label>

			<div class="col-6">
				<input type="text" class="form-control"
					   id="quantity"
					   name="quantity"
					   [(ngModel)]="quantity"
					   placeholder="Quantity"
					   style="max-width: 150px" />
			</div>
		</div>

		<div class="row mt-3">
			<label class="col-9 col-form-label" for="frequency">
				Frequency
			</label>

			<div class="col-6">
				<input type="text" class="form-control"
					   id="frequency"
					   name="frequency"
					   [(ngModel)]="frequency"
					   placeholder="Frequency" />
			</div>
		</div>

		<div class="row mt-3">
			<label class="col-9 col-form-label" for="brandNameOnly">
				Brand name only?
			</label>

			<div class="col-6">
				<app-switch [id]="'brandNameOnly'"
							[name]="'brandNameOnly'"
							[(value)]="brandNameOnly"
							(save)="onBrandNameOnlyChange($event)">
				</app-switch>
			</div>
		</div>
	</div>
</div>
