<div class="card">
	<div class="card-body p-0">
		<app-aside-widget #filesAsideWidget [title]="title" [loading]="loadingFiles" [count]="files?.length ?? 0"
						  (retry)="onReloadFiles()">
			<ng-template #asideWidgetActions>
				<button type="button" class="btn btn-sm btn-primary" (click)="onShowNewFileModal()">
					New File
				</button>
			</ng-template>

			<ng-container *ngIf="!loadingFiles && !loadingFilesError">
				<ng-container *ngIf="files?.length == 0">
					<div class="mx-3 mb-3">
						<app-empty class="bg-light rounded" [message]="'no ' + title.toLowerCase()"></app-empty>
					</div>
				</ng-container>

				<ng-container *ngIf="files?.length > 0">
					<div class="card border-0">
						<ul class="list-group list-group-flush">
							<li class="list-group-item bg-light-hover c-pointer px-3" [class.pt-2]="!isFirst || isLast" [class.pb-2]="isFirst || !isLast"
								*ngFor="let file of files; let isFirst = first; let isLast = last" (click)="onViewFile(file)">
								<!-- TODO: MOVE INTO TEMPLATE FOR READABILITY -->
								<!-- <ng-container *ngTemplateOutlet="fileTemplate; context: { file: file }"></ng-container> -->

								<div class="d-flex align-items-start">
									<div class="flex-grow-1 text-truncate">
										{{ file.fileName }}
									</div>

									<div dropdown class="btn-group" container="body">
										<button class="btn btn-icon-only px-1" type="button" dropdownToggle
												(click)="$event.stopPropagation()">
											<fa-icon [icon]="faEllipsisVertical"></fa-icon>
										</button>

										<div class="dropdown-menu dropdown-menu-end" *dropdownMenu>
											<button class="dropdown-item" type="button"
													(click)="onDownloadFile(file)">
												Download
											</button>
							<li class="divider dropdown-divider"></li>
							<button class="dropdown-item" type="button"
									(click)="onRenameFile(file)">
								Rename
							</button>
							<button class="dropdown-item text-danger" type="button"
									(click)="onDeleteFile(file)">
								Delete
							</button>
					</div>
	</div>
</div>
<hr class="my-2 w-75">
<div class="text-muted small">
	{{ file.fileDate | date:'M/d/yyyy' }}
	<app-field-separator></app-field-separator>
	{{ (file.fileType || '???') | uppercase }} ({{ file.sizeKB | fileSize:1:'KB' }})
	<app-field-separator></app-field-separator>
	uploaded on {{ file.dateCreated | date:'M/d/yyyy' }}
</div>
			</li>
			</ul>
			</div>
		</ng-container>
	</ng-container>
</app-aside-widget>
	</div>
</div>
