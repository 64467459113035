import { Component, OnInit, Input, Output, EventEmitter, Optional } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

import { CasePreNegotiation } from '@app/data/cases/models';
import { Procedure } from '@app/settings/models';

import { faXmark, faPlus } from '@fortawesome/pro-solid-svg-icons';

@Component({
	selector: 'app-create-pre-negotiation',
	templateUrl: 'create-pre-negotiation.component.html',
	styleUrls: ['create-pre-negotiation.component.scss'],
	viewProviders: [{
		provide: ControlContainer,
		deps: [[Optional, NgForm]],
		useFactory: (ngForm: NgForm) => ngForm
	}]
})
export class CaseCreatePreNegotiationComponent implements OnInit {
	faXmark = faXmark;
	faPlus = faPlus;

	@Input()
	case: CasePreNegotiation;

	@Output()
	caseChange: EventEmitter<CasePreNegotiation> = new EventEmitter<CasePreNegotiation>();

	constructor(
	) {
	}

	ngOnInit() {
	}

	async onProcedureChange(procedure: Procedure) {
		this.case.procedureCPTCode = procedure?.cptCode || null;

		this.caseChange.emit(this.case);
	}
}
