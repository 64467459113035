import { SafePipe } from './pipes/safe.pipe';
import { PhonePipe } from './pipes/phone.pipe';
import { FileSizePipe } from './pipes/file-size.pipe';
import { ShortCurrencyPipe } from './pipes/short-currency.pipe';
import { ShortNumberPipe } from './pipes/short-number.pipe';
import { DateOnlyPipe } from './pipes/date-only.pipe';
import { TimePipe } from './pipes/time.pipe';

export { SafePipe } from './pipes/safe.pipe';
export { PhonePipe } from './pipes/phone.pipe';
export { FileSizePipe } from './pipes/file-size.pipe';
export { ShortCurrencyPipe } from './pipes/short-currency.pipe';
export { ShortNumberPipe } from './pipes/short-number.pipe';
export { DateOnlyPipe } from './pipes/date-only.pipe';
export { TimePipe } from './pipes/time.pipe';

export const PIPES: any[] = [
	SafePipe,
	PhonePipe,
	FileSizePipe,
	ShortCurrencyPipe,
	ShortNumberPipe,
	DateOnlyPipe,
	TimePipe
];
