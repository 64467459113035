<div class="input-group">
	<ng-select [id]="id"
			   [name]="name"
			   class="custom-ng-select"
			   [items]="filteredMembers"
			   bindLabel="fullName"
			   [virtualScroll]="true"
			   (scroll)="onDropdownScroll($event)"
			   (scrollToEnd)="onDropdownScrollToEnd()"
			   (search)="membersTypeahead.next($event.term)"
			   [(ngModel)]="selectedMember"
			   (ngModelChange)="onMemberChange($event)"
			   [clearable]="false"
			   [searchable]="true"
			   typeToSearchText="type to search members"
			   [loading]="loadingMembers"
			   loadingText="loading members..."
			   notFoundText="no matching members"
			   placeholder="{{ placeholder }}"
			   [readonly]="disabled">

		<!-- header template -->
		<ng-template ng-header-tmp>
			<a class="btn btn-link px-2" (click)="onAddNewMember()">
				<fa-icon class="me-2" [icon]="plusIcon"></fa-icon>
				New Member
			</a>
		</ng-template>
		<!-- dropdown option template -->
		<ng-template ng-option-tmp let-member="item">
			<div>
				<div class="text-nowrap">
					{{ member.fullName }} <span class="text-muted ms-1">({{ member.memberCode }})</span>
				</div>
				<div class="text-muted text-nowrap small">
					<span>{{ member.dateOfBirth | date:'M/d/yyyy' }}</span><span class="ms-1" *ngIf="member.genderName">({{ member.genderName }})</span> <app-field-separator></app-field-separator>
					<span class="ms-1">{{ member.city ? member.city + ',' : '' }} {{ member.stateCode }} {{ member.zipCode }}</span>
				</div>
			</div>
		</ng-template>
	</ng-select>

	<ng-container *ngIf="closeable">
		<button type="button" class="btn btn-outline-secondary" (click)="onClose()">
			<fa-icon [icon]="closeIcon"></fa-icon>
		</button>
	</ng-container>
</div>
