<div class="card h-100">
	<div class="row g-0 h-100">
		<div class="col-md-7 h-100 card-cover">
			<h5 class="mb-0">Patient Information</h5>
		</div>

		<div class="col-md-17 h-100 px-3">
			<div class="card-body pb-2">
				<div class="row field-required">
					<label class="col-9 col-form-label" for="clientPlanID">
						Healthcare plan
					</label>
					<div class="col-15">
						<app-client-plans-select id="clientPlan" name="clientPlan"
												 placeholder="Healthcare plan"
												 [clientID]="case.clientID"
												 [clientPlanID]="case.clientPlanID"
												 [showAllOption]="false"
												 [canSelectClient]="false"
												 [showSelectedPlanClient]="true"
												 (clientPlanChange)="onClientPlanChange($event)">
						</app-client-plans-select>
					</div>

					<!-- TODO: CONNECT TO THE SELECTED PLAN'S INCLUDED SERVICES -->
					<!-- <div class="col-24">
						<div class="alert alert-warning mt-3 mb-0">
							Pivot Health - Anchor plan does not include Bill Negotiation cases.
						</div>
					</div> -->
				</div>
			</div>

			<div class="card-body rounded-3 py-2 mt-0"
				[class.bg-light]="selectedPatient || loadingSelectedPatiendDetails">
				<div class="row field-required">
					<label class="col-9 col-form-label" for="selectedPatient">
						Patient
					</label>
					<div class="col-15">
						<app-member-select #patientSelect="memberSelect" id="selectedPatient" name="selectedPatient"
							[clientID]="case.clientID" [clientPlanID]="case.clientPlanID"
							[defaultMember]="defaultMember" [closeable]="false" (memberChange)="onPatientChange($event)"
							[disabled]="!case.clientPlanID">
						</app-member-select>
					</div>
				</div>

				<ng-container *ngIf="selectedPatient || loadingSelectedPatiendDetails">
					<hr />

					<ng-container *ngIf="loadingSelectedPatiendDetails">
						<div class="py-5">
							<app-spinner [message]="'loading ' + selectedPatientFullName + ' details'"></app-spinner>
						</div>
					</ng-container>

					<ng-container *ngIf="!loadingSelectedPatiendDetails">
						<div class="row mb-3">
							<div class="col-auto">
								<label class="form-label">
									Date of birth
								</label>
								<div>{{ selectedPatient.dateOfBirth | date:'M/d/yyyy' }}</div>
							</div>

							<div class="col-auto">
								<label class="form-label">
									Gender
								</label>
								<div *ngIf="selectedPatient.genderName; else emptyValue">{{ selectedPatient.genderName
									}}</div>
							</div>

							<div class="col-auto">
								<label class="form-label">
									Preferred language
								</label>
								<div *ngIf="selectedPatient.preferredLanguageName; else emptyValue">{{
									selectedPatient.preferredLanguageName }}</div>
							</div>

							<div class="col-auto">
								<label class="form-label">
									SSN (last 4)
								</label>
								<div *ngIf="selectedPatient.lastFourSSN; else emptyValue">••• •• {{
									selectedPatient.lastFourSSN }}</div>
							</div>

							<div class="col-auto">
								<label class="form-label">
									Is deceased?
								</label>
								<div>{{ (selectedPatient.isDeceased ? 'Yes' : 'No') }}</div>
							</div>
						</div>

						<div class="row">
							<div class="col-auto">
								<label class="form-label">
									Address
								</label>
								<!-- TODO: PRECOMPUTE ADDRESS STRINGS -->
								<ng-container *ngIf="selectedPatient.city || selectedPatient.zipCode; else emptyValue">
									<div *ngIf="selectedPatient.address1 || selectedPatient.address2">
										{{ selectedPatient.address1 + (selectedPatient.address2 ? ', ' +
										selectedPatient.address2 : '') }}
									</div>
									<div>{{ selectedPatient.city ? selectedPatient.city + ',' : '' }} {{
										selectedPatient.stateCode || '' }} {{ selectedPatient.zipCode || '' }}</div>
								</ng-container>
							</div>

							<div class="col-auto">
								<label class="form-label">
									Phone number
								</label>
								<div *ngIf="selectedPatient.phoneNumber; else emptyValue">
									<a href="tel:{{ (selectedPatient.phoneNumber?.length > 0 && selectedPatient.phoneNumber[0] === '+' ? selectedPatient.phoneNumber : '+1'+selectedPatient.phoneNumber) }}">
										{{ selectedPatient.phoneNumber | phone }}
									</a>
								</div>
							</div>

							<div class="col-auto">
								<label class="form-label">
									Mobile number
								</label>
								<div *ngIf="selectedPatient.mobileNumber; else emptyValue">
									<a href="tel:{{ (selectedPatient.mobileNumber?.length > 0 && selectedPatient.mobileNumber[0] === '+' ? selectedPatient.mobileNumber : '+1'+selectedPatient.mobileNumber) }}">
										{{ selectedPatient.mobileNumber | phone }}
									</a>
								</div>
							</div>

							<div class="col-auto">
								<label class="form-label">
									Email address
								</label>
								<div *ngIf="selectedPatient.emailAddress; else emptyValue">
									<a href="mailto:{{ selectedPatient.emailAddress }}">
										{{ selectedPatient.emailAddress }}
									</a>
								</div>
							</div>
						</div>
					</ng-container>
				</ng-container>
			</div>

			<div class="card-body rounded-3 py-2 mt-0"
				[ngClass]="{'bg-light mt-3 mb-3': selectedPatientContactIsOther }">
				<div class="row">
					<label class="col-9 col-form-label" for="selectedPatientContact">
						Patient contact
					</label>

					<div class="col-15">
						<ng-select class="custom-ng-select patient-contacts-select" id="patientContact"
							name="patientContact" [items]="selectedPatientDependents" [bindLabel]="'fullName'"
							[(ngModel)]="selectedPatientContact" (ngModelChange)="onPatientContactChange($event)"
							[clearable]="true" [multiple]="false" [searchable]="false"
							[loading]="loadingSelectedPatientDependents" [loadingText]="'loading patient dependants...'"
							[disabled]="!selectedPatient" [placeholder]="'Patient contact'">
							<ng-template ng-option-tmp let-member="item">
								<div>
									<div class="text-nowrap">
										{{ member.fullName }} <span *ngIf="member.externalID"
											class="text-muted ps-1">({{ member.externalID }})</span>
									</div>
								</div>
							</ng-template>
						</ng-select>
					</div>
				</div>

				<ng-container *ngIf="selectedPatientContactIsOther">
					<hr />

					<div class="row mb-3">
						<label class="col-9 col-form-label" for="otherContactName">
							Contact name
						</label>

						<div class="col-15">
							<input type="text" class="form-control" id="otherContactName" name="otherContactName" [(ngModel)]="case.otherContactName" placeholder="Contact name" autocomplete="off" />
						</div>
					</div>

					<div class="row mb-3">
						<label class="col-9 col-form-label" for="otherContactGenderCode">
							Gender
						</label>

						<div class="col-8">
							<ng-select class="custom-ng-select" id="otherContactGenderCode"
								name="otherContactGenderCode" [items]="genders" [bindLabel]="'name'"
								[bindValue]="'code'" [(ngModel)]="case.otherContactGenderCode" [clearable]="false"
								[multiple]="false" [searchable]="false" [loading]="loadingGenders"
								[loadingText]="'loading genders...'" [placeholder]="'Gender'">
							</ng-select>
						</div>
					</div>

					<div class="row mb-3">
						<label class="col-9 col-form-label" for="otherContactRelationshipTypeCode">
							Relationship
						</label>

						<div class="col-10">
							<ng-select class="custom-ng-select" id="otherContactRelationshipTypeCode"
								name="otherContactRelationshipTypeCode" [items]="relationshipTypes" [bindLabel]="'name'"
								[bindValue]="'code'" [(ngModel)]="case.otherContactRelationshipTypeCode"
								[clearable]="false" [multiple]="false" [searchable]="false"
								[loading]="loadingRelationshipTypes" [loadingText]="'loading relationship types...'"
								[placeholder]="'Relationship'">
							</ng-select>
						</div>
					</div>

					<div class="row mb-3">
						<label class="col-9 col-form-label" for="otherContactPreferredLanguageCode">
							Preferred language
						</label>

						<div class="col-10">
							<ng-select class="custom-ng-select" id="otherContactPreferredLanguageCode"
								name="otherContactPreferredLanguageCode" [items]="languages" [bindLabel]="'name'"
								[bindValue]="'code'" [(ngModel)]="case.otherContactPreferredLanguageCode"
								[clearable]="false" [multiple]="false" [searchable]="false" [loading]="loadingLanguages"
								[loadingText]="'loading languages...'" [placeholder]="'Preferred language'">
							</ng-select>
						</div>
					</div>

					<div class="row mb-3">
						<label class="col-9 col-form-label" for="otherContactPhoneNumber">
							Phone number
						</label>

						<div class="col-8">
							<app-phone-input [id]="'otherContactPhoneNumber'" [name]="'otherContactPhoneNumber'"
								[(ngModel)]="case.otherContactPhoneNumber" [placeholder]="'Phone number'">
							</app-phone-input>
						</div>
					</div>

					<div class="row mb-3">
						<label class="col-9 col-form-label" for="otherContactEmail">
							Email address
						</label>

						<div class="col-15">
							<input type="email" class="form-control" id="otherContactEmail" name="otherContactEmail"
								[(ngModel)]="case.otherContactEmail" placeholder="Email address" autocomplete="off" />
						</div>
					</div>
				</ng-container>
			</div>
		</div>
	</div>
</div>

<ng-template #emptyValue>
	<div class="value-empty">empty</div>
</ng-template>
