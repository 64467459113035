import { Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'app-table-placeholder',
	templateUrl: 'table-placeholder.component.html',
	styleUrls: ['table-placeholder.component.scss']
})
export class TablePlaceholderComponent implements OnInit {
	@Input()
	columnsCount: number = 5;
	@Input()
	rowsCount: number = 3;
	@Input()
	isSmall: boolean = false;

	ngOnInit() {
	}
}
