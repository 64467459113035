import { Injectable } from '@angular/core';

import { NgForm } from '@angular/forms';

import { Observable, Subject } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';

import { ActionConfirmationComponent } from '@app/shared/components';
import { Messages } from '@app/constants';

export interface UnsavedChangesGuardedForm {
	form: NgForm
}

@Injectable({ providedIn: 'root' })
export class UnsavedChangesGuard  {
	constructor(
		private modalService: BsModalService
	) {
	}

	canDeactivate(component: UnsavedChangesGuardedForm): Observable<boolean> {
		let subject = new Subject<boolean>();

		if (component.form && component.form.dirty) {
			this.modalService.show<ActionConfirmationComponent>(ActionConfirmationComponent, {
				class: 'modal-md',
				ignoreBackdropClick: true,
				animated: false,
				initialState: {
					title: Messages.DiscardChangesModalTitle,
					messageHtml: Messages.DiscardChangesModalMessage,
					actionTitle: Messages.DiscardChangesConfirmButtonText,
					actionButtonClass: Messages.DiscardChangesConfirmButtonClass,
					actionFunc: async () => {
						subject.next(true);
						return true;
					},
					cancelTitle: Messages.DiscardChangesCancelButtonText,
					cancelButtonClass: Messages.DiscardChangesCancelButtonClass,
					cancelFunc: async () => {
						subject.next(false);
						return true;
					},
				}
			});
		} else {
			setTimeout(() => {
				subject.next(true);
			}, 0);
		}

		return subject.asObservable();
	}
}
