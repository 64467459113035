import { Component, OnInit, Input, Output, EventEmitter, Optional } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

import { ToastrService } from 'ngx-toastr';

import { CasePolicyPlanGuidance, CasePolicyPlanGuidanceProvider, CasePolicyPlanGuidanceFacility } from '@app/data/cases/models';
import { LookupService } from '@app/settings/services';
import { LookupValue } from '@app/settings/models';
import { Procedure, Specialty } from '@app/settings/models';

import { faXmark, faPlus } from '@fortawesome/pro-solid-svg-icons';

import { Messages } from '@app/constants';

@Component({
	selector: 'app-create-policy-and-plan-guidance',
	templateUrl: 'create-policy-and-plan-guidance.component.html',
	styleUrls: ['create-policy-and-plan-guidance.component.scss'],
	viewProviders: [{
		provide: ControlContainer,
		deps: [[Optional, NgForm]],
		useFactory: (ngForm: NgForm) => ngForm
	}]
})
export class CaseCreatePolicyAndPlanGuidanceComponent implements OnInit {
	faXmark = faXmark;
	faPlus = faPlus;

	showAddProviderForm: boolean = false;
	addProviderModel: CasePolicyPlanGuidanceProvider;

	showAddFacilityForm: boolean = false;
	addFacilityModel: CasePolicyPlanGuidanceFacility;

	@Input()
	case: CasePolicyPlanGuidance;

	@Output()
	caseChange: EventEmitter<CasePolicyPlanGuidance> = new EventEmitter<CasePolicyPlanGuidance>();

	constructor(
		private lookupService: LookupService,
		private toastrService: ToastrService
	) {
	}

	ngOnInit() {
		this.onReloadGenders();
	}

	loadingGenders: boolean = false;
	genders: LookupValue[];
	async onReloadGenders() {
		try {
			this.loadingGenders = true;

			this.genders = await this.lookupService.getLookupValues('Gender');
		} catch (error: any) {
			this.toastrService.error(Messages.ErrorRetry, `Failed to Load Genders`);
		} finally {
			this.loadingGenders = false;
		}
	}

	async onSpecialtiesChange(specialties: Specialty[]) {
		this.case.specialties = specialties.map(specialty => ({
			specialtyCode: specialty.code,
			specialtyName: specialty.name
		}));
	}

	async onShowAddProviderForm() {
		this.addProviderModel = new CasePolicyPlanGuidanceProvider();
		this.showAddProviderForm = true;
	}

	async onHideAddProviderForm() {
		this.showAddProviderForm = false;
	}

	async onAddProvider() {
		if (!this.case.providers) {
			this.case.providers = [];
		}

		this.case.providers.push({
			...this.addProviderModel
		});

		this.onHideAddProviderForm();
	}

	async onRemoveProvider(provider: CasePolicyPlanGuidanceProvider) {
		this.case.providers.splice(this.case.providers.indexOf(provider), 1);
	}

	async onShowAddFacilityForm() {
		this.addFacilityModel = new CasePolicyPlanGuidanceFacility();
		this.showAddFacilityForm = true;
	}

	async onHideAddFacilityForm() {
		this.showAddFacilityForm = false;
	}

	async onAddFacility() {
		if (!this.case.facilities) {
			this.case.facilities = [];
		}

		this.case.facilities.push({
			...this.addFacilityModel
		});

		this.onHideAddFacilityForm();
	}

	async onRemoveFacility(facility: CasePolicyPlanGuidanceFacility) {
		this.case.facilities.splice(this.case.facilities.indexOf(facility), 1);
	}

	async onProcedureChange(procedure: Procedure) {
		this.case.procedures = [{
			procedureCode: procedure.cptCode,
			procedureName: procedure.name
		}];
	}
}
