import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';

import { Observable, Observer, switchMap, map, catchError, of } from 'rxjs';
import { TypeaheadConfig, TypeaheadMatch } from 'ngx-bootstrap/typeahead';

import { ProvidersService } from '@app/settings/services';
import { Procedure } from '@app/settings/models';

export function getTypeaheadConfig(): TypeaheadConfig {
	return Object.assign(new TypeaheadConfig(), { cancelRequestOnFocusLost: true });
}

@Component({
	selector: 'app-procedure-input',
	templateUrl: 'procedure-input.component.html',
	styleUrls: ['procedure-input.component.scss'],
	providers: [{ provide: TypeaheadConfig, useFactory: getTypeaheadConfig }]
})
export class ProcedureInputComponent implements OnInit {
	/* ======================== inputs/outputs ======================== */
	@Input() zipCode: string;
	@Input() placeholder: string = 'search procedures';
	@Input() inputSize: 'sm' | 'lg';

	@Input()
	get procedureName() { return this._procedureName; }
	set procedureName(value: string) {
		if (this._procedureName === value) return;

		if (!value) {
			this.selectedProcedure = null;
		}
		this._procedureName = value;
		this.procedureNameChange.emit(value);
	}
	private _procedureName: string;

	@Output() procedureNameChange = new EventEmitter<string>();
	@Output() procedureChange = new EventEmitter<Procedure>();


	/* ======================== internal state ======================== */
	procedures$: Observable<Procedure[]>;
	proceduresLoading: boolean = false;
	private get selectedProcedure() { return this._selectedProcedure; }
	private set selectedProcedure(value: Procedure) {
		if (this._selectedProcedure === value) return;

		this._selectedProcedure = value;
		this.procedureChange.emit(value);
	}
	private _selectedProcedure: Procedure;

	constructor(
		private providersService: ProvidersService
	) {
	}

	ngOnInit() {
		this.procedures$ = new Observable((observer: Observer<string>) => observer.next(this.procedureName)).pipe(
			switchMap((query: string) => {
				this.selectedProcedure = null;

				if (!query) {
					return of([]);
				}

				return this.providersService.getProcedures$(query, 1).pipe(
					catchError(err => {
						this.proceduresLoading = false;
						return of(null);
					}),
					map((proceduresResponse: PagedDataModel<Procedure>) => proceduresResponse?.pageData || [])
				);
			})
		)
	}

	onProcedureTypeaheadLoading(loading: boolean) {
		this.proceduresLoading = loading;
	}

	onTypeaheadSelect(e: TypeaheadMatch) {
		this.selectedProcedure = e.item;
	}
}
