import { Component, OnInit } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
	templateUrl: 'action-confirmation.component.html',
	styleUrls: ['action-confirmation.component.scss']
})
export class ActionConfirmationComponent implements OnInit {
	// inputs
	title: string = '??????';
	messageHtml: string;

	actionTitle: string = '?????';
	actionButtonClass: string = 'btn-danger';
	actionFunc: () => Promise<boolean>;

	cancelTitle: string = 'Cancel';
	cancelButtonClass: string = 'btn-outline-primary';
	cancelFunc: () => Promise<boolean>;

	// internal state
	inProgress: boolean = false;

	// output variables
	confirmed: boolean = false;

	constructor(
		private modalRef: BsModalRef,
		private toastr: ToastrService
	) {
	}

	ngOnInit() {
	}

	async onConfirm() {
		if (!this.actionFunc) {
			this.confirmed = true;
			this.onClose();
			return;
		}

		try {
			this.inProgress = true;
			const success = await this.actionFunc();
			if (success) {
				this.confirmed = true;
				this.onClose();
			} else {
				this.confirmed = false;
			}
		} catch (error: any) {
			this.confirmed = false;
		} finally {
			this.inProgress = false;
		}
	}

	onClose() {
		if (this.cancelFunc) {
			this.cancelFunc();
		}

		this.modalRef.hide();
	}
}
