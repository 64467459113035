import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';

import { lastValueFrom, catchError, throwError, BehaviorSubject, tap } from 'rxjs';

import { Setting } from '@app/settings/models';

import { environment } from '@app/environment';
import { handleHttpError } from '@app/functions';

const WorkerLabelCode = 'worker_label';

@Injectable({ providedIn: 'root' })
export class SettingsService {
	protected get baseApiUrl() {
		return `${environment.api.host}/api/v1/settings`;
	}

	workerLabel: BehaviorSubject<string> = new BehaviorSubject<string>('Worker');

	constructor(
		private http: HttpClient
	) {
		this.getSetting(WorkerLabelCode);
	}

	async getSettings() {
		return lastValueFrom(
			this.http.get<Setting[]>(`${this.baseApiUrl}`).pipe(
				catchError(handleHttpError)
			)
		);
	}

	async getSetting(settingCode: string) {
		return lastValueFrom(
			this.http.get<Setting>(`${this.baseApiUrl}/${settingCode}`).pipe(
				tap(response => {
					if (response && settingCode == WorkerLabelCode) {
						this.workerLabel.next(response.value);
					}
				}),
				catchError(handleHttpError)
			)
		);
	}

	async createSetting(setting: Setting) {
		return lastValueFrom(
			this.http.post<Setting>(`${this.baseApiUrl}`, setting).pipe(
				catchError(handleHttpError)
			)
		);
	}

	async updateSetting(setting: Setting) {
		return lastValueFrom(
			this.http.put<Setting>(`${this.baseApiUrl}/${setting.code}`, setting).pipe(
				tap(response => {
					if (response && setting.code == WorkerLabelCode) {
						this.workerLabel.next(response.value);
					}
				}),
				catchError(handleHttpError)
			)
		);
	}

	async deleteSetting(setting: Setting) {
		let options = {
			params: new HttpParams({
				fromObject: {
					version: setting.version
				}
			})
		};

		return lastValueFrom(
			this.http.delete<void>(`${this.baseApiUrl}/${setting.code}`, options).pipe(
				catchError(handleHttpError)
			)
		);
	}
}
