export class Setting {
	code: string;
	name: string;
	value: string;
	description: string;
	version: string;

	// UI Only
	loading: boolean = false;
}
