export class UserProfile {
	portalUserID: number;
	auth0UserID: string;
	firstName: string;
	lastName: string;
	initials: string;
	fullName: string;
	shortName: string;
	portalUserImageURL?: string;
	emailAddress: string;
	timeZoneIana?: string;
	clientID: number;
	clientName: string;
	clientLogoIconURL?: string;
	roleCodes: string[];
	permissionCodes: string[];
}
