<div class="modal-header px-4">
	<h5 class="modal-title">
		{{ title }}
	</h5>

	<button type="button" class="btn-close" (click)="onClose()">
		<span class="visually-hidden">&times;</span>
	</button>
</div>

<div class="modal-body px-4 py-3" [innerHTML]="messageHtml" *ngIf="messageHtml">
</div>

<div class="modal-footer bg-white px-4">
	<button type="button" class="btn" [ngClass]="cancelButtonClass" (click)="onClose()" [disabled]="inProgress">
		{{ cancelTitle }}
	</button>

	<button type="submit" class="btn" [ngClass]="actionButtonClass" (click)="onConfirm()" [disabled]="inProgress">
		{{ actionTitle }} <app-spinner-wheel class="ms-1" *ngIf="inProgress"></app-spinner-wheel>
	</button>
</div>
