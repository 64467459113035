import { Component, OnInit, Input, Output, EventEmitter, Optional } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

import { CaseMemberAppSupport } from '@app/data/cases/models';

@Component({
	selector: 'app-create-member-app-support',
	templateUrl: 'create-member-app-support.component.html',
	styleUrls: ['create-member-app-support.component.scss'],
	viewProviders: [{
		provide: ControlContainer,
		deps: [[Optional, NgForm]],
		useFactory: (ngForm: NgForm) => ngForm
	}]
})
export class CaseCreateMemberAppSupportComponent implements OnInit {
	@Input()
	case: CaseMemberAppSupport;

	@Output()
	caseChange: EventEmitter<CaseMemberAppSupport> = new EventEmitter<CaseMemberAppSupport>();

	constructor(
	) {
	}

	ngOnInit() {
	}
}
