import { SecurityService } from "./services/security.service";
import { EmailsService } from "./services/emails.service";
import { LookupService } from "./services/lookup.service";
import { SettingsService } from "./services/settings.service";
import { DataService } from "./services/data.service";
import { ProvidersService } from "./services/providers.service";

export { SecurityService } from "./services/security.service";
export { EmailsService } from "./services/emails.service";
export { LookupService } from "./services/lookup.service";
export { SettingsService } from "./services/settings.service";
export { DataService } from "./services/data.service";
export { ProvidersService } from "./services/providers.service";

export const SERVICES: any[] = [
	SecurityService,
	EmailsService,
	LookupService,
	SettingsService,
	DataService,
	ProvidersService
];
