// TODO: DELETE THIS - USE ACTIONCONFIRMATION
import { Component } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
	templateUrl: 'confirm.component.html',
	styleUrls: ['confirm.component.scss']
})
export class ConfirmComponent {
	title: string = 'Confirm';
	message: string = 'Are you sure you want to proceed?';

	confirmButtonText: string = 'OK';
	confirmButtonClass: string = 'btn-primary';

	cancelButtonText: string = 'Cancel';
	cancelButtonClass: string = 'btn-outline-primary';

	confirmed: boolean = false;

	constructor(
		private confirmModalRef: BsModalRef
	) {
	}

	onConfirm() {
		this.confirmed = true;
		this.confirmModalRef.hide();
	}

	onClose() {
		this.confirmed = false;
		this.confirmModalRef.hide();
	}
}
