import {
	CaseDashboard,
	// CaseList,
	Case,
	CaseBillNegociation,
	CaseBill,
	BillNegotiationProperty,
	ContactEditModel,
	CasePreNegotiation,
	CaseLowHighAmounts,
	CasePreNegotiationAmounts,
	CasePreNegotiationChange,
	CaseItemizedStatements,
	CaseItemizedStatementsProvider,
	CaseFacilityProvider,
	CaseProviderResolutionOption,
	CaseProviderResolutionOptionFee,
	CasePrescriptionResolutionOption,
	CasePrescription,
	CaseScheduling,
	CaseTransportation,
	CaseTransportationResolutionOption,
	CasePolicyPlanGuidance,
	CasePolicyPlanGuidanceProvider,
	CasePolicyPlanGuidanceFacility,
	CasePolicyPlanGuidanceProcedure,
	CaseChaplaincy,
	CaseMemberAppSupport,
	CaseGeneralSupport,
	CasePaymentViewModel
} from './models/case.models';

export {
	CaseDashboard,
	// CaseList,
	Case,
	CaseBillNegociation,
	CaseBill,
	BillNegotiationProperty,
	ContactEditModel,
	CasePreNegotiation,
	CaseLowHighAmounts,
	CasePreNegotiationAmounts,
	CasePreNegotiationChange,
	CaseItemizedStatements,
	CaseItemizedStatementsProvider,
	CaseFacilityProvider,
	CaseProviderResolutionOption,
	CaseProviderResolutionOptionFee,
	CasePrescriptionResolutionOption,
	CasePrescription,
	CaseScheduling,
	CaseTransportation,
	CaseTransportationResolutionOption,
	CasePolicyPlanGuidance,
	CasePolicyPlanGuidanceProvider,
	CasePolicyPlanGuidanceFacility,
	CasePolicyPlanGuidanceProcedure,
	CaseChaplaincy,
	CaseMemberAppSupport,
	CaseGeneralSupport,
	CasePaymentViewModel
} from './models/case.models';

export type CaseTypes = (
	Case |
	CaseBillNegociation |
	CasePreNegotiation |
	CaseItemizedStatements |
	CaseFacilityProvider |
	CasePrescription |
	CaseScheduling |
	CaseTransportation |
	CasePolicyPlanGuidance |
	CaseChaplaincy |
	CaseMemberAppSupport
);

export const MODELS: any[] = [
	CaseDashboard,
	// CaseList,
	Case,
	CaseBillNegociation,
	CaseBill,
	BillNegotiationProperty,
	ContactEditModel,
	CasePreNegotiation,
	CaseLowHighAmounts,
	CasePreNegotiationAmounts,
	CasePreNegotiationChange,
	CaseItemizedStatements,
	CaseItemizedStatementsProvider,
	CaseFacilityProvider,
	CaseProviderResolutionOption,
	CaseProviderResolutionOptionFee,
	CasePrescriptionResolutionOption,
	CasePrescription,
	CaseScheduling,
	CaseTransportation,
	CaseTransportationResolutionOption,
	CasePolicyPlanGuidance,
	CasePolicyPlanGuidanceProvider,
	CasePolicyPlanGuidanceFacility,
	CasePolicyPlanGuidanceProcedure,
	CaseChaplaincy,
	CaseMemberAppSupport,
	CaseGeneralSupport,
	CasePaymentViewModel
];
