import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-details-placeholder',
	templateUrl: 'details-placeholder.component.html',
	styleUrls: ['details-placeholder.component.scss']
})
export class DetailsPlaceholderComponent {
	@Input() showBreadcrumbs: boolean = true;
	@Input() showTitle: boolean = true;
	@Input() showStatusBar: boolean = true;
}
