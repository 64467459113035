import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';

import { Observable, Subscription, tap, catchError, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { SettingsService, LookupService } from '@app/shared/services';
import { SystemSetting, PortalUserLookup } from '@app/shared/models';

import { Messages, SystemSettingCodes, LookupCodes } from '@app/constants';

import { faXmark } from '@fortawesome/pro-regular-svg-icons';

@Component({
	selector: 'app-worker-select',
	templateUrl: 'worker-select.component.html',
	styleUrls: ['worker-select.component.scss'],
	encapsulation: ViewEncapsulation.None,
	exportAs: 'workerSelect'
})
export class WorkerSelectComponent implements OnInit, OnDestroy {
	closeIcon = faXmark;

	static AllWorkersOption = {
		portalUserID: null,
		fullName: 'All',
		firstName: 'Everyone',
		initials: 'E',
	} as PortalUserLookup;
	allWorkersOption = WorkerSelectComponent.AllWorkersOption;

	/* ============================== inputs/outputs ============================== */
	@Input() id: string;
	@Input() name: string;
	@Input() placeholder: string = '';
	@Input() showAllOption: boolean = false;
	@Input() closeable: boolean = true;
	@Input() clearable: boolean = false;
	@Input() workerID: number;
	@Output() workerIDChange: EventEmitter<number> = new EventEmitter<number>();

	@Output() change: EventEmitter<PortalUserLookup> = new EventEmitter<PortalUserLookup>();

	@Output() close: EventEmitter<void> = new EventEmitter<void>();

	/* ============================== internal state ============================== */
	selectedWorker: PortalUserLookup;

	/* ============================== lookups ============================== */
	workerLabelSetting$: Observable<SystemSetting>;

	loadingWorkersLookup: boolean = true;
	workersLookup: PortalUserLookup[];
	workersLookupSubscription: Subscription;

	constructor(
		private lookupService: LookupService,
		private settingsService: SettingsService,
		private toastrService: ToastrService
	) {
	}

	ngOnInit() {
		this.workerLabelSetting$ = this.settingsService.getSystemSetting(SystemSettingCodes.WorkerLabel);

		this.workersLookupSubscription = this.lookupService.getLookup<PortalUserLookup>(LookupCodes.PortalUsers)
			.pipe(
				tap(() => setTimeout(() => this.loadingWorkersLookup = false, 0)),
				catchError(err => {
					setTimeout(() => this.loadingWorkersLookup = false, 0);
					return this.showLookupErrorToast('Workers', err);
				}))
			.subscribe(workers => {
				this.workersLookup = workers;
				this.selectedWorker = workers.find(w => w.portalUserID === this.workerID);
				if (!this.selectedWorker && this.showAllOption) {
					this.selectedWorker = WorkerSelectComponent.AllWorkersOption;
				}
			});
	}

	ngOnDestroy() {
		this.workersLookupSubscription?.unsubscribe();
	}

	// workerTrackFunc(worker: PortalUserLookup) {
	// 	return worker.portalUserID === null ? -1 : worker.portalUserID;
	// }

	onWorkerChange(worker: PortalUserLookup) {
		this.workerIDChange.emit(worker.portalUserID);
		this.change.emit(worker);
		// this.modelChange.emit(worker);
	}

	onClose() {
		this.close.emit();
	}

	private showLookupErrorToast(lookupName: string, error: any) {
		this.toastrService.error(Messages.ErrorRetry, `Failed to Load '${lookupName}'`);
		return throwError(() => error);
	}
}
