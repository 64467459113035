import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-client-plan-value',
	templateUrl: 'client-plan-value.component.html',
	styleUrls: ['client-plan-value.component.scss']
})
export class ClientPlanValueComponent {
	@Input() class: string;
	@Input() size: number = 20;
	@Input() clientName: string;
	@Input() planName: string;
	@Input() clientLogoSrc: string;
	@Input() showPlan = true;
}
