import { MemberDashboard, Member, MemberPlan, MemberDependent, MemberCase, CreateDependentModel } from './models/member.models';

export { MemberDashboard, Member, MemberPlan, MemberDependent, MemberCase, CreateDependentModel } from './models/member.models';

export const MODELS: any[] = [
	MemberDashboard,
	Member,
	MemberPlan,
	MemberDependent,
	MemberCase,

	CreateDependentModel
];
