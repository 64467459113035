<div class="form-check form-switch" [ngClass]="'form-switch-' + size" [class.saving]="saving"
	[class.checked]="innerValue">
	<div class="form-check-spinner">
		<fa-icon [icon]="faSpinnerThird" [spin]="true"></fa-icon>
	</div>

	<input [id]="id" [name]="name" class="form-check-input" type="checkbox" role="switch" [(ngModel)]="innerValue"
		(change)="onToggle()" [disabled]="disabled || readonly || saving" />
	<label class="form-check-label" [for]="id">
		<ng-content></ng-content>
	</label>
</div>
