export class Activity {
	clientActivityID: number;
	activityDate: Date;
	portalUserID: number;
	portalUserName: string;
	portalUserImageURL: string;
	activityTypeCode: string;
	activityTypeName: string;
	activityEntityCode: string;
	activityEntityName: string;
	content: string;
	isVisibleToClient: boolean;
	isImportant: boolean;

	// UI only
	showMoreContent?: boolean = false;
	hasTrimedContent?: boolean = false;
	trimedContent?: string;
}
