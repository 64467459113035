<div class="card h-100">
	<div class="row g-0 h-100">
		<div class="col-md-7 h-100 card-cover">
			<h5 class="mb-0">Device Details</h5>
		</div>

		<div class="col-md-17 h-100 px-3">
			<div class="card-body">
				<div class="row">
					<label class="col-9 col-form-label" for="details">
						Details
					</label>

					<div class="col-15">
						<textarea class="form-control"
								  id="details"
								  name="details"
								  [(ngModel)]="case.details"
								  placeholder="Details"
								  rows="4"></textarea>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
