import { HttpParams, HttpErrorResponse } from '@angular/common/http';

import { throwError } from 'rxjs';

import * as Sentry from "@sentry/angular-ivy";

/**
 * Removes null/undefined params from HttpParams object.
 * @param params HttpParams object containing query parameters.
 * @returns Cleaned HttpParams object.
 */
export function removeEmptyParams(params: HttpParams) {
	return params.keys().reduce((queryParams: { [key: string]: any }, key) => {
		let value: any = params.get(key);

		if (!!value && value != 'undefined' && value != 'null') {
			queryParams[key] = value;
		}

		return queryParams;
	}, {});
}

/**
 * Replaces all empty string fields with nulls in the specified object.
 * @param obj Object to remove null empty string fields in.
 */
export function nullEmptyStringFields(obj: any) {
	Object.keys(obj).forEach(key => {
		if (obj[key] === '') {
			obj[key] = null;
		}
	});
}

/**
 * Handles HTTP errors, logs to Sentry, and returns the error observable.
 * @param error
 * @returns Error observable.
 */
export function handleHttpError(error: HttpErrorResponse) {
	// extended error class
	class CustomHttpError extends Error {
		public status: number;
		public statusText: string;
		public url: string | null;

		constructor(httpError: HttpErrorResponse) {
			super(httpError.message);

			this.name = 'CustomHttpError';
			this.status = httpError.status;
			this.statusText = httpError.statusText;
			this.url = httpError.url;

			// Conditionally apply captureStackTrace for environments that support it
			if ('captureStackTrace' in Error) {
				(Error as any).captureStackTrace(this, CustomHttpError);
			}
		}
	}

	const customError = new CustomHttpError(error);

	console.error(customError);
	Sentry.captureException(customError);

	return throwError(() => error);
}

/**
 * Sorts specified array by specified field name in the specified direction,
 * taking into account field data type (string, number, date).
 * @param arr Array to sort.
 * @param fieldName Field name to sort by.
 * @param direction Sort direction.
 * @returns Sorted array.
 */
export function sortArrayByField<T>(arr: T[], fieldName: string, direction: 'asc' | 'desc') {
	return arr.sort((a, b) => {
		const aVal = direction === 'asc' ? (<any>a)[fieldName] : (<any>b)[fieldName];
		const bVal = direction === 'asc' ? (<any>b)[fieldName] : (<any>a)[fieldName];

		if (typeof (aVal) === 'string') {
			return aVal.localeCompare(bVal);
		} else if (aVal instanceof Date) {
			return aVal.getTime() - bVal.getTime();
		} else {
			// number or anything else
			return aVal - bVal;
		}
	});
}

/**
 * Determines if specified error is a validation error.
 * @param err Error to check.
 * @returns True if specified error is a validation error, false otherwise.
 */
export function isValidationError(err: any) {
	return err?.status === 400 && err?.error?.validationErrors
}

/**
 * Gets array of formatted validation error strings from the specified error.
 * @param err Error to get validation errors from.
 * @returns Array of formatted validation error strings.
 */
export function getValidationErrors(err: any) {
	if (!isValidationError(err)) {
		return null;
	}

	const errors = err.error.validationErrors;
	const validationErrors = Object.keys(errors).reduce((validations: string[], current: string) => {
		return [...validations, ...errors[current]];
	}, []);
	return validationErrors;
}

/**
 * Formats specified Date object as a YYYY-MM-DD string.
 * @param date Date to format.
 * @returns Formatted date string.
 */
export function getFormattedYYYYMMDDdate(date: Date | null) {
	return date ? `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}` : null;
}

/**
 * Scrolls to the element with the specified ID.
 * @param elementId ID of the element to scroll to.
 */
export function scrollToElementById(elementId: string) {
	setTimeout(() => {
		const element = document.getElementById(elementId);
		if (element) {
			element.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}
	}, 0);
}
