import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation, ChangeDetectorRef, Optional } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';

import { Subject, debounceTime } from 'rxjs';

import { ProvidersService, LookupService } from '@app/settings/services';
import { Provider, State } from '@app/settings/models';

@Component({
	selector: 'app-provider-facility-select',
	templateUrl: 'provider-facility-select.component.html',
	styleUrls: ['provider-facility-select.component.scss'],
	encapsulation: ViewEncapsulation.None,
	exportAs: 'providerFacilitySelect',
	viewProviders: [{
		provide: ControlContainer,
		deps: [[Optional, NgForm]],
		useFactory: (ngForm: NgForm) => ngForm
	}]
})
export class ProviderFacilitySelectComponent implements OnInit {
	providerQuery: string = '';
	providerQueryChange = new Subject<string>();

	facilityQuery: string = '';
	facilityQueryChange = new Subject<string>();

	@Input()
	showProviderSelect: boolean = true;

	@Input()
	showFacilitySelect: boolean = true;

	@Input()
	searchNearZipCode: string;

	@Input()
	providerNPI: number;

	@Output()
	providerNPIChange: EventEmitter<number> = new EventEmitter<number>();

	@Input()
	providerName: string;

	@Output()
	providerNameChange: EventEmitter<string> = new EventEmitter<string>();

	@Input()
	facilityNPI: number;

	@Output()
	facilityNPIChange: EventEmitter<number> = new EventEmitter<number>();

	@Input()
	facilityName: string;

	@Output()
	facilityNameChange: EventEmitter<string> = new EventEmitter<string>();

	@Input()
	address1: string;

	@Output()
	address1Change: EventEmitter<string> = new EventEmitter<string>();

	@Input()
	address2: string;

	@Output()
	address2Change: EventEmitter<string> = new EventEmitter<string>();

	@Input()
	city: string;

	@Output()
	cityChange: EventEmitter<string> = new EventEmitter<string>();

	@Input()
	stateCode: string;

	@Output()
	stateCodeChange: EventEmitter<string> = new EventEmitter<string>();

	@Input()
	zipCode: string;

	@Output()
	zipCodeChange: EventEmitter<string> = new EventEmitter<string>();

	@Input()
	phoneNumber: string;

	@Output()
	phoneNumberChange: EventEmitter<string> = new EventEmitter<string>();

	@Input()
	faxNumber: string;

	@Output()
	faxNumberChange: EventEmitter<string> = new EventEmitter<string>();

	@Input()
	emailAddress: string;

	@Output()
	emailAddressChange: EventEmitter<string> = new EventEmitter<string>();

	@Output()
	change: EventEmitter<Provider> = new EventEmitter<Provider>();

	constructor(
		private providersService: ProvidersService,
		private lookupService: LookupService,
		private cdr: ChangeDetectorRef
	) {
	}

	async ngOnInit() {
		//this.providerQueryChange.pipe(
		//	debounceTime(500)
		//).subscribe(query => {
		//	this.providerQuery = query;
		//	this.onReloadProviders();
		//});

		//this.facilityQueryChange.pipe(
		//	debounceTime(500)
		//).subscribe(query => {
		//	this.facilityQuery = query;
		//	this.onReloadFacilities();
		//});

		this.onReloadStates();

		//if (this.showProviderSelect) {
		//	this.onReloadProviders();
		//}

		//if (this.showFacilitySelect) {
		//	this.onReloadFacilities();
		//}
	}

	loadingStates: boolean = false;
	states: State[];
	async onReloadStates() {
		try {
			this.loadingStates = true;

			this.states = await this.lookupService.getStates();
		} catch (error: any) {
			console.log(error);
		} finally {
			this.loadingStates = false;
		}
	}

	//loadingProviders: boolean = false;
	//providers: Provider[] = [];
	//async onReloadProviders() {
	//	if (!this.providerZipCode) {
	//		return;
	//	}

	//	try {
	//		this.loadingProviders = true;

	//		let response = await this.providersService.getProviders(this.providerQuery, 'Individual', this.providerZipCode, 1);

	//		this.providers = response.pageData;

	//		//if (this.providerID && !this.selectedProvider) {
	//		//	this.selectedProvider = this.providers.find(m => m.providerID == this.providerID);
	//		//}

	//		this.cdr.detectChanges();
	//	} catch (error: any) {
	//		console.log(error);
	//	} finally {
	//		this.loadingProviders = false;
	//	}
	//}

	selectedProvider: Provider;
	async onProviderChange(provider: Provider) {
		this.selectedProvider = provider;

		this.providerNPI = provider?.npi || null;
		this.providerNPIChange.emit(this.providerNPI);

		if (!this.selectedFacility) {
			this.address1 = provider?.address1 || this.address1 || null;
			this.address1Change.emit(this.address1);

			this.address2 = provider?.address2 || this.address2 || null;
			this.address2Change.emit(this.address2);

			this.city = provider?.city || this.city || null;
			this.cityChange.emit(this.city);

			this.stateCode = provider?.stateCode || this.stateCode || null;
			this.stateCodeChange.emit(this.stateCode);

			this.zipCode = provider?.zipCode || this.zipCode || null;
			this.zipCodeChange.emit(this.zipCode);

			this.phoneNumber = provider?.phoneNumber || this.phoneNumber || null;
			this.phoneNumberChange.emit(this.phoneNumber);

			this.faxNumber = provider?.faxNumber || this.faxNumber || null;
			this.faxNumberChange.emit(this.faxNumber);

			this.emailAddress = provider?.emailAddress || this.emailAddress || null;
			this.emailAddressChange.emit(this.emailAddress);
		}
	}

	//loadingFacilities: boolean = false;
	//facilities: Provider[] = [];
	//async onReloadFacilities() {
	//	if (!this.providerZipCode) {
	//		return;
	//	}

	//	try {
	//		this.loadingFacilities = true;

	//		let response = await this.providersService.getProviders(this.facilityQuery, 'Organization', this.providerZipCode, 1);

	//		this.facilities = response.pageData;

	//		//if (this.providerID && !this.selectedProvider) {
	//		//	this.selectedProvider = this.providers.find(m => m.providerID == this.providerID);
	//		//}

	//		this.cdr.detectChanges();
	//	} catch (error: any) {
	//		console.log(error);
	//	} finally {
	//		this.loadingFacilities = false;
	//	}
	//}

	selectedFacility: Provider;
	async onFacilityChange(facility: Provider) {
		this.selectedFacility = facility;

		this.facilityNPI = facility?.npi || null;
		this.facilityNPIChange.emit(this.facilityNPI);

		this.address1 = facility?.address1 || this.address1 || null;
		this.address1Change.emit(this.address1);

		this.address2 = facility?.address2 || this.address2 || null;
		this.address2Change.emit(this.address2);

		this.city = facility?.city || this.city || null;
		this.cityChange.emit(this.city);

		this.stateCode = facility?.stateCode || this.stateCode || null;
		this.stateCodeChange.emit(this.stateCode);

		this.zipCode = facility?.zipCode || this.zipCode || null;
		this.zipCodeChange.emit(this.zipCode);

		this.phoneNumber = facility?.phoneNumber || this.phoneNumber || null;
		this.phoneNumberChange.emit(this.phoneNumber);

		this.faxNumber = facility?.faxNumber || this.faxNumber || null;
		this.faxNumberChange.emit(this.faxNumber);

		this.emailAddress = facility?.emailAddress || this.emailAddress || null;
		this.emailAddressChange.emit(this.emailAddress);
	}

	onAddNewOption(term: string) {
		return <Provider>{
			fullName: term,
			organizationName: term
		}
	}
}
