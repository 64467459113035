import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';

import { ToastrService } from 'ngx-toastr';

import { ProvidersService } from '@app/settings/services';
import { Specialty } from '@app/settings/models';

import { Messages } from '@app/constants';

import { faCheck, faXmark } from '@fortawesome/pro-regular-svg-icons';

@Component({
	selector: 'app-specialties-select',
	templateUrl: 'specialties-select.component.html',
	styleUrls: ['specialties-select.component.scss'],
	encapsulation: ViewEncapsulation.None,
	exportAs: 'specialtiesSelect'
})
export class SpecialtiesSelectComponent implements OnInit {
	faCheck = faCheck;
	faXmark = faXmark;

	loadingSpecialties: boolean = false;
	specialities: Specialty[] = [];
	selectedSpecialties: Specialty[];

	@Input()
	id: string;

	@Input()
	name: string;

	@Input()
	placeholder: string = 'Specialties';

	@Input()
	closeable: boolean = true;

	@Input()
	specialtyCodes: string[] = [];

	@Input() readonly: boolean = false;

	@Output()
	change: EventEmitter<Specialty[]> = new EventEmitter<Specialty[]>();

	@Output()
	close: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private providersService: ProvidersService,
		private toastrService: ToastrService
	) {
	}

	async ngOnInit() {
		this.onReloadSpecialties();
	}

	async onReloadSpecialties() {
		try {
			this.loadingSpecialties = true;

			this.specialities = await this.providersService.getSpecialties('');

			if (this.specialtyCodes && !this.selectedSpecialties) {
				this.selectedSpecialties = this.specialities.filter(m => this.specialtyCodes.includes(m.code));
			}
		} catch (error: any) {
			console.log(error);
			this.toastrService.error(Messages.ErrorRetry, `Failed to Load Specialities`);
		} finally {
			this.loadingSpecialties = false;
		}
	}

	async onSpecialtiesChange(specialties: Specialty[]) {
		if (this.closeable) {
			return;
		}

		this.change.emit(specialties);
	}

	async onSave() {
		this.change.emit(this.selectedSpecialties);
	}

	async onClose() {
		this.close.emit();
	}
}
