import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';

import { BehaviorSubject, Observable, Subscription, interval, switchMap, lastValueFrom, catchError, throwError } from 'rxjs';
import * as Sentry from "@sentry/angular-ivy";

import { SystemSetting } from '@app/shared/models';
import { environment } from '@app/environment';
import { handleHttpError } from '@app/functions';

@Injectable({ providedIn: 'root' })
export class SettingsService implements OnDestroy {
	private get baseApiUrl() {
		return `${environment.api.host}/api/v1/settings`;
	}

	// map of behavior subjects for each system setting
	private settingSubjects: { [key: string]: BehaviorSubject<SystemSetting> } = {};
	private settingsLoaded: boolean;

	private intervalSubscription: Subscription;

	constructor(
		private http: HttpClient
	) {
	}

	ngOnDestroy() {
		this.intervalSubscription?.unsubscribe();
	}

	/**
	 * Retrieves the value of the specified system setting.
	 * @param systemSettingCode Code for the system setting to retrieve (use SystemSettingCodes constants from \@app/constants - don't hardcode strings).
	 */
	public getSystemSetting(systemSettingCode: string) {
		if (!this.settingSubjects[systemSettingCode]) {
			this.settingSubjects[systemSettingCode] = new BehaviorSubject<SystemSetting>(null);
		}
		this.loadSystemSettings(systemSettingCode);
		return this.settingSubjects[systemSettingCode].asObservable();
	}

	public reloadAllSystemSettings() {
		this.fetchSystemSettings().subscribe(data => {
			console.log(`SettingsService.reloadAllSystemSettings`);
			this.updateSystemSettings(data);
		});
	}

	private loadSystemSettings(systemSettingCode: string) {
		if (!this.settingsLoaded) {
			this.fetchSystemSettings().subscribe(data => {
				console.log(`SettingsService.loadSystemSettings`);
				this.updateSystemSettings(data);
				this.settingsLoaded = true;
			});

			// start reloading the system settings every 5 minutes after the first request
			this.intervalSubscription = interval(1 * 60 * 1000)
				.pipe(switchMap(() => this.fetchSystemSettings()))
				.subscribe(data => {
					console.log('SettingsService.userProfileReloader');
					this.updateSystemSettings(data);
				});
		}
	}

	private updateSystemSettings(data: SystemSetting[]) {
		data.forEach(setting => {
			if (!this.settingSubjects[setting.code]) {
				this.settingSubjects[setting.code] = new BehaviorSubject<SystemSetting>(null);
			}
			if (this.settingSubjects[setting.code].value?.value !== setting.value) {
				this.settingSubjects[setting.code].next(setting);
			}
		});
	}

	private fetchSystemSettings() {
		return this.http.get<SystemSetting[]>(`${this.baseApiUrl}`)
			.pipe(catchError(handleHttpError));
	}
}
