export class MemberDashboard {
	clientMemberID: number;
	clientID: number;
	memberCode: string;
	externalID: string | null;
	clientName: string;
	clientLogoURL: string;
	memberName: string;
	primaryRelationship: string;
	genderName: string;
	dateOfBirth: string;
	city: string;
	stateCode: string;
	zipCode: string;
	phoneNumber: string;
	mobileNumber: string;
	emailAddress: string;
	hasGenericAuth: boolean;
	otherAuthCount: number;
	openCaseCount: number;
	closedCaseCount: number;
	linkedMemberCount: number;
	plans: MemberPlan[];

	// UI Only
	linkedMembersLoading: boolean = false;
	showLinkedMembers: boolean = false;
	linkedMembers: MemberDashboard[] = [];
	activePlans: MemberPlan[] = [];
	inactivePlans: MemberPlan[] = [];
}

export class Member {
	clientMemberID: number;
	memberCode: string;
	externalID: string;
	clientID: number;
	clientName: string;
	clientLogoURL: string;

	// Demographics
	firstName: string;
	lastName: string;
	fullName: string;
	dateOfBirth: string;
	genderCode: string;
	genderName: string;
	maritalStatusCode: string;
	maritalStatusName: string;
	preferredLanguageCode: string;
	preferredLanguageName: string;
	lastFourSSN: string;

	// Contact Information
	address1: string;
	address2: string;
	city: string;
	stateCode: string;
	stateName: string;
	zipCode: string;
	phoneNumber: string;
	mobileNumber: string;
	emailAddress: string;
	isDeceased: boolean;

	// other services
	includesCoverdell: boolean;
	coverdellMemberID: string | null;

	// TODO: REMOVE - THESE ARE NOT PART OF THE DETAILS MODEL
	// Plan Information
	clientPlanID: number;
	externalMemberCode: string;
	effectiveDate: string;
	coverageTypeCode: string;
	groupName: string;
	groupID: string;
	copay: number;
	primaryClientMemberPlanID: number;
	dependentRelationshipTypeCode: string;
	dependentRelationshipTypeName: string;
	primaryClientMemberID: number;
	primaryClientMemberName: string;

	version: string;
}

export class MemberPlan {
	clientMemberPlanID: number;
	planID: number;
	planName: string;
	externalMemberCode: string;
	isActive: boolean;
	effectiveDate: string;
	terminationDate: string;
	clientID: number;
	clientName: string;
	clientLogoUrl: string;
	coverageTypeCode: string;
	coverageTypeName: string;
	groupName: string;
	groupID: string;
	copay: number;
	primaryClientMemberID: number;
	primaryMemberName: string;
	primaryRelationshipCode: string;
	primaryRelationshipName: string;
	canManuallyDeactivateMembers: boolean;
	canManuallyAddDependents: boolean;
	version: string;

	// UI Only
	editing: boolean;
	deleting: boolean;
}

export class MemberDependent {
	clientMemberID: number;
	memberCode: string;
	externalID: number;

	fullName: string;
	relationships: string;
	dateOfBirth: string;
	clientName: string;
	clientLogoURL: string;
	planNames: string[];
	phoneNumber: string;
	mobileNumber: string;
	emailAddress: string;
	version: string;

	// UI Only
	editing: boolean;
	deleting: boolean;
}

export class MemberCase {
	caseID: number;
	externalID: number;
	caseCode: string;
	isOpen: boolean;
	caseStatusCode: string;
	caseStatusName: string;
	caseTypeCode: string;
	caseTypeName: string;
	subject: string;
	description: string;
	clientID: number;
	clientName: string;
	clientLogoURL: string;
	clientPlanID: number;
	planName: string;
	assignedToPortalUserID: string;
	assignedToName: string;
	dateOpened: string;
	dateClosed: string;
	ageDays: number;
	lastActivityDate: string;
}

export class CreateDependentModel {
	clientMemberPlanID: number;
	planID: number;
	relationshipTypeCode: string;
}
